import { UriBuilder } from '../util/index';
import { RestPaginator } from '../restpaginator';
import { ChannelDescriptor } from '../channeldescriptor';
import { Network } from '../services/network';
import { Client } from '../client';

export interface PublicChannelServices {
  network: Network;
}

/**
 * Public channels collection
 * It's a cassandra-backed pull-based collection
 */
class PublicChannels {
  private client: Client;
  private services: PublicChannelServices;
  private url: string;

  constructor(client: Client, services: PublicChannelServices, url: string) {
    this.client = client;
    this.services = services;
    this.url = url;
  }

  public async getChannels(args: any = {}): Promise<RestPaginator<ChannelDescriptor>> {
    const url = new UriBuilder(this.url).arg('PageToken', args.pageToken).build();
    let response = await this.services.network.get(url);
    return new RestPaginator<ChannelDescriptor>(response.body.conversations.map(x => new ChannelDescriptor(this.client, x))
      , pageToken => this.getChannels({ pageToken })
      , response.body.meta.previous_token
      , response.body.meta.next_token);
  }

  public async getChannelBySid(sid: string): Promise<ChannelDescriptor> {
    const url = new UriBuilder(this.url).path(sid).build();
    let response = await this.services.network.get(url);
    return new ChannelDescriptor(this.client, response.body);
  }

  public async getChannelByUniqueName(uniqueName: string): Promise<ChannelDescriptor> {
    const url = new UriBuilder(this.url).path(uniqueName).build();
    let response = await this.services.network.get(url);
    return new ChannelDescriptor(this.client, response.body);
  }
}

export { PublicChannels };
