import {ADD_ITEM, UPDATE_ITEM} from '../constants/ActionTypes';


const INIT_STATE = {
    items: [],
};


export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case ADD_ITEM: {
            return{
                ...state,
                items: action.item
            }
        }
        case UPDATE_ITEM: {
            return{
                ...state,
                items: [...state.items, action.item]
            }
        }
        default:
            return state;
    }
}