    import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
const Menu = ({match}) => (

    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/menus`}/>
            <Route path={`${match.url}/menus`} component={asyncComponent(() => import('./routes/menuList/index'))}/>
            <Route path={`${match.url}/menu-detail`} component={asyncComponent(() => import('./routes/menuDetail/index'))}/>
            <Route path={`${match.url}/section`} component={asyncComponent(() => import('./routes/Section/index'))}/>
            <Route path={`${match.url}/localize`} component={asyncComponent(() => import('./routes/localize/index'))}/>
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Menu;