import {ADD_CAMPAIGN, DELETE_CAMPAIGN, EDIT_CAMPAIGN, UPDATE_CAMPAIGN,} from "./../constants/ActionTypes";

export const addCampaign=(campaign)=>{
    return{
        type:ADD_CAMPAIGN,
        campaign:campaign,
    }
};

export const editCampaign = (id, campaign) => {
    return {
        type: EDIT_CAMPAIGN,
        id: id,
        campaign: campaign
    }
};

export const updateCampaign = (campaign) => {
    return {
        type: UPDATE_CAMPAIGN,
        campaign: campaign
    }
};

export const deleteCampaign = (id) => {
    return {
        type: DELETE_CAMPAIGN,
        id: id
    }
};