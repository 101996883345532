import React from 'react';
import {connect} from 'react-redux';
import TextField from '@material-ui/core/TextField';
import IconButton from '@material-ui/core/IconButton';
import Button from '@material-ui/core/Button';
import {NotificationContainer, NotificationManager} from 'react-notifications';
import CircularProgress from '@material-ui/core/CircularProgress';
import {Link} from 'react-router-dom';
import IntlMessages from 'util/IntlMessages';
import {
    showAuthLoader,

} from 'actions/Auth';
import {performRequest} from './services/apiHandler';
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";

const style = {
    padding: 0
};

class SignUp extends React.Component {
    constructor() {
        super();
        this.state = {
            name: '',
            first_name: '',
            last_name: '',
            email: '',
            password: '',
            warningEmail: '',
            warningPassword: '',
            warningFullName: ''
        }
    }

    /** 06/08/2019
     * sign up button click
     * @param event
     */
    handleButton = (event) => {
        event.preventDefault();
        /** validate name before send request
         * name is in 2nd position in UI. so we have to check email(top content).
         */
        if (!this.state.email) {
            this.setState({warningEmail: "This field can not be empty."})
        } else if (this.state.name.length === 0 || this.state.name.indexOf(' ') === -1) {
            this.setState({
                warningFullName: true,
                warningEmail: ''
            })
        } else {
            this.setState({
                warningEmail: '',
                warningPassword: '',
            });
            let params = {
                "email": this.state.email.toLowerCase(),
                "password": this.state.password,
                "first_name": this.state.name.split(' ', 1).join(' '),
                "last_name": this.state.name.split(' ').slice(1).join(' '),
                "venue_owner":true
            };

            if (!this.state.warningFullName) {
                performRequest('post', 'users/', params, null, false)
                    .then(response => {
                        this.setState({warningFullName: false})
                        localStorage.removeItem('currentVenue');
                        localStorage.setItem('accessToken', response.data.token);
                        this.props.history.push('/signin');
                    }).catch(error => {
                    if (error.response.data.email) {
                        this.setState({
                            warningEmail: error.response.data.email
                        });
                    }
                    if (error.response.data.password) {
                        this.setState({
                            warningPassword: error.response.data.password
                        });
                    }
                })
            }
        }
    };

    getChanges = (e) => {
        let names = [];
        if (e.target.name === 'name') {
            try {
                names = this.state.name.split(" ");
            } catch (e) {
                console.log(e, 'error');
            }
            if (names.length <= 1) {
                this.setState({warningFullName: true})
            } else {
                this.setState({warningFullName: false})
            }
        }
        this.setState({[e.target.name]: e.target.value})
    };

    loadTermsAndConditions = () =>{
        const url = 'https://www.mydigimenu.com/privacy-policy';
        window.open(url, "_blank");
    };

    render() {
        const {
            name,
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">
                    <div className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        {/*<Link className="logo-lg" to="/" title="MyMenu">*/}
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        {/*</Link>*/}
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header">
                            <h1><IntlMessages id="appModule.signup"/></h1>
                        </div>

                        <div className="mb-4">
                            <h2><IntlMessages id="signIn.signUp"/></h2>
                        </div>

                        <div className="app-login-form">
                            <form onSubmit={this.handleButton}>

                                <TextField
                                    type="email"
                                    name={'email'}
                                    onChange={(e) => this.getChanges(e)}
                                    label={<IntlMessages id="common.email"/>}
                                    fullWidth
                                    defaultValue={email}
                                    margin="normal"
                                    className="mt-0 mb-2"
                                />
                                <p style={{color: 'red'}}>{this.state.warningEmail && 'Sorry... User already exists'}</p>
                                <TextField
                                    type="text"
                                    onChange={(e) => this.getChanges(e)}
                                    label={<IntlMessages id="common.full_name"/>}
                                    fullWidth
                                    name={'name'}
                                    defaultValue={name}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                {this.state.warningFullName &&
                                <p style={{color: 'red'}}><IntlMessages id="common.please_enter_your_full_name"/></p>}
                                <TextField
                                    type="password"
                                    onChange={(e) => this.getChanges(e)}
                                    label={<IntlMessages id="appModule.password"/>}
                                    fullWidth
                                    name={'password'}
                                    defaultValue={password}
                                    margin="normal"
                                    className="mt-0 mb-4"
                                />
                                {this.state.warningPassword &&
                                <p style={{color: 'red'}}>{this.state.warningPassword}</p>
                                }
                                <div className="mb-3 d-flex align-items-center justify-content-between">

                                    <Button variant="raised" type={'submit'} style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}>
                                    <IntlMessages id="appModule.register"/>
                                    </Button>
                                    <Link to="/signin" style={{color: "#252525", alignSelf:"flex-end"}}>
                                        <IntlMessages id="signUp.alreadyMember"/>
                                    </Link>
                                </div>
                                <div className="mb-3 d-flex align-items-center justify-content-between">
                                    {/* <p>
                                        Upon Register, you agree to the <a href='#'
                                                                          onClick={() => this.loadTermsAndConditions()}>Terms
                                        & Conditions.</a>
                                    </p> */}
                                    <p>
                                        <IntlMessages id="common.agree_t_c"/><a href='#'
                                                                              onClick={() => this.loadTermsAndConditions()}><IntlMessages id="common.t_c"/></a>
                                        </p>
                                </div>
                                {/*<Link to=""  style={{color: "#252525", alignSelf:"flex-end"}}>*/}
                                {/*    <p className='mb-2 mt-2 pointer' style={{textAlign: 'end'}} onClick={() => this.loadTermsAndConditions()}>*/}
                                {/*        Upon Sign-up, you agree to the terms & conditions.*/}
                                {/*    </p>*/}
                                {/*</Link>*/}
                            </form>
                        </div>
                    </div>

                </div>

                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && NotificationManager.error(alertMessage)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = ({auth}) => {
    return {}
};

export default connect(mapStateToProps, {

    showAuthLoader,

})(SignUp);