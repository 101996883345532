import {ADD_SURVEY, ADD_SURVEY_ID, DELETE_SURVEY, EDIT_SURVEY, UPDATE_SURVEY} from './../constants/ActionTypes'

export const addSurvey=(survey)=>{
    return{
        type:ADD_SURVEY,
        survey:survey
    }
};

export const addSurveyId = (id) => {
    return{
        type: ADD_SURVEY_ID,
        id: id
    }
};

export const editSurvey = (id, survey) => {
    return {
        type: EDIT_SURVEY,
        id: id,
        survey: survey
    }
};

export const updateSurvey = (survey) => {
    return {
        type: UPDATE_SURVEY,
        survey: survey
    }
};

export const deleteSurvey = (id) => {
    return {
        type: DELETE_SURVEY,
        id: id
    }
};