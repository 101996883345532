import {
    ADD_PREVIEW,
    UPDATE_PREVIEW,
    REMOVE_PREVIEW,
    SELECT_TABLE,
    SELECTED_PREVIEW_MENU, SELECT_ITEM, ITEMS_UNDER_SECTION, EDIT_EXPAND
} from "../constants/ActionTypes";
import arrayMove from "array-move";

const INIT_STATE = {
    previewChildren: [],
    previewDesign: [],
    previewMenu: [],
    previewConfig: [],
    previewTable: [],
    previewInfo: [],
    selectedPreviewMenu: [],
    selectedItem: [],
    index: 0

};

export default (state = INIT_STATE, actions) => {

    switch (actions.type) {
        case ADD_PREVIEW: {
            return {
                ...state,
                previewChildren: actions.preview.children,
                previewDesign: actions.preview.design,
                previewMenu: actions.preview.menu,
                previewConfig: actions.preview.app_configuration,
                previewTable: actions.preview.tables,
                previewInfo: actions.preview.info
            }
        }

        case SELECTED_PREVIEW_MENU: {
            return {
                ...state,
                selectedPreviewMenu: [...state.selectedPreviewMenu, actions.menu]
            }
        }

        case REMOVE_PREVIEW: {
            let temp = [...state.selectedPreviewMenu];
            return {
                ...state,
                selectedPreviewMenu: temp.slice(0, -1)
            }
        }

        case SELECT_TABLE: {
            let items = [...state.previewTable];
            let replace = [];
            let idx= 0;
            items.map((item, index) => {
                if (item.id === actions.id) {
                    item.selected = true;
                    replace = item;
                    idx = index;
                    items.splice(index, 1)
                }else {
                    item.selected = false
                }
            });
            return {
                ...state,
                previewTable: arrayMove([...items, replace], items.length, idx)
            }
        }

        case SELECT_ITEM: {
            return{
                ...state,
                selectedItem: actions.item
            }
        }

        case ITEMS_UNDER_SECTION: {
            let idx = 0;
            actions.list.map((item, index) =>{
                    if (item.selected) idx = index
            });
            return{
                ...state,
                index: idx
            }
        }


        case EDIT_EXPAND: {
            let items = [...state.previewChildren];
            items.map(item =>{
                if (item.id === actions.item.id){
                    item.expand = actions.item.expand
                }

            });
            return{
                ...state,
                previewChildren: items
            }
        }

        default:
            return state

    }
}
