// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'toggle_collapse_menu';
export const DRAWER_TYPE = 'drawer_type';
export const FIXED_DRAWER = 'fixed_drawer';
export const COLLAPSED_DRAWER = 'collapsible';
export const MINI_DRAWER = 'mini_drawer';
export const THEME_COLOR = 'theme_color';
export const DARK_THEME = 'dark_theme';
export const WINDOW_WIDTH = 'window-width';
export const SWITCH_LANGUAGE = 'switch-language';
export const CHANGE_DIRECTION = 'change-direction';

export const CHANGE_NAVIGATION_STYLE = 'change-navigation-style';
export const HORIZONTAL_NAVIGATION = 'horizontal_navigation';
export const VERTICAL_NAVIGATION = 'vertical_navigation';

export const HORIZONTAL_MENU_POSITION = 'horizontal_menu_position';
export const ABOVE_THE_HEADER = 'above_the_header';
export const INSIDE_THE_HEADER = 'inside_the_header';
export const BELOW_THE_HEADER = 'below_the_header';

//Contact Module const

export const SHOW_MESSAGE = 'show_message';
export const HIDE_MESSAGE = 'hide_message';

export const FETCH_ALL_CONTACT = 'fetch_all_contact';
export const FETCH_ALL_CONTACT_SUCCESS = 'fetch_all_contact_success';
export const ADD_FAVOURITE = 'add_favourite';
export const ON_CONTACT_SELECT = 'on_contact_select';
export const ON_ADD_CONTACT = 'on_add_contact';
export const ON_CONTACT_CLOSE = 'on_contact_close';
export const ON_FILTER_OPTION_SELECT = 'on_filter_option_select';
export const ON_SAVE_CONTACT = 'on_save_contact';
export const ON_DELETE_CONTACT = 'on_delete_contact';
export const ON_DELETE_SELECTED_CONTACT = 'on_delete_selected_contact';
export const FILTER_CONTACT = 'filter_contact';
export const GET_ALL_CONTACT = 'get_all_contact';
export const GET_UNSELECTED_ALL_CONTACT = 'get_unselected_all_contact';
export const ON_ALL_CONTACT_SELECT = 'on_all_contact_select';
export const UPDATE_SEARCH_USER = 'update_search_user';
export const ON_TOGGLE_DRAWER = 'on_toggle_drawer';
export const HANDLE_REQUEST_CLOSE = 'handle_request_close';
export const HIDE_CONTACT_LOADER = 'hide_contact_loader';

//Auth const
export const INIT_URL = 'init_url';
export const GET_CURRENT_USER = 'get_current_user';


// Chat Module const

export const FETCH_ALL_CHAT_USER = 'fetch_all_chat_user';
export const FETCH_ALL_CHAT_USER_CONVERSATION = 'fetch_all_chat_user_conversation';
export const FETCH_ALL_CHAT_USER_SUCCESS = 'fetch_all_chat_user_success';
export const FETCH_ALL_CHAT_USER_CONVERSATION_SUCCESS = 'fetch_all_chat_user_conversation_success';
export const FILTER_USERS = 'filter_users';
export const ON_SELECT_USER = 'on_select_user';
export const ON_SHOW_LOADER = 'on_show_loader';
export const ON_HIDE_LOADER = 'on_hide_loader';
export const USER_INFO_STATE = 'user_info_state';
export const SUBMIT_COMMENT = 'submit_comment';
export const UPDATE_MESSAGE_VALUE = 'update_message_value';
export const UPDATE_SEARCH_CHAT_USER = 'update_search_chat_user';

//// Mail Module const


export const ADD_LABEL = 'ADD_LABEL';
export const GET_ALL_MAIL = 'get_all_mail';
export const FETCH_ALL_MAIL = 'fetch_all_mail';
export const FETCH_ALL_MAIL_SUCCESS = 'fetch_all_mail_success';
export const GET_IMPORTANT_MAIL = 'get_important_mail';
export const GET_NAV_FILTERS = 'get_nav_filters';
export const GET_NAV_LABELS = 'get_nav_labels';
export const GET_NAV_FOLDER = 'get_nav_folder';
export const GET_READ_MAIL = 'get_read_mail';
export const GET_STARRED_MAIL = 'get_starred_mail';
export const GET_UNIMPORTANT_MAIL = 'get_unimportant_mail';
export const GET_UNREAD_MAIL = 'get_unread_mail';
export const GET_UNSELECTED_ALL_MAIL = 'get_unselected_all_mail';
export const GET_UNSTARRED_MAIL = 'get_unstarred_mail';
export const ON_ALL_MAIL_SELECT = 'on_all_mail_select';
export const ON_FOLDER_MENU_ITEM_SELECT = 'on_folder_menu_item_select';
export const ON_FOLDER_SELECT = 'on_folder_select';
export const ON_IMPORTANT_SELECT = 'on_important_select';
export const ON_LABEL_MENU_ITEM_SELECT = 'on_label_menu_item_select';
export const GET_MARK_AS_START = 'get_mark_as_start';
export const ON_LABEL_SELECT = 'on_label_select';
export const ON_MAIL_CHECKED = 'on_mail_checked';
export const ON_MAIL_SELECT = 'on_mail_select';
export const ON_MAIL_SEND = 'on_mail_send';
export const ON_OPTION_MENU_ITEM_SELECT = 'on_option_menu_item_select';
export const ON_OPTION_MENU_SELECT = 'on_option_menu_select';
export const ON_START_SELECT = 'on_start_select';
export const SEARCH_MAIL = 'search_mail';
export const ON_DELETE_MAIL = 'on_delete_mail';
export const SET_CURRENT_MAIL_NULL = 'set_current_mail_null';
export const ON_COMPOSE_MAIL = 'on_compose_mail';

//// TO-DO Module const

export const ON_SORTEND = 'on_sortend';
export const FETCH_ALL_TODO = 'fetch_all_todo';
export const FETCH_ALL_TODO_SUCCESS = 'fetch_all_todo-success';
export const FETCH_ALL_TODO_CONVERSATION = 'fetch_all_todo_conversation';
export const FETCH_ALL_TODO_CONVERSATION_SUCCESS = 'fetch_all_todo_conversation_success';
export const SELECT_ALL_TODO = 'select_all_todo';
export const GET_ALL_TODO = 'get_all_todo';
export const GET_UNSELECTED_ALL_TODO = 'get_unselected_all_todo';
export const GET_STARRED_TODO = 'get_starred_todo';
export const GET_UNSTARRED_TODO = 'get_unstarred_todo';
export const GET_IMPORTANT_TODO = 'get_important_todo';
export const GET_UNIMPORTANT_TODO = 'get_unimportant_todo';
export const ON_LABEL_UPDATE = 'on_label_update';
export const ON_TODO_UPDATE = 'on_todo_update';
export const ON_DELETE_TODO = 'on_delete_todo';
export const SEARCH_TODO = 'search_todo';
export const SHOW_TODOS = 'show_todos';
export const GET_TODO_CONVERSATION = 'get_todo_conversation';
export const ON_TODO_CHECKED = 'on_todo_checked';
export const ON_TODO_ADD = 'on_todo_add';
export const ON_TODO_SELECT = 'on_todo_select';
export const SET_CURRENT_TODO_NULL = 'set_current_todo_null';
export const REMOVE_LABEL = 'remove_label';
export const UPDATE_SEARCH = 'update_search';

//Venue
export const SELECT_VENUE = 'select-venue';
export const ADD_VENUE = 'add-venue';
export const VENUE_UPDATE = 'venue_update';
export const EDIT_VENUE = 'edit_venue';
export const UPDATE_SELECT_VENUE = 'update_select_venue';


//Menu
export const ADD_MENU = 'add_menu';
export const UPDATE_MENU = 'update_menu';
export const SELECT_MENU = 'select_menu';
export const ORDER_MENU = 'order_menu';
export const ADD_PROGRESS = 'add_progress';
export const DELETE_ONE_MENU = 'delete_one_menu';
export const EDIT_MENU = 'edit_menu'
//Section
export const ADD_SECTION = 'add_section';
export const UPDATE_SECTION = 'update_section';
export const ADD_SECTION_ID = 'add_section_id';
export const ADD_SECTION_INSIDE_SECTION = 'add_section_inside_section';
export const ADD_ITEM_INSIDE_SECTION = 'add_item_inside_section';
export const UPDATE_ITEM_INSIDE_SECTION = 'update_item_inside_section';
export const UPDATE_SECTION_INSIDE_SECTION = 'update_section_inside_section';

//item
export const ADD_ITEM = 'add_item';
export const UPDATE_ITEM = 'update_item';

//Design
export const ADD_DESIGN = 'add_design';
export const ADD_DESIGN_ID = 'add_design_id';
export const EDITED_ITEMS = 'edited_items';
export const CLEAR_EDITED_ITEMS = 'clear edited items';
export const UPDATE_DESIGN = 'update_design';

//Table
export const ADD_TABLE = 'add_table';
export const DELETE_TABLE = 'delete_table';

//Waiter
export const ADD_WAITER = 'add_waiter';
export const DELETE_WAITER = 'delete_waiter';

//Modifier
export const ADD_MODIFIER = 'add_modifier';
export const DELETE_MODIFIER = 'delete_modifier';
export const EDIT_MODIFIER = 'edit_modifier';

//Loader
export const UPDATE_LOADER = 'update_loader';

//Breadcrumbs path
export const ADD_PATH = 'add_path';
export const UPDATE_PATH = 'update_path';
export const DELETE_PATH = 'delete_path';

//Order
export const ADD_ORDER_ENABLED = 'add_order_enabled';
export const UPDATE_ORDER_ENABLED = 'update_order_enabled';
export const ADD_ORDER = 'add_order';
export const UPDATE_ORDER = 'update_order';
export const DELETE_ORDER = 'delete_order';

//Children
export const ORDER_CHILDREN = 'order_childern';
export const ADD_CHILDREN = 'add_children';
export const UPDATE_CHILDREN = 'update_children';
export const DELETE_ONE_CHILD = 'delete_one_child';
export const DELETE_ONE_PRICE = 'delete_one_price';
export const EDIT_CHILD = "edit_child";

//Section Children
export const ORDER_SECTION_CHILDREN = 'order_section_children';
export const ADD_SECTION_CHILDREN = 'add_section_children';
export const DELETE_ONE_SECTION_CHILD = 'delete_one_section_child';
export const UPDATE_SECTION_CHILDREN = 'update_section_children';
export const DELETE_ONE_SECTION_PRICE = 'delete_one_section_price';
export const EDIT_SECTION_CHILD = "edit_section_child";

//Campaign
export const ADD_CAMPAIGN = 'add_campaign';
export const EDIT_CAMPAIGN = 'edit_campaign';
export const UPDATE_CAMPAIGN = 'update_campaign';
export const DELETE_CAMPAIGN = 'delete_campaign';

// survey
export const ADD_SURVEY = 'add_survey';
export const EDIT_SURVEY = 'edit_survey';
export const UPDATE_SURVEY = 'update_survey';
export const DELETE_SURVEY = 'delete_survey';
export const ADD_SURVEY_ID = 'add_survey_id';

//Preview
export const ADD_PREVIEW = 'add_preview';
export const SELECTED_PREVIEW_MENU = 'selected_preview_menu';
export const UPDATE_PREVIEW = 'update_preview';
export const REMOVE_PREVIEW = 'remove_preview';
export const SELECT_TABLE = 'select_table';
export const SELECT_ITEM = 'select_item';
export const ITEMS_UNDER_SECTION = 'items_under_section';
export const ADD_EXPAND = 'add_expand';
export const EDIT_EXPAND = ' edit_expand';

export const ADD_LANGUAGE = 'add_language';

export const ADD_SURVEY_QUESTION = 'add_survey_question';
export const UPDATE_SURVEY_QUESTION = 'update_survey_question';

export const ADD_PLANS = 'add_plans';

export const ADD_INTEGRATIONS = 'ADD_INTEGRATIONS'