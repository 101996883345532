import { EventEmitter } from 'events';
import { Logger } from './logger';

import { Members } from './data/members';
import { Member } from './member';
import { Messages } from './data/messages';
import { Message } from './message';

import { UriBuilder, isDeepEqual, parseToNumber } from './util';
import { UserDescriptor } from './userdescriptor';
import { Users } from './data/users';
import { Paginator } from './interfaces/paginator';
import { Channels } from './data/channels';
import { McsClient } from 'twilio-mcs-client';

import { SyncClient } from 'twilio-sync';
import { TypingIndicator } from './services/typingindicator';
import { Network } from './services/network';
import { validateTypesAsync, custom, literal, nonEmptyString, nonNegativeInteger, objectSchema } from 'twilio-sdk-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { JoinChannelRequest, JoinChannelResponse } from './interfaces/commands/joinchannel';
import { EditChannelRequest } from './interfaces/commands/editchannel';
import { ChannelResponse } from './interfaces/commands/channel';
import { EditNotificationLevelRequest } from './interfaces/commands/editnotificationlevel';
import { EditLastConsumedMessageIndexRequest, EditLastConsumedMessageIndexResponse } from './interfaces/commands/editlastconsumedmessageindex';

const log = Logger.scope('Channel');

const fieldMappings = {
  lastMessage: 'lastMessage',
  attributes: 'attributes',
  createdBy: 'createdBy',
  dateCreated: 'dateCreated',
  dateUpdated: 'dateUpdated',
  friendlyName: 'friendlyName',
  lastConsumedMessageIndex: 'lastConsumedMessageIndex',
  notificationLevel: 'notificationLevel',
  sid: 'sid',
  status: 'status',
  type: 'type',
  uniqueName: 'uniqueName',
  state: 'state'
};

function parseTime(timeString) {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

export interface ChannelServices {
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

interface ChannelState {
  uniqueName: string;
  status: Channel.Status;
  type: Channel.Type;
  attributes: any;
  createdBy?: string;
  dateCreated: Date;
  dateUpdated: Date;
  friendlyName: string;
  lastConsumedMessageIndex: number | null;
  lastMessage?: Channel.LastMessage;
  notificationLevel?: Channel.NotificationLevel;
  state?: Channel.State;
}

interface ChannelDescriptor {
  channel: string;
  entityName: string;
  uniqueName: string;
  attributes: any;
  createdBy?: string;
  friendlyName: string;
  lastConsumedMessageIndex: number;
  dateCreated: any;
  dateUpdated: any;
  type: Channel.Type;
  notificationLevel?: Channel.NotificationLevel;
}

interface ChannelLinks {
  self: string;
  messages: string;
  participants: string;
  invites: string;
}

namespace Channel {
  export type UpdateReason = 'attributes' | 'createdBy' | 'dateCreated' | 'dateUpdated' |
    'friendlyName' | 'lastConsumedMessageIndex' | 'state' | 'status' | 'uniqueName' | 'lastMessage' | 'notificationLevel';

  export type Status = 'unknown' | 'notParticipating' | 'invited' | 'joined';

  export type Type = 'public' | 'private';

  export type NotificationLevel = 'default' | 'muted';

  export type State = {
    current: 'active' | 'inactive' | 'closed',
    dateUpdated: Date
  } | undefined;

  export interface UpdatedEventArgs {
    channel: Channel;
    updateReasons: Channel.UpdateReason[];
  }

  export interface SendMediaOptions {
    contentType: string;
    media: string | Buffer;
  }

  export interface LastMessage {
    index?: number;
    dateCreated?: Date;
  }
}

/**
 * @classdesc A Channel represents a remote channel of communication between multiple Programmable Chat Clients
 * @property {any} attributes - The Channel's custom attributes
 * @property {String} createdBy - The identity of the User that created this Channel
 * @property {Date} dateCreated - The Date this Channel was created
 * @property {Date} dateUpdated - The Date this Channel was last updated
 * @property {String} friendlyName - The Channel's name
 * @property {Boolean} isPrivate - Whether the channel is private (as opposed to public)
 * @property {Number} lastConsumedMessageIndex - Index of the last Message the User has consumed in this Channel
 * @property {Channel#LastMessage} lastMessage - Last Message sent to this Channel
 * @property {Channel#NotificationLevel} notificationLevel - User Notification level for this Channel
 * @property {String} sid - The Channel's unique system identifier
 * @property {Channel#State} state - The Channel's state
 * @property {Channel#Status} status - The Channel's status
 * @property {Channel#Type} type - The Channel's type
 * @property {String} uniqueName - The Channel's unique name (tag)
 * @fires Channel#memberJoined
 * @fires Channel#memberLeft
 * @fires Channel#memberUpdated
 * @fires Channel#messageAdded
 * @fires Channel#messageRemoved
 * @fires Channel#messageUpdated
 * @fires Channel#typingEnded
 * @fires Channel#typingStarted
 * @fires Channel#updated
 * @fires Channel#removed
 */

class Channel extends EventEmitter {
  private channelState: ChannelState;
  private statusSource: Channels.DataSource;

  private entityPromise: Promise<any>;
  private entityName: string;
  private entity: any;
  private messagesEntity: any;
  private membersEntity: Members;
  private members: any;

  constructor(
    descriptor: ChannelDescriptor,
    public readonly sid: string,
    public readonly links: ChannelLinks,
    private readonly configuration: Configuration,
    private readonly services: ChannelServices
  ) {
    super();

    let attributes = descriptor.attributes || {};
    let createdBy = descriptor.createdBy;
    let dateCreated = parseTime(descriptor.dateCreated);
    let dateUpdated = parseTime(descriptor.dateUpdated);
    let friendlyName = descriptor.friendlyName || null;
    let lastConsumedMessageIndex =
      Number.isInteger(descriptor.lastConsumedMessageIndex) ? descriptor.lastConsumedMessageIndex : null;
    let uniqueName = descriptor.uniqueName || null;

    try {
      JSON.stringify(attributes);
    } catch (e) {
      throw new Error('Attributes must be a valid JSON object.');
    }

    this.entityName = descriptor.channel;
    this.channelState = {
      uniqueName,
      status: 'notParticipating',
      type: descriptor.type,
      attributes,
      createdBy,
      dateCreated,
      dateUpdated,
      friendlyName,
      lastConsumedMessageIndex
    };

    if (descriptor.notificationLevel) {
      this.channelState.notificationLevel = descriptor.notificationLevel;
    }

    const membersLinks = {
      participants: this.links.participants
    };

    this.members = new Map();
    this.membersEntity = new Members(this, this.members, membersLinks, this.configuration, this.services);
    this.membersEntity.on('memberJoined', this.emit.bind(this, 'memberJoined'));
    this.membersEntity.on('memberLeft', this.emit.bind(this, 'memberLeft'));
    this.membersEntity.on('memberUpdated',
      (args: Member.UpdatedEventArgs) => this.emit('memberUpdated', args));

    this.messagesEntity = new Messages(this, this.configuration, services);
    this.messagesEntity.on('messageAdded', message => this._onMessageAdded(message));
    this.messagesEntity.on('messageUpdated',
      (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    this.messagesEntity.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
  }

  /**
   * The Channel's state. Set to undefined if the channel is not a conversation.
   * @typedef {Object | undefined} Channel#State
   * @property {('active' | 'inactive' | 'closed')} current - the current state
   * @property {Date} dateUpdated - date at which the latest channel state update happened
   */

  /**
   * These options can be passed to {@link Channel#sendMessage}.
   * @typedef {Object} Channel#SendMediaOptions
   * @property {String} contentType - content type of media
   * @property {String | Buffer} media - content to post
   */

  /**
   * The update reason for <code>updated</code> event emitted on Channel
   * @typedef {('attributes' | 'createdBy' | 'dateCreated' | 'dateUpdated' |
    'friendlyName' | 'lastConsumedMessageIndex' | 'state' | 'status' | 'uniqueName' | 'lastMessage' |
    'notificationLevel' )} Channel#UpdateReason
   */

  /**
   * The status of the Channel, relative to the Client: whether the Channel
   * is <code>notParticipating</code> to local Client, Client is <code>invited</code> to or
   * is <code>joined</code> to this Channel
   * @typedef {('unknown' | 'notParticipating' | 'invited' | 'joined')} Channel#Status
   */

  /**
   * The type of Channel (<code>public</code> or <code>private</code>).
   * @typedef {('public' | 'private')} Channel#Type
   */

  /**
   * The User's Notification level for Channel, determines whether the currently logged-in User will receive
   * pushes for events in this Channel. Can be either <code>muted</code> or <code>default</code>,
   * where <code>default</code> defers to global Service push configuration.
   * @typedef {('default' | 'muted')} Channel#NotificationLevel
   */

  public get status(): Channel.Status { return this.channelState.status; }

  public get type(): Channel.Type { return this.channelState.type; }

  public get uniqueName(): string { return this.channelState.uniqueName; }

  public get isPrivate(): boolean { return this.channelState.type === 'private'; }

  public get friendlyName(): string { return this.channelState.friendlyName; }

  public get dateUpdated(): any { return this.channelState.dateUpdated; }

  public get dateCreated(): any { return this.channelState.dateCreated; }

  public get createdBy(): string { return this.channelState.createdBy; }

  public get attributes(): Object { return this.channelState.attributes; }

  public get lastConsumedMessageIndex(): number | null { return this.channelState.lastConsumedMessageIndex; }

  public get lastMessage(): Channel.LastMessage { return this.channelState.lastMessage; }

  public get notificationLevel(): Channel.NotificationLevel { return this.channelState.notificationLevel; }

  public get state(): Channel.State { return this.channelState.state; }

  /**
   * The Channel's last message's information.
   * @typedef {Object} Channel#LastMessage
   * @property {Number} index - Message's index
   * @property {Date} dateCreated - Message's creation date
   */

  /**
   * Load and Subscribe to this Channel and do not subscribe to its Members and Messages.
   * This or _subscribeStreams will need to be called before any events on Channel will fire.
   * @returns {Promise}
   * @private
   */
  _subscribe() {
    if (this.entityPromise) { return this.entityPromise; }

    return this.entityPromise = this.entityPromise ||
      this.services.syncClient.document({ id: this.entityName, mode: 'open_existing' })
        .then(entity => {
          this.entity = entity;
          this.entity.on('updated', args => { this._update(args.data); });
          this.entity.on('removed', () => this.emit('removed', this));
          this._update(this.entity.data);
          return entity;
        })
        .catch(err => {
          this.entity = null;
          this.entityPromise = null;
          if (this.services.syncClient.connectionState != 'disconnected') {
            log.error('Failed to get channel object', err);
          }
          log.debug('ERROR: Failed to get channel object', err);
          throw err;
        });
  }

  /**
   * Load the attributes of this Channel and instantiate its Members and Messages.
   * This or _subscribe will need to be called before any events on Channel will fire.
   * This will need to be called before any events on Members or Messages will fire
   * @returns {Promise}
   * @private
   */
  async _subscribeStreams() {
    try {
      await this._subscribe();
      log.trace('_subscribeStreams, this.entity.data=', this.entity.data);
      const messagesObjectName = this.entity.data.messages;
      const rosterObjectName = this.entity.data.roster;
      await Promise.all([
        this.messagesEntity.subscribe(messagesObjectName),
        this.membersEntity.subscribe(rosterObjectName)
      ]);
    } catch (err) {
      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error('Failed to subscribe on channel objects', this.sid, err);
      }
      log.debug('ERROR: Failed to subscribe on channel objects', this.sid, err);
      throw err;
    }
  }

  /**
   * Stop listening for and firing events on this Channel.
   * @returns {Promise}
   * @private
   */
  async _unsubscribe() {
    // Keep our subscription to public channels objects
    if (this.isPrivate && this.entity) {
      await this.entity.close();
      this.entity = null;
      this.entityPromise = null;
    }

    return Promise.all([
      this.membersEntity.unsubscribe(),
      this.messagesEntity.unsubscribe()
    ]);
  }

  /**
   * Set channel status
   * @private
   */
  _setStatus(status: Channel.Status, source: Channels.DataSource) {
    this.statusSource = source;

    if (this.channelState.status === status) { return; }

    this.channelState.status = status;

    if (status === 'joined') {
      this._subscribeStreams()
          .catch(err => {
            log.debug('ERROR while setting channel status ' + status, err);
            if (this.services.syncClient.connectionState !== 'disconnected') {
              throw err;
            }
          });
    } else if (status === 'invited') {
      this._subscribe()
          .catch(err => {
            log.debug('ERROR while setting channel status ' + status, err);
            if (this.services.syncClient.connectionState !== 'disconnected') {
              throw err;
            }
          });
    } else if (this.entityPromise) {
      this._unsubscribe().catch(err => {
        log.debug('ERROR while setting channel status ' + status, err);
        if (this.services.syncClient.connectionState !== 'disconnected') {
          throw err;
        }
      });
    }
  }

  /**
   * If channel's status update source
   * @private
   * @return {Channels.DataSource}
   */
  _statusSource(): Channels.DataSource {
    return this.statusSource;
  }

  private static preprocessUpdate(update, channelSid) {
    try {
      if (typeof update.attributes === 'string') {
        update.attributes = JSON.parse(update.attributes);
      } else if (update.attributes) {
        JSON.stringify(update.attributes);
      }
    } catch (e) {
      log.warn('Retrieved malformed attributes from the server for channel: ' + channelSid);
      update.attributes = {};
    }

    try {
      if (update.dateCreated) {
        update.dateCreated = new Date(update.dateCreated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateCreated from the server for channel: ' + channelSid);
      delete update.dateCreated;
    }

    try {
      if (update.dateUpdated) {
        update.dateUpdated = new Date(update.dateUpdated);
      }
    } catch (e) {
      log.warn('Retrieved malformed dateUpdated from the server for channel: ' + channelSid);
      delete update.dateUpdated;
    }

    try {
      if (update.lastMessage && update.lastMessage.timestamp) {
        update.lastMessage.timestamp = new Date(update.lastMessage.timestamp);
      }
    } catch (e) {
      log.warn('Retrieved malformed lastMessage.timestamp from the server for channel: ' + channelSid);
      delete update.lastMessage.timestamp;
    }
  }

  /**
   * Updates local channel object with new values
   * @private
   */
  _update(update) {
    log.trace('_update', update);

    Channel.preprocessUpdate(update, this.sid);
    const updateReasons = new Set<Channel.UpdateReason>();

    for (const key of Object.keys(update)) {
      const localKey = fieldMappings[key];

      if (!localKey) {
        continue;
      }

      switch (localKey) {
        case fieldMappings.status:
          if (!update.status || update.status === 'unknown'
            || this.channelState.status === update.status) {
            break;
          }

          this.channelState.status = update.status;
          updateReasons.add(localKey);

          break;
        case fieldMappings.attributes:
          if (isDeepEqual(this.channelState.attributes, update.attributes)) {
            break;
          }

          this.channelState.attributes = update.attributes;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastConsumedMessageIndex:
          if (update.lastConsumedMessageIndex === undefined
            || update.lastConsumedMessageIndex === this.channelState.lastConsumedMessageIndex) {
            break;
          }

          this.channelState.lastConsumedMessageIndex = update.lastConsumedMessageIndex;
          updateReasons.add(localKey);

          break;
        case fieldMappings.lastMessage:
          if (this.channelState.lastMessage && !update.lastMessage) {
            delete this.channelState.lastMessage;
            updateReasons.add(localKey);

            break;
          }

          this.channelState.lastMessage = this.channelState.lastMessage || {};

          if (update.lastMessage?.index !== undefined
            && update.lastMessage.index !== this.channelState.lastMessage.index) {
            this.channelState.lastMessage.index = update.lastMessage.index;
            updateReasons.add(localKey);
          }

          if (update.lastMessage?.timestamp !== undefined
            && this.channelState.lastMessage?.dateCreated?.getTime() !== update.lastMessage.timestamp.getTime()) {
            this.channelState.lastMessage.dateCreated = update.lastMessage.timestamp;
            updateReasons.add(localKey);
          }

          if (isDeepEqual(this.channelState.lastMessage, {})) {
            delete this.channelState.lastMessage;
          }

          break;
        case fieldMappings.state:
          const state = update.state || undefined;

          if (state !== undefined) {
            state.dateUpdated = new Date(state.dateUpdated);
          }

          if (isDeepEqual(this.channelState.state, state)) {
            break;
          }

          this.channelState.state = state;
          updateReasons.add(localKey);

          break;
        default:
          const isDate = update[key] instanceof Date;
          const keysMatchAsDates = isDate && this.channelState[localKey]?.getTime() === update[key].getTime();
          const keysMatchAsNonDates = !isDate && this[localKey] === update[key];

          if (keysMatchAsDates || keysMatchAsNonDates) {
            break;
          }

          this.channelState[localKey] = update[key];
          updateReasons.add(localKey);
      }
    }

    if (updateReasons.size > 0) {
      this.emit('updated', { channel: this, updateReasons: [...updateReasons] });
    }
  }

  /**
   * @private
   */
  private _onMessageAdded(message) {
    for (let member of this.members.values()) {
      if (member.identity === message.author) {
        member._endTyping();
        break;
      }
    }
    this.emit('messageAdded', message);
  }

  private async _setLastConsumedMessageIndex(index: number | null): Promise<number> {
    const result = await this.services.commandExecutor.mutateResource<
      EditLastConsumedMessageIndexRequest,
      EditLastConsumedMessageIndexResponse
    >(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        last_consumed_message_index: index
      }
    );

    return result.unread_messages_count;
  }

  /**
   * Add a participant to the Channel by its Identity.
   * @param {String} identity - Identity of the Client to add
   * @returns {Promise<void>}
   */
  @validateTypesAsync(nonEmptyString)
  async add(identity: string): Promise<void> {
    await this.membersEntity.add(identity);
  }

  /**
   * Advance last consumed Channel's Message index to current consumption horizon.
   * Rejects if User is not Member of Channel.
   * Last consumed Message index is updated only if new index value is higher than previous.
   * @param {Number} index - Message index to advance to as last read
   * @returns {Promise<number>} resulting unread messages count in the channel
   */
  @validateTypesAsync(nonNegativeInteger)
  async advanceLastConsumedMessageIndex(index: number): Promise<number> {
    await this._subscribeStreams();

    if (index < this.lastConsumedMessageIndex) {
      return await this._setLastConsumedMessageIndex(this.lastConsumedMessageIndex);
    }

    return await this._setLastConsumedMessageIndex(index);
  }

  /**
   * Decline an invitation to the Channel and unsubscribe from its events.
   * @returns {Promise<Channel>}
   */
  async decline(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      `${this.links.invites}/${this.configuration.userIdentity}`
    );

    return this;
  }

  /**
   * Delete the Channel and unsubscribe from its events.
   * @returns {Promise<Channel>}
   */
  async delete(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      this.links.self,
    );

    return this;
  }

  /**
   * Get the custom attributes of this Channel.<br/>
   *
   * <i>NOTE: {@link Channel}'s <code>attributes</code> property will be empty for public channels until this function is called.</i>
   * @returns {Promise<any>} attributes of this Channel
   */
  async getAttributes(): Promise<any> {
    await this._subscribe();
    return this.attributes;
  }

  /**
   * Returns messages from channel using paginator interface.
   * @param {Number} [pageSize=30] Number of messages to return in single chunk
   * @param {Number} [anchor] - Index of newest Message to fetch. From the end by default
   * @param {('backwards'|'forward')} [direction=backwards] - Query direction. By default it query backwards
   *                                                          from newer to older. 'forward' will query in opposite direction
   * @returns {Promise<Paginator<Message>>} page of messages
   */
  @validateTypesAsync(
    ['undefined', nonNegativeInteger],
    ['undefined', nonNegativeInteger],
    ['undefined', literal('backwards', 'forward')]
  )
  async getMessages(pageSize?: number, anchor?: number, direction?: 'backwards' | 'forward'): Promise<Paginator<Message>> {
    await this._subscribeStreams();
    return this.messagesEntity.getMessages(pageSize, anchor, direction);
  }

  /**
   * Get a list of all Members joined to this Channel.
   * @returns {Promise<Member[]>}
   */
  async getMembers(): Promise<Member[]> {
    await this._subscribeStreams();
    return this.membersEntity.getMembers();
  }

  /**
   * Get channel members count.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Chat system does not
   * provide real time events for counter values changes.
   * <br/>
   * So this is quite useful for any UI badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number>}
   */
  async getMembersCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    return response.body.participants_count;
  }

  /**
   * Get a Member by its SID.
   * @param {String} memberSid - Member sid
   * @returns {Promise<Member>}
   */
  @validateTypesAsync(nonEmptyString)
  async getMemberBySid(memberSid: string): Promise<Member> {
    return this.membersEntity.getMemberBySid(memberSid);
  }

  /**
   * Get a Member by its identity.
   * @param {String} identity - Member identity
   * @returns {Promise<Member>}
   */
  @validateTypesAsync(nonEmptyString)
  async getMemberByIdentity(identity: string): Promise<Member> {
    return this.membersEntity.getMemberByIdentity(identity);
  }

  /**
   * Get total message count in a channel.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Chat system does not
   * provide real time events for counter values changes.
   * <br/>
   * So this is quite useful for any UI badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number>}
   */
  async getMessagesCount(): Promise<number> {
    const url = new UriBuilder(this.configuration.links.conversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    return response.body.messages_count;
  }

  /**
   * Get unconsumed messages count for a User if they are a Member of this Channel.
   * Rejects if the User is not a Member of the Channel.
   * <br/>
   * This method is semi-realtime. This means that this data will be eventually correct,
   * but will also possibly be incorrect for a few seconds. The Chat system does not
   * provide real time events for counter values changes.
   * <br/>
   * So this is quite useful for any “unread messages count” badges, but is not recommended
   * to build any core application logic based on these counters being accurate in real time.
   * @returns {Promise<number|null>}
   */
  async getUnconsumedMessagesCount(): Promise<number | null> {
    const url = new UriBuilder(this.configuration.links.myConversations).path(this.sid).build();
    const response = await this.services.network.get(url);

    if (response.body.conversation_sid !== this.sid) {
      throw new Error('Channel was not found in the user channels list');
    }

    const unreadMessageCount = response.body.unread_messages_count;

    if (typeof unreadMessageCount === 'number') {
      return unreadMessageCount;
    }

    return null;
  }

  /**
   * Invite a user to the Channel by their Identity.
   * @param {String} identity - Identity of the user to invite
   * @returns {Promise<void>}
   */
  @validateTypesAsync(nonEmptyString)
  async invite(identity: string): Promise<void> {
    await this.membersEntity.invite(identity);
  }

  /**
   * Join the Channel and subscribe to its events.
   * @returns {Promise<Channel>}
   */
  async join(): Promise<Channel> {
    await this.services.commandExecutor.mutateResource<JoinChannelRequest, JoinChannelResponse>(
      'post',
      this.links.participants,
      {
        identity: this.configuration.userIdentity
      }
    );

    return this;
  }

  /**
   * Leave the Channel.
   * @returns {Promise<Channel>}
   */
  async leave(): Promise<Channel> {
    if (this.channelState.status === 'joined') {
      await this.services.commandExecutor.mutateResource(
        'delete',
        `${this.links.participants}/${this.configuration.userIdentity}`,
      );
    }

    return this;
  }

  /**
   * Remove a Member from the Channel.
   * @param {String|Member} member - Member to remove. Could either be an identity string or a Member instance.
   * @returns {Promise<void>}
   */
  @validateTypesAsync([nonEmptyString, Member])
  async removeMember(member: string | Member): Promise<void> {
    await this.membersEntity.remove(typeof member === 'string' ? member : member.sid);
  }

  /**
   * Send a Message in the Channel.
   * @param {String|FormData|Channel#SendMediaOptions|null} message - The message body for text message,
   * FormData or MediaOptions for media content. Sending FormData supported only with browser engine
   * @param {any} [messageAttributes] - attributes for the message
   * @returns {Promise<number>} new Message's index in the Channel's messages list
   */
  @validateTypesAsync(
    [
      'string',
      literal(null),
      // Wrapping it into a custom rule is necessary because the FormData class is not available on initialization.
      custom((value) => [value instanceof FormData, 'an instance of FormData']),
      objectSchema('media options', {
        contentType: [nonEmptyString, 'undefined'],
        media: custom((value) => {
          let isValid = (typeof value === 'string' && value.length > 0) || value instanceof Uint8Array || value instanceof ArrayBuffer;

          if (typeof Blob === 'function') {
            isValid = isValid || value instanceof Blob;
          }

          return [
            isValid,
            'a non-empty string, an instance of Buffer or an instance of Blob'
          ];
        })
      })
    ],
    ['undefined', 'string', 'number', 'boolean', 'object', literal(null)]
  )
  async sendMessage(message: string | FormData | Channel.SendMediaOptions | null, messageAttributes?: any): Promise<number> {
    if (typeof message === 'string' || message === null) {
      const response = await this.messagesEntity.send(message, messageAttributes);
      return parseToNumber(response.index);
    }

    const response = await this.messagesEntity.sendMedia(message, messageAttributes);
    return parseToNumber(response.index);
  }

  /**
   * Set last consumed Channel's Message index to last known Message's index in this Channel.
   * @returns {Promise<number>} resulting unread messages count in the channel
   */
  async setAllMessagesConsumed(): Promise<number> {
    await this._subscribeStreams();
    let messagesPage = await this.getMessages(1);
    if (messagesPage.items.length > 0) {
      return this.advanceLastConsumedMessageIndex(messagesPage.items[0].index);
    }
    return Promise.resolve(0);
  }

  /**
   * Set all messages in the channel unread.
   * @returns {Promise<number>} resulting unread messages count in the channel
   */
  async setNoMessagesConsumed(): Promise<number> {
    await this._subscribeStreams();
    return await this._setLastConsumedMessageIndex(null);
  }

  /**
   * Set User Notification level for this channel.
   * @param {Channel#NotificationLevel} notificationLevel - The new user notification level
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('default', 'muted'))
  async setUserNotificationLevel(notificationLevel: Channel.NotificationLevel): Promise<void> {
    await this.services.commandExecutor.mutateResource<EditNotificationLevelRequest>(
      'post',
      `${this.configuration.links.myConversations}/${this.sid}`,
      {
        notification_level: notificationLevel
      }
    );
  }

  /**
   * Send a notification to the server indicating that this Client is currently typing in this Channel.
   * Typing ended notification is sent after a while automatically, but by calling again this method you ensure typing ended is not received.
   * @returns {Promise<void>}
   */
  typing(): Promise<void> {
    return this.services.typingIndicator.send(this.sid);
  }

  /**
   * Update the Channel's attributes.
   * @param {any} attributes new attributes for Channel.
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Channel> {
    await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
      'post',
      this.links.self,
      { attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined }
    );

    return this;
  }

  /**
   * Update the Channel's friendlyName.
   * @param {String} friendlyName - The new Channel friendlyName
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync('string')
  async updateFriendlyName(friendlyName: string): Promise<Channel> {
    if (this.channelState.friendlyName !== friendlyName) {
      await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
        'post',
        this.links.self,
        { friendly_name: friendlyName }
      );
    }

    return this;
  }

  /**
   * Set last consumed Channel's Message index to current consumption horizon.
   * @param {Number|null} index - Message index to set as last read.
   * If null provided, then the behavior is identical to {@link Channel#setNoMessagesConsumed}
   * @returns {Promise<number>} resulting unread messages count in the channel
   */
  @validateTypesAsync([literal(null), nonNegativeInteger])
  async updateLastConsumedMessageIndex(index: number | null): Promise<number> {
    await this._subscribeStreams();
    return this._setLastConsumedMessageIndex(index);
  }

  /**
   * Update the Channel's unique name.
   * @param {String|null} uniqueName - New unique name for the Channel. Setting unique name to null removes it.
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync(['string', literal(null)])
  async updateUniqueName(uniqueName: string | null): Promise<Channel> {
    if (this.channelState.uniqueName !== uniqueName) {
      if (!uniqueName) {
        uniqueName = '';
      }

      await this.services.commandExecutor.mutateResource<EditChannelRequest, ChannelResponse>(
        'post',
        this.links.self,
        { unique_name: uniqueName }
      );
    }
    return this;
  }

  /**
   * Gets User Descriptors for this channel.
   * @returns {Promise<Paginator<UserDescriptor>>}
   */
  async getUserDescriptors(): Promise<Paginator<UserDescriptor>> {
    return this.services.users.getChannelUserDescriptors(this.sid);
  }
}

export { ChannelDescriptor, Channel };

/**
 * Fired when a Member has joined the Channel.
 * @event Channel#memberJoined
 * @type {Member}
 */
/**
 * Fired when a Member has left the Channel.
 * @event Channel#memberLeft
 * @type {Member}
 */
/**
 * Fired when a Member's fields has been updated.
 * @event Channel#memberUpdated
 * @type {Object}
 * @property {Member} member - Updated Member
 * @property {Member#UpdateReason[]} updateReasons - Array of Member's updated event reasons
 */
/**
 * Fired when a new Message has been added to the Channel.
 * @event Channel#messageAdded
 * @type {Message}
 */
/**
 * Fired when Message is removed from Channel's message list.
 * @event Channel#messageRemoved
 * @type {Message}
 */
/**
 * Fired when an existing Message's fields are updated with new values.
 * @event Channel#messageUpdated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
/**
 * Fired when a Member has stopped typing.
 * @event Channel#typingEnded
 * @type {Member}
 */
/**
 * Fired when a Member has started typing.
 * @event Channel#typingStarted
 * @type {Member}
 */
/**
 * Fired when a Channel's attributes or metadata have been updated.
 * During Channel's {@link Client.create | creation and initialization} this event might be fired multiple times
 * for same joined or created Channel as new data is arriving from different sources.
 * @event Channel#updated
 * @type {Object}
 * @property {Channel} channel - Updated Channel
 * @property {Channel#UpdateReason[]} updateReasons - Array of Channel's updated event reasons
 */
/**
 * Fired when the Channel was destroyed or currently logged in User has left private Channel
 * @event Channel#removed
 * @type {Channel}
 */
