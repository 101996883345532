import React from "react";
import {performRequest} from './services/apiHandler';
import {Link} from "react-router-dom";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "util/IntlMessages";


class ConfirmEmail extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            non_field_errors: '',
            passwordError: '',
            emailError: '',
            isverified: ''
        }
    }

    componentDidMount() {

        let url = window.location.href;
        let splitUrl = url.split("/").slice(-1)[0];
        let data = {id: splitUrl};
        performRequest('post', 'api/verify-email/', data, null, false)
            .then(response => {
                this.setState({
                    isverified: response.data
                });
            })
            .catch(error => {
                this.setState({
                    isverified: error.response.data
                });

            })


    }


    render() {

        const {
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>{this.state.isverified}</h1>
                        </div>


                        <div className="app-login-form">
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <Link to={"/signin"} style={{color: "#252525", alignSelf: "flex-end"}}>
                                    <Button variant="raised"
                                            style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}>
                                        SignIn
                                    </Button>
                                </Link>

                            </div>
                        </div>
                    </div>
                </div>

            </div>
        );
    }
}


export default ConfirmEmail;
