import { ADD_PROGRESS } from "../constants/ActionTypes";

const INIT_STATE = {
  total: 0,
};

export default (state = INIT_STATE, actions) => {
  switch (actions.type) {
    case ADD_PROGRESS: {
      alert("progress    ");
      //         console.log('ssssssssssssssssssssssssssssssssssssss')

      return {
        ...state,
        total: actions.total,
      };
    }
    default:
      return state;
  }
};
