import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import {showAuthLoader,} from "actions/Auth";
import Menu from 'components/TopNav/Menu';
import Footer from 'components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import {isIOS, isMobile} from "react-device-detect";
import TopNav from 'components/TopNav';
import Toolbar from '@material-ui/core/Toolbar';
import {Button, Col, Dropdown, DropdownMenu, DropdownToggle, Row,} from "reactstrap";
import Select from "react-select";
import UserInfo from 'components/UserInfo';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import './profile.css'
import {ERROR, ERROR_MESSAGE, TABLE_HEAD_COLOR} from "../constants/constant";
import {performRequest} from "./services/apiHandler";
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from 'util/IntlMessages';
import moment from "moment";
import {getCurrentUser} from "../actions/index"
import axios from "axios";
import {ScaleLoader} from "react-spinners";
import LoadingOverlay from 'react-loading-overlay';
import {toggleCollapsedNav} from "../actions/Setting"
import Sidenav from "../containers/SideNav/index";
import jsPDF from 'jspdf';
import html2canvas from "html2canvas";

import publicIp from "public-ip";
import {saveAs} from "file-saver";
import * as FileSaver from "file-saver";
import ReactToPdf from 'react-to-pdf'
import $ from 'jquery'

const style = {
    padding: 0
}

class Checkout extends React.Component {
    constructor() {
        super();
        this.state = {
            nav: true,
            handleBillingInfo: true,
            handlePaymentMethod: false,
            publicKey: 'pk_test_f55c64ad-d8b6-415e-93cb-a7b15a2e3ea6',
            // id: true,
            id: false,
            email: '',
            full_name: '',
            address_line_1: '',
            address_line_2: '',
            city: '',
            state: '',
            zip_code: '',
            tax_number: '',
            tax_office: '',
            country: '',
            user: '',
            billingInformation: true,
            cardId: false,
            cardNumber: '',
            cardName: '',
            expiresOn: '',
            transaction: [],
            countries: [],
            loader: false,
            currentUserId: '',
            description: '',
            amount: 0,
            invoiceDetail: [],
            venueOptions: [],
            selectedVenueOption: "All",
            venueSelectedItem: "",
            invoicePackage: "",
            allPackages: [],
            timestamp: '',
            payment_id: '',
            amount_discount: null,
            invoice_id: 0,
            payment_method: '',
            billing_period: '',
        }
    }

    componentDidMount = async () => {
        const script = document.createElement("script");
        script.src = "https://cdn.checkout.com/js/framesv2.min.js";
        script.async = true;
        this.setState({loader: true});
        document.body.appendChild(script);
        let result = [];

        let ip = await publicIp.v4();
        performRequest('get', 'api/subscriptions/', {ip: ip})
            .then(response => {
                this.setState({allPackages: response.data.results})
            });

        axios.all([
            performRequest('get', 'current_user/')
                .then(response => {
                    this.props.getCurrentUser(response.data.first_name, response.data.last_name, response.data.email, response.data.id)
                    this.setState({currentUserId: response.data.id})
                }),
            performRequest('get', 'api/transactions')
                .then(res => {
                    let venues = [];
                    venues.push({'value': 1, 'label': 'All'});
                    res.data.results.map(item =>
                        venues.push({value: item.venue, label: item.venue_name})
                    );
                    this.setState({
                        transaction: res.data.results,
                        venueOptions: venues
                    })
                }),

            performRequest('get', 'api/card/')
                .then(respo => {
                    respo.data.results.length &&
                    this.setState({
                        cardId: respo.data.results[0].id,
                        cardNumber: 'XXXX XXXX XXXX ' + respo.data.results[0].last_four_digits,
                        cardName: respo.data.results[0].card_scheme,
                        expiresOn: respo.data.results[0].expires_on
                    })
                }),

            performRequest('get', 'api/countries/')
                .then(response => {
                    response.data.results.map(item => {
                        result.push({value: item['iso'], label: item['name']})
                    });
                    performRequest('get', 'api/countries/?offset=100')
                        .then(response => {
                            response.data.results.map(item => {
                                result.push({value: item['iso'], label: item['name']})
                            });
                            performRequest('get', 'api/countries/?offset=200')
                                .then(response => {
                                    response.data.results.map(item => {
                                        result.push({value: item['iso'], label: item['name']})
                                    });
                                    this.setState({countries: [...result]}, function () {
                                        performRequest('get', '/api/billing-address')
                                            .then(res => {
                                                let temp = '';
                                                this.state.countries.map(each => {
                                                    if (res.data.results[0] !== undefined) {
                                                        if (each.value === res.data.results[0].country) {
                                                            temp = each;
                                                        }
                                                    }
                                                });
                                                if (res.data.results.length)
                                                    this.setState({
                                                        'id': res.data.results[0].id,
                                                        'email': res.data.results[0].email,
                                                        'full_name': res.data.results[0].full_name,
                                                        'address_line_1': res.data.results[0].address_line_1,
                                                        'address_line_2': res.data.results[0].address_line_2,
                                                        'city': res.data.results[0].city,
                                                        'state': res.data.results[0].state,
                                                        'zip_code': res.data.results[0].zip_code,
                                                        'tax_number': res.data.results[0].tax_number === 'undefined' ? '' : res.data.results[0].tax_number,
                                                        'tax_office': res.data.results[0].tax_office === 'undefined' ? '' : res.data.results[0].tax_office,
                                                        'country': temp,
                                                        'user': this.props.id,
                                                    })
                                            })
                                    })
                                })
                        })
                }).catch(error => {
                this.setState({isFetching: false})
            })
        ]).then(res => {
            this.setState({loader: false})
        })

    };

    handleVenue = (event, i) => {
        this.setState({
            selectedVenueOption: event.label,
            venueSelectedItem: event.label === "All" ? "" : event.label
        })
    };

    navControl = (e) => {
        e.preventDefault();
        this.setState({
            nav: !this.state.nav
        })
    };
    navControl = (e) => {
        const val = !this.state.navCollapsed;
        this.props.toggleCollapsedNav(val);
        // this.setState({
        //     nav: !this.state.nav
        // })
    };
    handleProfile = () => {
        // this.setState({profile: false})
        this.props.history.push('/profile');

    };
    onOptionBoxSelect = () => {
        this.setState({
            selectBox: !this.state.selectBox
        })
    };
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps
        })
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    print = (payment) => {


        this.state.allPackages.map(item =>
            item.id === payment.package &&
            this.setState({invoicePackage: item.description})
        );
        performRequest('get', '/api/invoice/2checkout/', {payment_id: payment.payment_id})
            .then(response => {
                this.setState({
                    description: response.data.description,
                    amount: response.data.amount,
                });
                let content = document.getElementById('printarea');

                let print = document.getElementById('ifmcontentstoprint').contentWindow;
                print.document.open();
                print.document.write(content.innerHTML);
                print.document.close();
                print.focus();
                print.print();

            })


    };

    download = (payment) => {
        window.html2canvas = html2canvas;
        let content = document.getElementById('pdfDocs');
        this.state.allPackages.map(item =>
            item.id === payment.package &&
            this.setState({invoicePackage: item.description})
        );
        performRequest('get', '/api/invoice/2checkout/', {payment_id: payment.payment_id})
            .then(response => {
                this.setState({
                    description: response.data.description,
                    amount: response.data.amount,
                    timestamp: response.data.timestamp,
                    payment_id: response.data.payment_id,
                    invoice_id: payment.id,
                    amount_discount: payment.amount_discount,
                    payment_method: payment.payment_method,
                    billing_period: payment.billing_period,
                }, function () {
                    // content.click()

                    let doc = new jsPDF("p", "pt", "a4");
                    doc.page = 1; // use this as a counter.
                    let totalPages = 10; // define total amount of pages
                    // HEADER
                    doc.setFontSize(20);//optional
                    doc.setTextColor(40);//optional
                    doc.setFontStyle('normal');//optional
                    doc.text("Report", 50, 22);// set your margins
                    // FOOTER
                    // let str = "Page " + doc.page  + " of " +  totalPages;
                    let str = "My Menu at info @mydigimenu.com";
                    doc.setFontSize(10);// optional
                    doc.text(str, 50, doc.internal.pageSize.height - 10);//key is the interal pageSize function

                    // doc.fontcolor("#514d4d")

                    // doc.html(content, {
                    //     callback: function (pdf) {
                    //         window.open(pdf.output('bloburl'));
                    //         // pdf.save("invoice.pdf");
                    //     }
                    // })
                    let srcwidth = content.scrollWidth;

                    doc.html(content, {
                        html2canvas: {
                            scale: 595.26 / srcwidth, //595.26 is the width of A4 page
                            scrollY: 0
                        },
                        filename: 'jspdf',
                        x: 0,
                        y: 0,
                        callback: function (pdf) {
                            // window.open(pdf.output('bloburl'));
                            pdf.save('invoice - ' + payment.id + '.pdf');
                        }
                    });
                    // this.addHtml(content.outerHTML, doc).then(function(){
                    //     doc.save('original.pdf');
                    // });
                });

            })


    };

    addHtml(html, doc) {
        var canvas = doc.canvas;
        canvas.pdf = doc;

        html = html.replace(/<script\b[^<]*(?:(?!<\/script>)<[^<]*)*<\/script>/gi, '');

        var div = document.createElement('div');
        div.setAttribute('style', 'position:fixed;left:15px; top:15px; display: none');
        div.setAttribute('id', 'hidden_div_123');
        document.body.insertBefore(div, document.body.firstChild);
        div.insertAdjacentHTML('beforeend', html);

        // Replace spaces with non-breaking space (nbsp)
        var tags = div.getElementsByTagName('*');
        for (var i = 0; i < tags.length; i++) {
            if (tags[i].children.length == 0 && tags[i].innerHTML.length > 0) {
                tags[i].innerHTML = tags[i].innerHTML.replace(/ /gm, '&nbsp;');
            }
        }

        return html2canvas(div.firstChild, {
            canvas: canvas,
            onclone: _onclone
        }).then(function (canvas) {
            if (div) {
                div.parentElement.removeChild(div);
            }
        });

        function _onclone(clone) {
            $(clone.getElementById('hidden_div_123')).show();
        }
    }

    download1 = (p) => {
        const input = document.getElementById('printareas');
        input.style.display = '';

        const divHeight = 29.7
        const divWidth = 3508
        const ratio = divHeight / divWidth;

        html2canvas(input, {scale: '1'}).then((canvas) => {
            input.style.display = 'none';

            const imgData = canvas.toDataURL('image/jpeg');
            const pdfDOC = new jsPDF("l", "mm", "a0"); //  use a4 for smaller page

            const width = pdfDOC.internal.pageSize.getWidth();
            let height = pdfDOC.internal.pageSize.getHeight();
            height = ratio * width;

            pdfDOC.addImage(imgData, 'JPEG', 0, 0, width - 20, height - 10);
            pdfDOC.save('summary.pdf');   //Download the rendered PDF.
        });
    }


//save Payment Method
    onSubmit = (event) => {
        event.preventDefault();
        window.Frames.submitCard().then(res => {
            if (!this.state.cardId) {

                performRequest('post', '/api/card/create/', {
                    "bin": res.bin,
                    "expires_on": res.expires_on,
                    "last_four_digits": res.last4,
                    "scheme": res.scheme,
                    "token": res.token,
                })
                    .then(response => {
                        NotificationManager.success(<IntlMessages id={"billing.payment_method_added_success"}/>);
                        this.setState({
                            handlePaymentMethod: false,
                        });
                        this.setState({
                            'cardId': response.data.id,
                            'cardNumber': 'XXXX XXXX XXXX ' + response.data.last_four_digits,
                            'cardName': response.data.card_scheme,
                            'expiresOn': response.data.expires_on
                        })
                    })
                    .catch(error => {
                        NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                    })
            } else {
                performRequest('put', '/api/card/update/' + this.state.cardId, null, {
                    "bin": res.bin,
                    "expires_on": res.expires_on,
                    "last_four_digits": res.last4,
                    "scheme": res.scheme,
                    "token": res.token,
                })
                    .then(response => {
                        NotificationManager.success(<IntlMessages id={"billing.payment_method_added_success"}/>);
                        this.setState({handlePaymentMethod: false})
                        this.setState({
                            'cardId': response.data.id,
                            'cardNumber': 'XXXX XXXX XXXX ' + response.data.last_four_digits,
                            'cardName': response.data.card_scheme,
                            'expiresOn': response.data.expires_on
                        })

                    })
                    .catch(error => {
                        NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                    })
            }
        });
    };
// Trigger Frame onModal PopUp
    onOpened = () => {
        let Frames = window.Frames;
        var payButton = document.getElementById("pay-button");
        var form = document.getElementById("payment-form");
        Frames.init({
            publicKey: this.state.publicKey,
            cardValidationChanged: () => {
                // if all fields contain valid information, the Pay now
                // button will be enabled and the form can be submitted
                form.disabled = !Frames.isCardValid();
                payButton.disabled = !Frames.isCardValid();
            },
            cardSubmitted: (e) => {
                // form.disabled = true;
                // display loader
            },
            cardTokenized: (data) => {
                // Frames.addCardToken(form, data.token)
                // form.submit()
            },
            cardTokenizationFailed: (event) => {
                // catch the error
            }
        });

    };

    saveData = () => {
        if (!this.state.country) {
            this.setState({countryWarning: true});
        } else {
            this.setState({countryWarning: false});

            const formData = new FormData();
            formData.append('email', this.state.email);
            formData.append('full_name', this.state.full_name);
            formData.append('address_line_1', this.state.address_line_1);
            formData.append('address_line_2', this.state.address_line_2);
            formData.append('city', this.state.city);
            formData.append('state', this.state.state);
            formData.append('zip_code', this.state.zip_code);
            formData.append('tax_number', this.state.tax_number === 'undefined' ? '' : this.state.tax_number);
            formData.append('tax_office', this.state.tax_office === 'undefined' ? '' : this.state.tax_office);
            formData.append('country', this.state.country ? this.state.country.value : '');
            // formData.append('user', this.props.id);
            formData.append('user', this.state.currentUserId);
            if (this.state.id) {
                performRequest('put', '/api/billing-address/' + this.state.id, null, formData)
                    .then(res => {
                        NotificationManager.success(<IntlMessages id={"billing.information_added_success"}/>);
                        this.setState({
                            id: res.data.id,
                            billingInformation: true
                        })
                    })
                    .catch(error => {
                        NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                    })
            } else {
                performRequest('post', '/api/billing-address/', formData)
                    .then(re => {
                        NotificationManager.success(<IntlMessages id={"billing.information_added_success"}/>);

                        this.setState({
                            id: re.data.id,
                            billingInformation: true
                        })
                    })
                    .catch(error => {
                        NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                    })
            }
        }

    };

    handleChange = (e, event) => {

        this.setState({[event.name]: e})
    };

    getChanges = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({warningNoInfo: false})

    };

    paymentUpdateHandler = () => {
        // alert(this.state.handlePaymentMethod)
        this.setState({
            handlePaymentMethod: !this.state.handlePaymentMethod,
        });
        this.onOpened();
    };


    render() {
        const ref = React.createRef();

        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';
        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        return (

            <div className={`app-container ${drawerStyle}`}>
                <div className={'navInMin'} style={{zIndex: '10'}}>
                    {/*<ProfileSidenav/>*/}
                    <Sidenav/>

                </div>
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <AppBar
                            className={`app-main-header customGradient ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>

                            <Toolbar className="app-toolbar" disableGutters={false}>

                                {this.props.nav ? <div className='menuCustom' onClick={this.props.navControl}>
                                        <i className="zmdi zmdi-close zmdi-hc-2x"/>
                                    </div> :
                                    <div className='menuCustom' onClick={this.navControl}>
                                        {/*<i className="zmdi zmdi-menu zmdi-hc-2x"/>*/}
                                        <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                                    </div>
                                }
                                <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard/default">
                                    <img src={require('./../../src/assets/images/logo.png')} alt="mydine"
                                         title="mymenu"/>
                                </Link>

                                <div style={{paddingLeft: 40, paddingTop: 5}}>
                                    <Button className="btn btn-secondary cardButton1Detail float-right"
                                            onClick={() => this.props.history.push('/app/dashboard/default')}>
                                        <IntlMessages id="header.back_to_dashboard"/>

                                    </Button>
                                </div>
                                {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                                <Menu/>}

                                <ul className="header-notifications list-inline ml-auto">
                                    <li className="d-inline-block d-lg-none list-inline-item">
                                        <Dropdown
                                            className="quick-menu nav-searchbox"
                                            isOpen={this.state.selectBox}
                                            toggle={this.onOptionBoxSelect.bind(this)}>
                                            <DropdownMenu right className="p-0">
                                                <Select styleName="search-dropdown"
                                                        onChange={this.handleChange}
                                                        value={this.props.selectedVenue}
                                                        options={this.props.venueList}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                    {/*Apps Icons*/}
                                    <li className="list-inline-item  d-lg-none">
                                        <Dropdown
                                            className="quick-menu app-notification"
                                            isOpen={this.state.apps}
                                            toggle={this.onAppsSelect.bind(this)}>

                                            <DropdownToggle
                                                className="d-inline-block hov"
                                                tag="span"
                                                data-toggle="dropdown">

                                                {/*<span className="app-notification-menu">*/}
                                                {/*  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>*/}
                                                {/*  <span>Apps</span>*/}
                                                {/*</span>*/}
                                            </DropdownToggle>

                                            <DropdownMenu style={{width: 200}}>
                                                {/*{this.Apps()}*/}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>


                                    <li className="list-inline-item" style={{marginLeft: '30px'}}>
                                        <UserInfo {...this.props}
                                                  handleProfile={this.props.handleProfile}/>

                                    </li>
                                </ul>
                            </Toolbar>
                        </AppBar>
                    </div>


                    <main className="app-main-content-wrapper">
                        {this.state.loader ? <LoadingOverlay
                                active={this.state.loader}
                                styles={{

                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0, 0, 0, 0)'
                                    })
                                }}
                                spinner={<ScaleLoader
                                    sizeUnit={"px"}
                                    size={35}
                                    color={TABLE_HEAD_COLOR}
                                    loading={this.state.loader}

                                />}
                            >
                                <div style={{height: '400px'}}/>
                            </LoadingOverlay> :
                            <div className="container">
                                <br/>
                                <br/>
                                {/*<div className='col-md-12'>*/}
                                {/*<h3>Billing</h3>*/}
                                {/*</div>*/}
                                <div className='col-md-12'>
                                    <h2><IntlMessages id="billing.billing_information"/>
                                    </h2>
                                </div>

                                {!this.state.id ? this.state.handleBillingInfo ?
                                    <Row>
                                        <Col xs='6'>
                                            <div className="container">
                                                <span><IntlMessages id="billing.no_payment_method_found"/></span>
                                            </div>
                                        </Col>
                                        <Col xs='6'>
                                            <Button color="primary"
                                                    onClick={() => this.setState({handleBillingInfo: false})}>
                                                <IntlMessages id="billing.add_now"/>
                                            </Button>
                                        </Col>
                                    </Row> :
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="common.name"/></label>
                                                    </Col>
                                                    <Col xs='4'>


                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'full_name'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.full_name}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.address_line"/> 1</label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'address_line_1'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.address_line_1}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.address_line"/> 2</label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'address_line_2'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.address_line_2}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages id="common.country"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <Select
                                                            name={'country'}
                                                            onChange={this.handleChange}
                                                            value={this.state.country}
                                                            options={this.state.countries}
                                                        />
                                                    </Col>

                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.tax_number"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'tax_number'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.tax_number}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>

                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='' className='align-self-center'>
                                                        <Button color="primary"
                                                                onClick={this.saveData}><IntlMessages id="common.save"/></Button>

                                                        <Button color="secondary"
                                                                onClick={() => this.setState({
                                                                    handleBillingInfo: !this.state.handleBillingInfo
                                                                })}><IntlMessages id="common.cancel"/></Button>
                                                    </Col>

                                                </Row>

                                            </div>

                                        </div>
                                        <div className="col-md-6">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages
                                                        id="common.email"/>*</label>
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <input name={'email'}
                                                           onChange={(e) => this.getChanges(e)}
                                                           value={this.state.email}
                                                           className="form-control"/>
                                                </Col>
                                            </Row>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="header.city"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'city'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.city}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="header.state"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'state'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.state}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.zip_code"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'zip_code'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.zip_code}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.tax_office"/></label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'tax_office'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.tax_office}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>

                                    </div> : ''
                                }

                                {this.state.id ? this.state.billingInformation ?
                                    <Row>
                                        <div className='col-md-6'>
                                            <div className="container">
                                                {this.state.full_name &&
                                                <span>{this.state.full_name} {this.state.full_name !== '' && this.state.email !== '' ? '-' : ''} {this.state.email}<br/></span>
                                                }
                                                {this.state.address_line_1 &&
                                                <span>{this.state.address_line_1}<br/></span>
                                                }
                                                {this.state.address_line_2 &&
                                                <span>{this.state.address_line_2}<br/></span>
                                                }
                                                {this.state.state &&
                                                <span>{this.state.state}{this.state.state !== '' && this.state.zip_code !== '' ? ',' : ''} {this.state.zip_code}<br/></span>
                                                }
                                                {this.state.country.label &&
                                                <span>{this.state.country.label}</span>
                                                }
                                            </div>
                                        </div>
                                        <Col xs='6'>
                                            <Button color="primary"
                                                    onClick={() => this.setState({billingInformation: false})}><IntlMessages
                                                id="common.update"/></Button>
                                        </Col>
                                    </Row>
                                    :
                                    <div className="row">
                                        <div className="col-md-6">
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="common.name"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'full_name'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.full_name}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.address_line"/> 1</label>
                                                    </Col>
                                                    <Col xs='4'>

                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'address_line_1'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.address_line_1}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.address_line"/> 2</label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'address_line_2'}
                                                               value={this.state.address_line_2}
                                                               onChange={(e) => this.getChanges(e)}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages id="common.country"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        {/*<input name={'country'}*/}
                                                        {/*value={this.state.country}*/}
                                                        {/*onChange={(e) => this.getChanges(e)}*/}
                                                        {/*className="form-control"/>*/}
                                                        <Select
                                                            name={'country'}
                                                            onChange={this.handleChange}
                                                            value={this.state.country}
                                                            options={this.state.countries}
                                                        />
                                                        {this.state.countryWarning ?
                                                            <p style={{color: 'red'}}><IntlMessages
                                                                id="common.country_required"/></p> : ''}
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.tax_number"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'tax_number'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.tax_number}
                                                               className="form-control"/>

                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='' className='align-self-center'>
                                                        <Button color="primary"
                                                                onClick={this.saveData}><IntlMessages id="common.save"/></Button>

                                                        <Button color="secondary"
                                                                onClick={() => this.setState({
                                                                    billingInformation: true,
                                                                    countryWarning: false
                                                                })}>Cancel</Button>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                        <div className="col-md-6">
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages id="common.email"/>*</label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'email'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.email}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="header.city"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'city'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.city}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="header.state"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'state'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.state}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.zip_code"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'zip_code'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.zip_code}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                            <br/>
                                            <div className="container">
                                                <Row>
                                                    <Col xs='8' className='align-self-center'>
                                                        <label className="d-inline "><IntlMessages
                                                            id="billing.tax_office"/></label>
                                                    </Col>
                                                    <Col xs='4'>
                                                    </Col>
                                                </Row>
                                                <Row>
                                                    <Col xs='12' className='align-self-center'>
                                                        <input name={'tax_office'}
                                                               onChange={(e) => this.getChanges(e)}
                                                               value={this.state.tax_office}
                                                               className="form-control"/>
                                                    </Col>
                                                </Row>
                                            </div>
                                        </div>
                                    </div> : ''
                                }
                                <div className="row"
                                     style={{display: this.state.handlePaymentMethod ? '' : 'none'}}>
                                    <div className="col-md-12">
                                        <div className="container">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    {/*<label className="d-inline ">Name On Card</label>*/}
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    {/*<script src='https://cdn.checkout.com/js/framesv2.min.js'/>*/}
                                                    <form id="payment-form"
                                                          className='pull-left'
                                                          onSubmit={this.onSubmit}
                                                          method="POST"
                                                          action="https://merchant.com/charge-card"
                                                    >
                                                        <div className="one-liner">
                                                            <div className="card-frame"/>
                                                            <div style={{paddingTop: '3px'}}>
                                                                <Button id="pay-button"
                                                                        className='secondary'
                                                                        style={{
                                                                            width: '80px',
                                                                            height: '32px',
                                                                            backgroundColor: '#69f0ae'
                                                                        }}
                                                                        disabled>
                                                                    <IntlMessages id="common.save"/>
                                                                </Button>
                                                                <Button
                                                                    style={{
                                                                        width: '80px',
                                                                        height: '32px',
                                                                        backgroundColor: '#69f0ae'
                                                                    }}

                                                                    onClick={() => this.setState({handlePaymentMethod: !this.state.handlePaymentMethod})}><IntlMessages
                                                                    id="common.cancel"/>
                                                                </Button>
                                                            </div>
                                                        </div>
                                                        <p className="error-message"/>
                                                        <p className="success-payment-message"/>
                                                    </form>
                                                </Col>
                                            </Row>
                                        </div>
                                    </div>

                                </div>
                                <br/>
                                <br/>
                                <div className='col-md-12'>
                                    <h2><IntlMessages id="billing.billing_history"/>
                                    </h2>
                                </div>
                                <div className="col-md-12">
                                    <h4><IntlMessages id="billing.view_invoices"/>
                                    </h4>
                                </div>
                                <Row className='mr-0 ml-0'>
                                    <Col md={6} lg={8}>
                                        <h2><IntlMessages id="billing.invoices"/></h2>
                                    </Col>
                                    <Col md={6} lg={4}>
                                        <Select styleName="search-dropdown"
                                                onChange={(e, i) => this.handleVenue(e, i)}
                                                value={this.state.venueOptions.map(i =>
                                                    i.label === this.state.selectedVenueOption && i
                                                )}
                                                name='type'
                                                options={this.state.venueOptions}
                                        />
                                    </Col>
                                </Row>
                                <br/>
                                <div className="col-md-12">
                                    <Table
                                        className="table table-hover m-table m-table--head-bg-brand table-responsive-material">
                                        <TableHead>
                                            <tr>
                                                <th padding="none"
                                                    style={{backgroundColor: TABLE_HEAD_COLOR}}><IntlMessages
                                                    id="dashboard.date"/>
                                                </th>
                                                <th numeric
                                                    style={{backgroundColor: TABLE_HEAD_COLOR}}><IntlMessages
                                                    id="order.venue"/>
                                                </th>
                                                <th padding="none"
                                                    style={{backgroundColor: TABLE_HEAD_COLOR}}><IntlMessages
                                                    id="common.amount"/>
                                                </th>
                                                <th numeric
                                                    style={{backgroundColor: TABLE_HEAD_COLOR}}><IntlMessages
                                                    id="dashboard.status"/>
                                                </th>
                                                <th numeric
                                                    style={{backgroundColor: TABLE_HEAD_COLOR}}>
                                                </th>
                                            </tr>
                                        </TableHead>
                                        <tbody>
                                        {this.state.transaction.map(each => {
                                            if (each.venue_name.search(this.state.venueSelectedItem) !== -1) {
                                                return (
                                                    <tr>
                                                        <td>{moment(each.timestamp).format('MMM D, Y')}</td>
                                                        <td>{each.venue_name}</td>
                                                        <td>{"$ " + parseFloat(each.total_price).toFixed(2)}</td>
                                                        <td>
                                                            <span
                                                                className="jr-link badge text-white text-uppercase bg-green">{each.status}</span>
                                                        </td>
                                                        <td>
                                                            <Button color="primary">
                                                                <a style={{cursor: 'pointer'}}
                                                                   onClick={() => this.download(each)}><IntlMessages
                                                                    id="common.Download"/></a>
                                                            </Button>


                                                            {/*<a style={{cursor: 'pointer'}}*/}
                                                            {/*   onClick={() => this.print(each)}><IntlMessages*/}
                                                            {/*    id="billing.invoices"/></a>*/}
                                                        </td>
                                                    </tr>
                                                )
                                            }
                                        })}

                                        </tbody>

                                    </Table>
                                </div>
                            </div>
                        }
                    </main>
                    <Footer/>
                    <div id='printsss'
                         style={{display: 'none', justifyContent: 'center'}}
                    >
                        <div>

                            <table style={{width: '100%'}}>
                                <tr>
                                    <td>imgix</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>sdfassdfsdf</td>
                                </tr>
                                <tr>
                                    <td>423 Tehama St.</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>423 Tehama St. 2008-1409</td>
                                </tr>
                                <tr>
                                    <td>San Francisco CA 94103</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>Invoice number 7627F131-0015</td>
                                </tr>
                                <tr>
                                    <td>United States</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>Date paid May 2, 2021</td>
                                </tr>
                                <tr>
                                    <td>+1 415-484-5964</td>
                                    <td></td>
                                    <td style={{textAlign: "right"}}>Payment method – 2002</td>
                                </tr>

                            </table>
                        </div>

                    </div>

                </div>
                <ReactToPdf targetRef={ref} filename="invoice.pdf">
                    {({toPdf}) => (
                        <button id='toPDF' onClick={toPdf} style={{display: 'none'}}>Generate pdf</button>
                    )}
                </ReactToPdf>
                <iframe id="ifmcontentstoprint" style={{
                    height: '0px',
                    width: '0px',
                    display: 'none',
                    position: 'absolute'
                }}>
                </iframe>
                <NotificationContainer/>

                <div id='printarea'
                     style={{position: "absolute", zIndex: '-1'}}
                >
                    <div>
                        <img
                            src={require('../assets/images/logo_single_line_golden.png')}
                            alt="mymenu" title="MyMenu"
                            style={{height: '25px', width: '110px', position: 'absolute', right: 0, top: 0}}/>

                        <div style={{width: '100%', float: 'left', margin: '5px'}}>
                            <h2>Mymenu - Techmte.</h2>
                            <div style={{width: '50%'}}>
                                <p>Abhi Bose
                                    Office 1001, Sohba Sapphire, Business
                                    Bay
                                    P O Box 53675
                                    Dubai Dubai 1
                                    United Arab Emirates
                                    abhi@techmte.com
                                </p>
                            </div>
                        </div>
                    </div>
                    <div style={{
                        width: '60%',
                        float: 'left',
                        margin: '5px'
                    }}>
                        <div
                            style={{
                                width: '60%',
                            }}>
                            <p><b>Bill to</b><br/>
                                <span>{this.state.full_name} - {this.state.email}</span><br/>
                                {this.state.address_line_1 &&
                                <span>{this.state.address_line_1}<br/></span>
                                }
                                {this.state.address_line_2 &&
                                <span>{this.state.address_line_2}<br/></span>
                                }
                                {(this.state.state || this.state.zip_code) &&
                                <span>{this.state.state}, {this.state.zip_code}</span>
                                }
                            </p>
                        </div>
                    </div>
                    <div style={{
                        width: '60%',
                        float: 'left',
                        margin: '5px'
                    }}>
                        {/*<h2>$18.00 due August 31, 2019*/}
                        {/*</h2>*/}
                    </div>
                    <div style={{
                        width: '100%',
                        float: 'left',
                        margin: '5px'
                    }}>

                        <table
                            className='table-striped'
                            style={{
                                borderBottom: '1px solid black',
                                borderCollapse: 'collapse'
                            }}>
                            <thead>
                            <tr style={{}}>
                                <th style={{
                                    textAlign: 'left',
                                    width: '25%',
                                    height: '50px',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'
                                }}>
                                    Description
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'
                                }}>
                                    Qty
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '35%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'


                                }}>
                                    Unit price
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'

                                }}>
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                style={{
                                    height: '50px',
                                    backgroundColor: '#9999CC',
                                }}
                            >
                                <td style={{
                                    width: '25%',
                                    backgroundColor: 'yellowgreen',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse',
                                }}>
                                    {this.state.invoicePackage}

                                </td>

                                <td style={{
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse',

                                }}>
                                    1
                                </td>
                                <td style={{
                                    width: '35%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse',

                                }}>
                                    {"$ " + this.state.amount}
                                </td>
                                <td style={{
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse',

                                }}>
                                    {"$ " + this.state.amount}
                                </td>
                            </tr>
                            <tr className=''>
                                <td style={{
                                    width: '40%',
                                    paddingTop: 20
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                    paddingTop: 20
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                    paddingTop: 20
                                }}>
                                    Subtotal
                                </td>
                                <td style={{
                                    width: '20%',
                                    paddingTop: 20
                                }}>
                                    {"$ " + this.state.amount}
                                </td>
                            </tr>
                            <tr
                                style={{
                                    height: '50px'
                                }}
                            >
                                <td style={{
                                    width: '40%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    Discount (0% off)
                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    {"$ " + 0.00}
                                </td>
                            </tr>
                            <tr style={{
                                height: '50px',
                            }}
                            >
                                <td style={{
                                    width: '40%',

                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    Amount due
                                </td>
                                <td style={{
                                    width: '20%',

                                }}>
                                    {"$ " + this.state.amount}
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                    {/*<div style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    bottom: '80px'*/}
                    {/*}}>*/}
                    {/*    <p>Pay with card*/}
                    {/*        Visit pay.stripe.com/invoice/invst_1e75BAVIKGt8h1fGewTcdZPabI</p>*/}
                    {/*    <p>Questions? Contact FineDine - Bambulabs, Inc. at support@finedinemenu.com or call*/}
                    {/*        at +1 415-688-7217</p>*/}
                    {/*</div>*/}
                </div>
                {/*//---------------------------------------------------------------------------------------*/}

                <div
                    style={{
                        position: "absolute",
                        zIndex: '-9999',
                        width: '37rem',
                        // color: "#514d4d",
                        color: "#757474",
                        padding: 40
                    }}
                    ref={ref}
                    id="pdfDocs"
                    className="container"
                >
                    <div className="container" style={{marginTop: '20px'}}>
                        <table
                            style={{
                                borderCollapse: 'collapse',
                                fontSize: 10
                            }}>
                            <thead>
                            <tr>
                                <td style={{
                                    textAlign: 'left',
                                    width: '40%',
                                }}>
                                    <div>
                                        <h3 style={{color: "#514d4d"}}>My Menu</h3>
                                        1408, Armada Bluebay<br/>
                                        Cluster P, JLT<br/>
                                        Dubai<br/>
                                        United Arab Emirates<br/>
                                        info@mydigimenu.com<br/>

                                        {/*Abhi Bose<br/>*/}
                                        {/*Office 1001, Sohba Sapphire,<br/>*/}
                                        {/*Business Bay<br/>*/}
                                        {/*P O Box 53675<br/>*/}
                                        {/*Dubai Dubai 1<br/>*/}
                                        {/*United Arab Emirates<br/>*/}
                                        {/*abhi@techmte.com<br/>*/}
                                    </div>
                                </td>
                                <td style={{
                                    textAlign: 'left',
                                    width: '10%',
                                }}>

                                </td>
                                <td style={{
                                    width: '10%',
                                }}>
                                </td>
                                <td style={{
                                    width: '40%',
                                    textAlign: 'right'
                                }}>
                                    <div>
                                        <h3 style={{marginTop: '-35px', float: "right"}}>Receipt </h3>
                                    </div>
                                    <div style={{width: 200}}>
                                        <p style={{float: "left"}}>Receipt number</p>
                                        <p style={{float: "right"}}>#1001-{this.state.invoice_id}</p>
                                        <br/>
                                        <p style={{float: "left"}}>Invoice number</p>
                                        <p style={{float: "right"}}>#1001-{this.state.invoice_id}</p>
                                        <br/>
                                        <p style={{float: "left"}}>Date paid</p>
                                        <p style={{float: "right"}}>{moment(this.state.timestamp).format('MMM D, Y')}</p>
                                        <br/>
                                        {this.state.payment_method &&
                                        <>
                                            <p style={{float: "left"}}>Payment method</p>
                                            <p style={{float: "right"}}></p>
                                        </>
                                        }

                                    </div>
                                </td>
                            </tr>
                            <tr>
                                <td style={{
                                    textAlign: 'left',
                                    width: '40%',
                                    borderCollapse: 'collapse'
                                }}>
                                    <div style={{marginTop: 10}}>
                                        <b style={{color: "#514d4d"}}>Paid By</b><br/>
                                        {this.state.full_name &&
                                        <span>{this.state.full_name} {this.state.full_name !== '' && this.state.email !== '' ? '-' : ''} {this.state.email}<br/></span>
                                        }
                                        {this.state.address_line_1 &&
                                        <span>{this.state.address_line_1}<br/></span>
                                        }
                                        {this.state.address_line_2 &&
                                        <span>{this.state.address_line_2}<br/></span>
                                        }
                                        {this.state.state &&
                                        <span>{this.state.state}{this.state.state !== '' && this.state.zip_code !== '' ? ',' : ''} {this.state.zip_code}<br/></span>
                                        }
                                        {this.state.country.label &&
                                        <span>{this.state.country.label}</span>
                                        }
                                    </div>
                                </td>
                                <td style={{
                                    textAlign: 'left',
                                    width: '10%',
                                    borderCollapse: 'collapse'
                                }}>

                                </td>
                                <td style={{
                                    textAlign: 'left',
                                    width: '16%',
                                    borderCollapse: 'collapse'
                                }}>

                                </td>
                                <td style={{
                                    textAlign: 'right',
                                    width: '40%',
                                    borderCollapse: 'collapse',
                                }}>

                                </td>
                            </tr>

                            </thead>
                        </table>
                        {/*<div>*/}

                        <h1 style={{marginTop: '20px', color: "#514d4d"}}>${this.state.amount.toFixed(2)} paid
                            on {moment(this.state.timestamp).format('MMM D, Y')} </h1>
                        {
                            this.state.billing_period &&
                            <p style={{fontSize: 10}}>Billing Period: {this.state.billing_period}</p>
                        }
                        {/*-----------------------------------------------------------------------------------------------------*/}
                        <div style={{padding: 2, marginTop: 20}}>
                            <table
                                // className='table-striped'
                                style={{borderCollapse: 'collapse'}}>
                                <thead>
                                <tr>
                                    <th style={{
                                        textAlign: 'left',
                                        width: '25%',
                                        height: '25px',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingLeft: 8,
                                        paddingBottom: '8px',
                                        color: "#514d4d"
                                    }}> Description
                                    </th>
                                    <th style={{
                                        textAlign: 'left',
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        color: "#514d4d"
                                    }}> Qty
                                    </th>
                                    <th style={{
                                        textAlign: 'left',
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingLeft: 8,
                                        color: "#514d4d"
                                    }}> Unit price
                                    </th>
                                    <th style={{
                                        textAlign: 'left',
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingRight: 8,
                                        paddingBottom: '8px',
                                        color: "#514d4d"
                                    }}> Amount
                                    </th>
                                </tr>
                                </thead>
                                <tbody>
                                <tr style={{}}>
                                    <td style={{
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingLeft: 8,
                                        color: "#514d4d"
                                    }}> {this.state.invoicePackage} </td>
                                    <td style={{
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px'
                                    }}> 1
                                    </td>
                                    <td style={{
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingLeft: 8
                                    }}> {"$ " + this.state.amount.toFixed(2)} </td>
                                    <td style={{
                                        width: '25%',
                                        borderBottom: '1px solid #dee2e6',
                                        borderCollapse: 'collapse',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingRight: 8,
                                        color: "#514d4d"
                                    }}> {"$ " + this.state.amount.toFixed(2)} </td>
                                </tr>
                                <tr className=''>
                                    <td style={{width: '38%', paddingTop: 20}}></td>
                                    <td style={{width: '15%', paddingTop: 20}}></td>
                                    <td style={{
                                        width: '35%', fontSize: 10, paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingLeft: 8,
                                        color: "#514d4d"
                                    }}> Subtotal
                                    </td>
                                    <td style={{
                                        width: '20%', fontSize: 10, paddingBottom: '8px',
                                        paddingTop: '3px', paddingRight: 8, color: "#514d4d"
                                    }}> {"$ " + this.state.amount.toFixed(2)} </td>
                                </tr>
                                {this.state.amount_discount &&
                                <tr style={{
                                    height: '25px',

                                }}>
                                    <td style={{width: '38%', backgroundColor: 'white'}}>

                                    </td>
                                    <td style={{
                                        width: '15%',
                                        backgroundColor: 'white'
                                    }}>
                                    </td>
                                    <td style={{
                                        width: '35%',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingLeft: 8,
                                        color: "#514d4d"
                                    }}>
                                        Discount (0% off)
                                    </td>
                                    <td style={{
                                        width: '20%',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingRight: 8,
                                        color: "#514d4d"
                                    }}>
                                        {"$ " + 0.00}
                                    </td>
                                </tr>
                                }
                                <tr style={{height: '25px',}}>
                                    <td style={{width: '38%', backgroundColor: 'white'}}></td>
                                    <td style={{width: '15%', backgroundColor: 'white'}}></td>
                                    <td style={{
                                        width: '35%',
                                        borderBottom: '1px solid #dee2e6',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        backgroundColor: "#dee2e6",
                                        paddingLeft: 8,
                                        color: "#514d4d"
                                    }}> Amount due
                                    </td>
                                    <td style={{
                                        width: '20%',
                                        borderBottom: '1px solid #dee2e6',
                                        fontSize: 10,
                                        paddingBottom: '8px',
                                        paddingTop: '3px',
                                        paddingRight: 8,
                                        color: "#514d4d",
                                        backgroundColor: "#dee2e6"
                                    }}> {"$ " + this.state.amount.toFixed(2)} </td>
                                </tr>
                                </tbody>

                            </table>
                        </div>

                        {/*</div>*/}
                    </div>


                    {/*<div style={{*/}
                    {/*    position: "fixed",*/}
                    {/*    bottom: 0*/}
                    {/*}}>*/}
                    {/*    <p style={{borderTop: '1px solid black'}}>Questions? Contact Mymenu at*/}
                    {/*        abhi@techmte.com </p>*/}

                    {/*</div>*/}
                </div>

            </div>
        )
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;

    return {
        drawerType, navigationStyle, horizontalNavPosition,
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues,
        id: auth.id,
    }
};

export default connect(mapStateToProps, {
    showAuthLoader,
    getCurrentUser,
    toggleCollapsedNav

})(Checkout);
