import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import {
    showAuthLoader,
} from "actions/Auth";
import { selectVenue, } from './../actions/Venue';

import Menu from 'components/TopNav/Menu';
import ProfileSidenav from 'containers/ProfileSidenav/index';
import Footer from 'components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER

} from 'constants/ActionTypes';
import {isIOS, isMobile} from "react-device-detect";
import TopNav from 'components/TopNav';
import Toolbar from '@material-ui/core/Toolbar';
import {
    Button,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText, Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Progress,
    Row,
} from "reactstrap";
// import Select from "../components/Header";
import Select from 'react-select';

import UserInfo from 'components/UserInfo';
import TableBody from '@material-ui/core/TableBody';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableCell from '@material-ui/core/TableCell';
import TableRow from '@material-ui/core/TableRow';
import './profile.css'
import {
    ERROR,
    ERROR_MESSAGE, EXPORT_HEADER,
    MERCHANT_CODE,
    MERCHANT_CODE_TEST,
    PUBLISHABLE_KEY,
    PUBLISHABLE_KEY_TEST,
    TABLE_HEAD_COLOR,
    CHECKOUT_PUB_KEY
} from '../constants/constant'
import {performRequest} from "./services/apiHandler";
import Sidenav from "../containers/SideNav/index";
import {toggleCollapsedNav} from "../actions/Setting"
import PropTypes from "prop-types";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import DateTimeRangeContainer from "react-advanced-datetimerange-picker";
import moment from "moment";
import LoadingOverlay from "react-loading-overlay";
import {ScaleLoader} from "react-spinners";
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from "../util/IntlMessages";
import publicIp from "public-ip";
import { injectIntl } from 'react-intl';
import {intlLoader} from 'util/intlLoader';

const style = {
    padding: 0
}



class DataTableHead extends React.Component {
    static propTypes = {
        numSelected: PropTypes.number.isRequired,
        onRequestSort: PropTypes.func.isRequired,
        onSelectAllClick: PropTypes.func.isRequired,
        order: PropTypes.string.isRequired,
        orderBy: PropTypes.string.isRequired,
        rowCount: PropTypes.number.isRequired,
    };

    createSortHandler = property => event => {
        this.props.onRequestSort(event, property);
    };

    render() {
        const {onSelectAllClick, order, orderBy, numSelected, rowCount} = this.props;
        const columnData = [
            {id: 'venuename', numeric: false, disablePadding: false, label: intlLoader('order.venue', this.props.intl)},
            {id: 'current_package', numeric: false, disablePadding: false, label: intlLoader('sidebar.plan', this.props.intl)},
            {id: 'type', numeric: false, disablePadding: false, label: intlLoader('common.amount', this.props.intl)},
            {id: 'next_billing_date', numeric: false, disablePadding: false, label: intlLoader('billing.next_billing', this.props.intl)},
            {id: 'expiry_date', numeric: false, disablePadding: false, label: intlLoader('billing.expiry_date', this.props.intl)},
            {id: 'status', numeric: false, disablePadding: false, label: intlLoader('dashboard.status', this.props.intl)},
            {id: 'lastscanned', numeric: false, disablePadding: false, label: intlLoader('common.actions', this.props.intl)},
        ];

        return (
            <TableHead>
                <TableRow>
                    {columnData.map(column => {
                        return (
                            <TableCell
                                key={column.id}
                                numeric={column.numeric}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={{backgroundColor: TABLE_HEAD_COLOR}}
                            >
                                <Tooltip
                                    title="Sort"
                                    placement={column.numeric ? 'bottom-end' : 'bottom-start'}
                                    enterDelay={300}
                                >
                                    <TableSortLabel
                                        active={orderBy === column.id}
                                        direction={order}
                                        onClick={this.createSortHandler(column.id)}
                                    >
                                        <b> {column.label}</b>
                                    </TableSortLabel>
                                </Tooltip>
                            </TableCell>
                        );
                    }, this)}
                </TableRow>
            </TableHead>
        );
    }
}


class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            nav: true,
            profile: true,
            plans: [],
            selected: [],
            renewModal: false,
            renewSelectedVenue: {},
            renewPlanId: 0,
            spinner: false,
            symbol: '',
            renewAmount: 0,
            billingInformation: true,
            full_name: '',
            email: '',
            address_line_1: '',
            address_line_2: '',
            zip_code: '',
            countries: [],
            user: 0,
            isFetching: false,
            cancelSubModal: false,

            ccNo: '',
            expMonth: '',
            expYear: '',
            cvv: '',
            sellerId: MERCHANT_CODE_TEST,
            publishableKey: PUBLISHABLE_KEY_TEST,
            checkoutError: '',
        }
    }

    componentDidMount() {
        const script = document.createElement("script");
        script.src = "https://cdn.checkout.com/js/framesv2.min.js";
        script.async = true;

        document.body.appendChild(script);

        this.loadData()

    }

    loadData = () => {
        let result = [];
        this.setState({isFetching: true});

        performRequest('get', 'api/plans/')
            .then(res => {
                res.data.results.filter(each => {
                    each['venuename'] = each.venue.venueName
                });
                console.log(res.data.results, 'res.data.resultsres.data.results')
                this.setState({
                    plans: res.data.results,
                })

            });

        performRequest('get', 'api/countries/')
            .then(response => {
                response.data.results.map(item => {
                    result.push({value: item['iso'], label: item['name']})
                });
            
                this.setState({countries: [...result]}, function () {
                    performRequest('get', '/api/billing-address')
                        .then(res => {
                            let temp = '';
                            this.state.countries.map(each => {
                                if (res.data.results[0] !== undefined)
                                    if (each.value === res.data.results[0].country)
                                        temp = each;

                            });
                            if (res.data.results.length)
                                this.setState({
                                    id: res.data.results[0].id,
                                    'email': res.data.results[0].email,
                                    'full_name': res.data.results[0].full_name,
                                    'address_line_1': res.data.results[0].address_line_1,
                                    'address_line_2': res.data.results[0].address_line_2,
                                    'city': res.data.results[0].city,
                                    'state': res.data.results[0].state,
                                    'zip_code': res.data.results[0].zip_code,
                                    'tax_number': res.data.results[0].tax_number,
                                    'tax_office': res.data.results[0].tax_office,
                                    'country': temp,
                                    user: res.data.results[0].user,
                                    isFetching: false
                                });
                            else
                                this.setState({
                                    'addNew': true,
                                    isFetching: false
                                })
                        })
                })
    
            })
            .catch( error =>{
                this.setState({isFetching: false})
            });

        performRequest('get', 'api/card/')
            .then(respo => {
                console.log("CARD DETAIL:  ", respo)
                respo.data.results.length &&
                this.setState({
                    cardId: respo.data.results[0].id,
                    cardNumber: 'XXXX XXXX XXXX ' + respo.data.results[0].last_four_digits,
                    cardName: respo.data.results[0].card_scheme,
                    expiresOn: respo.data.results[0].expires_on
                })
            });
    }

    getChanges = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        });
        this.setState({warningNoInfo: false})

    };

    // Save Billing Information
    saveData = () => {
        const formData = new FormData();
        formData.append('email', this.state.email);
        formData.append('full_name', this.state.full_name);
        formData.append('address_line_1', this.state.address_line_1);
        formData.append('address_line_2', this.state.address_line_2);
        formData.append('city', this.state.city);
        formData.append('state', this.state.state);
        formData.append('zip_code', this.state.zip_code);
        formData.append('tax_number', this.state.tax_number === 'undefined' ? '' : this.state.tax_number);
        formData.append('tax_office', this.state.tax_office === 'undefined' ? '' : this.state.tax_office);
        formData.append('country', this.state.country ? this.state.country.value : '');
        formData.append('user', this.state.user);
        if (this.state.id) {
            performRequest('put', '/api/billing-address/' + this.state.id, null, formData)
                .then(res => {
                    NotificationManager.success(<IntlMessages id={"billing.information_added_success"}/>);
                    this.setState({
                        id: res.data.id,
                        billingInformation: true

                    })
                })
                .catch(error => {
                    NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                })
        } else {
            performRequest('post', '/api/billing-address/', formData)
                .then(re => {
                    NotificationManager.success(<IntlMessages id={"billing.information_added_success"}/>);

                    this.setState({
                        id: re.data.id,
                        billingInformation: true
                    })
                })
                .catch(error => {
                    NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                })
        }
    };

    //save Payment Method
    checkPayment = () => {
     
        this.setState({
            checkoutError: '',
            spinner: true
        });
        if(!this.state.cardId){
            window.Frames.submitCard().then(res => {
                performRequest('post', '/api/card/create/', {
                    "bin": res.bin,
                    "expires_on": res.expires_on,
                    "last_four_digits": res.last4,
                    "scheme": res.scheme,
                    "token": res.token,
                })
                    .then(response => {
                        console.log('card response .........')
                        this.updatePlanCheckout()
        
                    })
                    .catch(error => {
                        this.setState({spinner:false, renewModal: false})
                        NotificationManager.error(<IntlMessages id="billing.card_not_acceptable"/>, ERROR, 5000);
                    })
            })
            .catch(error => {
                this.setState({spinner:false,checkoutError: error})
                console.log(error,'ooooo')
                return;
            })
        }else if(this.state.isCardEdited){
            window.Frames.submitCard().then(res => {
                performRequest('delete', `/api/card/delete/${this.state.cardId}`,)
                .then(response => {
                    performRequest('post', '/api/card/create/', {
                        "bin": res.bin,
                        "expires_on": res.expires_on,
                        "last_four_digits": res.last4,
                        "scheme": res.scheme,
                        "token": res.token,
                    })
                        .then(response => {
                            this.updatePlanCheckout()
            
                        })
                        .catch(error => {
                            this.setState({spinner:false})

                            NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                        })
                })
            })
            .catch(error => {
                this.setState({spinner:false,checkoutError:error})
                return
            })
        }else{
            this.updatePlanCheckout()
        }
        // var payWithCard = data => {
        //     this.updatePlan(data.response.token.token)
        // };

        // var error = error => {
        //     this.setState({
        //         spinner: false,
        //         checkoutError: error.errorCode === 300 ? "Unauthorized" : "Missing data"
        //     })
        // };


        // window.TCO.loadPubKey("production", () => {
        //     window.TCO.requestToken(payWithCard, error, "tcoCCForm")
        // })
    };

    updatePlanCheckout = () => {
        performRequest('get', '/api/checkout/?package=' + this.state.renewPlanId + '&venue=' + this.state.renewSelectedVenue.id)
        .then(res => {
            this.setState({
                spinner: false,
                renewModal: false,
              
            }, () => {
                this.loadData()
                NotificationManager.success(<IntlMessages id={"billing.payment_completed_successfully"}/>);

            });
                
        })
        .catch(error => {
            this.setState({
                spinner: false,
                renewModal: false,

            });
            NotificationManager.error(<IntlMessages id="billing.payment_failed"/>, ERROR, 5000);
        })
    }

//Confirm Payment
    updatePlan = async (token) => {
        console.log(this.state.renewSelectedVenue,)
        // const currentVenue = await JSON.parse(
        //     localStorage.getItem('currentVenue'));

        performRequest('get', '/api/transaction/2checkout/?venue=' + this.state.renewSelectedVenue.id + "&token=" + token + '&package=' + this.state.renewPlanId)
            .then(res => {
                NotificationManager.success(<IntlMessages id={"billing.payment_completed_successfully"}/>);
                this.setState({
                    spinner: false,
                    modal: false,
                    ccNo: '',
                    expMonth: '',
                    expYear: '',
                    cvv: '',
                });
            })
            .catch(error => {
                this.setState({
                    checkoutError: error.response.data,
                    spinner: false
                });
                NotificationManager.error(<IntlMessages id={"billing.payment_failed"}/>, ERROR, 5000);
            })
    };

    navControl = (e) => {
        e.preventDefault();
        this.setState({
            nav: !this.state.nav
        })
    };
    handleProfile = () => {
        // this.setState({profile: false})
        this.props.history.push('/profile');

    };
    onOptionBoxSelect = () => {
        this.setState({
            selectBox: !this.state.selectBox
        })
    };
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps
        })
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    navControl = (e) => {
        const val = !this.state.navCollapsed;
        this.props.toggleCollapsedNav(val);
        // this.setState({
        //     nav: !this.state.nav
        // })
    };

    handleSelectAllClick = (event, checked) => {
        if (checked) {
            this.setState({selected: this.props.tableList.map(n => n.id)});
            return;
        }
        this.setState({selected: []});
    };
    handleRequestSort = (event, property) => {
        const orderBy = property;
        let order = 'desc';

        if (this.state.orderBy === property && this.state.order === 'desc') {
            order = 'asc';
        }
        const data =
            order === 'desc'
                ? this.state.plans.sort((a, b) => (b[orderBy] < a[orderBy] ? -1 : 1))
                : this.state.plans.sort((a, b) => (a[orderBy] < b[orderBy] ? -1 : 1));

        this.setState({data, order, orderBy});
    };

    // RENEW MODAL
    renewSubscription = (id, venue, amount) => {
        let ip = publicIp.v4();
        this.setState({
            renewModal: true,
            renewSelectedVenue: venue,
            renewAmount: amount,
            renewPlanId: id
        });
    };

    cancelSubscription = () =>{
        performRequest('get', 'api/cancel/subscription', {venue: this.state.renewSelectedVenue.id})
            .then(response => {
                NotificationManager.success(<IntlMessages id={"billing.subscription_cancelled"}/>);
                this.setState({cancelSubModal: false});
                console.log("HHHH", response)
                performRequest('get', 'api/plans/')
                    .then(res => {
                        res.data.results.filter(each => {
                            each['venuename'] = each.venue.venueName
                        });
                        this.setState({
                            plans: res.data.results,
                        })

                    });
            })
            .catch( error =>{
                NotificationManager.error(<IntlMessages id={"billing.failed_to_cancel_subscription"}/>, ERROR, 5000);
            })
    };

    handleCancelModal = (venue) => {
        this.setState({cancelSubModal: true,
            renewSelectedVenue: venue})
    };

    handleChange = (e, event) => {
        this.setState({[event.name]: e})
    };
    handleChangeSubscription = (venue) => {
        console.log(venue, 'vvvv')
        performRequest('get', 'api/venue/' + venue.id)
        .then(response => {
            console.log(response.data, 'kkkkkkkkk')
            this.props.selectVenue({
                defaultLanguage: response.data.languages,
                label: response.data.venueName,
                value: response.data.id,
                venueQrId: response.data.venue_id,
                venueQrImage: response.data.qr_image,
                venueQrPin: response.data.pin_code,
                venueTimezone: response.data.timezone,
            })
            this.props.history.push('/app/subscription')
        })
        .catch(error=> {
            console.log('errrr', error)
        })


        
    }
    // Trigger Frame onModal PopUp
    onOpened = () => {

        let Frames = window.Frames;
        console.log(Frames, 'kkkkkkkkkkk')
        // var payButton = document.getElementById("pay-button");
        // var form = document.getElementById("tcoCCForm");
        Frames.init({
            publicKey: CHECKOUT_PUB_KEY,
            cardValidationChanged: () => {
                console.log('hereeeeeee', Frames.isCardValid())
                // if all fields contain valid information, the Pay now
                // button will be enabled and the form can be submitted
                // form.disabled = !Frames.isCardValid();
                // payButton.disabled = !Frames.isCardValid();
            },
            cardSubmitted: (e) => {
                // form.disabled = true;
                // display loader
            },
            cardTokenized: (data) => {
                console.log(data, 'ffffffff card dataaaaa')
                // Frames.addCardToken(form, data.token)
                // form.submit()
            },
            cardTokenizationFailed: (event) => {
                
                // catch the error
            }
        });

    };


    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        const {order, orderBy, selected} = this.state;
        return (
            <div className={`app-container ${drawerStyle}`}>
                <div className={'navInMin'} style={{zIndex: '10'}}>
                    <Sidenav/>
                </div>
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <AppBar
                            className={`app-main-header customGradient ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>

                            <Toolbar className="app-toolbar" disableGutters={false}>

                                {this.props.nav ? <div className='menuCustom' onClick={this.props.navControl}>
                                        <i className="zmdi zmdi-close zmdi-hc-2x"/>
                                    </div> :
                                    <div className='menuCustom' onClick={this.navControl}>
                                        {/*<i className="zmdi zmdi-menu zmdi-hc-2x"/>*/}
                                        <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                                    </div>
                                }
                                <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard/default">
                                    <img src={require('./../../src/assets/images/logo.png')} alt="mydine"
                                         title="mymenu"/>
                                </Link>


                                <div style={{
                                    paddingLeft: 40, paddingTop: '5px'
                                }}>
                                    <Button className="btn btn-secondary cardButton1Detail float-right"
                                            onClick={() => this.props.history.push('/app/dashboard/default')}><IntlMessages id="header.back_to_dashboard"/></Button>
                                </div>
                                {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                                <Menu/>}

                                <ul className="header-notifications list-inline ml-auto">
                                    <li className="d-inline-block d-lg-none list-inline-item">
                                        <Dropdown
                                            className="quick-menu nav-searchbox"
                                            isOpen={this.state.selectBox}
                                            toggle={this.onOptionBoxSelect.bind(this)}>
                                            <DropdownMenu right className="p-0">
                                                <Select styleName="search-dropdown"
                                                        onChange={this.handleChange}
                                                        value={this.props.selectedVenue}
                                                        options={this.props.venueList}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                    {/*Apps Icons*/}
                                    <li className="list-inline-item  d-lg-none">
                                        <Dropdown
                                            className="quick-menu app-notification"
                                            isOpen={this.state.apps}
                                            toggle={this.onAppsSelect.bind(this)}>

                                            <DropdownToggle
                                                className="d-inline-block hov"
                                                tag="span"
                                                data-toggle="dropdown">

                                  {/*<span className="app-notification-menu">*/}
                                  {/*  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>*/}
                                  {/*  <span>Apps</span>*/}
                                  {/*</span>*/}
                                            </DropdownToggle>

                                            <DropdownMenu style={{width: 200}}>
                                                {/*{this.Apps()}*/}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                    <li className="list-inline-item" style={{marginLeft: '30px'}}>
                                        <UserInfo {...this.props}
                                                  handleProfile={this.props.handleProfile}/>

                                    </li>
                                </ul>
                            </Toolbar>
                        </AppBar>
                    </div>
                    <main className="app-main-content-wrapper">
                        {this.state.isFetching ? <LoadingOverlay
                                active={this.state.isFetching}
                                styles={{

                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0, 0, 0, 0)'
                                    })
                                }}
                                spinner={<ScaleLoader
                                    sizeUnit={"px"}
                                    size={35}
                                    color={TABLE_HEAD_COLOR}
                                    loading={this.state.isFetching}

                                />}
                            >
                                <div style={{height: '400px'}}/>
                            </LoadingOverlay> :
                            <div className="app-main-content">
                                <div className="app-wrapper ">
                                    <div className="animated slideInUpTiny animation-duration-3">

                                        <div className='container'>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <h2><IntlMessages id="billing.plans"/></h2>
                                                </div>
                                            </div>
                                            <br/>
                                  
                                            <br/>
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <Table
                                                        className="table table-hover m-table m-table--head-bg-brand table-responsive-material">
                                                        <DataTableHead
                                                        intl={this.props.intl}
                                                            numSelected={selected.length}
                                                            order={order}
                                                            orderBy={orderBy}
                                                            onSelectAllClick={this.handleSelectAllClick}
                                                            onRequestSort={this.handleRequestSort}
                                                            rowCount={this.state.plans.length}
                                                        />
                                                        <TableBody>
                                                            {
                                                                this.state.plans.map(each => {
                                                                    return (<tr>
                                                                        <td padding="checkbox">{each.venue.venueName}
                                                                        </td>
                                                                        <td padding="none">{each.current_package}</td>
                                                                        <td numeric>{each.current_package === 'Trial' ? '---' : '$ '+each.package_detail.price}</td>
                                                                        <td numeric>{each.next_billing_date}</td>
                                                                        <td numeric>{each.expiry_date}</td>
                                                                        <td numeric>{each.status}</td>
                                                                        <td numeric>
                                                                            {(each.current_package !== 'Trial' && each.status !== 'ACTIVE') &&
                                                                            <button className=' btn btn-secondary'
                                                                                    onClick={() => this.renewSubscription(each.package_detail.id, each.venue, each.package_detail.price)}><IntlMessages id="billing.renew"/>
                                                                            </button>
                                                                            }
                                                                            <Button color="warning"
                                                                                    style={{color: "#fff"}}
                                                                                    onClick={() => this.handleChangeSubscription(each.venue)}>
                                                                                {each.current_package === 'Trial' ? <IntlMessages id="billing.upgrade"/> : <IntlMessages id="billing.change_plan"/>}
                                                                                <bt/>
                                                                            </Button> 
                                                                            {(each.current_package !== 'Trial' && each.status === 'ACTIVE') &&
                                                                            <button className='btn btn-primary'
                                                                                    onClick={() => this.handleCancelModal(each.venue)}><IntlMessages id="billing.cancel_subscription"/>
                                                                                
                                                                            </button>
                                                                            }
                                                                        </td>
                                                                    </tr>)
                                                                })
                                                            }

                                                        </TableBody>
                                                    </Table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        }

                        <Modal isOpen={this.state.cancelSubModal}
                               className={this.props.className}
                               style={{maxWidth: '500px'}}
                        >
                            <ModalHeader>Sad to see you go!</ModalHeader>
                            <ModalBody>
                                <div className='container'>
                                    <span>
                                        {"Are you really want to cancel the subscription of "+ this.state.renewSelectedVenue.venueName}
                                    </span>
                                </div>
                            </ModalBody>
                            <ModalFooter>
                                {/*<Link to={"/export"}>*/}
                                <Button color="primary" onClick={()=>this.cancelSubscription()}><IntlMessages id="common.submit"/></Button>{' '}
                                {/*</Link>*/}
                                <Button color="secondary"
                                        onClick={() => this.setState({cancelSubModal: false})}><IntlMessages id="common.cancel"/></Button>
                            </ModalFooter>
                        </Modal>

                        <Modal isOpen={this.state.renewModal}
                               style={{position: 'relative', zIndex: 1050}}
                               onOpened={this.onOpened}
                               >
                            <ModalHeader>{"Renew Plan for " + this.state.renewSelectedVenue.venueName}</ModalHeader>
                            <ModalBody>
                                <LoadingOverlay
                                    active={this.state.spinner}
                                    styles={{
                                        overlay: (base) => ({
                                            ...base,
                                            background: 'rgba(0, 0, 0, 0.1)'
                                        })
                                    }}
                                    spinner={<ScaleLoader
                                        sizeUnit={"px"}
                                        size={35}
                                        color={TABLE_HEAD_COLOR}
                                        loading={this.state.spinner}
                                    />}
                                >
                                    <div className='container'>
                                        <Row>
                                            <Col xs='8'>
                                                <div>
                                                    <h1>{this.state.selected_sub}</h1>
                                                    {/*<span>{this.state.selected_sub + " Plan, up to "}</span>*/}
                                                </div>
                                            </Col>
                                            <Col xs='4'>
                                                <div><h1
                                                    className={"float-right"}>{'$ '+ this.state.renewAmount.toFixed(2)}</h1>
                                                </div>
                                            </Col>
                                        </Row>

                                        {this.state.id ? this.state.billingInformation ?
                                            <Row>
                                                <div className='col-md-6'>
                                                    <div className="container">
                                                        {this.state.full_name &&
                                                        <span>{this.state.full_name} - {this.state.email}<br/></span>
                                                        }
                                                        {this.state.address_line_1 &&
                                                        <span>{this.state.address_line_1}<br/></span>
                                                        }
                                                        {this.state.address_line_2 &&
                                                        <span>{this.state.address_line_2}<br/></span>
                                                        }
                                                        {this.state.state &&
                                                        <span>{this.state.state}, {this.state.zip_code}<br/></span>
                                                        }
                                                        {this.state.country &&
                                                        <span>{this.state.country.label}<br/></span>
                                                        }

                                                    </div>
                                                </div>
                                                <Col xs='6'>
                                                    <Button color="primary"
                                                            onClick={() => this.setState({billingInformation: false})}><IntlMessages id="common.update"/></Button>
                                                </Col>
                                            </Row>
                                            :
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="common.name"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'full_name'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.full_name}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="billing.address_line"/> 1</label>
                                                            </Col>
                                                            <Col xs='4'>

                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'address_line_1'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.address_line_1}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="billing.address_line"/> 2</label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'address_line_2'}
                                                                       value={this.state.address_line_2}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="common.country"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <Select
                                                                    name={'country'}
                                                                    onChange={this.handleChange}
                                                                    value={this.state.country}
                                                                    options={this.state.countries}
                                                                />
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="billing.tax_number"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'tax_number'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.tax_number}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='' className='align-self-center'>
                                                                <Button color="primary"
                                                                        onClick={this.saveData}><IntlMessages id="common.save"/></Button>

                                                                <Button color="secondary"
                                                                        onClick={() => this.setState({billingInformation: true})}><IntlMessages id="common.cancel"/></Button>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                                <div className="col-md-6">
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="common.email"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'email'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.email}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="header.city"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'city'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.city}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="header.state"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'state'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.state}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="billing.zip_code"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'zip_code'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.zip_code}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                    <br/>
                                                    <div className="container">
                                                        <Row>
                                                            <Col xs='8' className='align-self-center'>
                                                                <label className="d-inline "><IntlMessages id="billing.tax_office"/></label>
                                                            </Col>
                                                            <Col xs='4'>
                                                            </Col>
                                                        </Row>
                                                        <Row>
                                                            <Col xs='12' className='align-self-center'>
                                                                <input name={'tax_office'}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       value={this.state.tax_office}
                                                                       className="form-control"/>
                                                            </Col>
                                                        </Row>
                                                    </div>
                                                </div>
                                            </div> : ''
                                        }
                                        <br/>
                                        <Row>
                                            <Col xs='8'>
                                                <div>
                                                    <h2><IntlMessages id="billing.saved_card"/></h2>
                                                </div>
                                            </Col>
                                        </Row>
                 
                                        <Col style={{display: this.state.cardIsEdited || !this.state.cardId ? 'flex' : 'none'}} xs='6 text-right'>
                                                        <form id="payment-form"
                                                            // className='pull-left'
                                                            onSubmit={this.onSubmit}
                                                            method="POST"
                                                            action="https://merchant.com/charge-card"
                                                    >
                                                        <div className="one-liner">
                                                            <div className="card-frame"/>
                                                            <div style={{paddingTop: '3px'}}>
                                                
                                                            </div>
                                                        </div>
                                                        <p className="error-message"/>
                                                        <p className="success-payment-message"/>
                                                    </form>
                                        </Col>
                                        {
                                            this.state.cardId &&
                                            <Col xs='6' style={{border: '1px solid grey',borderRadius:'10px',padding:10,marginVertical:7}}>
                                                <Row>
                                                    <Col xs='8'>
                                                    <p>{this.state.cardNumber}</p>
                                                    <p>{this.state.cardName}</p>
                                                    </Col>
                                                    
                                                </Row>
                                            </Col>
                                        }
                                    
                                        <br/>
                                        {/* <div className="row container">
                                            <div className="col-md-12">
                                                <span style={{color: "red"}}>{this.state.checkoutError}</span>
                                            </div>
                                        </div> */}
                                        {/* <form id="tcoCCForm">
                                            <div className="one-liner">
                                                <div className="col-md-6" style={{paddingRight: "24px"}}>
                                                    <input id="sellerId" name="sellerId" type="hidden"
                                                           value={MERCHANT_CODE}/>
                                                    <input id="publishableKey" name="publishableKey"
                                                           type="hidden" value={PUBLISHABLE_KEY}/>
                                                    <Row>
                                                        <Col md="12" xs="12">
                                                            <div>
                                                                <span>Card Number</span>
                                                                <input id="ccNo" name="ccNo" type="text"
                                                                       value={this.state.ccNo}
                                                                       onChange={(e) => this.getChanges(e)}
                                                                       autoComplete="off"
                                                                       required
                                                                       className="form-control"/>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                    <Row>
                                                        <Col xs="6">
                                                            <span>Expiration Date</span>
                                                            <Row>
                                                                <Col xs="5">
                                                                    <label>
                                                                        <input id="expMonth" name="expMonth" type="text"
                                                                               value={this.state.expMonth}
                                                                               onChange={(e) => this.getChanges(e)}
                                                                               size="2"
                                                                               required
                                                                               className="form-control"
                                                                               placeholder="MM"/>
                                                                    </label>
                                                                </Col>
                                                                <Col xs="1"
                                                                     style={{paddingLeft: "0px", paddingRight: "0px"}}>
                                                                    <span style={{fontSize: "20px"}}> / </span>
                                                                </Col>
                                                                <Col xs="6">
                                                                    <input id="expYear" name="expYear" type="text"
                                                                           value={this.state.expYear}
                                                                           onChange={(e) => this.getChanges(e)} size="4"
                                                                           required
                                                                           className="form-control"
                                                                           placeholder="YYYY"/>
                                                                </Col>
                                                            </Row>
                                                        </Col>
                                                        <Col xs="6">
                                                            <div>
                                                                <label>
                                                                    <span>CVC/CVV</span>
                                                                    <input id="cvv" name="cvv" type="password"
                                                                           value={this.state.cvv}
                                                                           onChange={(e) => this.getChanges(e)}
                                                                           autoComplete="off"
                                                                           required
                                                                           className="form-control"/>
                                                                </label>
                                                            </div>
                                                        </Col>
                                                    </Row>
                                                    <br/>
                                                </div>
                                                <div className="col-md-6" style={{paddingTop: '22px'}}>
                                                </div>
                                            </div>
                                            <p className="error-message"/>
                                            <p className="success-payment-message"/>
                                        </form> */}
                                        <Row>
                                            <Col xs='8'>
                                                <div>
                                                    <h1><IntlMessages id="common.total"/></h1>
                                                </div>

                                            </Col>
                                            <Col>
                                                <div className={"float-right"}>
                                                    <h1>{'$ '+ this.state.renewAmount.toFixed(2)}</h1>
                                                    {/*<h1>{this.state.symbol}{this.state.billing_type === 'Yearly' ? (this.state.selected_amount * 12).toFixed(3) : this.state.selected_amount}</h1>*/}
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                </LoadingOverlay>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" className='secondary'
                                        disabled={!this.state.id}
                                        onClick={() => this.checkPayment()}
                                ><IntlMessages id="billing.pay"/></Button>
                                <Button color="secondary"
                                        onClick={() => this.setState({renewModal: false})}><IntlMessages id="common.cancel"/></Button>
                            </ModalFooter>
                        </Modal>
                    </main>
                    <Footer/>
                </div>
                <NotificationContainer/>

            </div>
        );
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;

    return {
        drawerType, navigationStyle, horizontalNavPosition,
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues
    }
};

export default injectIntl(connect(mapStateToProps, {
    showAuthLoader,
    toggleCollapsedNav,
    selectVenue,


})(SignIn));
