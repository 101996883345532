import React from "react";

const Footer = () => {
  return (
    <footer className="app-footer">
      <div className="d-flex flex-row">
        <div>
          <span> Copyright My Menu &copy; 2021</span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
