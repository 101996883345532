import {ADD_SURVEY, ADD_SURVEY_ID, DELETE_SURVEY, EDIT_SURVEY, UPDATE_SURVEY} from "../constants/ActionTypes";
import arrayMove from "array-move";


const INIT_STATE = {
    survey: [],
    surveyId: null,
};

export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_SURVEY: {
            return {
                ...state,
                survey: actions.survey.reverse()
            }
        }
        case ADD_SURVEY_ID: {
            return{
                ...state,
                surveyId: actions.id
            }
        }
        case UPDATE_SURVEY: {
            return {
                ...state,
                survey: [...state.survey, actions.survey]
            }
        }
        case EDIT_SURVEY: {
            let items = [...state.survey];
            let idx = 0;
            items.map((item,index) => {
                if ( item.id === actions.id ) {
                    idx = index;
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                survey: arrayMove([...items, actions.survey], items.length, idx)
            }

        }
        case DELETE_SURVEY: {
            let items = [...state.survey];
            items.map((item,index) => {
                if ( item.id === actions.id ) {
                    items.splice(index, 1)
                }
            });
            return{
                ...state,
                survey: [...items]
            }
        }
        default:
            return state
    }
}