import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  GET_CURRENT_USER
} from "constants/ActionTypes";


export const getCurrentUser = (first_name, last_name, email, id) => {
  return {
    type: GET_CURRENT_USER,
    first_name: first_name,
    last_name: last_name,
    email: email,
    id: id
  };
};

export const setInitUrl = (url) => {
  return {
    type: INIT_URL,
    payload: url
  };
};


export const showAuthLoader = () => {
  return {
    type: ON_SHOW_LOADER,
  };
};


export const hideAuthLoader = () => {
  return {
    type: ON_HIDE_LOADER,
  };
};
