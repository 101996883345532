import {
    ADD_INTEGRATIONS
} from "./../constants/ActionTypes";


const INIT_STATE = {
    integrations: []
};


export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_INTEGRATIONS: {
            return {
                ...state,
                integrations: actions.integrations
            }
        }
   
  
        default:
            return state


    }
}