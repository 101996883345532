import React from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import {Config} from 'constants/ThemeColors';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import './style.css'
import '@trendmicro/react-sidenav/dist/react-sidenav.css';
import SideNav, { Toggle, Nav, NavItem, NavIcon, NavText } from '@trendmicro/react-sidenav';

import NavUserinfo from 'components/NavUserInfo'
const styles = {
    paper: {
      background: "blue",
      top:70
    }
  }
class SideNavI extends React.PureComponent {

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor(props) {
        super(props);
        this.state = {
            profilePage: false
        }
    }

    componentDidMount() {
        console.log(this.props.location.pathname.split('/')[1].length, 'kdkdkddk')



        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    render() {
        const {navCollapsed, drawerType, width, navigationStyle} = this.props;
        let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
        let type = 'permanent';
        if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {

            // if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) || width < 990)) {
            type = 'temporary';
        }
        if (width < 768) {
            type = 'temporary';
        }

        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = 'temporary';
        }

        return (
            <div className={`app-sidebar width d-none ${drawerStyle}`} style={{width: 90 + '!important'}}>
                <SideNav
                    onSelect={(selected) => {
                        // Add your code here
                    }}
                >
       
                    <SidenavContent profilePage={this.state.profilePage}
                                    banner={this.props.banner}
                    />
                </SideNav>
            </div>
        );
    }
}

const mapStateToProps = ({settings}) => {
    const {navCollapsed, drawerType, width, navigationStyle} = settings;
    return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(SideNavI));

