import appLocaleData from 'react-intl/locale-data/bg';
import bgMessages from '../locales/bg_BG.json';

const bgLang = {
  messages: {
    ...bgMessages
  },
  locale: 'bg-BG',
  data: appLocaleData
};
export default bgLang;
