import { EventEmitter } from 'events';

import { Logger } from './logger';
import { SyncClient } from 'twilio-sync';
import { isDeepEqual, parseAttributes } from './util';
import { validateTypesAsync, literal } from 'twilio-sdk-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { EditUserRequest, EditUserResponse } from './interfaces/commands/edituser';

const log = Logger.scope('User');

interface UserState {
  identity: string;
  entityName: string;
  friendlyName: string;
  attributes: any;
  online: boolean;
  notifiable: boolean;
}

export interface UserServices {
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

interface UserLinks {
  self: string;
}

namespace User {
  export type SubscriptionState = 'initializing' | 'subscribed' | 'unsubscribed';

  export type UpdateReason = 'friendlyName' | 'attributes' | 'online' | 'notifiable';

  export interface UpdatedEventArgs {
    user: User;
    updateReasons: User.UpdateReason[];
  }
}

/**
 * @classdesc Extended user information.
 * Note that <code>online</code> and <code>notifiable</code> properties are eligible
 * to use only if reachability function is enabled.
 * You may check if it is enabled by reading value of {@link Client}'s <code>reachabilityEnabled</code> property.
 *
 * @property {String} identity - User identity
 * @property {String} friendlyName - User friendly name, null if not set
 * @property {any} attributes - Object with custom attributes for user
 * @property {Boolean} online - User real-time channel connection status
 * @property {Boolean} notifiable - User push notification registration status
 * @property {Boolean} isSubscribed - Check if this user receives real-time status updates
 *
 * @fires User#updated
 * @fires User#userSubscribed
 * @fires User#userUnsubscribed
 *
 * @constructor
 * @param {String} identity - Identity of user
 * @param {String} entityId - id of user's object
 * @param {Object} datasync - datasync service
 */
class User extends EventEmitter {

  private entity: any;
  private state: UserState;
  private promiseToFetch: Promise<User>;
  private subscribed: User.SubscriptionState;

  constructor(
    identity: string,
    entityName: string,
    private readonly links: UserLinks,
    private readonly configuration: Configuration,
    private readonly services: UserServices
  ) {
    super();

    this.subscribed = 'initializing';
    this.setMaxListeners(0);

    this.state = {
      identity,
      entityName,
      friendlyName: null,
      attributes: {},
      online: null,
      notifiable: null
    };
  }

  /**
   * The update reason for <code>updated</code> event emitted on User
   * @typedef {('friendlyName' | 'attributes' | 'online' | 'notifiable')} User#UpdateReason
   */

  public get identity(): string { return this.state.identity; }

  public set identity(identity: string) { this.state.identity = identity; }

  public set entityName(name: string) { this.state.entityName = name; }

  public get attributes() { return this.state.attributes; }

  public get friendlyName(): string { return this.state.friendlyName; }

  public get online(): boolean { return this.state.online; }

  public get notifiable(): boolean { return this.state.notifiable; }

  public get isSubscribed(): boolean { return this.subscribed == 'subscribed'; }

  // Handles service updates
  _update(key: string, value: any) {
    let updateReasons: User.UpdateReason[] = [];
    log.debug('User for', this.state.identity, 'updated:', key, value);
    switch (key) {
      case 'friendlyName':
        if (this.state.friendlyName !== value.value) {
          updateReasons.push('friendlyName');
          this.state.friendlyName = value.value;
        }
        break;
      case 'attributes':
        const updateAttributes = parseAttributes(value.value, `Retrieved malformed attributes from the server for user: ${this.state.identity}`, log);
        if (!isDeepEqual(this.state.attributes, updateAttributes)) {
          this.state.attributes = updateAttributes;
          updateReasons.push('attributes');
        }
        break;
      case 'reachability':
        if (this.state.online !== value.online) {
          this.state.online = value.online;
          updateReasons.push('online');
        }
        if (this.state.notifiable !== value.notifiable) {
          this.state.notifiable = value.notifiable;
          updateReasons.push('notifiable');
        }
        break;
      default:
        return;
    }
    if (updateReasons.length > 0) {
      this.emit('updated', { user: this, updateReasons: updateReasons });
    }
  }

  // Fetch reachability info
  private async _updateReachabilityInfo(map, update) {
    if (!this.configuration.reachabilityEnabled) {
      return Promise.resolve();
    }

    return map.get('reachability')
      .then(update)
      .catch(err => { log.warn('Failed to get reachability info for ', this.state.identity, err); });
  }

  // Fetch user
  async _fetch() {
    if (!this.state.entityName) {
      return this;
    }

    this.promiseToFetch = this.services.syncClient.map({ id: this.state.entityName, mode: 'open_existing', includeItems: true })
                              .then(map => {
                                this.entity = map;
                                map.on('itemUpdated', args => {
                                  log.debug(this.state.entityName + ' (' + this.state.identity + ') itemUpdated: ' + args.item.key);
                                  return this._update(args.item.key, args.item.data);
                                });
                                return Promise.all([
                                  map.get('friendlyName')
                                     .then(item => this._update(item.key, item.data)),
                                  map.get('attributes')
                                     .then(item => this._update(item.key, item.data)),
                                  this._updateReachabilityInfo(map,
                                    item => this._update(item.key, item.data))
                                ]);
                              })
                              .then(() => {
                                log.debug('Fetched for', this.identity);
                                this.subscribed = 'subscribed';
                                this.emit('userSubscribed', this);
                                return this;
                              })
                              .catch(err => {
                                this.promiseToFetch = null;
                                throw err;
                              });
    return this.promiseToFetch;
  }

  _ensureFetched() {
    return this.promiseToFetch || this._fetch();
  }

  /**
   * Updates user attributes.
   * @param {any} attributes new attributes for User.
   * @returns {Promise<User>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  public async updateAttributes(attributes: any): Promise<User> {
    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.commandExecutor.mutateResource<EditUserRequest, EditUserResponse>(
      'post',
      this.links.self,
      {
        attributes: JSON.stringify(attributes)
      }
    );

    return this;
  }

  /**
   * Update Users friendlyName.
   * @param {String} friendlyName - Updated friendlyName
   * @returns {Promise<User>}
   */
  @validateTypesAsync('string')
  public async updateFriendlyName(friendlyName): Promise<User> {
    if (this.subscribed == 'unsubscribed') {
      throw new Error('Can\'t modify unsubscribed object');
    }

    await this.services.commandExecutor.mutateResource<EditUserRequest, EditUserResponse>(
      'post',
      this.links.self,
      {
        friendly_name: friendlyName
      }
    );

    return this;
  }

  /**
   * Removes User from subscription list.
   * @returns {Promise<void>} Promise of completion
   */
  async unsubscribe(): Promise<void> {
    if (this.promiseToFetch) {
      await this.promiseToFetch;
      this.entity.close();
      this.promiseToFetch = null;
      this.subscribed = 'unsubscribed';
      this.emit('userUnsubscribed', this);
    }
  }
}

export { User };

/**
 * Fired when User's properties or reachability status have been updated.
 * @event User#updated
 * @type {Object}
 * @property {User} user - Updated User
 * @property {User#UpdateReason[]} updateReasons - Array of User's updated event reasons
 */
/**
 * Fired when Client is subscribed to User.
 * @event User#userSubscribed
 * @type {User}
 */
/**
 * Fired when Client is unsubscribed from this User.
 * @event User#userUnsubscribed
 * @type {User}
 */
