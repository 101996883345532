import React from "react";
import ReactDOM from "react-dom";
import MainApp from "./MainApp";
import {initializeFirebase} from './push-notification';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";



if(window.location.hostname != 'localhost' ){
    Sentry.init({
        dsn: "https://f0f7dccf48ee48609d87a48efc842808@o707438.ingest.sentry.io/5778432",
        integrations: [new Integrations.BrowserTracing()],
      
        // Set tracesSampleRate to 1.0 to capture 100%
        // of transactions for performance monitoring.
        // We recommend adjusting this value in production
        tracesSampleRate: 0.7,
      });
}



const rootEl = document.getElementById('app-site');

// Create a reusable render method that we can call more than once
let render = () => {
    // Dynamically import our main App component, and render it
    const MainApp = require('./MainApp').default;
    ReactDOM.render(
        <MainApp/>,
        rootEl
    );
    initializeFirebase();

};

if (module.hot) {
    module.hot.accept('./MainApp', () => {
        const NextApp = require('./MainApp').default;
        render(
            <NextApp/>,
            rootEl
        );
        initializeFirebase();
    });
}

render();