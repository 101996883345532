import React, {Component} from 'react';
import {Link, NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {UncontrolledCollapse, Collapse} from 'reactstrap'
import CustomScrollbars from 'util/CustomScrollbars';
import './style.css';
import IntlMessages from 'util/IntlMessages';
import SideNav, {Toggle, Nav, NavItem, NavIcon, NavText} from '@trendmicro/react-sidenav';
import {connect} from "react-redux"

class SidenavContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: false,
            feedback: false,
            profilePage: false,
            qrMenu: false,
            tablet: false,
            online_menu: false,
            menu: false

        }
    }

    onItemClick = (event) => {
        event.currentTarget.style.backgroundColor = '#ccc';
    };

    componentDidMount() {
        if (this.props.location.pathname.split('/')[1] === 'app') {
            this.setState({profilePage: true})
        }
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path
        const array = pathname.split("/");
        if (array[array.length - 1] === 'orders' || array[array.length - 1] === 'tables' || array[array.length - 1] === 'waiters')
            this.setState({order: true});
        if (array[array.length - 1] === 'surveys' || array[array.length - 1] === 'feedbacks')
            this.setState({feedback: true});
        if (array[array.length - 1] === 'qrTable' || array[array.length - 1] === 'qrConfig')
            this.setState({qrMenu: true});

        // alert(window.location.href);
        let full_url = window.location.href;
        let origin = window.location.origin + '/#/';
        let get_url = full_url.replace(origin, '');

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        // const subMenuLi = document.querySelectorAll('.sub-menu ');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                // event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }
        return null;
    }

/////////////////
    handleClickTab = () => this.setState({
        order: false,
        feedback: false,
        qrMenu: false,
        tablet: !this.state.tablet,
        menu: false
    })
    handleClickMenuTab = () => {
        this.setState({
            order: false,
            feedback: false,
            qrMenu: false,
            tablet: false,
            menu: !this.state.menu
        })

    }
    handleOrderClick = () => {
        this.setState({
            order: !this.state.order,
            feedback: false,
            qrMenu: false,
            tablet: false,
            menu: false

        })
    };
    handleClick = () => {
        this.setState({
            order: false,
            feedback: false,
            qrMenu: false,
            tablet: false,
            online_menu: false,
            menu: false

        })
    }
    handleFeedbackClick = () => {
        this.setState({
            feedback: !this.state.feedback,
            order: false,
            qrMenu: false,
            menu: false,
            tablet: false,
            online_menu: false
        })
    };
    handleQrOrderClick = () => {
        this.setState({
            qrMenu: !this.state.qrMenu,
            feedback: false,
            order: false,
            tablet: false,
            menu: false,
            online_menu: false,
            menu: false,


        })
    };
    onClickOnlineMenu = () => this.setState({
        online_menu: !this.state.online_menu,
        order: false,
        feedback: false,
        qrMenu: false,
        tablet: false,
        menu: false,

    });
    handleClicks = () => {
        let each = document.querySelectorAll('.no-arrow')
        // let each = document.querySelector('li');
        for (let i = 0; i < each.length; i++) {
            each[i].classList.remove('open');
        }

    }


    render() {
        const style = {
            // fontWeight: "bold",
            // // backgroundColor: '#e8eaf6'
            // backgroundColor: '#545a60',

        };
        let full_url = window.location.href;
        let origin = window.location.origin + '/#/';
        // let get_url = full_url.replace(origin, '');
        let get_url = 'd';
        let height = (window.innerHeight * 92) / 100;
        console.log('screen height', window.innerHeight);
        return (
            this.state.profilePage ?

                <div className={"nav-menu container-fluid p-0 m-0 "}
                     style={{backgroundColor: '#252525', height: '100%', width: 80, overflowX: 'scroll',}}>
                    <br/>
                    <br/>
                    <br/>
                    <br/>
                    {
                        this.props.banner && <><br/>
                            <br/>
                        </>
                    }

                    <div onClick={() => this.handleClick()}
                         className={"menu no-arrow align-menu-center text-center hover-effect customMarginTop"}>
                        <NavLink className="nav-icon-style" to="/app/dashboard/default"
                                 activeStyle={style}

                        >
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                            <span className="nav-header p-0 m-0 nav-text-style"><IntlMessages
                                id="sidebar.dashboard"/></span>
                        </NavLink>

                    </div>
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li onClick={() => this.handleClickMenuTab()}
                            className={"menu no-arrow align-menu-center text-center hover-effect"}>

                            <div className="nav-icon-style" href="javascript:void(0)" id='menu'

                            >
                                <i className="zmdi zmdi-menu zmdi-hc-fw" style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header  p-0 m-0 nav-text-style"><IntlMessages
                                    id="header.menu"/></span>
                            </div>
                        </li>
                    }
                    <Collapse toggler='#menu' style={{background: '#545a60'}}
                              isOpen={this.state.menu}
                    >
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/menu/menus')}
                        >
                            <NavLink className="nav-icon-style" to="/app/menu/menus"
                                // onClick={() => this.handleClick()}
                            >
                                <i className="fa fa-cutlery" style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="header.menu"/></span>
                            </NavLink>
                        </li>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/modifiers')}
                        >
                            <NavLink className="nav-icon-style" to="/app/modifiers"
                                     activeStyle={style}
                                // onClick={() => this.handleClick()}

                            >
                                <i className="zmdi zmdi-arrows zmdi-hc-fw"
                                   style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="common.modifiers"/></span>

                            </NavLink>
                        </li>
                    </Collapse>
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li onClick={() => this.handleClickTab()}
                            className={"menu no-arrow align-menu-center text-center hover-effect"}>

                            <div className="nav-icon-style" href="javascript:void(0)" id='tablet'

                            >
                                <i className="zmdi zmdi-tablet-mac zmdi-hc-fw"
                                   style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header  p-0 m-0 nav-text-style"><IntlMessages id="sidebar.tablet"/></span>
                            </div>
                        </li>
                    }
                    <Collapse toggler='#tablet' style={{background: '#545a60'}}
                              isOpen={this.state.tablet}
                    >
                        {/* <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/design/designs')}
                            // this.props.history.push('/preview/home');
                        >
                            <NavLink className="nav-icon-style" to="/app/design/designs"
                                // onClick={() => this.handleClick()}
                                     onClick={() => this.handleClicks()}
                                     activeStyle={style}>
                                <i className="zmdi zmdi-tv-list zmdi-hc-fw"
                                   style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.design"/></span>
                            </NavLink>
                        </li> */}
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/tables')}
                        >
                            <NavLink className="nav-icon-style" to="/app/tables"
                                     activeStyle={style}
                                     onClick={() => this.handleClicks()}
                                // onClick={() => this.handleClick()}
                            >
                                <i className="zmdi zmdi-airline-seat-recline-normal zmdi-hc-fw"
                                   style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0  nav-text-style"
                                ><IntlMessages id="common.tables"/></span>

                            </NavLink>
                        </li>

                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/device')}
                        >
                            <NavLink className="nav-icon-style" to="/app/device"
                                     activeStyle={style}
                                     onClick={() => this.handleClicks()}
                                // onClick={() => this.handleClick()}
                            >
                                <i className="zmdi zmdi-smartphone zmdi-hc-fw " style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0  nav-text-style"
                                ><IntlMessages id="sidebar.devices"/></span>
                            </NavLink>
                        </li>

                    </Collapse>
                    <div onClick={() => this.handleClick()}
                         className={"menu no-arrow align-menu-center text-center hover-effect customMarginTop"}>
                        <NavLink className="nav-icon-style" to="/app/design-new/designs"
                                 activeStyle={style}

                        >
                            <i className="zmdi zmdi-tv-list zmdi-hc-fw"
                                   style={{color: '#cbb252', fontSize: 16}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.design"/></span>
                        </NavLink>

                    </div>

                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li onClick={() => this.handleQrOrderClick()}
                            className={"menu no-arrow align-menu-center text-center hover-effect"}>
                            <div className="nav-icon-style" href="javascript:void(0)" id='qrMenu'
                            >
                                <i className="fa fa-qrcode" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customAppConfig customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="common.qr_menu"/></span>
                            </div>
                        </li>
                    }
                    <Collapse toggler='#qrMenu' style={{background: '#545a60'}}
                              isOpen={this.state.qrMenu}>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push({pathname:'/app/appConfig', state: {platform: "qr"} })}
                        >
                            <a className="nav-icon-style" 
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-check-all zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="sidebar.config"/></span>
                            </a>
                        </li>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/qrTable')}
                        >
                            <NavLink className="nav-icon-style" to="/app/qrTable">
                                <i className="zmdi zmdi-receipt zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="common.table"/></span>
                            </NavLink>
                        </li>

                    </Collapse>

                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li onClick={() => this.onClickOnlineMenu()}
                            className={"menu no-arrow align-menu-center text-center hover-effect"}>
                            <div className="nav-icon-style" href="javascript:void(0)" id='online'
                            >
                                <i className="zmdi zmdi-store zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span
                                    className="nav-header customAppConfig customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="order.online_menu"/></span>
                            </div>
                        </li>
                    }
                    <Collapse toggler='#online' style={{background: '#545a60'}}
                              isOpen={this.state.online_menu}>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push({pathname:'/app/appConfig', state: {platform: "online"} })}
                            >
                            <a className="nav-icon-style" 
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.settings"/></span>
                            </a>
                        </li>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/online/orderWidget')}
                        >
                            <NavLink className="nav-icon-style" to="/app/online/orderWidget"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.orderWidget"/></span>
                            </NavLink>
                        </li>

                    </Collapse>


                    <li onClick={() => this.handleOrderClick()}
                        className={"menu no-arrow align-menu-center text-center hover-effect"}>

                        <div className="nav-icon-style" href="javascript:void(0)" id='menu'


                        >
                            <i className="zmdi zmdi-shopping-cart-plus zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                            <span className="nav-header  p-0 m-0 nav-text-style"><IntlMessages
                                id="dashboard.orders"/></span>
                        </div>

                    </li>

                    <Collapse toggler='#menu' style={{background: '#545a60'}}
                              isOpen={this.state.order}
                    >

                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/orders')}

                        >
                            <NavLink className="nav-icon-style" to="/app/orders"
                            >
                                <i className="zmdi zmdi-receipt zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="dashboard.orders"/></span>
                            </NavLink>
                        </li>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/history')}

                        >
                            <NavLink className="nav-icon-style" to="/app/history"
                            >
                                <i className="zmdi zmdi-receipt zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="sidebar.history"/></span>
                            </NavLink>
                        </li>
                    </Collapse>
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/crm')}

                        >
                            <NavLink className="nav-icon-style" to="/app/crm"
                                     activeStyle={style}
                                     onClick={() => this.handleClick()}
                            >
                                <i className="fa fa-bar-chart" style={{color: '#cbb252'}}/><br/>
                                <span
                                    className="nav-header  customAppConfig customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.crm"/></span>
                            </NavLink>
                        </li>
                    }
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li onClick={() => this.handleFeedbackClick()}
                            className={"menu no-arrow align-menu-center text-center hover-effect"}>
                            <div className="nav-icon-style" href="javascript:void(0)" id='feedback'

                            >
                                <i className="zmdi zmdi-assignment zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"
                                ><IntlMessages id="dashboard.feedback"/></span>
                            </div>
                        </li>
                    }
                    <Collapse toggler='#feedback' style={{background: '#545a60'}}
                              isOpen={this.state.feedback}>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/surveys')}
                        >
                            <NavLink className="nav-icon-style" to="/app/surveys"
                                     activeStyle={style}

                            >
                                <i className="zmdi zmdi-comment-edit zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="survey.survey_forms"/></span>
                            </NavLink>
                        </li>
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/feedbacks')}
                        >
                            <NavLink className="nav-icon-style" to="/app/feedbacks"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-check-all zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="survey.survey_results"/></span>
                            </NavLink>
                        </li>
                    </Collapse>

                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/campaign')}
                        >
                            <NavLink className="nav-icon-style" to="/app/campaign"
                                     onClick={() => this.handleClick()}

                            >
                                <i className="fa fa-bullhorn" aria-hidden="true" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.campaigns"/></span>
                            </NavLink>
                        </li>
                    }
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/info')}

                        >
                            <NavLink className="nav-icon-style" to="/app/info"
                                     activeStyle={style}
                                     onClick={() => this.handleClick()}
                            >
                                <i className="zmdi zmdi-info-outline zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span
                                    className="nav-header customAppConfig customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="info.info_page"/></span>
                            </NavLink>
                        </li>
                    }
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/appConfig')}
                        >
                            <NavLink className="nav-icon-style" to="/app/appConfig"
                                     activeStyle={style}
                                     onClick={() => this.handleClick()}

                            >
                                <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span
                                    className="nav-header customAppConfig customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="sidebar.settings"/></span>
                            </NavLink>
                        </li>
                    }
                    {
                        this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                        <li className={"menu no-arrow align-menu-center text-center hover-effect"}
                            onClick={()=>this.props.history.push('/app/integration')}
                        >
                            <NavLink className="nav-icon-style" to="/app/integration"
                                     activeStyle={style}
                                     onClick={() => this.handleClick()}
                            >
                                <i className="zmdi zmdi-puzzle-piece zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header customLeftAlign p-0 m-0 nav-text-style"><IntlMessages
                                    id="integration.integrations"/></span>

                            </NavLink>
                        </li>
                    }
                </div>
                :
                <CustomScrollbars
                    className=" scrollbar"
                    renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                    style={{backgroundColor: '#252525', position: 'initial'}}>
                    <ul className="nav-menu" style={{marginTop: 70}}>

                        <li className="menu no-arrow  align-menu-center"
                            onClick={()=>this.props.history.push('/profile')}
                        >
                            <NavLink to="/profile"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-account-box zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                <span className="nav-header p-0 m-0"><IntlMessages id="popup.profile"/></span>
                            </NavLink>

                        </li>
                        {
                            this.props.selectedVenue && (this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2") &&
                            <li className="menu no-arrow align-menu-center"
                                onClick={()=>this.props.history.push('/billing')}
                            >
                                <NavLink to="/billing"
                                         activeStyle={style}

                                >
                                    <i className="zmdi zmdi-chart zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                    <span className="nav-header p-0 m-0"
                                    ><IntlMessages id="sidebar.billing"/> </span>
                                </NavLink>
                            </li>
                        }
                        {
                            this.props.selectedVenue && (this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2") &&
                            <li className="menu no-arrow align-menu-center"
                                onClick={()=>this.props.history.push('/plan')}

                            >
                                <NavLink to="/plan"
                                         activeStyle={style}

                                >
                                    <i className="zmdi zmdi-tv-list zmdi-hc-fw" style={{color: '#cbb252'}}/><br/>
                                    <span className="nav-header p-0 m-0"><IntlMessages id="sidebar.plan"/> </span>
                                </NavLink>
                            </li>
                        }

                    </ul>
                </CustomScrollbars>
        );
    }
}

const mapStateToProps = ({settings, venue, auth, design, Plans}) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} = settings;

    return {
        selectedVenue: venue.selectedVenue,
    }
};

export default connect(mapStateToProps, null)(withRouter(SidenavContent));