import {withRouter} from "react-router-dom";
import PropTypes from "prop-types";
import axios from "axios";
import {has} from "lodash";
import {connect} from "react-redux";
import {addProgress} from "../../actions/Progress";
// const API_URL = "https://mydine-backend.herokuapp.com/";

/** Live */
// const API_URL = 'http://3.91.181.192/';
/** Test */
// const API_URL = 'https://d9f2b862de81.ngrok.io';
/** Local */
// const API_URL = "http://localhost:8000/";
// /**Current API*/
// const API_URL = "https://backend-clone.mydigimenu.com/";
// const API_URL = "https://backend.mydigimenu.com";

// live
export const API_URL = window.location.host.startsWith('localhost') ? "http://localhost:8000/" : window.location.host.startsWith('testing') ? "https://backend-clone.mydigimenu.com/" : "https://backend.mydigimenu.com/"
//export const API_URL = window.location.host.startsWith('localhost') ? "https://backend.mydigimenu.com/" : window.location.host.startsWith('testing') ? "https://backend-clone.mydigimenu.com/" : "https://backend.mydigimenu.com/"

// arun
// const API_URL = window.location.host.startsWith("localhost")
//     ? "https://backend-clone.mydigimenu.com/"
//     : window.location.host.startsWith("staging")
//         ? "https://backend-clone.mydigimenu.com/"
//         : window.location.host.startsWith("app") ? "https://backend.mydigimenu.com/" : "https://backend-clone.mydigimenu.com/";
// manzeer testing
// const API_URL = window.location.host.startsWith('localhost') ? "https://backend-clone.mydigimenu.com/" : window.location.host.startsWith('testing') ? "https://backend-clone.mydigimenu.com/" : "https://backend.mydigimenu.com/"

export const performRequest = (method, url, params, data, login = true) => {
    const body =
        method === "get" ||
        method === "delete" ||
        method === "put" ||
        method == "patch"
            ? "params"
            : "data";

    const config = {
        method,
        url,
        baseURL: API_URL,
        [body]: params || {},

        /** 01/08/2019
         * calculating uploading ratio
         */
        onUploadProgress: (progressEvent) => {
            const progress = (progressEvent.loaded * 100) / progressEvent.total;
            localStorage.getItem("progress") >= 100
                ? localStorage.removeItem("progress")
                : localStorage.setItem("progress", progress.toFixed(0));
            localStorage.setItem("progress", progress.toFixed(0));
        },
    };
    if (method === "put" || method == "patch") {
        config.data = data;
    }
    config.headers = {
        "Content-Type": "application/json; charset=utf-8",
        // Authorization: "Bearer " + localStorage.getItem('accessToken')
    };

    if (login) {
        config.headers.Authorization =
            "Bearer " + localStorage.getItem("accessToken");
    }
    return axios.request(config);
    // Add a request interceptor
};

axios.interceptors.response.use(
    (response) => {
        return response;
    },
    function (error) {
        // this.props.history.push('/signin');

        const token = localStorage.getItem("accessToken");

        const {
            config,
            response: {status},
        } = error;
        const originalRequest = config;
        if (status === 403) {
            performRequest("post", "api-token-refresh/", {token: token})
                .then((response) => {
                    localStorage.setItem("accessToken", response.data.token);
                })
                .catch((error) => {
                    localStorage.removeItem("accessToken");
                    // console.log(this.props, 'LLLLLLLLL');
                    window.location = "/";
                });
            // if (!isAlreadyFetchingAccessToken) {
            // isAlreadyFetchingAccessToken = true
            // store.dispatch(fetchAccessToken()).then((access_token) => {
            //     isAlreadyFetchingAccessToken = false
            //     onAccessTokenFetched(access_token)
            // })
            // }

            // const retryOriginalRequest = new Promise((resolve) => {
            //     addSubscriber(access_token => {
            //         originalRequest.headers.Authorization = 'Bearer ' + access_token
            //         resolve(axios(originalRequest))
            //     })
            // });
            // return retryOriginalRequest
        }
        return Promise.reject(error);
    }
);
connect(null, addProgress)(performRequest);