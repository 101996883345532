import {ADD_MENU, UPDATE_MENU, SELECT_MENU, ORDER_MENU, DELETE_ONE_MENU, EDIT_MENU} from '../constants/ActionTypes';
import arrayMove from 'array-move';


const INIT_STATE = {
    menus: [],
    selectedMenu: null,

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_MENU: {
            return {
                ...state,
                menus: action.menus
            }
        }
        case UPDATE_MENU: {
            return {
                ...state,
                menus: [...state.menus, action.menu]
            }
        }
        case SELECT_MENU: {
            return {
                ...state,
                selectedMenu: action.menuId
            }
        }
        case ORDER_MENU: {
            return {
                ...state,
                menus: arrayMove([...state.menus], action.oldIndex, action.newIndex)
            }
        }
        case DELETE_ONE_MENU: {
            let items = [...state.menus]
            items.map((item, index) => {
                if (item.id === action.id) {
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                menus: [...items]
            }
        }
        case EDIT_MENU: {
            let menus = [...state.menus]
            let idx = 0;
            menus.map((each, index) => {
                if (each.id === action.menu.id) {
                    idx = index;
                    menus.splice(index, 1)
                }
            });
            return {
                ...state,
                menus: arrayMove([...menus, action.menu], menus.length, idx)
            }
        }
        default:
            return state;
    }
}