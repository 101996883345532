import {ADD_TABLE, DELETE_TABLE} from "./../constants/ActionTypes";


const INIT_STATE = {
    table: []
};


export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_TABLE: {
            return {
                ...state,
                table: actions.table
            }
        }
        case DELETE_TABLE: {
            let items = [...state.table];
            items.map((item, index) => {
                if (item.id === actions.id) {
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                table: [...items]
            }
        }
        default:
            return state
    }
}