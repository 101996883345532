import {
  INIT_URL,
  ON_HIDE_LOADER,
  ON_SHOW_LOADER,
  GET_CURRENT_USER

} from "constants/ActionTypes";

const INIT_STATE = {
  loader: false,
  initURL: '',
  first_name: '',
  last_name: '',
  email: '',
  id: '',
};


export default (state = INIT_STATE, action) => {
  switch (action.type) {

    case GET_CURRENT_USER: {
      return{
        ...state,
        first_name: action.first_name,
        last_name: action.last_name,
        email: action.email,
        id:action.id
      }
    }

    case INIT_URL: {
      return {
        ...state,
        initURL: action.payload
      }
    }


    case ON_SHOW_LOADER: {
      return {
        ...state,
        loader: true
      }
    }
    case ON_HIDE_LOADER: {
      return {
        ...state,
        loader: false
      }
    }
    default:
      return state;
  }
}
