import {ADD_PLANS} from "../constants/ActionTypes";

const INIT_STATE = {
    plans: []
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ADD_PLANS: {
            return {
                ...state,
                plans: action.plans
            }
        }
        default:
            return state;
    }
}