import appLocaleData from 'react-intl/locale-data/de';
import DeMessages from '../locales/de_DE.json';

const Russian = {
  messages: {
    ...DeMessages
  },
  locale: 'de-DE',
  data: appLocaleData
};
export default Russian;
