import React from 'react';
import Avatar from '@material-ui/core/Avatar'
import {connect} from 'react-redux'
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
// import {userSignOut} from '../../actions/Auth';
import IntlMessages from 'util/IntlMessages';
import Avatarw from 'react-avatar';

class NavUserInfo extends React.Component {

    state = {
        anchorEl: null,
        open: false,
    };

    handleClick = event => {
        this.setState({open: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.props.history.push('/profile');
        this.setState({open: false});
    };
    userSignOut = () => {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentVenue');
        this.props.history.push('signin/');
    };
    userManagement = () => {
        this.props.history.push('/app/user-management/user-list')
    }

    render() {
        const full_name = this.props.first_name ? this.props.first_name + ' ' + this.props.last_name : this.props.email;
        return (
            <div className="user-profile d-flex flex-row align-items-center">
                <Avatarw className="user-avatar " name={full_name} size="150" round={true}/>
                <div className="user-detail">
                    <h4 className="user-name" onClick={this.handleClick}>{full_name} <i
                        className="zmdi zmdi-caret-down zmdi-hc-fw align-middle"/>
                    </h4>
                </div>
                <Menu className="user-info"
                      id="simple-menu"
                      anchorEl={this.state.anchorEl}
                      open={this.state.open}
                      onClose={this.handleRequestClose}
                      PaperProps={{
                          style: {
                              minWidth: 120,
                              paddingTop: 0,
                              paddingBottom: 0
                          }
                      }}
                >
                    <MenuItem onClick={this.handleRequestClose}>
                        <i className="zmdi zmdi-account zmdi-hc-fw mr-2"/>
                        <IntlMessages id="My Account"/>

                    </MenuItem>
                    {
                            this.props.selectedVenue && this.props.selectedVenue.role !="3"&&this.props.selectedVenue.role !="2"&&
                    <MenuItem onClick={() => {
                        this.userManagement();
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                        <IntlMessages id="common.user_management"/>
                    </MenuItem>
                    }
                    <MenuItem onClick={() => {
                        this.userSignOut();
                    }}>
                        <i className="zmdi zmdi-sign-in zmdi-hc-fw mr-2"/>

                        <IntlMessages id="common.logout"/>
                    </MenuItem>
                </Menu>
            </div>
        );
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {locale} = settings;
    return {
        locale,
        first_name: auth.first_name,
        last_name: auth.last_name,
        email: auth.email,
        selectedVenue: venue.selectedVenue,
        
    }
};
export default connect(mapStateToProps)(NavUserInfo);


