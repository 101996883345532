import { EventEmitter } from 'events';
import { Logger } from './logger';
import { Configuration } from './configuration';

import { User } from './user';
import { PublicChannels } from './data/publicchannels';
import { Network } from './services/network';

import { Transport } from './interfaces/transport';
import { NotificationTypes } from './interfaces/notificationtypes';

import { Twilsock as TwilsockClient } from 'twilsock';
import { Notifications as NotificationClient, ChannelType, ConnectionState as NotificationConnectionState } from 'twilio-notifications';
import { SyncClient } from 'twilio-sync';
import { McsClient } from 'twilio-mcs-client';

import { Channels as ChannelsEntity, Channel } from './data/channels';

import { Users } from './data/users';
import { TypingIndicator } from './services/typingindicator';
import { UserDescriptor } from './userdescriptor';
import { UserChannels } from './data/userchannels';
import { ChannelDescriptor } from './channeldescriptor';
import { Paginator } from './interfaces/paginator';
import { PushNotification } from './pushnotification';
import { parseToNumber, deepClone } from './util';
import { Member } from './member';
import { Message } from './message';
import { validateTypesAsync, validateTypes, literal, nonEmptyString, pureObject, objectSchema } from 'twilio-sdk-type-validator';
import { CommandExecutor } from './commandexecutor';
import { ConfigurationResponse } from './interfaces/commands/configuration';
import { version } from '../package.json';

const log = Logger.scope('Client');
const SDK_VERSION = version;

class ClientServices {
  commandExecutor: CommandExecutor;
  twilsockClient: TwilsockClient;
  users: Users;
  notificationClient: NotificationClient;
  publicChannels: PublicChannels;
  userChannels: UserChannels;
  network: Network;
  typingIndicator: TypingIndicator;
  syncClient: SyncClient;
  mcsClient: McsClient;
  transport: Transport;
}

namespace Client {
  export type ConnectionState = NotificationConnectionState;

  export type NotificationsChannelType = ChannelType;

  export type LogLevel = 'trace' | 'debug' | 'info' | 'warn' | 'error' | 'silent' | null;

  export interface Options {
    region?: string;
    logLevel?: Client.LogLevel;
    productId?: string;
    twilsockClient?: TwilsockClient;
    transport?: Transport;
    notificationsClient?: NotificationClient;
    syncClient?: SyncClient;
    typingIndicatorTimeoutOverride?: number;
    consumptionReportIntervalOverride?: string;
    httpCacheIntervalOverride?: string;
    userInfosToSubscribeOverride?: number;
    retryWhenThrottledOverride?: boolean;
    backoffConfigOverride?: any;
    Chat?: any;
    IPMessaging?: any;
    Sync?: any;
    Notification?: any;
    Twilsock?: any;
    clientMetadata?: any;
    disableDeepClone?: boolean;
  }

  export interface CreateChannelOptions {
    attributes?: any;
    friendlyName?: string;
    isPrivate?: boolean;
    uniqueName?: string;
  }

  export type ChannelSortingCriteria = 'lastMessage' | 'friendlyName' | 'uniqueName';

  export type ChannelSortingOrder = 'ascending' | 'descending';

  export interface ChannelSortingOptions {
    criteria?: ChannelSortingCriteria;
    order?: ChannelSortingOrder;
  }
}

/**
 * A Client is a starting point to access Twilio Programmable Chat functionality.
 *
 * @property {Client#ConnectionState} connectionState - Client connection state
 * @property {Boolean} reachabilityEnabled - Client reachability state
 * @property {User} user - Information for logged in user
 * @property {String} version - Current version of Chat client
 *
 * @fires Client#connectionError
 * @fires Client#connectionStateChanged
 * @fires Client#channelAdded
 * @fires Client#channelInvited
 * @fires Client#channelJoined
 * @fires Client#channelLeft
 * @fires Client#channelRemoved
 * @fires Client#channelUpdated
 * @fires Client#memberJoined
 * @fires Client#memberLeft
 * @fires Client#memberUpdated
 * @fires Client#messageAdded
 * @fires Client#messageRemoved
 * @fires Client#messageUpdated
 * @fires Client#pushNotification
 * @fires Client#tokenAboutToExpire
 * @fires Client#tokenExpired
 * @fires Client#typingEnded
 * @fires Client#typingStarted
 * @fires Client#userSubscribed
 * @fires Client#userUnsubscribed
 * @fires Client#userUpdated
 */
class Client extends EventEmitter {
  public connectionState: Client.ConnectionState = 'connecting';
  private channelsPromise: Promise<any> = null;
  private configurationPromise: Promise<ConfigurationResponse> | null = null;
  private fpaToken: string;
  private configuration: Configuration;
  private channels: any;
  private services: ClientServices;
  public static readonly version: string = SDK_VERSION;
  public readonly version: string = SDK_VERSION;
  private static readonly supportedPushChannels: Client.NotificationsChannelType[] = ['fcm', 'apn', 'gcm'];
  private static readonly supportedPushDataFields = {
    'channel_sid': 'channelSid',
    'message_sid': 'messageSid',
    'message_index': 'messageIndex'
  };

  private constructor(
    token: string,
    private readonly options: Client.Options = {}
  ) {
    super();

    if (!this.options.disableDeepClone) {
      let options = {
        ...this.options,
        transport: undefined,
        twilsockClient: undefined
      };

      options = deepClone(options);
      options.transport = this.options.transport;
      options.twilsockClient = this.options.twilsockClient;

      this.options = options;
    }

    this.options.logLevel = this.options.logLevel || 'silent';
    log.setLevel(this.options.logLevel);

    const productId = this.options.productId = 'ip_messaging';

    // Filling ClientMetadata
    this.options.clientMetadata = this.options.clientMetadata || {};

    if (!this.options.clientMetadata.hasOwnProperty('type')) {
      this.options.clientMetadata.type = 'chat';
    }

    if (!this.options.clientMetadata.hasOwnProperty('sdk')) {
      this.options.clientMetadata.sdk = 'JS';
      this.options.clientMetadata.sdkv = SDK_VERSION;
    }

    // Enable session local storage for Sync
    this.options.Sync = this.options.Sync || {};

    if (!this.options.Sync?.enableSessionStorage) {
      this.options.Sync.enableSessionStorage = true;
    }

    if (this.options.region) {
      this.options.Sync.region = this.options.region;
    }

    if (!token) {
      throw new Error('A valid Twilio token should be provided');
    }

    this.services = new ClientServices();

    this.options.twilsockClient = this.options.twilsockClient || new TwilsockClient(token, productId, this.options);
    this.options.transport = this.options.transport || this.options.twilsockClient;
    this.options.notificationsClient = this.options.notificationsClient || new NotificationClient(token, this.options);
    this.options.syncClient = this.options.syncClient || new SyncClient(token, this.options);

    this.services.syncClient = this.options.syncClient;
    this.services.transport = this.options.transport;
    this.services.twilsockClient = this.options.twilsockClient;
    this.services.notificationClient = this.options.notificationsClient;

    const configurationOptions = options.Chat || options.IPMessaging || options || {};
    const region = configurationOptions.region || options.region;
    const baseUrl = configurationOptions.apiUri
      || configurationOptions.typingUri
      || `https://aim.${region || 'us1'}.twilio.com`;

    this.services.commandExecutor = new CommandExecutor(baseUrl, { transport: this.options.transport }, productId);
    this.configurationPromise = this.services.commandExecutor.fetchResource<void, ConfigurationResponse>(
      'Client/v1/Configuration'
    );

    this.configurationPromise
      .then((configurationResponse) => {
        this.configuration = new Configuration(this.options, configurationResponse, log);

        this.services.typingIndicator = new TypingIndicator(this.getChannelBySid.bind(this), this.configuration, this.services);
        this.services.network = new Network(this.configuration, this.services);
        this.services.users = new Users(this.configuration, this.services);

        this.services.users.on('userSubscribed', this.emit.bind(this, 'userSubscribed'));
        this.services.users.on('userUpdated', (args: User.UpdatedEventArgs) => this.emit('userUpdated', args));
        this.services.users.on('userUnsubscribed', this.emit.bind(this, 'userUnsubscribed'));

        this.services.twilsockClient.on('tokenAboutToExpire', ttl => this.emit('tokenAboutToExpire', ttl));
        this.services.twilsockClient.on('tokenExpired', () => this.emit('tokenExpired'));
        this.services.twilsockClient.on('connectionError', (error) => this.emit('connectionError', error));

        this.channels = new ChannelsEntity(this.configuration, this.services);

        this.channels.on('channelAdded', this.emit.bind(this, 'channelAdded'));
        this.channels.on('channelInvited', this.emit.bind(this, 'channelInvited'));
        this.channels.on('channelRemoved', this.emit.bind(this, 'channelRemoved'));
        this.channels.on('channelJoined', this.emit.bind(this, 'channelJoined'));
        this.channels.on('channelLeft', this.emit.bind(this, 'channelLeft'));
        this.channels.on('channelUpdated',
          (args: Channel.UpdatedEventArgs) => this.emit('channelUpdated', args));

        this.channels.on('memberJoined', this.emit.bind(this, 'memberJoined'));
        this.channels.on('memberLeft', this.emit.bind(this, 'memberLeft'));
        this.channels.on('memberUpdated',
          (args: Member.UpdatedEventArgs) => this.emit('memberUpdated', args));

        this.channels.on('messageAdded', this.emit.bind(this, 'messageAdded'));
        this.channels.on('messageUpdated',
          (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
        this.channels.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));

        this.channels.on('typingStarted', this.emit.bind(this, 'typingStarted'));
        this.channels.on('typingEnded', this.emit.bind(this, 'typingEnded'));

        return this.services.users.myself._ensureFetched();
      });

    this.channelsPromise = this.configurationPromise
      .then(() => this.channels.fetchChannels())
      .then(() => this.channels);

    this.services.notificationClient.on('connectionStateChanged', (state: Client.ConnectionState) => {
      let changedConnectionState = null;
      switch (state) {
        case 'connected':
          changedConnectionState = 'connected';
          break;
        case 'denied':
          changedConnectionState = 'denied';
          break;
        case 'disconnecting':
          changedConnectionState = 'disconnecting';
          break;
        case 'disconnected':
          changedConnectionState = 'disconnected';
          break;
        default:
          changedConnectionState = 'connecting';
      }
      if (changedConnectionState !== this.connectionState) {
        this.connectionState = changedConnectionState;
        this.emit('connectionStateChanged', this.connectionState);
      }
    });

    this.fpaToken = token;
  }

  /**
   * These options can be passed to {@link Client#getLocalChannels}.
   * @typedef {Object} Client#ChannelSortingOptions
   * @property {('lastMessage'|'friendlyName'|'uniqueName')} [criteria] - Sorting criteria for Channels array
   * @property {('ascending'|'descending')} [order] - Sorting order. If not present, then default is <code>ascending</code>
   */

  /**
   * These options can be passed to Client constructor.
   * @typedef {Object} Client#ClientOptions
   * @property {String} [logLevel='error'] - The level of logging to enable. Valid options
   *   (from strictest to broadest): ['silent', 'error', 'warn', 'info', 'debug', 'trace']
   */

  /**
   * These options can be passed to {@link Client#createChannel}.
   * @typedef {Object} Client#CreateChannelOptions
   * @property {any} [attributes] - Any custom attributes to attach to the Channel
   * @property {String} [friendlyName] - The non-unique display name of the Channel
   * @property {Boolean} [isPrivate] - Whether or not this Channel should be visible to uninvited Clients
   * @property {String} [uniqueName] - The unique identity name of the Channel
   */

  /**
   * Connection state of Client.
   * @typedef {('connecting'|'connected'|'disconnecting'|'disconnected'|'denied')} Client#ConnectionState
   */

  /**
   * Notifications channel type.
   * @typedef {('gcm'|'fcm'|'apn')} Client#NotificationsChannelType
   */

  /**
   * Factory method to create Chat client instance.
   *
   * @param {String} token - Access token
   * @param {Client#ClientOptions} [options] - Options to customize the Client
   * @returns {Promise<Client>}
   */
  @validateTypesAsync('string', ['undefined', pureObject])
  static async create(token: string, options?: Client.Options): Promise<Client> {
    let client = new Client(token, options);
    await client.initialize();
    return client;
  }

  public get user(): User { return this.services.users.myself; }

  public get reachabilityEnabled(): boolean { return this.configuration.reachabilityEnabled; }

  public get token(): string { return this.fpaToken; }

  private subscribeToPushNotifications(channelType: Client.NotificationsChannelType) {
    let subscriptions: Promise<any>[] = [];
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CHANNEL,
      NotificationTypes.INVITED_TO_CHANNEL,
      NotificationTypes.REMOVED_FROM_CHANNEL,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE]
      .forEach(messageType => {
        subscriptions.push(this.services.notificationClient.subscribe(messageType, channelType));
      });
    return Promise.all(subscriptions);
  }

  private unsubscribeFromPushNotifications(channelType: Client.NotificationsChannelType) {
    let subscriptions: Promise<any>[] = [];
    [NotificationTypes.NEW_MESSAGE,
      NotificationTypes.ADDED_TO_CHANNEL,
      NotificationTypes.INVITED_TO_CHANNEL,
      NotificationTypes.REMOVED_FROM_CHANNEL,
      NotificationTypes.TYPING_INDICATOR,
      NotificationTypes.CONSUMPTION_UPDATE]
      .forEach(messageType => {
        subscriptions.push(this.services.notificationClient.unsubscribe(messageType, channelType));
      });
    return Promise.all(subscriptions);
  }

  private async initialize() {
    await this.configurationPromise;

    Client.supportedPushChannels.forEach(channelType => this.subscribeToPushNotifications(channelType));

    this.services.publicChannels = new PublicChannels(this, this.services, this.configuration.links.conversations);
    this.services.userChannels = new UserChannels(this, this.services, this.configuration.links.myConversations);

    const options = Object.assign(this.options);
    options.transport = null;

    this.services.mcsClient = new McsClient(this.fpaToken, this.configuration.links.mediaService, options);

    await this.services.typingIndicator.initialize();
  }

  /**
   * Gracefully shutting down library instance.
   * @public
   * @returns {Promise<void>}
   */
  async shutdown(): Promise<void> {
    await this.services.twilsockClient.disconnect();
  }

  /**
   * Update the token used by the Client and re-register with Programmable Chat services.
   * @param {String} token - Access token
   * @public
   * @returns {Promise<Client>}
   */
  @validateTypesAsync(nonEmptyString)
  async updateToken(token: string): Promise<Client> {
    log.info('updateToken');

    if (this.fpaToken === token) {
      return this;
    }

    await this.services.twilsockClient.updateToken(token)
      .then(() => this.fpaToken = token)
      .then(() => this.services.mcsClient.updateToken(token))
      .then(() => this.configurationPromise);

    return this;
  }

  /**
   * Get a known Channel by its SID.
   * @param {String} channelSid - Channel sid
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync(nonEmptyString)
  async getChannelBySid(channelSid: string): Promise<Channel> {
    return this.channels.myChannelsRead.promise.then(() =>
      this.channels.getChannel(channelSid)
        .then(channel => channel || this.services.publicChannels.getChannelBySid(channelSid).then(x => this.channels.pushChannel(x))));
  }

  /**
   * Get a known Channel by its unique identifier name.
   * @param {String} uniqueName - The unique identifier name of the Channel to get
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync(nonEmptyString)
  async getChannelByUniqueName(uniqueName: string): Promise<Channel> {
    return this.channels.myChannelsRead.promise.then(() =>
      this.services.publicChannels.getChannelByUniqueName(uniqueName).then(x => this.channels.pushChannel(x)));
  }

  /**
   * Get the current list of all subscribed Channels.
   * @returns {Promise<Paginator<Channel>>}
   */
  getSubscribedChannels(args?): Promise<Paginator<Channel>> {
    return this.channelsPromise.then(channels => channels.getChannels(args));
  }

  /**
   * Get array of Channels locally known to Client in provided sorting order.
   * Locally known channels are the ones created and/or joined during client runtime and currently logged in User subscribed Channels.
   * To ensure full list of subscribed Channels fetched - call the {@link Client#getSubscribedChannels} method
   * and fetch all pages with help of {@link Paginator#nextPage} method.
   * @param {Client#ChannelSortingOptions} [sortingOptions] - Options for the Channel sorting
   * @returns {Promise<Array<Channel>>}
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('sorting options', {
      criteria: [literal('lastMessage', 'friendlyName', 'uniqueName'), 'undefined'],
      order: [literal('ascending', 'descending'), 'undefined']
    })
  ])
  getLocalChannels(sortingOptions?: Client.ChannelSortingOptions): Promise<Array<Channel>> {
    return this.channelsPromise.then(channels => {
      let result: Channel[] = [];
      channels.channels.forEach(value => {
        result.push(value);
      });

      const sortingOrder = sortingOptions?.order || 'ascending';

      if (sortingOptions && sortingOptions.criteria) {
        if (sortingOptions.criteria === 'lastMessage') {
          result.sort((a: Channel, b: Channel) =>
            Client.compareChannelsByLastMessage(a, b, sortingOrder));
        } else if (sortingOptions.criteria === 'uniqueName') {
          result.sort((a: Channel, b: Channel) =>
            Client.compareChannelsByStringProperty(a.uniqueName, b.uniqueName, sortingOrder));
        } else if (sortingOptions.criteria === 'friendlyName') {
          result.sort((a: Channel, b: Channel) =>
            Client.compareChannelsByStringProperty(a.friendlyName, b.friendlyName, sortingOrder));
        }
      }

      return result;
    });
  }

  private static compareChannelsByLastMessage(a: Channel, b: Channel, order: Client.ChannelSortingOrder) {
    if (a.lastMessage && b.lastMessage) {
      if (a.lastMessage.dateCreated && b.lastMessage.dateCreated) {
        if (a.lastMessage.dateCreated.getTime() < b.lastMessage.dateCreated.getTime()) {
          return (order === 'ascending') ? -1 : 1;
        } else {
          return (order === 'ascending') ? 1 : -1;
        }
      } else {
        if (a.lastMessage.dateCreated) {
          return -1;
        } else if (b.lastMessage.dateCreated) {
          return 1;
        }
      }
    }

    if (a.lastMessage) {
      return -1;
    } else if (b.lastMessage) {
      return 1;
    }

    return 0;
  }

  private static compareChannelsByStringProperty(str1: string, str2: string, order: Client.ChannelSortingOrder) {
    if (str1 && str2) {
      return (order === 'ascending') ? str1.localeCompare(str2) : -1 * str1.localeCompare(str2);
    } else if (str1) {
      return -1;
    } else if (str2) {
      return 1;
    }

    return 0;
  }

  /**
   * Get the public channels directory content.
   * @returns {Promise<Paginator<ChannelDescriptor>>}
   */
  getPublicChannelDescriptors(): Promise<Paginator<ChannelDescriptor>> {
    return this.services.publicChannels.getChannels();
  }

  /**
   * Get the User's (created by, joined or invited to) channels directory content.
   * @returns {Promise<Paginator<ChannelDescriptor>>}
   */
  getUserChannelDescriptors(): Promise<Paginator<ChannelDescriptor>> {
    return this.services.userChannels.getChannels();
  }

  /**
   * Create a Channel on the server and subscribe to its events.
   * Default options are public Channel type with empty uniqueName and friendlyName.
   * @param {Client#CreateChannelOptions} [options] - Options for the Channel
   * @returns {Promise<Channel>}
   */
  @validateTypesAsync([
    'undefined',
    objectSchema('channel options', {
      friendlyName: ['string', 'undefined'],
      isPrivate: ['boolean', 'undefined'],
      uniqueName: ['string', 'undefined']
    })
  ])
  createChannel(options?: Client.CreateChannelOptions): Promise<Channel> {
    options = options || {};
    return this.channelsPromise.then((channelsEntity) => channelsEntity.addChannel(options));
  }

  /**
   * Registers for push notifications.
   * @param {Client#NotificationsChannelType} channelType - 'gcm', 'apn' and 'fcm' are supported
   * @param {string} registrationId - Push notification id provided by platform
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('gcm', 'fcm', 'apn'), 'string')
  async setPushRegistrationId(channelType: Client.NotificationsChannelType, registrationId: string): Promise<void> {
    await this.subscribeToPushNotifications(channelType)
      .then(() => {
        return this.services.notificationClient.setPushRegistrationId(registrationId, channelType);
      });
  }

  /**
   * Unregisters from push notifications.
   * @param {Client#NotificationsChannelType} channelType - 'gcm', 'apn' and 'fcm' are supported
   * @returns {Promise<void>}
   */
  @validateTypesAsync(literal('gcm', 'fcm', 'apn'))
  async unsetPushRegistrationId(channelType: Client.NotificationsChannelType): Promise<void> {
    if (Client.supportedPushChannels.indexOf(channelType) === -1) {
      throw new Error('Invalid or unsupported channelType: ' + channelType);
    }
    await this.unsubscribeFromPushNotifications(channelType);
  }

  private static parsePushNotificationChatData(data: Object): Object {
    let result: Object = {};
    for (let key in Client.supportedPushDataFields) {
      if (typeof data[key] !== 'undefined' && data[key] !== null) {
        if (key === 'message_index') {
          if (parseToNumber(data[key]) !== null) {
            result[Client.supportedPushDataFields[key]] = Number(data[key]);
          }
        } else {
          result[Client.supportedPushDataFields[key]] = data[key];
        }
      }
    }

    return result;
  }

  /**
   * Static method for push notification payload parsing. Returns parsed push as {@link PushNotification} object
   * @param {Object} notificationPayload - Push notification payload
   * @returns {PushNotification|Error}
   */
  @validateTypes(pureObject)
  static parsePushNotification(notificationPayload): PushNotification {
    log.debug('parsePushNotification, notificationPayload=', notificationPayload);

    // APNS specifics
    if (typeof notificationPayload.aps !== 'undefined') {
      if (!notificationPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload);

      let apsPayload = notificationPayload.aps;
      let body: string = null;
      let title: string = null;
      if (typeof apsPayload.alert === 'string') {
        body = apsPayload.alert || null;
      } else {
        body = apsPayload.alert.body || null;
        title = apsPayload.alert.title || null;
      }

      return new PushNotification({
        title: title,
        body: body,
        sound: apsPayload.sound || null,
        badge: apsPayload.badge || null,
        action: apsPayload.category || null,
        type: notificationPayload.twi_message_type,
        data: data
      });
    }

    // FCM/GCM specifics
    if (typeof notificationPayload.data !== 'undefined') {
      let dataPayload = notificationPayload.data;
      if (!dataPayload.twi_message_type) {
        throw new Error('Provided push notification payload does not contain Programmable Chat push notification type');
      }

      let data = Client.parsePushNotificationChatData(notificationPayload.data);
      return new PushNotification({
        title: dataPayload.twi_title || null,
        body: dataPayload.twi_body || null,
        sound: dataPayload.twi_sound || null,
        badge: null,
        action: dataPayload.twi_action || null,
        type: dataPayload.twi_message_type,
        data: data
      });
    }

    throw new Error('Provided push notification payload is not Programmable Chat notification');
  }

  public parsePushNotification = Client.parsePushNotification;

  /**
   * Handle push notification payload parsing and emits event {@link Client#event:pushNotification} on this {@link Client} instance.
   * @param {Object} notificationPayload - Push notification payload
   * @returns {Promise<void>}
   */
  @validateTypesAsync(pureObject)
  async handlePushNotification(notificationPayload): Promise<void> {
    log.debug('handlePushNotification, notificationPayload=', notificationPayload);
    this.emit('pushNotification', Client.parsePushNotification(notificationPayload));
  }

  /**
   * Gets user for given identity, if it's in subscribed list - then return the user object from it,
   * if not - then subscribes and adds user to the subscribed list.
   * @param {String} identity - Identity of User
   * @returns {Promise<User>} Fully initialized user
   */
  @validateTypesAsync(nonEmptyString)
  public getUser(identity: string): Promise<User> {
    return this.services.users.getUser(identity);
  }

  /**
   * Gets user descriptor for given identity.
   * @param {String} identity - Identity of User
   * @returns {Promise<UserDescriptor>} User descriptor
   */
  @validateTypesAsync(nonEmptyString)
  public async getUserDescriptor(identity: string): Promise<UserDescriptor> {
    return this.services.users.getUserDescriptor(identity);
  }

  /**
   * @returns {Promise<Array<User>>} List of subscribed User objects
   */
  public async getSubscribedUsers(): Promise<Array<User>> {
    return this.services.users.getSubscribedUsers();
  }
}

export { Client };

/**
 * Fired when a Channel becomes visible to the Client.
 * Fired for created and not joined private channels and for all type of channels Client has joined or invited to.
 * @event Client#channelAdded
 * @type {Channel}
 */
/**
 * Fired when the Client is invited to a Channel.
 * @event Client#channelInvited
 * @type {Channel}
 */
/**
 * Fired when the Client joins a Channel.
 * @event Client#channelJoined
 * @type {Channel}
 */
/**
 * Fired when the Client leaves a Channel.
 * @event Client#channelLeft
 * @type {Channel}
 */
/**
 * Fired when a Channel is no longer visible to the Client.
 * @event Client#channelRemoved
 * @type {Channel}
 */
/**
 * Fired when a Channel's attributes or metadata have been updated.
 * During Channel's {@link Client.create| creation and initialization} this event might be fired multiple times
 * for same joined or created Channel as new data is arriving from different sources.
 * @event Client#channelUpdated
 * @type {Object}
 * @property {Channel} channel - Updated Channel
 * @property {Channel#UpdateReason[]} updateReasons - Array of Channel's updated event reasons
 */
/**
 * Fired when Client's connection state has been changed.
 * @event Client#connectionStateChanged
 * @type {Client#ConnectionState}
 */
/**
 * Fired when a Member has joined the Channel.
 * @event Client#memberJoined
 * @type {Member}
 */
/**
 * Fired when a Member has left the Channel.
 * @event Client#memberLeft
 * @type {Member}
 */
/**
 * Fired when a Member's fields has been updated.
 * @event Client#memberUpdated
 * @type {Object}
 * @property {Member} member - Updated Member
 * @property {Member#UpdateReason[]} updateReasons - Array of Member's updated event reasons
 */
/**
 * Fired when a new Message has been added to the Channel on the server.
 * @event Client#messageAdded
 * @type {Message}
 */
/**
 * Fired when Message is removed from Channel's message list.
 * @event Client#messageRemoved
 * @type {Message}
 */
/**
 * Fired when an existing Message's fields are updated with new values.
 * @event Client#messageUpdated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
/**
 * Fired when token is about to expire and needs to be updated.
 * @event Client#tokenAboutToExpire
 * @type {void}
 */
/**
 * Fired when token is expired.
 * @event Client#tokenExpired
 * @type {void}
 */
/**
 * Fired when a Member has stopped typing.
 * @event Client#typingEnded
 * @type {Member}
 */
/**
 * Fired when a Member has started typing.
 * @event Client#typingStarted
 * @type {Member}
 */
/**
 * Fired when client received (and parsed) push notification via one of push channels (apn, gcm, fcm).
 * @event Client#pushNotification
 * @type {PushNotification}
 */
/**
 * Fired when the Client is subscribed to a User.
 * @event Client#userSubscribed
 * @type {User}
 */
/**
 * Fired when the Client is unsubscribed from a User.
 * @event Client#userUnsubscribed
 * @type {User}
 */
/**
 * Fired when the User's properties or reachability status have been updated.
 * @event Client#userUpdated
 * @type {Object}
 * @property {User} user - Updated User
 * @property {User#UpdateReason[]} updateReasons - Array of User's updated event reasons
 */
/**
 * Fired when connection is interrupted by unexpected reason
 * @event Client#connectionError
 * @type {Object}
 * @property {Boolean} terminal - twilsock will stop connection attempts
 * @property {String} message - root cause
 * @property {Number} [httpStatusCode] - http status code if available
 * @property {Number} [errorCode] - Twilio public error code if available
 */
