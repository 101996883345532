import React from 'react';
import './style.css'
import Tooltip from "@material-ui/core/Tooltip";
import Button from "@material-ui/core/Button";
import {injectIntl} from 'react-intl';
import {intlLoader} from 'util/intlLoader';

class NavIcons extends React.Component {

    // intlLoader = (id) => {
    //     const {intl} = this.props;
    //     let text = intl.formatMessage({ id })
    //     return text

    // }
    render() {
        const {intl} = this.props
        return (

            <div className='d-none d-lg-block '>
                {
                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                    // <Tooltip className='ml-5 mx-2' id="tooltip-bottom"
                    <Tooltip className='mx-2' id="tooltip-bottom"
                             title={intlLoader('header.edit_venue', intl) + '.'} placement="bottom">
                        <Button variant="raised" className="BtnStyle" onClick={this.props.addVenue}>
                            <i style={{color: 'white'}} className="zmdi zmdi-settings zmdi-hc-lg"/>
                        </Button>
                    </Tooltip>
                }
                {
                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                    <Tooltip className='mx-2' id="tooltip-bottom"
                             title={intlLoader('header.publish_changes', intl) + "."} placement="bottom">
                        <Button variant="raised" className="BtnStyle" onClick={this.props.pushAction}>
                            <i style={{color: 'white'}} className="zmdi zmdi-flash zmdi-hc-lg"/>
                        </Button>
                    </Tooltip>
                }
                <Tooltip className='mx-2' id="tooltip-bottom" title={intlLoader('header.preview_menu', intl) + "."}
                         placement="bottom">
                    <Button variant="raised" className="BtnStyle" onClick={this.props.preview}>
                        <i style={{color: 'white'}} className="zmdi zmdi-eye zmdi-hc-lg"/>
                    </Button>
                </Tooltip>
                <Tooltip className='mx-2' id="tooltip-bottom" title={intlLoader('header.display_qr', intl) + "."}
                         placement="bottom">
                    <Button variant="raised" className="BtnStyle" onClick={this.props.changeQrcode}>
                        <i style={{color: 'white'}} className="zmdi  zmdi-code-smartphone zmdi-hc-lg"/>
                    </Button>
                </Tooltip>
            </div>
        );
    }
}

export default injectIntl(NavIcons);
