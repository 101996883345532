import {ADD_TABLE,DELETE_TABLE} from "./../constants/ActionTypes";

export const addTable = (table) => {
    return {
        type: ADD_TABLE,
        table: table,
    }
}
export const deleteTable = (id) => {
    return {
        type: DELETE_TABLE,
        id: id
    }
}