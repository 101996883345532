import appLocaleData from 'react-intl/locale-data/hi';
import HiMessages from '../locales/hi_IN.json';

const Hindi = {
  messages: {
    ...HiMessages
  },
  locale: 'hi-IN',
  data: appLocaleData
};
export default Hindi;
