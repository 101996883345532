import {
    ADD_CHILDREN, DELETE_ONE_CHILD, DELETE_ONE_PRICE, EDIT_CHILD,
    ORDER_CHILDREN, UPDATE_CHILDREN,
} from "../constants/ActionTypes";
import arrayMove from "array-move";

const INIT_STATE = {
    children: [],
    // order_children: []
};

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case ADD_CHILDREN: {
            return {
                ...state,
                children: action.children
            }
        }
        case UPDATE_CHILDREN: {
            return {
                ...state,
                children: [...state.children, action.children]
            }
        }
        case ORDER_CHILDREN: {
            return {
                ...state,
                children: arrayMove([...state.children], action.oldIndex, action.newIndex)
            }
        }
        case DELETE_ONE_CHILD: {
            let items = [...state.children];
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    items.splice(index, 1)
                }
            });
            return{
                ...state,
                children: [...items]
            }
        }
        case DELETE_ONE_PRICE: {
            let items = [...state.children];
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    items[index].price.splice(action.index, 1)
                }
            });
            return{
                ...state,
                children: [...items]
            }
        }

        case EDIT_CHILD: {
            let items = [...state.children];
            let idx = 0;
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    idx = index;
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                children: arrayMove([...items, action.children], items.length, idx)
            }

        }

        default:
            return state;
    }
}