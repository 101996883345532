import {ADD_MODIFIER, DELETE_MODIFIER, EDIT_MODIFIER} from "./../constants/ActionTypes"
import arrayMove from "array-move";


const INIT_STATE = {
    modifier: []

};

export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_MODIFIER: {
            return {
                ...state,
                modifier: actions.modifier
            }
        }
        case EDIT_MODIFIER: {
            let items = [...state.modifier];
            let idx = 0;
            items.map((item,index) => {
                if ( item.id === actions.id ) {
                    idx = index;
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                modifier: arrayMove([...items, actions.modifier], items.length, idx)
            }

        }
        case DELETE_MODIFIER: {
            let items = [...state.modifier];
            items.map((item, index) => {
                if (item.id === actions.id) {
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                modifier: [...items]
            }
        }
        default:
            return state
    }
}