import { EventEmitter } from 'events';
import { isDeepEqual, parseAttributes } from './util';
import { Logger } from './logger';

import { Channel } from './channel';
import { McsClient } from 'twilio-mcs-client';
import { Media } from './media';
import { Member } from './member';
import { validateTypesAsync, literal } from 'twilio-sdk-type-validator';
import { Configuration } from './configuration';
import { CommandExecutor } from './commandexecutor';
import { EditMessageRequest } from './interfaces/commands/editmessage';
import { MessageResponse } from './interfaces/commands/messageresponse';

const log = Logger.scope('Message');

namespace Message {
  export type Type = 'text' | 'media';
}

interface MessageState {
  sid: string;
  index: number;
  author?: string;
  body: string;
  dateUpdated: Date;
  lastUpdatedBy: string;
  attributes: Object;
  timestamp: Date;
  type: Message.Type;
  media?: Media;
  memberSid?: string;
}

export interface MessageServices {
  mcsClient: McsClient;
  commandExecutor: CommandExecutor;
}

interface MessageLinks {
  self: string;
  conversation: string;
  messages_receipts: string;
}

namespace Message {
  export type UpdateReason = 'body' | 'lastUpdatedBy' | 'dateCreated' | 'dateUpdated'  | 'attributes' | 'author';

  export interface UpdatedEventArgs {
    message: Message;
    updateReasons: Message.UpdateReason[];
  }
}

/**
 * @classdesc A Message represents a Message in a Channel.
 * @property {String} author - The name of the user that sent Message
 * @property {String} body - The body of the Message. Is null if Message is Media Message
 * @property {any} attributes - Message custom attributes
 * @property {Channel} channel - Channel Message belongs to
 * @property {Date} dateCreated - When Message was created
 * @property {Date} dateUpdated - When Message was updated
 * @property {Number} index - Index of Message in the Channel's messages list
 * @property {String} lastUpdatedBy - Identity of the last user that updated Message
 * @property {Media} media - Contains Media information (if present)
 * @property {String} memberSid - Authoring Member's server-assigned unique identifier
 * @property {String} sid - The server-assigned unique identifier for Message
 * @property {'text' | 'media' } type - Type of message: 'text' or 'media'
 * @fires Message#updated
 */
class Message extends EventEmitter {
  private state: MessageState;

  constructor(
    index: number,
    data: any,
    public readonly channel: Channel,
    private readonly links: MessageLinks,
    private readonly configuration: Configuration,
    private readonly services: MessageServices
  ) {
    super();

    this.state = {
      sid: data.sid,
      index: index,
      author: data.author == null ? null : data.author,
      body: data.text,
      timestamp: data.timestamp ? new Date(data.timestamp) : null,
      dateUpdated: data.dateUpdated ? new Date(data.dateUpdated) : null,
      lastUpdatedBy: data.lastUpdatedBy ? data.lastUpdatedBy : null,
      attributes: parseAttributes(data.attributes, `Got malformed attributes for the message ${data.sid}`, log),
      type: data.type ? data.type : 'text',
      media: (data.type && data.type === 'media' && data.media)
        ? new Media(data.media, this.services) : null,
      memberSid: data.memberSid == null ? null : data.memberSid
    };
  }

  /**
   * The update reason for <code>updated</code> event emitted on Message
   * @typedef {('body' | 'lastUpdatedBy' | 'dateCreated' | 'dateUpdated' | 'attributes' | 'author')} Message#UpdateReason
   */

  public get sid(): string { return this.state.sid; }

  public get author(): string { return this.state.author; }

  public get body(): string {
    if (this.type === 'media') { return null; }
    return this.state.body;
  }

  public get dateUpdated(): Date { return this.state.dateUpdated; }

  public get index(): number { return this.state.index; }

  public get lastUpdatedBy(): string { return this.state.lastUpdatedBy; }

  public get dateCreated(): Date { return this.state.timestamp; }

  public get attributes(): Object { return this.state.attributes; }

  public get type(): Message.Type { return this.state.type; }

  public get media(): Media { return this.state.media; }

  public get memberSid(): string { return this.state.memberSid; }

  _update(data) {
    let updateReasons: Message.UpdateReason[] = [];

    if ((data.text || ((typeof data.text) === 'string')) && data.text !== this.state.body) {
      this.state.body = data.text;
      updateReasons.push('body');
    }

    if (data.lastUpdatedBy && data.lastUpdatedBy !== this.state.lastUpdatedBy) {
      this.state.lastUpdatedBy = data.lastUpdatedBy;
      updateReasons.push('lastUpdatedBy');
    }

    if (data.author && data.author !== this.state.author) {
      this.state.author = data.author;
      updateReasons.push('author');
    }

    if (data.dateUpdated &&
      new Date(data.dateUpdated).getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = new Date(data.dateUpdated);
      updateReasons.push('dateUpdated');
    }

    if (data.timestamp &&
      new Date(data.timestamp).getTime() !== (this.state.timestamp && this.state.timestamp.getTime())) {
      this.state.timestamp = new Date(data.timestamp);
      updateReasons.push('dateCreated');
    }

    let updatedAttributes = parseAttributes(data.attributes, `Got malformed attributes for the message ${this.sid}`, log);
    if (!isDeepEqual(this.state.attributes, updatedAttributes)) {
      this.state.attributes = updatedAttributes;
      updateReasons.push('attributes');
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { message: this, updateReasons: updateReasons });
    }
  }

  /**
   * Get Member who is author of the Message
   * @returns {Promise<Member>}
   */
  async getMember(): Promise<Member> {
    let member: Member = null;
    if (this.state.memberSid) {
      member = await this.channel.getMemberBySid(this.memberSid)
                         .catch(() => {
                           log.debug('Member with sid "' + this.memberSid + '" not found for message ' + this.sid);
                           return null;
                         });
    }
    if (!member && this.state.author) {
      member = await this.channel.getMemberByIdentity(this.state.author)
                         .catch(() => {
                           log.debug('Member with identity "' + this.author + '" not found for message ' + this.sid);
                           return null;
                         });
    }
    if (member) {
      return member;
    }
    let errorMesage = 'Member with ';
    if (this.state.memberSid) {
      errorMesage += 'SID \'' + this.state.memberSid + '\' ';
    }
    if (this.state.author) {
      if (this.state.memberSid) {
        errorMesage += 'or ';
      }
      errorMesage += 'identity \'' + this.state.author + '\' ';
    }
    if (errorMesage === 'Member with ') {
      errorMesage = 'Member ';
    }
    errorMesage += 'was not found';
    throw new Error(errorMesage);
  }

  /**
   * Remove the Message.
   * @returns {Promise<Message>}
   */
  async remove(): Promise<Message> {
    await this.services.commandExecutor.mutateResource(
      'delete',
      this.links.self,
    );

    return this;
  }

  /**
   * Edit message body.
   * @param {String} body - new body of Message.
   * @returns {Promise<Message>}
   */
  @validateTypesAsync('string')
  async updateBody(body: string): Promise<Message> {
    await this.services.commandExecutor.mutateResource<EditMessageRequest, MessageResponse>(
      'post',
      this.links.self,
      {
        body
      }
    );

    return this;
  }

  /**
   * Edit message attributes.
   * @param {any} attributes new attributes for Message.
   * @returns {Promise<Message>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Message> {
    await this.services.commandExecutor.mutateResource<EditMessageRequest, MessageResponse>(
      'post',
      this.links.self,
      {
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );

    return this;
  }
}

export { Message };

/**
 * Fired when the Message's properties or body has been updated.
 * @event Message#updated
 * @type {Object}
 * @property {Message} message - Updated Message
 * @property {Message#UpdateReason[]} updateReasons - Array of Message's updated event reasons
 */
