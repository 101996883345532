import {
  ADD_DESIGN,
  ADD_DESIGN_ID,
  CLEAR_EDITED_ITEMS,
  EDITED_ITEMS,
  UPDATE_DESIGN,
} from "../constants/ActionTypes";

const INIT_STATE = {
  designs: [],
  designId: null,
  editedItems: [],
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case ADD_DESIGN: {
      return {
        ...state,
        designs: action.designs,
      };
    }
    case ADD_DESIGN_ID: {
      return {
        ...state,
        designId: action.id,
      };
    }
    case EDITED_ITEMS: {
      const items = [...state.editedItems];
      items.map((item, index) => {
        if (item.name === action.name) {
          items.splice(index, 1);
        }
      });
      return {
        ...state,
        editedItems: [...items, { name: action.name, color: action.color }],
      };
    }
    case CLEAR_EDITED_ITEMS: {
      return {
        ...state,
        editedItems: [],
      };
    }

    case UPDATE_DESIGN: {
      return {
        ...state,
        designs: [...state.designs, action.designs],
      };
    }

    default:
      return state;
  }
};
