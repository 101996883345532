import {

    ADD_SURVEY_QUESTION,
    UPDATE_SURVEY_QUESTION
} from "../constants/ActionTypes";
import arrayMove from "array-move";

const INIT_STATE = {
    survey_qn: null
}

export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_SURVEY_QUESTION: {
            return {
                ...state,
                survey_qn: actions.survey_qn
            }
        }

        case UPDATE_SURVEY_QUESTION: {
            let items = [...actions.survey_qn];
            let idx = 0;

            items.map((item, index) => {
                if (index === actions.extra.i) {
                    idx = index;
                    item.questions['en'] = actions.extra.e.target.value
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                // survey_qn: actions.survey_qn,
                survey_qn: arrayMove([...items, actions.survey_qn[idx]], items.length, idx)

            }
        }

        default:
            return state
    }
}
