import {UPDATE_LOADER} from "./../constants/ActionTypes"


const INIT_STATE={
    loader:false

};

export default (state=INIT_STATE,actions)=>{
    switch (actions.type) {
        case UPDATE_LOADER:{
            return{
                ...state,
                loader: actions.loader
            }
        }
        default:
            return state
    }
}