import {
  SELECT_VENUE,
  ADD_VENUE,
  VENUE_UPDATE,
  EDIT_VENUE,
  UPDATE_SELECT_VENUE,
} from "../constants/ActionTypes";
import arrayMove from "array-move";

const INIT_STATE = {
  venues: [],
  selectedVenue: null,
};

export default (state = INIT_STATE, action) => {
  switch (action.type) {
    case SELECT_VENUE: {
      return {
        ...state,
        selectedVenue: action.venueName,
      };
    }
    case ADD_VENUE: {
      return {
        ...state,
        venues: action.venue.map((item) => {
          return {
            value: item.id,
            label: item.venueName,
            venueQrId: item.venue_id,
            venueQrImage: item.qr_image,
            venueQrPin: item.pin_code,
            defaultLanguage: item.languages,
            venueTimezone: item.timezone,
            general_qr: item.general_qr,
            role: item.role,
            company: item.company,
            venue_type: item.venue_type,
            serves_alcohol: item.serves_alcohol,
          };
        }),
      };
    }

    case EDIT_VENUE: {
      const venues = [...state.venues];
      let idx = 0;
      venues.map((each, index) => {
        if (each.value === action.venue.value) {
          idx = index;
          venues.splice(index, 1);
        }
      });
      return {
        ...state,
        venues: arrayMove([...venues, action.venue], venues.length, idx),
      };
    }

    case VENUE_UPDATE: {
      state.venues.push({
        value: action.venueId,
        label: action.venueName,
        venueQrId: action.venueQrId,
        venueQrImage: action.venueQrImage,
        venueQrPin: action.venueQrPin,
        defaultLanguage: action.defaultLanguage,
        venueTimezone: action.venueTimezone,
        company: action.company,
        venue_type: action.venue_type,
        serves_alcohol: action.serves_alcohol,
        // 'defaultLanguage': action.languages,
        // 'venueTimezone':action.timezone
      });
    }

    case UPDATE_SELECT_VENUE: {
      const venue = { ...state.selectedVenue };
      if (action.venue) {
        venue.label = action.venue.venueName;
        venue.venueTimezone = action.venue.timezone;
        venue.company = action.venue.company;
        venue.venue_type = action.venue.venue_type;
        venue.serves_alcohol = action.venue.serves_alcohol;
        localStorage.setItem("currentVenue", JSON.stringify(venue));
        return {
          ...state,
          selectedVenue: venue,
        };
      }
    }

    default:
      return state;
  }
};
