import { EventEmitter } from 'events';
import { Logger } from '../logger';
import { Channel } from '../channel';

import { SyncMap, SyncClient } from 'twilio-sync';
import { ChannelDescriptor } from '../channeldescriptor';
import { Users } from './users';
import { Network } from '../services/network';
import { TypingIndicator } from '../services/typingindicator';
import { McsClient } from 'twilio-mcs-client';
import { Deferred } from '../util/deferred';
import { Member } from '../member';
import { Message } from '../message';
import { isDeepEqual, UriBuilder } from '../util';
import { Configuration } from '../configuration';
import { CommandExecutor } from '../commandexecutor';
import { CreateChannelRequest } from '../interfaces/commands/createchannel';
import { ChannelResponse } from '../interfaces/commands/channel';

const log = Logger.scope('Channels');

export interface ChannelsServices {
  syncClient: SyncClient;
  users: Users;
  typingIndicator: TypingIndicator;
  network: Network;
  mcsClient: McsClient;
  commandExecutor: CommandExecutor;
}

/**
 * Represents channels collection
 * {@see Channel}
 */
class Channels extends EventEmitter {

  public readonly channels: Map<string, Channel> = new Map<string, Channel>();
  private readonly tombstones: Set<string> = new Set<string>();
  private myChannelsFetched = false;
  private myChannelsRead: Deferred<boolean> = new Deferred<boolean>();

  constructor(
    private readonly configuration: Configuration,
    private readonly services: ChannelsServices
  ) {
    super();
  }

  private async getMap(): Promise<SyncMap> {
    return await this.services.syncClient.map({
      id: this.configuration.myConversations,
      mode: 'open_existing'
    });
  }

  /**
   * Add channel to server
   * @private
   * @returns {Promise<Channel>} Channel
   */
  async addChannel(options): Promise<Channel> {
    let attributes;
    if (typeof options.attributes === 'undefined') {
      attributes = {};
    } else {
      attributes = options.attributes;
    }

    const response = await this.services.commandExecutor.mutateResource<CreateChannelRequest, ChannelResponse>(
      'post',
      this.configuration.links.conversations,
      {
        type: options.isPrivate ? 'private' : 'public',
        unique_name: options.uniqueName,
        friendly_name: options.friendlyName,
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined,
      }
    );

    const channelSid = response.sid || null;
    const channelDocument = response.sync_objects.conversation || null;
    const links = {
      self: response.url,
      ...response.links
    };

    let existingChannel = this.channels.get(channelSid);
    if (existingChannel) {
      await existingChannel._subscribe();
      return existingChannel;
    }

    let channel = new Channel(
      {
        channel: channelDocument,

        entityName: null,
        uniqueName: null,
        attributes: null,
        createdBy: null,
        friendlyName: null,
        lastConsumedMessageIndex: null,
        type: options.isPrivate ? 'private' : 'public',
        dateCreated: null,
        dateUpdated: null
      },
      channelSid,
      links,
      this.configuration,
      this.services
    );

    this.channels.set(channel.sid, channel);
    this.registerForEvents(channel);

    await channel._subscribe();
    this.emit('channelAdded', channel);
    return channel;
  }

  /**
   * Fetch channels list and instantiate all necessary objects
   */
  async fetchChannels() {
    try {
      const map = await this.getMap();

      map.on('itemAdded', args => {
        log.debug(`itemAdded: ${args.item.key}`);
        this.upsertChannel('sync', args.item.key, args.item.data);
      });

      map.on('itemRemoved', args => {
        log.debug(`itemRemoved: ${args.key}`);
        const sid = args.key;

        if (!this.myChannelsFetched) {
          this.tombstones.add(sid);
        }

        const channel = this.channels.get(sid);

        if (!channel) {
          return;
        }

        if (channel.status === 'joined' || channel.status === 'invited') {
          channel._setStatus('notParticipating', 'sync');
          this.emit('channelLeft', channel);
        }

        if (channel.isPrivate) {
          this.channels.delete(sid);
          this.emit('channelRemoved', channel);
          channel.emit('removed', channel);
        }
      });

      map.on('itemUpdated', args => {
        log.debug(`itemUpdated: ${args.item.key}`);
        this.upsertChannel('sync', args.item.key, args.item.data);
      });

      const myChannels = await this._fetchMyChannels();
      const upserts = [];

      for (const channel of myChannels) {
        upserts.push(this.upsertChannel('rest', channel.channel_sid, channel));
      }

      this.myChannelsRead.set(true);

      await Promise.all(upserts);

      this.myChannelsFetched = true;
      this.tombstones.clear();

      log.debug('The channels list has been successfully fetched');

      return this;
    } catch (error) {
      const errorMessage = 'Failed to fetch the channels list';

      if (this.services.syncClient.connectionState !== 'disconnected') {
        log.error(errorMessage, error);
      }

      log.debug(`ERROR: ${errorMessage}`, error);

      throw error;
    }
  }

  private _wrapPaginator(page, op) {
    return op(page.items)
      .then(items => ({
        items: items,
        hasNextPage: page.hasNextPage,
        hasPrevPage: page.hasPrevPage,
        nextPage: () => page.nextPage().then(x => this._wrapPaginator(x, op)),
        prevPage: () => page.prevPage().then(x => this._wrapPaginator(x, op))
      }));
  }

  getChannels(args) {
    return this.getMap()
               .then(channelsMap => channelsMap.getItems(args))
               .then(page => this._wrapPaginator(page
                 , items => Promise.all(items.map(item => this.upsertChannel('sync', item.key, item.data))))
               );
  }

  getChannel(sid: string): Promise<Channel> {
    return this.getMap()
               .then(channelsMap => channelsMap.getItems({ key: sid }))
               .then(page => page.items.map(item => this.upsertChannel('sync', item.key, item.data)))
               .then(items => items.length > 0 ? items[0] : null);
  }

  pushChannel(descriptor: ChannelDescriptor): Promise<Channel> {
    const sid = descriptor.sid;
    const data = {
      entityName: null,
      lastConsumedMessageIndex: descriptor.lastConsumedMessageIndex,
      type: descriptor.type,
      status: descriptor.status,
      friendlyName: descriptor.friendlyName,
      dateUpdated: descriptor.dateUpdated,
      dateCreated: descriptor.dateCreated,
      uniqueName: descriptor.uniqueName,
      createdBy: descriptor.createdBy,
      attributes: descriptor.attributes,
      channel: descriptor.channel,
      notificationLevel: descriptor.notificationLevel,
      sid: sid
    };

    return this.upsertChannel('chat', sid, data);
  }

  private _updateChannel(source: Channels.DataSource, channel: Channel, data): void {
    const areSourcesDifferent = channel._statusSource() !== undefined && source !== channel._statusSource();
    const isChannelSourceSync = source !== 'rest' || channel._statusSource() === 'sync';

    if (areSourcesDifferent && isChannelSourceSync && source !== 'sync') {
      log.trace('upsertChannel: the channel is known from sync and it came from chat, ignoring', {
        sid: channel.sid,
        data: data.status,
        channel: channel.status
      });

      return;
    }

    if (['joined', 'invited'].includes(data.status) && channel.status !== data.status) {
      channel._setStatus(data.status, source);

      let updateData: any = {};

      if (data.notificationLevel !== undefined) {
        updateData.notificationLevel = data.notificationLevel;
      }

      if (data.lastConsumedMessageIndex !== undefined) {
        updateData.lastConsumedMessageIndex = data.lastConsumedMessageIndex;
      }

      if (!isDeepEqual(updateData, {})) {
        channel._update(updateData);
      }

      channel._subscribe().then(() => {
        this.emit(data.status === 'joined' ? 'channelJoined' : 'channelInvited', channel);
      });

      return;
    }

    if (['joined', 'invited'].includes(channel.status) && data.status === 'notParticipating') {
      channel._setStatus('notParticipating', source);
      channel._update(data);
      channel._subscribe().then(() => {
        this.emit('channelLeft', channel);
      });

      return;
    }

    if (data.type === 'private' && data.status === 'notParticipating') {
      channel._subscribe();

      return;
    }

    channel._update(data);
  }

  private upsertChannel(source: Channels.DataSource, sid: string, data): Promise<Channel> {
    log.trace(`upsertChannel called for ${sid}`, data);
    const channel = this.channels.get(sid);

    // If the channel is known, update it
    if (channel) {
      log.trace(
        `upsertChannel: the channel ${channel.sid} is known;` +
        `its status is known from source ${channel._statusSource()} ` +
        `and the update came from source ${source}`,
        channel
      );
      this._updateChannel(source, channel, data);

      return channel._subscribe().then(() => channel);
    }

    // If the channel is deleted, ignore it
    if (['chat', 'rest'].includes(source) && this.tombstones.has(sid)) {
      log.trace('upsertChannel: the channel is deleted but reappeared again from chat, ignoring', sid);

      return;
    }

    // If the channel is unknown, fetch it
    log.trace(`upsertChannel: creating a local channel object with sid ${sid}`, data);
    const baseLink = `${this.configuration.links.conversations}/${sid}`;
    const links = {
      self: baseLink,
      messages: `${baseLink}/Messages`,
      participants: `${baseLink}/Participants`,
      invites: `${baseLink}/Invites`
    };
    const newChannel = new Channel(data, sid, links, this.configuration, this.services);
    this.channels.set(sid, newChannel);

    return newChannel._subscribe().then(() => {
      this.registerForEvents(newChannel);
      this.emit('channelAdded', newChannel);

      if (['joined', 'invited'].includes(data.status)) {
        newChannel._setStatus(data.status, source);
        this.emit(data.status === 'joined' ? 'channelJoined' : 'channelInvited', newChannel);
      }

      return newChannel;
    });
  }

  private onChannelRemoved(sid: string) {
    let channel = this.channels.get(sid);
    if (channel) {
      this.channels.delete(sid);
      this.emit('channelRemoved', channel);
    }
  }

  private registerForEvents(channel) {
    channel.on('removed', () => this.onChannelRemoved(channel.sid));
    channel.on('updated', (args: Channel.UpdatedEventArgs) => this.emit('channelUpdated', args));
    channel.on('memberJoined', this.emit.bind(this, 'memberJoined'));
    channel.on('memberLeft', this.emit.bind(this, 'memberLeft'));
    channel.on('memberUpdated', (args: Member.UpdatedEventArgs) => this.emit('memberUpdated', args));
    channel.on('messageAdded', this.emit.bind(this, 'messageAdded'));
    channel.on('messageUpdated', (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    channel.on('messageRemoved', this.emit.bind(this, 'messageRemoved'));
    channel.on('typingStarted', this.emit.bind(this, 'typingStarted'));
    channel.on('typingEnded', this.emit.bind(this, 'typingEnded'));
  }

  private async _fetchMyChannels() {
    let channels = [];
    let pageToken: null | string = null;

    do {
      const url = new UriBuilder(this.configuration.links.myConversations);

      if (pageToken) {
        url.arg('PageToken', pageToken);
      }

      const response = await this.services.network.get(url.build());
      const preProcessedChannels = response.body.conversations.map(
        (channelDescriptor) => ({
          descriptor: channelDescriptor,
          channel_sid: channelDescriptor.conversation_sid,
          status: channelDescriptor.status,
          channel: channelDescriptor.sync_objects.conversation,
          messages: channelDescriptor.sync_objects.messages,
          roster: `${channelDescriptor.conversation_sid}.roster`,
          lastConsumedMessageIndex: channelDescriptor.last_consumed_message_index,
          notificationLevel: channelDescriptor.notification_level
        })
      );

      pageToken = response.body.meta.next_token;
      channels = [...channels, ...preProcessedChannels];
    } while (pageToken);

    return channels;
  }
}

namespace Channels {
  export type DataSource = 'sync' | 'chat' | 'rest';
}

export { Channel, Channels };
