import {ADD_PROGRESS,} from '../constants/ActionTypes';


export const addProgress = (total) => {
    return{
        type: ADD_PROGRESS,
        total: total,
        // loaded: loaded,
    };
};
