import React from "react";
import {Link, withRouter} from "react-router-dom";
import {connect} from "react-redux";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Swal from "sweetalert2";
import "sweetalert2/src/sweetalert2.scss";

import moment from "moment-timezone";
import {
    Button,
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import {performRequest} from "../../containers/services/apiHandler";
import {
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER,
} from "constants/ActionTypes";
import {switchLanguage, toggleCollapsedNav} from "actions/Setting";
import IntlMessages from "util/IntlMessages";
import Menu from "components/TopNav/Menu";
import Select from "react-select";
import NavIcons from "components/navButtons";
import {
    addVenue,
    selectVenue,
    venueUpdate,
    editOneVenue,
} from "../../actions/Venue";
import {addPlans, getCurrentUser} from "./../../actions/index";
import {
    NotificationContainer,
    NotificationManager,
} from "react-notifications";
import Drawer from "@material-ui/core/Drawer";
import Iframe from "react-iframe";
import "./style.css";
import UserInfo from "components/UserInfo";
import {Scrollbars} from "react-custom-scrollbars";
import firebase, {initializeApp} from "firebase";
import Sound from "react-sound";
import {updateSelectVenue} from "../../actions";
import SweetAlert from "react-bootstrap-sweetalert";
import {injectIntl} from "react-intl";
import {intlLoader} from "util/intlLoader";
import LanguageSwitcher from "components/LanguageSwitcher/index";
import {Tabs, Tab, ListItem, Chip, Divider} from "@material-ui/core";
import {IMAGE_ERROR_DURATION} from "../../constants/constant";
import {onMessageListener} from "firebase";
import chatClientInstance from "twilio-chat";
import ChatGrant from "twilio-chat";
import client from "twilio";

// const accountSid = 'ACc8b957a7353a60af86fbb36e3e3753e0';
// const authToken = '1b3fd072021d2bf3bb166ad39ebd1059';

class Header extends React.Component {
    onAppNotificationSelect = () => {
        this.setState({
            appNotification: !this.state.appNotification,
        });
    };
    onMailNotificationSelect = () => {
        this.setState({
            mailNotification: !this.state.mailNotification,
        });
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher,
            anchorEl: event.currentTarget,
        });
    };
    onSearchBoxSelect = () => {
        this.setState({
            searchBox: !this.state.searchBox,
        });
    };
    onOptionBoxSelect = () => {
        this.setState({
            selectBox: !this.state.selectBox,
        });
    };
    onUserInfoSelect = () => {
        this.setState({
            userInfo: !this.state.userInfo,
        });
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
            userInfo: false,
            mailNotification: false,
            appNotification: false,
            searchBox: false,
        });
    };

    constructor() {
        super();
        this.state = {
            anchorEl: undefined,
            searchBox: false,
            selectBox: false,
            searchText: "",
            mailNotification: false,
            userInfo: false,
            langSwitcher: false,
            appNotification: false,
            selectedOption: null,
            selectedLanguage: null,
            selectedCountry: null,
            modal: false,
            langages: [],
            country: [],
            venueName: "",
            mobile_number: "",
            venus: [],
            warnings: "",
            right: false,
            apps: false,
            dropdownToggle: false,
            selectedValue: "selecteddd",
            editModal: false,
            editVenueName: "",
            editVenueId: "",
            editVenuePhone: "",
            // timezone: [{value: 1, label: "Asia/Dubai"}],
            editVenueAddress: "",
            editVenueCity: "",
            editVenueEmail: "",
            editVenueCountry: [],
            editVenueWebsite: "",
            editVenueTimezone: "",
            editVenueState: "",
            editVenueUser: "",
            editVenueLanguage: "",
            qrModal: false,
            qr_image: null,
            venue: "",
            password: "",
            foursquare: "",
            twitter: "",
            instagram: "",
            facebook: "",
            countVenue: true,
            country_warning: "",
            language_warning: "",
            venue_warning: "",
            edit_venue_warning: "",
            default_language: "",
            edit_email_warning: "",
            edit_website_warning: "",
            edit_phone_warning: "",
            reg: /^[0-9\b]+$/,
            pincodeWarning: false,
            clickCount: 0,
            planActive: false,
            plans: [],
            timezoneList: [],
            timezoneOptions: [],
            timezoneEditError: false,
            timezoneCreateError: false,
            createVenueTimezone: "",
            new_order: false,
            notification_message: "",
            notification_body: "",
            selectedTab: 0,
            general_qr: "",
            banner: true,
            createVenue: false,
            editVenueTypeList: [
                {value: 0, label: "BISTRO"},
                {value: "BUFFET", label: "BUFFET"},
                {value: "CAFE", label: "CAFE"},
                {value: "CASUAL DINING", label: "CASUAL DINING"},
                {value: "COFFEE SHOP", label: "COFFEE SHOP"},
                {value: "DRIVE-THRU", label: "DRIVE-THRU"},
                {value: "FAST CASUAL", label: "FAST CASUAL"},
                {value: "FAST FOOD", label: "FAST FOOD"},
                {value: "FINE DINING", label: "FINE DINING"},
                {value: "FOOD TRUCK", label: "FOOD TRUCK"},
                {value: "LOUNGE", label: "LOUNGE"},
                {value: "PUB", label: "PUB"},
                {value: "SPORTS BAR", label: "SPORTS BAR"},
            ],
            createVenueAlcohol: "",
            company_new: "",
            createSellAlcahol: "",
            editVenueType: "",
            createVenueType: "",
            company_newError: false,
            createVenueTypeError: false,
            createSellAlcaholError: false,
            updatePopup: false,
            venueList: []
        };
        this.print = this.print.bind(this);
        const accountSid = "ACc8b957a7353a60af86fbb36e3e3753e0";
        const authToken = "1b3fd072021d2bf3bb166ad39ebd1059";
    }

    /**
     * UserSignOut
     */
    userSignOut = () => {
        localStorage.removeItem("accessToken");
        this.props.history.push("signin/");
    };
    handleClose = () => {
        this.setState({anchorEl: null});
    };

    print() {
        var content = document.getElementById("printarea");
        var pri = document.getElementById("ifmcontentstoprint").contentWindow;
        pri.document.open();
        pri.document.write(content.innerHTML);
        pri.document.close();
        pri.focus();
        pri.print();
    }

    /**
     * Toggle AppsMenu In mobile responsive view
     */
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps,
        });
    };

    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    /**
     * Update Venue In Props When Select Venue from list
     * @param selectedOption
     */
    handleChange = (selectedOption) => {

        this.setState({selectedOption});
        this.props.selectVenue(selectedOption);
    };

    handleChangeCountry = (selectedCountry) => {
        this.setState({selectedCountry});
        this.setState({
            country_warning: "",
        });
    };

    handleEditChangeCountry = (selectedCountry) => {
        this.setState({editVenueCountry: selectedCountry});
    };
    handleCreateVenueType = (createVenueType) => {
        this.setState({createVenueType: createVenueType});
    };
    handleCreateSellAlcahol = (createSellAlcahol) => {
        this.setState({createSellAlcahol: createSellAlcahol});
    };

    handleEditChangeTimezone = (editVenueTimezone) => {
        this.setState({editVenueTimezone: editVenueTimezone});
    };
    handleEditVenueTypeList = (editVenueType) => {
        this.setState({editVenueType: editVenueType});
    };

    handleEditSell = (editVenueSell) => {
        this.setState({editVenueSell: editVenueSell});
    };

    handleCreateChangeTimezone = (createVenueTimezone) => {
        this.setState({createVenueTimezone: createVenueTimezone});
    };
    handleCreateVenueAlcohol = (createVenueAlcohol) => {
        this.setState({createVenueAlcohol: createVenueAlcohol});
    };

    handleChangeLanguage = (selectedLanguage) => {
        this.setState({selectedLanguage});
    };

    tokenPost = (t, v) => {
        return performRequest("post", "/api/web-app-token/", {
            fcm_token: t,
            venue: v,
        });
    };

    generateQrImage = (uuid) => {
        if (uuid) {
            let data = {
                background_color: "#ffffff",
                foreground_color: "#000000",
                // url: 'https://qr-menu.mydigimenu.com/' + response.data.results[0].general_qr.qr_id
                url: "https://qr.mydigimenu.com/" + uuid,
            };
            performRequest("post", "api/qr/generate/", data).then((res) => {
                this.setState({
                    general_qr: res.data.image,
                });
            });
        }
    };
    componentDidMount = async () => {
        //
        // let chatGrant = new ChatGrant({
        //     serviceSid: 'ISddb24b97f92148cebb1caeffa2674b1f',
        //     pushCredentialSid: 'CR4b4c8f2ff4b5aba342a5306caf7f2a9a',
        // });
        var dt = "30/05/2020 12:34:18";
        var m = moment(dt, "DD-MM-YYYY h:mm:ss A"); // parse input as UTC
        var tz = "Europe/London"; // example value, you can use moment.tz.guess()

        this.audio = new Audio("../../assets/sounds/eventually.mp3");
        this.audio.load();

        navigator.serviceWorker.addEventListener("message", (message) => {
            this.setState({
                playSound: true,
                new_order: true,
                notification_message:
                message.data["firebase-messaging-msg-data"].notification.title,
                notification_body:
                message.data["firebase-messaging-msg-data"].notification.body,
            });
            // setTimeout(function () { //Start the timer
            //     this.setState({playSound: false}) //After 1 second, set render to true
            // }.bind(this), 120000)
            // NotificationManager.success(message.data['firebase-messaging-msg-data']
            //     .notification.title, message.data['firebase-messaging-msg-data'].notification.body, 120000, () => {
            //     this.setState({playSound: false})
            //     this.props.history.push('/app/orders');
            // });
        });
        let result = [];
        performRequest("get", "api/plans/").then((res) => {
            this.props.addPlans(res.data.results);
            // this.setState({plans: res.data.results})
            this.props.toggleBanner(true);
        });
        performRequest("get", "api/timezone/").then((res) => {
            let timezoneOptions = [];
            res.data.map(function (each) {
                timezoneOptions.push({value: each.id, label: each.timezone});
            });
            this.setState({
                timezoneOptions: timezoneOptions.sort((a, b) =>
                    parseInt(a.offset) > parseInt(b.offset) ? -1 : 1
                ),
            });
        });
        // .sort((a, b) => (a['label'] < b['label'] ? -1 : 1))
        performRequest("get", "api/venue/list/")
            // performRequest('get', 'api/venue/')
            .then((response) => {
                this.props.addVenue(response.data);
                this.setState({venueList: response.data})

                if (response.data.length) {
                    let selectedVenue =
                        localStorage.getItem("currentVenue") !== "undefined" &&
                        localStorage.getItem("currentVenue")
                            ? JSON.parse(localStorage.getItem("currentVenue"))
                            : this.props.venueList[0];
                    if (
                        !selectedVenue.company ||
                        !selectedVenue.serves_alcohol == null ||
                        !selectedVenue.venue_type
                    ) {
                        // this.additionalDataToggle();
                        this.setState({
                            updatePopup: true,
                            editVenueCompany: "",
                            editVenueType: "",
                            editVenueSell: "",
                        });

                    }

                    this.props.selectVenue(selectedVenue);
                    try {
                        const messaging = firebase.messaging();
                        messaging.requestPermission();
                        messaging
                            .getToken()
                            .then((token) => {
                                let venue = JSON.parse(localStorage.getItem("currentVenue"));
                                this.props.venueList.map(function (e) {
                                    performRequest("post", "/api/web-app-token/", {
                                        fcm_token: token,
                                        venue: e.value,
                                    });
                                    // this.tokenPost(token, e.value)
                                });
                            })
                            .catch((e) => {

                            });
                        messaging.onMessage((payload) => {
                            // NotificationManager.success(payload.data.notification.title, payload.data.notification.body, 1000);
                        });

                        // return token;
                    } catch (error) {

                    }
                }
                !this.props.venueList.length &&
                this.setState({modal: true, countVenue: false});
            })
            .catch((error) => {
            });
        performRequest("get", "current_user/")
            .then((response) => {
                // if (!response.data.is_active)
                //     this.props.history.push('/verify-email');
                this.props.getCurrentUser(
                    response.data.first_name,
                    response.data.last_name,
                    response.data.email,
                    response.data.id
                );
                let fullname = response.data.first_name + " " + response.data.last_name;
                window.Intercom("hide");
                window.Intercom("boot", {
                    app_id: "t8wab3x6",
                    name: fullname, // Full name
                    email: response.data.email, // Email address
                    created_at: "1312182000", // Signup date as a Unix timestamp
                });
            })
            .catch((error) => {
            });

        performRequest("get", "api/countries/")
            .then((response) => {
                response.data.results.map((item) => {
                    result.push({value: item["iso"], label: item["name"]});
                });
                performRequest("get", "api/countries/?offset=100").then((response) => {
                    response.data.results.map((item) => {
                        result.push({value: item["iso"], label: item["name"]});
                    });
                    performRequest("get", "api/countries/?offset=200").then(
                        (response) => {
                            response.data.results.map((item) => {
                                result.push({value: item["iso"], label: item["name"]});
                            });
                            this.setState({country: [...result]});
                        }
                    );
                });
            })
            .catch((error) => {
            });
        performRequest("get", "api/languages/")
            .then((response) => {
                let result = [];
                response.data.map((item) => {
                    let label = item["name_en"] + " - " + item["name_native"];
                    result.push({value: item["iso_639_1"], label: label});
                });
                this.setState({langages: [...result]});
            })
            .catch((error) => {
            });
    };

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    updateSearchText(evt) {
        this.setState({
            searchText: evt.target.value,
        });
    }

    saveData = () => {
        this.setState({
            company_newError: false,
            createVenueTypeError: false,
            createSellAlcaholError: false,
        });
        if (this.state.selectedCountry === null) {
            this.setState({
                country_warning: intlLoader("header.counry_req", this.props.intl),
            });
        } else {
            if (this.state.selectedLanguage === null) {
                this.setState({
                    language_warning: intlLoader("header.language_req", this.props.intl),
                });
            } else {
                if (!this.state.createVenueTimezone) {
                    this.setState({
                        timezoneCreateError: true,
                    });
                } else {
                    if (
                        !this.state.company_new ||
                        !this.state.createSellAlcahol ||
                        !this.state.createVenueType
                    ) {
                        !this.state.company_new &&
                        this.setState({company_newError: true});
                        !this.state.createVenueType &&
                        this.setState({createVenueTypeError: true});
                        !this.state.createSellAlcahol &&
                        this.setState({createSellAlcaholError: true});
                    } else {
                        this.setState({
                            country_warning: "",
                            timezoneCreateError: false,
                        });
                        let data = {
                            venueName: this.state.venueName,
                            mobile_number: this.state.mobile_number,
                            country: this.state.selectedCountry
                                ? this.state.selectedCountry["value"]
                                : null,
                            languages_id: this.state.selectedLanguage
                                ? this.state.selectedLanguage["value"]
                                : null,
                            timezone: this.state.createVenueTimezone.value,
                            company: this.state.company_new,
                            serves_alcohol: this.state.createSellAlcahol.value,
                            venue_type: this.state.createVenueType.value,
                        };
                        this.setState({clickCount: this.state.clickCount + 1}, () => {
                            if (this.state.clickCount === 1)
                                performRequest("post", "api/venue/", data)
                                    .then((response) => {
                                        this.props.history.push("/app/dashboard/default");
                                        const qr_image =
                                            response.data[0].qr_image.substring(4, 0) === "http"
                                                ? response.data[0].qr_image
                                                : "https://mymenu-backend-staging.s3.amazonaws.com/media/" +
                                                response.data[0].qr_image;
                                        this.props.venueUpdate(
                                            response.data[0].id,
                                            response.data[0].venueName,
                                            response.data[0].pin_code,
                                            qr_image,
                                            response.data[0].venue_id,
                                            response.data[0].languages_id,
                                            response.data[0].timezone,
                                            response.data[0].company,
                                            response.data[0].venue_type,
                                            response.data[0].serves_alcohol
                                        );
                                        this.props.selectVenue({
                                            value: response.data[0].id,
                                            label: response.data[0].venueName,
                                            venueQrPin: response.data[0].pin_code,
                                            venueQrImage:
                                                response.data[0].qr_image.substring(4, 0) === "http"
                                                    ? response.data[0].qr_image
                                                    : "https://mymenu-backend-staging.s3.amazonaws.com/media/" +
                                                    response.data[0].qr_image,
                                            venueQrId: response.data[0].venue_id,
                                            defaultLanguage: response.data[0].languages_id,
                                            venueTimezone: response.data[0].timezone,
                                            company: response.data[0].company,
                                            venue_type: response.data[0].venue_type,
                                            serves_alcohol: response.data[0].serves_alcohol,
                                        });

                                        this.createDemoMenu(response.data);
                                        this.createDemoAppConfig(response.data[0]);
                                        this.createQrConfig(response.data[0]);
                                        NotificationManager.success(
                                            <IntlMessages id={"header.venue_created"}/>,
                                            <IntlMessages id={"common.save"}/>
                                        );
                                        this.setState({
                                            modal: false,
                                            countVenue: true,
                                        });
                                    })
                                    .catch((error) => {
                                        this.setState({
                                            venue_warning: error.response.data.venueName,
                                            phone_warning: error.response.data.mobile_number,
                                            clickCount: this.state.clickCount - 1,
                                        });
                                    });
                        });
                    }
                }
            }
        }
    };

    createDemoMenu = (datas) => {
        let currentVenue = JSON.parse(localStorage.getItem("currentVenue"));
        const language = currentVenue.defaultLanguage;
        let data = {
            user: this.props.id,
            venue: this.props.selectedVenue.value,
            menu_title: JSON.stringify({
                [language]: intlLoader("header.default_menu", this.props.intl),
            }),
            menu_description: JSON.stringify({
                [language]: intlLoader("header.design_menu", this.props.intl),
            }),
            published: true,
        };
        performRequest("post", "api/menu/", data);
    };

    createDemoAppConfig = (venue) => {
        let data = {
            venue: this.props.selectedVenue.value,
            display_language_button: false,
            display_info_button: false,
            feedback_button: false,
            display_icon_label: false,
            autoplay: true,
            display_logo: false,
            large_font: false,
            text_of_menu_button: intlLoader("header.menu", this.props.intl),
            screen_orientation: "portrait",
            order_enabled: false,
            display_add_button_on_grid_item: false,
            order_submit_enabled: false,
            tips_enabled: false,
            logo: 2,
            return_to_home_after_submission: false,
            table_top_enabled: false,
            kiosk_mode_enabled: false,
            display_prices: false,
            display_currency_sign: false,
            display_currency_fraction: false,
            facebook_sharing: false,
            twitter_sharing: false,
            currency: 1,
            default_language: venue.languages_id,
        };
        performRequest("post", "api/app-config-web/", data);
    };
    createQrConfig = (venue) => {
        let data = {
            accepting_orders_online: null,
            schedule_online_delivery: null,
            allow_cash_on_delivery: null,
            minimum_order_amount: null,
            menu_to_display: null,
            venue: venue.id,
            delivery_circle: null,
            online_special_notes: null,

            allow_delivery: true,
            allow_pickup: false,
            allow_online_payment: false,
            allow_card_on_delivery: false,


            allow_online_payment_pickup: false,
            allow_cash_on_pickup: null,
            allow_card_on_pickup: false,
            label_enabled: false,
            pickup_custom_label: null,
            welcome_message_online: null,
            delivery_charge: null,
            online_delivery_date_enabled: false,
            pickup_delivery_date_enabled: false,
            enable_tips_online: null


        };
        performRequest('post', 'api/qr-menu-config/', data)
            .then(response => {
                let currentVenue = JSON.parse(localStorage.getItem("currentVenue"));
                currentVenue.general_qr = response.data.general_qr;
                localStorage.setItem("currentVenue", JSON.stringify(currentVenue));
                let data = {
                    background_color: '#ffffff',
                    foreground_color: '#000000',
                    // url: 'https://qr-menu.mydigimenu.com/' + response.data.results[0].general_qr.qr_id
                    url: 'https://qr.mydigimenu.com/' + response.data.general_qr.qr_id
                };
                performRequest('post', 'api/qr/generate/', data)
                    .then(res => {
                        this.setState({
                            general_qr: res.data.image,
                            // qrModal: true,
                            // spinner: false
                        });
                    });
            })
    };

    modalToggle = () => {
        if (!this.state.modal)
            this.setState({
                createVenue: true,
            });
            // Swal.fire({
            //     title: 'Warning',
            //     text: "New venue will need a seperate subscription plan. You will be entitled with 14 days trial for the new venue. ",
            //     icon: 'warning',
            //     showCancelButton: true,
            //     confirmButtonColor: '#00bcd4',
            //     cancelButtonColor: '#ff7c76',
            //     confirmButtonText: 'Proceed',
            //     confirmButtonClass: "btn btn-danger",
            //     cancelButtonClass: "btn",
            //     html:"<span>New venue will need a seperate subscription plan. You will be entitled with 14 days trial for the new venue. </span>" +
            //         "<br>" ,
            // }).then((result) => {
            //     if (result.value)
        // });
        else
            this.setState({
                selectedLanguage: null,
                selectedCountry: null,
                venueName: "",
                mobile_number: "",
                country_warning: "",
                language_warning: "",
                venue_warning: "",
                clickCount: 0,
                modal: !this.state.modal,
            });
    };

    getChanges = (e) => {
        if (
            e.target.name === "editVenuePhone" ||
            e.target.name === "mobile_number"
        ) {
            if (e.target.value)
                this.state.reg.test(e.target.value) &&
                this.setState({[e.target.name]: e.target.value});
            else this.setState({[e.target.name]: e.target.value});
        } else if (e.target.name === "password") {
            if (
                e.target.value.length === 0 ||
                (/^\d+$/.test(e.target.value) && e.target.value.length <= 4)
            )
                this.setState({[e.target.name]: e.target.value});
        } else {
            this.setState({[e.target.name]: e.target.value});
        }
        this.setState({
            edit_email_warning: "",
            edit_venue_warning: "",
            edit_website_warning: "",
            edit_phone_warning: "",
            venue_warning: "",
            country_warning: "",
            language_warning: "",
            phone_warning: "",
        });
    };

    pushAction = () => {
        performRequest("get", "api/push/", {
            venue: this.props.selectedVenue.value,
        }).then((response) => {
            // Swal.fire({
            //     position: 'center',
            //     type: "success"
            // })

            Swal.fire({
                position: "center",
                type: "success",
                text: intlLoader("sweetAlerts.success", this.props.intl) + " !",
                // showConfirmButton: false,
                className: "swalText",
            });
        });
    };

    newWindow = (url) => {
        // localStorage.setItem("pageData", "Data Retrieved from axios request")
        // route to new page by changing window.location
        window.open(url, "_blank");
    };
    //-------------------------------
    Apps = () => {
        let subscriptionButtonVisibility1 = "";
        let currentVenue = JSON.parse(localStorage.getItem("currentVenue"));
        let current_package1 = "";
        let found = null;

        if (this.state.venueList.length && currentVenue !== null) {
            found = this.state.venueList.find(
                (element) => element.id === currentVenue.value
            );
        }

        if (found !== null && found !== undefined) {
            current_package1 = found.subscription_plan.name;
            subscriptionButtonVisibility1 = found.subscription_plan.status;
        }
        return (
            <div>
                <Row>
                    <Col xs="4" className={"text-center"}>
                        <Button
                            onClick={this.editModalToggle}
                            className="j"
                            variant="contained"
                        >
                            <i
                                className="zmdi zmdi-settings zmdi-hc-fw"
                                style={{color: "black"}}
                            />
                            &nbsp;
                            {/*<p className='p'>Add Venue</p>*/}
                        </Button>
                    </Col>

                    <Col xs="4" className={"text-center"}>
                        <Button onClick={this.pushAction} className="j" variant="contained">
                            <i
                                className="zmdi zmdi-flash zmdi-hc-fw"
                                style={{color: "black"}}
                            />
                            &nbsp;
                            {/*<span>Localize</span>*/}
                        </Button>
                    </Col>
                    <Col xs="4" className={"text-center"}>
                        <Button
                            onClick={this.changeQrcode}
                            className="j"
                            variant="contained"
                        >
                            <i
                                className="zmdi  zmdi-code-smartphone zmdi-hc-fw"
                                style={{color: "black"}}
                            />{" "}
                            &nbsp;
                        </Button>
                    </Col>
                </Row>

                <Row>
                    {this.props.selectedVenue &&
                    this.props.selectedVenue.role != "3" &&
                    this.props.selectedVenue.role != "2" && (
                        <Col xs="12" className={"text-center notNavTextInMinMobile"}>
                            <button
                                type="button"
                                tabIndex="0"
                                className="dropdown-item"
                                style={{borderRadius: 25}}
                                onClick={() => this.props.history.push("/app/subscription")}
                            >
                  <span>
                    {
                        subscriptionButtonVisibility1 === "ACTIVE" &&
                        current_package1 === "Trial" && (
                            <IntlMessages id={"billing.upgrade"}/>
                        )
                        // <Button
                        //     className="btn btn-secondary  float-right drpBtns customButtonStyle"><IntlMessages
                        //     id={'billing.upgrade'}/></Button>
                    }

                      {subscriptionButtonVisibility1 === "EXPIRED" ? (
                          <IntlMessages id={"billing.renew"}/>
                      ) : (
                          ""
                      )}
                  </span>
                            </button>
                        </Col>
                    )}
                </Row>
                <Row>
                    <Col xs="6" className={"text-center"}>
                        <button
                            type="button"
                            tabIndex="0"
                            className="dropdown-item"
                            style={{borderRadius: 25}}
                            onClick={() => this.newWindow("https://learn.mydigimenu.com")}
                        >
              <span>
                <IntlMessages id={"header.help"}/>
              </span>
                        </button>
                    </Col>
                    <Col xs="6" className={"text-center"}>
                        <button
                            type="button"
                            tabIndex="0"
                            className="dropdown-item"
                            style={{borderRadius: 25, padding: 8}}
                            onClick={() => this.newWindow("https://learn.mydigimenu.com/")}
                        >
                            <IntlMessages id={"header.tutorial"}/>
                        </button>
                    </Col>
                </Row>
            </div>
        );
    };

    dropdownToggle = () => {
        this.setState({
            dropdownToggle: !this.state.dropdownToggle,
        });
    };

    editModalToggle = () => {
        let result = [];
        // get venu_data by venu_id if modal is true-----------------
        performRequest("get", "api/venue/" + this.props.selectedVenue.value + "/")
            .then((response) => {
                let timezone = this.state.timezoneOptions.filter((item) => {
                    return item.value == response.data.timezone ? item : "";
                });
                this.setState({
                    editVenueName: response.data.venueName ? response.data.venueName : "",
                    editVenueId: response.data.id ? response.data.id : "",
                    editVenuePhone: response.data.mobile_number
                        ? response.data.mobile_number
                        : "",
                    editVenueState: response.data.state ? response.data.state : "",
                    editVenueAddress: response.data.address ? response.data.address : "",
                    editVenueCity: response.data.city ? response.data.city : "",
                    editVenueEmail: response.data.email ? response.data.email : "",
                    editVenueCountry: this.state.country.filter(
                        (options) => options.value === response.data.country
                    )[0],
                    editVenueWebsite: response.data.website ? response.data.website : "",
                    // editVenueTimezone: response.data.timezone ? response.data.timezone : '',
                    editVenueTimezone: timezone[0],
                    editVenueUser: response.data.User ? response.data.User : "",
                    editVenueLanguage: response.data.languages
                        ? response.data.languages
                        : "",
                    foursquare: response.data.foursquare ? response.data.foursquare : "",
                    twitter: response.data.twitter ? response.data.twitter : "",
                    instagram: response.data.instagram ? response.data.instagram : "",
                    facebook: response.data.facebook ? response.data.facebook : "",
                    edit_venue_warning: "",
                    edit_email_warning: "",
                    edit_website_warning: "",
                    edit_phone_warning: "",
                    editVenueCompany: response.data.company ? response.data.company : "",
                    editVenueType: this.state.editVenueTypeList.filter(
                        (options) => options.value === response.data.venue_type
                    )[0],
                    editVenueSell: [
                        {value: false, label: "NO"},
                        {
                            value: true,
                            label: "YES",
                        },
                    ].filter(
                        (options) => options.value === response.data.serves_alcohol
                    )[0],
                });
            })
            .catch((error) => {
            });

        this.setState({
            editModal: !this.state.editModal,
        });
    };
    // save Edit Data
    saveEditData = () => {
        let venuelist = this.props.venueList;
        /**
         * check whether the venue name is already presented or not,
         * if not send a put request.
         * */
        let items = [];
        venuelist.map((item) => {
            if (
                item.label !== JSON.parse(localStorage.getItem("currentVenue"))["label"]
            )
                items.push(item.label);
        });

        if (
            items.some(
                (item) => item.toLowerCase() === this.state.editVenueName.toLowerCase()
            )
        ) {
            this.setState({
                edit_venue_warning: intlLoader("header.venue_warning", this.props.intl),
            });
        } else {
            this.setState({edit_venue_warning: ""});
            if (!this.state.editVenueTimezone) {
                this.setState({timezoneEditError: true});
            } else {
                this.setState({timezoneEditError: false});

                let datas = {
                    id: this.state.editVenueId,
                    venueName: this.state.editVenueName,
                    mobile_number: this.state.editVenuePhone,
                    state: this.state.editVenueState,
                    address: this.state.editVenueAddress,
                    city: this.state.editVenueCity,
                    email: this.state.editVenueEmail,
                    country: this.state.editVenueCountry["value"],
                    website: this.state.editVenueWebsite,
                    timezone: this.state.editVenueTimezone.value,
                    // timezone: '',
                    User: this.state.editVenueUser,
                    languages: this.state.editVenueLanguage,
                    foursquare: this.state.foursquare,
                    twitter: this.state.twitter,
                    instagram: this.state.instagram,
                    facebook: this.state.facebook,
                    company: this.state.editVenueCompany,
                    serves_alcohol: this.state.editVenueSell.value,
                    venue_type: this.state.editVenueType.value,
                };

                performRequest(
                    "put",
                    "api/venue/" + this.props.selectedVenue.value + "/",
                    "",
                    datas
                )
                    .then((response) => {

                        this.props.updateSelectVenue(response.data);
                        performRequest("get", "api/venue/").then((resp) => {
                            this.props.addVenue(resp.data.results);
                            NotificationManager.success(
                                response.data.venueName,
                                <IntlMessages id={"sweetAlerts.updated"}/>
                            );
                            this.setState({
                                editModal: !this.state.editModal,
                            });
                        });
                    })
                    .catch((error) => {

                    });
                // .catch(error => {
                //     this.setState({
                //         // edit_venue_warning: error.response.data.venueName,
                //         edit_venue_warning: error.response.data.venueName,
                //         edit_email_warning: error.response.data.email,
                //         edit_website_warning: error.response.data.website,
                //         edit_phone_warning: error.response.data.mobile_number,
                //     });
                // });
            }
        }
    };

    additionalDataToggle() {
        performRequest("get", "api/venue/" + this.props.selectedVenue.value + "/")
            .then((response) => {
                let timezone = this.state.timezoneOptions.filter((item) => {
                    return item.value == response.data.timezone ? item : "";
                });
                this.setState({
                    editVenueName: response.data.venueName ? response.data.venueName : "",
                    editVenueId: response.data.id ? response.data.id : "",
                    editVenuePhone: response.data.mobile_number
                        ? response.data.mobile_number
                        : "",
                    editVenueState: response.data.state ? response.data.state : "",
                    editVenueAddress: response.data.address ? response.data.address : "",
                    editVenueCity: response.data.city ? response.data.city : "",
                    editVenueEmail: response.data.email ? response.data.email : "",
                    editVenueCountry: this.state.country.filter(
                        (options) => options.value === response.data.country
                    )[0],
                    editVenueWebsite: response.data.website ? response.data.website : "",
                    // editVenueTimezone: response.data.timezone ? response.data.timezone : '',
                    editVenueTimezone: timezone[0],
                    editVenueUser: response.data.User ? response.data.User : "",
                    editVenueLanguage: response.data.languages
                        ? response.data.languages
                        : "",
                    foursquare: response.data.foursquare ? response.data.foursquare : "",
                    twitter: response.data.twitter ? response.data.twitter : "",
                    instagram: response.data.instagram ? response.data.instagram : "",
                    facebook: response.data.facebook ? response.data.facebook : "",
                    edit_venue_warning: "",
                    edit_email_warning: "",
                    edit_website_warning: "",
                    edit_phone_warning: "",
                    editVenueCompany: response.data.company ? response.data.company : "",
                    editVenueType: this.state.editVenueTypeList.filter(
                        (options) => options.value === response.data.venue_type
                    )[0],
                    editVenueSell: [
                        {value: false, label: "NO"},
                        {
                            value: true,
                            label: "YES",
                        },
                    ].filter(
                        (options) => options.value === response.data.serves_alcohol
                    )[0],
                });
            })
            .catch((error) => {
            });

        this.setState({
            update: true,
        });
    }

    saveEditAdditionalData = () => {
        if (
            this.state.editVenueCompany &&
            this.state.editVenueSell &&
            this.state.editVenueType
        )
            performRequest("get", "api/venue/" + this.props.selectedVenue.value + "/")
                .then((response) => {
                    let timezone = this.state.timezoneOptions.filter((item) => {
                        return item.value == response.data.timezone ? item : "";
                    });
                    this.setState(
                        {
                            editVenueName: response.data.venueName
                                ? response.data.venueName
                                : "",
                            editVenueId: response.data.id ? response.data.id : "",
                            editVenuePhone: response.data.mobile_number
                                ? response.data.mobile_number
                                : "",
                            editVenueState: response.data.state ? response.data.state : "",
                            editVenueAddress: response.data.address
                                ? response.data.address
                                : "",
                            editVenueCity: response.data.city ? response.data.city : "",
                            editVenueEmail: response.data.email ? response.data.email : "",
                            editVenueCountry: this.state.country.filter(
                                (options) => options.value === response.data.country
                            )[0],
                            editVenueWebsite: response.data.website
                                ? response.data.website
                                : "",
                            // editVenueTimezone: response.data.timezone ? response.data.timezone : '',
                            editVenueTimezone: timezone[0],
                            editVenueUser: response.data.User ? response.data.User : "",
                            editVenueLanguage: response.data.languages
                                ? response.data.languages
                                : "",
                            foursquare: response.data.foursquare
                                ? response.data.foursquare
                                : "",
                            twitter: response.data.twitter ? response.data.twitter : "",
                            instagram: response.data.instagram ? response.data.instagram : "",
                            facebook: response.data.facebook ? response.data.facebook : "",
                            edit_venue_warning: "",
                            edit_email_warning: "",
                            edit_website_warning: "",
                            edit_phone_warning: "",
                            // editVenueCompany: response.data.company ? response.data.company : '',
                            // editVenueType: this.state.editVenueTypeList.filter(options => options.value === response.data.venue_type)[0],
                            // editVenueSell: [{value: false, label: 'NO'}, {
                            //     value: true,
                            //     label: 'YES'
                            // }].filter(options => options.value === response.data.serves_alcohol)[0],
                        },
                        function () {
                            let datas = {
                                id: this.state.editVenueId,
                                venueName: this.state.editVenueName,
                                mobile_number: this.state.editVenuePhone,
                                state: this.state.editVenueState,
                                address: this.state.editVenueAddress,
                                city: this.state.editVenueCity,
                                email: this.state.editVenueEmail,
                                country: this.state.editVenueCountry["value"],
                                website: this.state.editVenueWebsite,
                                timezone: this.state.editVenueTimezone.value,
                                // timezone: '',
                                User: this.state.editVenueUser,
                                languages: this.state.editVenueLanguage,
                                foursquare: this.state.foursquare,
                                twitter: this.state.twitter,
                                instagram: this.state.instagram,
                                facebook: this.state.facebook,
                                company: this.state.editVenueCompany,
                                serves_alcohol: this.state.editVenueSell.value,
                                venue_type: this.state.editVenueType.value,
                            };
                            performRequest(
                                "put",
                                "api/venue/" + this.props.selectedVenue.value + "/",
                                "",
                                datas
                            ).then((response) => {

                                this.props.updateSelectVenue(response.data);
                                performRequest("get", "api/venue/").then((resp) => {
                                    this.props.addVenue(resp.data.results);
                                    NotificationManager.success(
                                        response.data.venueName,
                                        <IntlMessages id={"sweetAlerts.updated"}/>
                                    );
                                    this.setState({
                                        updatePopup: false,
                                        editVenueCompany: "",
                                        editVenueType: "",
                                        editVenueSell: "",
                                    });
                                });
                            });
                        }
                    );
                })
                .catch((error) => {
                });
    };

    changeQrcode = () => {

        if (this.props.selectedVenue.general_qr) {
            this.generateQrImage(this.props.selectedVenue.general_qr.qr_id);
        }
        this.setState({
            password: "",
            qr_image: null,
            venue: "",
        });
        this.setState(
            {
                password: this.props.selectedVenue.venueQrPin,
                qr_image: this.props.selectedVenue.venueQrImage,
                venue: this.props.selectedVenue.venueQrId,
                venueNames: this.props.selectedVenue.label,
            },
            () => {
                this.setState({
                    qrModal: !this.state.qrModal,
                });
            }
        );
    };
    qrModalToggle = () => {
        this.setState({
            qrModal: !this.state.qrModal,
            pincodeWarning: false,
        });
    };

    saveQrData = () => {
        let currentVenue = JSON.parse(localStorage.getItem("currentVenue"));
        if (this.state.password.length >= 4)
            performRequest("patch", "api/venue/" + currentVenue.value + "/", "", {
                pin_code: this.state.password,
            }).then((res) => {
                const qr_image =
                    res.data.qr_image.substring(4, 0) === "http"
                        ? res.data.qr_image
                        : "https://mymenu-backend-staging.s3.amazonaws.com/media/" +
                        res.data.qr_image;
                this.props.editOneVenue({
                    value: res.data.id,
                    label: res.data.venueName,
                    venueQrPin: this.state.password,
                    venueQrId: this.state.venue,
                    venueQrImage: qr_image,
                    defaultLanguage: res.data.languages,
                });

                this.props.selectVenue({
                    value: res.data.id,
                    label: res.data.venueName,
                    venueQrPin: this.state.password,
                    venueQrImage:
                        res.data.qr_image.substring(4, 0) === "http"
                            ? res.data.qr_image
                            : "https://mymenu-backend.s3.amazonaws.com/media/" +
                            res.data.qr_image,
                    venueQrId: this.state.venue,
                    defaultLanguage: res.data.languages,
                });
                this.setState({
                    qrModal: false,
                    pincodeWarning: false,
                });
                NotificationManager.success(
                    <IntlMessages id={"sweetAlerts.updated"}/>
                );
            });
        else this.setState({pincodeWarning: true});
    };

    setVenue = (item) => {
        let getUrl = this.props.location.pathname;
        let splitUrl = getUrl.split('/');
        let urlEndPoint = splitUrl[splitUrl.length - 1];
        this.props.toggleBanner(true);
        this.props.selectVenue(item);
        if (!item.company || !item.venue_type || item.serves_alcohol == null) {
            this.setState({
                updatePopup: true,
                editVenueCompany: "",
                editVenueType: "",
                editVenueSell: "",
            }, function () {
                if (!['user-list', 'subscription'].includes(urlEndPoint)) {
                    window.location.replace("#/app/dashboard/default");
                    window.location.reload(true);
                }

                // window.open("#/app/dashboard/default", "_self")
            });
        } else {
            if (!['user-list', 'subscription'].includes(urlEndPoint)) {
                window.location.replace("#/app/dashboard/default");
                window.location.reload(true);
            }
            // window.open("#/app/dashboard/default", "_self")


        }
    };

    keydown = (e) => {
        let code = e.keyCode || e.which;
        if (code == 27) {
            // $(".modal ").toggle();
        }
    };

    goToOrderPanel = () => {
        this.setState({new_order: false, playSound: false}, () => {
            this.props.history.push("/app/orders");
        });
    };
    cancelOrderWarning = () => {
        this.setState({new_order: false, playSound: false});
    };
    a11yProps = (index) => {
        return {
            id: `full-width-tab-${index}`,
            "aria-controls": `full-width-tabpanel-${index}`,
        };
    };
    handleTabChange = (event, newValue) => {
        this.setState({selectedTab: newValue});
    };

    render() {
        let currentVenue = JSON.parse(localStorage.getItem("currentVenue"));
        let subscriptionButtonVisibility = "";
        let isTodayAfterBillingDate = true;
        let current_package = "";
        let no_of_days = 0;
        let baseurl = window.location.host.startsWith("testing")
            ? "https://qr-menu.mydigimenu.com/"
            : "https://qr.mydigimenu.com/";
        let qr_full_url = currentVenue ? currentVenue.general_qr ? baseurl + currentVenue.general_qr.qr_id : '' : '';
        // let qr_full_url = currentVenue ? currentVenue.general_qr ? baseurl + currentVenue.general_qr.qr_id : '' : '';
        // let qr_full_url = "";
        if (currentVenue) {
            if (this.props.plans.length) {
                const found = this.state.venueList.find(
                    (element) => element.id === currentVenue.value
                );
                if (found !== undefined) {
                    current_package = found.subscription_plan.name;
                    subscriptionButtonVisibility = found.subscription_plan.status;
                    let date1 = moment(found.subscription_plan.expiry_date);
                    let date2 = moment();
                    isTodayAfterBillingDate = date2.isSameOrAfter(found.subscription_plan.next_billing_date);
                    no_of_days = date1.diff(date2, "days");
                    if (current_package !== "Trial")
                        if (this.props.banner) this.props.toggleBanner(false);
                }
                // if (this.state.venueList.length && currentVenue !== null) {
                //     found = this.state.venueList.find(
                //         (element) => element.id === currentVenue.value
                //     );
                // }
                //
                // if (found !== null && found !== undefined) {
                //     current_package1 = found.subscription_plan.name;
                //     subscriptionButtonVisibility1 = found.subscription_plan.status;
                // }

                // const found = this.props.plans.find(
                //     (element) => element.venue.id === currentVenue.value
                // );
                // if (found !== undefined) {
                //     current_package = found.current_package;
                //     subscriptionButtonVisibility = found.status;
                //     let date1 = moment(found.expiry_date);
                //     let date2 = moment();
                //     no_of_days = date1.diff(date2, "days");
                //     if (current_package !== "Trial")
                //         if (this.props.banner) this.props.toggleBanner(false);
                // }
            }
        }

        const {selectedLanguage} = this.state;
        const {selectedCountry} = this.state;
        const {drawerType, locale, navigationStyle, horizontalNavPosition, intl} =
            this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER)
            ? "d-block d-xl-none"
            : drawerType.includes(COLLAPSED_DRAWER)
                ? "d-block"
                : "d-none";
        const qr_image = this.state.qr_image + "?t=" + new Date().getTime();

        return (
            <AppBar
                className={`app-main-header ${
                    navigationStyle === HORIZONTAL_NAVIGATION &&
                    horizontalNavPosition === BELOW_THE_HEADER
                        ? "app-main-header-top"
                        : ""
                } customGradient`}
            >
                {subscriptionButtonVisibility === "ACTIVE" &&
                current_package === "Trial" &&
                this.props.banner ? (
                    <Row
                        className="p-1"
                        style={{
                            backgroundColor: "#252525",
                        }}
                    >
                        <Col xs={10} className={"m-auto "}>
                            <div className={"pl-3 buttonCustom"}>
                                {no_of_days > 0 ? (
                                    <span
                                        style={{
                                            float: "left",
                                            marginTop: "10px",
                                            overflow: "hidden",
                                        }}
                                        className={"marginTopCustom"}
                                    >
                    {currentVenue.label}'s trial is ending in {no_of_days}{" "}
                                        day(s).
                  </span>
                                ) : (
                                    <span
                                        style={{
                                            float: "left",
                                            marginTop: "10px",
                                            overflow: "hidden",
                                        }}
                                        className={"marginTopCustom"}
                                    >
                    {currentVenue.label} expired.
                  </span>
                                )}
                                <Button
                                    onClick={() => this.props.history.push("/app/subscription")}
                                    className="btn btn-secondary   drpBtns customButtonStyle mb-0"
                                >
                                    <IntlMessages id={"billing.upgrade"}/>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2} className={"m-auto"}>
                            {/*<i onClick={() => this.props.toggleBanner(false)} className="zmdi zmdi-close float-right mr-2"/>*/}
                            <span
                                onClick={() => this.props.toggleBanner(false)}
                                style={{cursor: "pointer"}}
                                className={
                                    "btn btn-secondary mb-0 float-right mr-2 closeButtonCustom"
                                }
                            >
                close
              </span>
                        </Col>
                    </Row>
                ) : subscriptionButtonVisibility === "EXPIRED" && this.props.banner ? (
                    <Row
                        className="p-1"
                        style={{
                            backgroundColor: "#252525",
                        }}
                    >
                        <Col xs={10} className={"m-auto "}>
                            <div className={"pl-3 buttonCustom"}>
                                {no_of_days > 0 ? (
                                    <span
                                        style={{
                                            float: "left",
                                            marginTop: "10px",
                                            overflow: "hidden",
                                        }}
                                        className={"marginTopCustom"}
                                    >
                    {currentVenue.label}'s trial is ending in {no_of_days}{" "}
                                        day(s).
                  </span>
                                ) : (
                                    <span
                                        style={{
                                            float: "left",
                                            marginTop: "10px",
                                            overflow: "hidden",
                                        }}
                                        className={"marginTopCustom"}
                                    >
                    {currentVenue.label} expired.
                  </span>
                                )}
                                <Button
                                    onClick={() => this.props.history.push("/app/subscription")}
                                    className="btn btn-secondary   drpBtns customButtonStyle mb-0"
                                >
                                    <IntlMessages id={"billing.renew"}/>
                                </Button>
                            </div>
                        </Col>
                        <Col xs={2} className={"m-auto"}>
                            {/*<i onClick={() => this.props.toggleBanner(false)} className="zmdi zmdi-close float-right mr-2"/>*/}
                            <span
                                onClick={() => this.props.toggleBanner(false)}
                                style={{borderRadius: "25px !important"}}
                                className={"btn btn-secondary mb-0 float-right mr-2 "}
                            >
                close
              </span>
                        </Col>
                    </Row>
                ) : (
                    ""
                )}

                <Toolbar className="app-toolbar" disableGutters={false}>
                    {/*<div className="d-block d-md-none pointer mr-3"*/}
                    {/*     onClick={this.onToggleCollapsedNav}>*/}
                    {/*        <span className="jr-menu-icon">*/}
                    {/*          <span className="menu-icon"/>*/}
                    {/*        </span>*/}
                    {/*</div>*/}

                    <ul className="header-notifications list-inline">
                        <li
                            className="list-inline-item  d-lg-none app-logo "
                            style={{backgroundColor: "#c6ac4e"}}
                        >
                            {/*<div className="d-block d-md-none pointer mr-3">*/}
                            <div className="d-block d-md-none pointer ">
                                <Link to="/app/dashboard/default">
                                    <img
                                        src={require("./../../../src/assets/images/logo_white.png")}
                                        alt="mydine"
                                        title="mydine"
                                    />
                                </Link>
                            </div>
                        </li>
                    </ul>

                    <Link
                        className="app-logo  d-lg-block navInMin"
                        to="/app/dashboard/default"
                    >
                        <img
                            src={require("./../../../src/assets/images/logo.png")}
                            alt="mydine"
                            title="mymenu"
                        />
                    </Link>

                    <Dropdown
                        style={{height: "40px", paddingLeft: 20}}
                        // style={{height: '40px'}}
                        isOpen={this.state.dropdownToggle}
                        toggle={this.dropdownToggle}
                    >
                        <DropdownToggle className="drpBtns customButtonStyle">
              <span className="dropSelect venueNameChangeOne">
                {this.props.selectedVenue
                    ? this.props.selectedVenue.label.length > 25
                        ? this.props.selectedVenue.label.substring(0, 25) + "..."
                        : this.props.selectedVenue.label
                    : null}
              </span>
                            <span className="dropSelect venueNameChangeTwo">
                {this.props.selectedVenue
                    ? this.props.selectedVenue.label.substring(0, 7) + "..."
                    : null}
              </span>
                            {/*className='dropSelect'>{this.props.selectedVenue ? (((this.props.selectedVenue.label).substring(0, 25)) + '...') : null}</span>*/}
                            <span className="caret" style={{paddingLeft: 10}}>
                <i className="zmdi zmdi-chevron-down"/>
              </span>
                        </DropdownToggle>
                        <DropdownMenu>
                            <Scrollbars
                                autoHide
                                autoHeight
                                autoHeightMax={230}
                                style={{width: "190px"}}
                            >
                                {this.props.venueList.map((item, index) => {
                                    return (
                                        <DropdownItem onClick={() => this.setVenue(item)}>
                      <span>
                        {item.label.length > 25
                            ? item.label.substring(0, 25) + "..."
                            : item.label}
                      </span>
                                            <span style={{float: "right"}}>
                        {localStorage.getItem("currentVenue") ? (
                            item.label ===
                            JSON.parse(localStorage.getItem("currentVenue"))
                                .label ? (
                                <i className="fa fa-star-o" aria-hidden="true"/>
                            ) : (
                                ""
                            )
                        ) : (
                            ""
                        )}
                      </span>
                                        </DropdownItem>
                                    );
                                })}
                            </Scrollbars>

                            <DropdownItem onClick={this.modalToggle}>
                <span className="nav-header">
                  {" "}
                    <IntlMessages id={"header.add_new_venue"}/>
                  <i
                      className="fa fa-plus "
                      style={{width: 25, textAlign: "right", color: "#ced5dc"}}
                  />
                </span>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    {navigationStyle === HORIZONTAL_NAVIGATION &&
                    horizontalNavPosition === INSIDE_THE_HEADER && <Menu/>}
                    <NavIcons
                        preview={this.toggleDrawer("right", true)}
                        addVenue={this.editModalToggle}
                        changeQrcode={this.changeQrcode}
                        pushAction={this.pushAction}
                        selectedVenue={this.props.selectedVenue}
                    />

                    <Link
                        // className="app-logo  d-lg-block navTextInMin ml-4"
                        className="app-logo  d-lg-block navTextInMinMobile ml-4"
                        to="/app/subscription"
                    >
                        <div style={{width: "100%", paddingTop: 5}}>
                            {subscriptionButtonVisibility === "ACTIVE" &&
                            current_package === "Trial" && (
                                <Button className="btn btn-secondary  float-right drpBtns customButtonStyle">
                                    <IntlMessages id={"billing.upgrade"}/>
                                </Button>
                            )}

                            {subscriptionButtonVisibility === "EXPIRED" && isTodayAfterBillingDate ? (
                                <Button className="btn btn-secondary  float-right drpBtns customButtonStyle">
                                    <IntlMessages id={"billing.renew"}/>
                                </Button>
                            ) : (
                                ""
                            )}
                        </div>
                    </Link>

                    <li
                        className="list-inline-item  mt-2 list-inline-item-new"
                        // style={{marginLeft: 40, marginRight: 40}}>
                    >
                        <Dropdown
                            className="quick-menu"
                            isOpen={this.state.langSwitcher}
                            toggle={this.onLangSwitcherSelect.bind(this)}
                        >
                            <DropdownToggle
                                className="d-inline-block"
                                tag="span"
                                data-toggle="dropdown"
                            >
                                <div className="d-flex align-items-center p-0 m-0">
                                    <i className={`flag flag-24 flag-${locale.icon}`}/>
                                </div>
                            </DropdownToggle>

                            <DropdownMenu right className="w-50">
                                <LanguageSwitcher
                                    switchLanguage={this.props.switchLanguage}
                                    handleRequestClose={this.handleRequestClose}
                                />
                            </DropdownMenu>
                        </Dropdown>
                    </li>
                    <ul className="header-notifications list-inline ml-auto">
                        {/* new ccompppp */}

                        {/* end new ccompppp */}
                        <li className="d-inline-block d-lg-none list-inline-item">
                            <Dropdown
                                className="quick-menu nav-searchbox"
                                isOpen={this.state.selectBox}
                                toggle={this.onOptionBoxSelect.bind(this)}
                            >
                                <DropdownMenu right className="p-0">
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleChange}
                                        value={this.props.selectedVenue}
                                        options={this.props.venueList}
                                    />
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        {/*Apps Icons*/}
                        <li className="list-inline-item  d-lg-none">
                            <Dropdown
                                className="quick-menu app-notification"
                                isOpen={this.state.apps}
                                toggle={this.onAppsSelect.bind(this)}
                            >
                                <DropdownToggle
                                    className="d-inline-block hov"
                                    tag="span"
                                    data-toggle="dropdown"
                                >
                  <span className="app-notification-menu">
                    <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>
                    <span className="navTextInMinMobile">
                      <IntlMessages id={"header.apps"}/>
                    </span>
                  </span>
                                </DropdownToggle>

                                <DropdownMenu style={{width: 250}}>
                                    <div className="container-fluid">{this.Apps()}</div>
                                </DropdownMenu>
                            </Dropdown>
                        </li>

                        {/*<li className="list-inline-item  mr-2  d-lg-block navInMin" style={{marginLeft: '30px'}}>*/}
                        <li
                            className="list-inline-item  mr-2  d-lg-block "
                            // style={{marginLeft: '30px'}}
                            style={{marginLeft: "5px"}}
                        >
                            <UserInfo
                                {...this.props}
                                handleProfile={this.props.handleProfile}
                                className="customHover "
                            />
                        </li>
                        {/*<li className="list-inline-item  d-lg-none app-logo "*/}
                        {/*    style={{marginLeft: '25px'}}*/}
                        {/*>*/}
                        {/*    /!*<div className="d-block d-md-none pointer mr-3">*!/*/}
                        {/*    <div className="d-block d-md-none pointer "*/}
                        {/*    >*/}
                        {/*        <Link to="/app/dashboard/default">*/}
                        {/*            <img src={require('./../../../src/assets/images/logo_white.png')} alt="mydine"*/}
                        {/*                 title="mydine"/>*/}
                        {/*        </Link>*/}
                        {/*    </div>*/}
                        {/*</li>*/}
                    </ul>
                </Toolbar>

                <NotificationContainer/>

                {this.state.playSound && (
                    <Sound
                        url={require("../../assets/sounds/alarm-frenzy.mp3")}
                        playStatus={Sound.status.PLAYING}
                        volume={100}
                        loop={true}
                        // onFinishedPlaying={()=>{this.setState({playSound: false})}}
                        ignoreMobileRestrictions={true}
                    />
                )}

                <Drawer
                    anchor="right"
                    open={this.state.right}
                    onClose={this.toggleDrawer("right", false)}
                >
                    <div
                        style={{
                            padding: 50,
                        }}
                        tabIndex={0}
                        role="button"
                    >
                        <div
                            className="tablet"
                            style={{
                                padding: 0,
                                width: "500px",
                                height: "750px",
                            }}
                        >
                            <Iframe
                                url="https://app.mydigimenu.com/#/preview/login"
                                frameBorder="0"
                                width={"100%"}
                                height={"100%"}
                                styles={padding}
                            />
                        </div>
                    </div>
                </Drawer>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.state.countVenue && this.modalToggle}
                >
                    <ModalHeader toggle={this.state.countVenue && this.modalToggle}>
                        <IntlMessages id="header.create_venue"/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            {/*<p>{this.state.warnings}</p>*/}
                            <label>
                                <IntlMessages id="header.create_venue"/>
                            </label>
                            <Input
                                type={"text"}
                                value={this.state.venueName}
                                name={"venueName"}
                                onChange={(e) => this.getChanges(e)}
                                placeholder={intlLoader("header.create_venue", intl)}
                            />
                            {this.state.venue_warning && (
                                <span style={{color: "red"}}>{this.state.venue_warning}</span>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="common.country"/>
                            </label>
                            <Select
                                styleName="search-dropdown"
                                onChange={this.handleChangeCountry}
                                value={selectedCountry}
                                options={this.state.country.sort()}
                                placeholder={intlLoader("header.select_country", intl)}
                            />
                            {this.state.country_warning && (
                                <p style={{color: "red"}}>{this.state.country_warning}</p>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="header.language"/>
                            </label>
                            <Select
                                styleName="search-dropdown"
                                onChange={this.handleChangeLanguage}
                                value={selectedLanguage}
                                options={this.state.langages}
                                placeholder={intlLoader("header.select_language", intl)}
                            />
                            {this.state.language_warning && (
                                <p style={{color: "red"}}>{this.state.language_warning}</p>
                            )}

                            <br/>
                            <label>
                                <IntlMessages id="common.phone"/>
                            </label>
                            <Input
                                placeholder={intlLoader("common.mobile_number", intl)}
                                value={this.state.mobile_number}
                                name={"mobile_number"}
                                onChange={(e) => this.getChanges(e)}
                            />
                            {this.state.phone_warning && (
                                <p style={{color: "red"}}>{this.state.phone_warning}</p>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="header.timezone"/>
                            </label>

                            <Select
                                styleName="search-dropdown"
                                onChange={this.handleCreateChangeTimezone}
                                value={this.state.createVenueTimezone}
                                options={this.state.timezoneOptions}
                                placeholder={intlLoader("header.select_timezone", intl)}
                            />
                            {this.state.timezoneCreateError && (
                                <p style={{color: "red"}}>
                                    <IntlMessages id="header.timezone_req"/>.
                                </p>
                            )}
                            {/*---------------*/}
                            <br/>
                            <label>
                                <IntlMessages id="header.company"/>
                            </label>
                            <Input
                                placeholder={intlLoader("header.company", intl)}
                                value={this.state.company_new}
                                name={"company_new"}
                                onChange={(e) => this.getChanges(e)}
                            />
                            {this.state.company_newError && (
                                <p style={{color: "red"}}>Field is Required</p>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="header.Venue_sells_alcohol"/>
                            </label>
                            <Select
                                styleName="search-dropdown"
                                onChange={this.handleCreateSellAlcahol}
                                value={this.state.createSellAlcahol}
                                name="createSellAlcahol"
                                options={[
                                    {value: false, label: "NO"},
                                    {value: true, label: "YES"},
                                ]}
                                placeholder={intlLoader("header.Venue_sells_alcohol", intl)}
                            />
                            {this.state.createSellAlcaholError && (
                                <p style={{color: "red"}}>Field is Required</p>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="header.venue_type"/>
                            </label>

                            <Select
                                styleName="search-dropdown"
                                onChange={this.handleCreateVenueType}
                                value={this.state.createVenueType}
                                name="createVenueType"
                                options={this.state.editVenueTypeList}
                                placeholder={intlLoader("header.venue_type", intl)}
                            />
                            {this.state.createVenueTypeError && (
                                <p style={{color: "red"}}>Field is Required</p>
                            )}
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveData}>
                            <IntlMessages id="common.save"/>
                        </Button>{" "}
                        <Button
                            color="secondary"
                            onClick={this.state.countVenue && this.modalToggle}
                        >
                            <IntlMessages id="common.cancel"/>
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.editModal}>
                    <ModalHeader>
                        <IntlMessages id="header.general_info"/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            {/*<p>{this.state.warnings}</p>*/}
                            <label>
                                <IntlMessages id="common.venue_name"/>
                            </label>
                            <Input
                                type={"text"}
                                value={this.state.editVenueName}
                                name={"editVenueName"}
                                onChange={(e) => this.getChanges(e)}
                                placeholder={intlLoader("common.venue_name", intl)}
                                // disabled
                            />
                            {this.state.edit_venue_warning && (
                                <p style={{color: "red"}}>{this.state.edit_venue_warning}</p>
                            )}
                            <br/>
                            <label>
                                <IntlMessages id="common.address"/>
                            </label>
                            <Input
                                type={"text"}
                                value={this.state.editVenueAddress}
                                name={"editVenueAddress"}
                                onChange={(e) => this.getChanges(e)}
                                placeholder={intlLoader("common.address", intl)}
                            />
                            <br/>
                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.city"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        name={"editVenueCity"}
                                        value={this.state.editVenueCity}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("header.city", intl)}
                                    />
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.state"/>
                                    </label>

                                    <Input
                                        type={"text"}
                                        name={"editVenueState"}
                                        value={this.state.editVenueState}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("header.state", intl)}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="common.country"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditChangeCountry}
                                        placeholder={intlLoader("header.select_country", intl)}
                                        options={this.state.country}
                                        value={this.state.editVenueCountry}
                                    />
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="common.phone"/>
                                    </label>
                                    <Input
                                        value={this.state.editVenuePhone}
                                        name={"editVenuePhone"}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("common.phone", intl)}
                                    />
                                    {this.state.edit_phone_warning && (
                                        <p style={{color: "red"}}>
                                            {this.state.edit_phone_warning}
                                        </p>
                                    )}
                                </Col>
                            </Row>

                            <br/>
                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="common.email"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        name={"editVenueEmail"}
                                        value={this.state.editVenueEmail}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("common.email", intl)}
                                    />
                                    {this.state.edit_email_warning && (
                                        <p style={{color: "red"}}>
                                            {this.state.edit_email_warning}
                                        </p>
                                    )}
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.website"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={this.state.editVenueWebsite}
                                        name={"editVenueWebsite"}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("header.website", intl)}
                                    />
                                    {this.state.edit_website_warning && (
                                        <p style={{color: "red"}}>
                                            {this.state.edit_website_warning}
                                        </p>
                                    )}
                                </Col>
                            </Row>
                            <br/>

                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.timezone"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditChangeTimezone}
                                        options={this.state.timezoneOptions}
                                        value={this.state.editVenueTimezone}
                                    />
                                    {this.state.timezoneEditError && (
                                        <p style={{color: "red"}}>
                                            <IntlMessages id="header.timezone_req"/>
                                        </p>
                                    )}
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.company"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={this.state.editVenueCompany}
                                        name={"editVenueCompany"}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("header.company", intl)}
                                    />
                                </Col>
                            </Row>
                            <br/>

                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.Venue_sells_alcohol"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditSell}
                                        options={[
                                            {value: false, label: "NO"},
                                            {value: true, label: "YES"},
                                        ]}
                                        name="editVenueSell"
                                        value={this.state.editVenueSell}
                                    />
                                    {this.state.timezoneEditError && (
                                        <p style={{color: "red"}}>
                                            <IntlMessages id="header.timezone_req"/>
                                        </p>
                                    )}
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.venue_type"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditVenueTypeList}
                                        name={"editVenueType"}
                                        options={this.state.editVenueTypeList}
                                        value={this.state.editVenueType}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveEditData}>
                            <IntlMessages id="common.save"/>
                        </Button>{" "}
                        <Button color="secondary" onClick={this.editModalToggle}>
                            <IntlMessages id="common.cancel"/>
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal isOpen={this.state.updatePopup}>
                    <ModalHeader>
                        <IntlMessages id="common.additional_info"/>
                    </ModalHeader>
                    <ModalBody>
                        <div className="container">
                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.company"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        value={this.state.editVenueCompany}
                                        name={"editVenueCompany"}
                                        onChange={(e) => this.getChanges(e)}
                                        placeholder={intlLoader("header.company", intl)}
                                    />
                                </Col>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.Venue_sells_alcohol"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditSell}
                                        options={[
                                            {value: false, label: "NO"},
                                            {value: true, label: "YES"},
                                        ]}
                                        name="editVenueSell"
                                        value={this.state.editVenueSell}
                                    />
                                </Col>
                            </Row>
                            <br/>
                            <Row>
                                <Col xs="6">
                                    <label>
                                        <IntlMessages id="header.venue_type"/>
                                    </label>
                                    <Select
                                        styleName="search-dropdown"
                                        onChange={this.handleEditVenueTypeList}
                                        name={"editVenueType"}
                                        options={this.state.editVenueTypeList}
                                        value={this.state.editVenueType}
                                    />
                                </Col>
                            </Row>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color="primary" onClick={this.saveEditAdditionalData}>
                            <IntlMessages id="common.save"/>
                        </Button>{" "}
                        <Button
                            color="secondary"
                            onClick={() => this.setState({updatePopup: false})}
                        >
                            <IntlMessages id="common.skip"/>
                        </Button>
                    </ModalFooter>
                </Modal>
                <Modal
                    isOpen={this.state.qrModal}
                    id="modal-sm"
                    style={{maxWidth: "500px"}}
                    keyboard
                    onEntered={this.keydown}
                >
                    <ModalHeader>
                        <IntlMessages id="header.connect_device"/>
                    </ModalHeader>
                    <Tabs
                        style={{indicator: {top: "0px"}}}
                        value={this.state.selectedTab}
                        onChange={this.handleTabChange}
                        indicatorColor="primary"
                        textColor="primary"
                    >
                        <Tab
                            style={{
                                color: this.state.selectedTab == 0 ? "#061d2e" : "grey",
                                fontWeight: this.state.selectedTab == 0 && "bold",
                                textTransform: "none",
                            }}
                            label={" Tablet"}
                            {...this.a11yProps(0)}
                        />
                        <Tab
                            style={{
                                color: this.state.selectedTab == 1 ? "#061d2e" : "grey",
                                fontWeight: this.state.selectedTab == 1 && "bold",
                                textTransform: "none",
                            }}
                            label={" QR Menu"}
                            {...this.a11yProps(1)}
                        />
                    </Tabs>
                    {this.state.selectedTab == 0 ? (
                        <ModalBody>
                            <div className="d-flex justify-content-center">
                                {/*<img className="card-img-top" src={this.state.qr_image} alt="Card image cap"*/}
                                <img
                                    src={qr_image}
                                    alt="Card image cap"
                                    style={{
                                        height: "200px",
                                        weight: "200px",
                                        marginRight: "40px",
                                    }}
                                />
                            </div>
                            <br/>
                            <Row>
                                <Col xl="1"/>
                                <Col xl="10">
                                    <label>
                                        <IntlMessages id="common.venue_name"/>
                                    </label>
                                    <Input type={"text"} disabled value={this.state.venueNames}/>
                                </Col>
                                <Col xl="1"/>
                            </Row>
                            <br/>
                            <Row>
                                <Col xl="1"/>
                                <Col xl="10">
                                    <label>
                                        <IntlMessages id="header.venue_id"/>
                                    </label>
                                    <Input
                                        type={"text"}
                                        className={""}
                                        disabled
                                        name={"venue"}
                                        value={this.state.venue}
                                        onChange={(e) => this.getChanges(e)}
                                    />
                                </Col>
                                <Col xl="1"/>
                            </Row>
                            <br/>
                            <Row>
                                <Col xl="1"/>
                                <Col xl="10">
                                    <label>
                                        <IntlMessages id="header.pincode"/>
                                    </label>
                                    <Input
                                        className={""}
                                        type={"text"}
                                        name={"password"}
                                        value={this.state.password}
                                        onChange={(e) => this.getChanges(e)}
                                    />
                                </Col>
                                <Col xl="1"/>
                            </Row>
                            {this.state.pincodeWarning && (
                                <Row>
                                    <Col xl="1"/>
                                    <Col xl="11">
                                        <p style={{color: "red"}}>
                                            <IntlMessages id="header.pincode_warn"/>
                                        </p>
                                    </Col>
                                </Row>
                            )}
                        </ModalBody>
                    ) : (
                        <ModalBody className={"text-center"}>
                            <div className="d-flex justify-content-center pl-3">
                                {/*<img className="card-img-top" src={this.state.qr_image} alt="Card image cap"*/}
                                <img
                                    src={"data:image/png;base64, " + this.state.general_qr}
                                    alt="Card image cap"
                                    style={{
                                        height: "200px",
                                        weight: "200px",
                                        marginRight: "35px",
                                    }}
                                />
                            </div>
                            <br/>
                            <p className="mb-2">
                                <a
                                    target="_blank"
                                    // href={baseurl + this.props.selectedVenue.general_qr.qr_id}>
                                    //  {baseurl + this.props.selectedVenue.general_qr.qr_id}
                                    href={qr_full_url}
                                >
                                    {qr_full_url}
                                </a>
                            </p>
                        </ModalBody>
                    )}

                    <ModalFooter>
                        {this.state.selectedTab == 0 && (
                            <>
                                <Button
                                    color="warning"
                                    onClick={this.saveQrData}
                                    style={{color: "#fff"}}
                                >
                                    <IntlMessages id="common.save"/>
                                </Button>
                                <Button color="secondary" onClick={this.print}>
                                    <IntlMessages id="header.print"/>
                                </Button>{" "}
                                <iframe
                                    id="ifmcontentstoprint"
                                    style={{
                                        height: "0px",
                                        width: "0px",
                                        display: "none",
                                        position: "absolute",
                                    }}
                                ></iframe>
                                <div
                                    id="printarea"
                                    style={{display: "none", justifyContent: "center"}}
                                >
                                    <div style={{justifyContent: "center", display: "flex"}}>
                                        <img
                                            src="https://mymenu-backend-staging.s3.amazonaws.com/media/logos/logo_dark_bak.png"
                                            alt="mymenu"
                                            title="MyMenu"
                                            style={{height: "100px", weight: "150px"}}
                                        />
                                    </div>
                                    <div style={{justifyContent: "center", display: "flex"}}>
                                        <img
                                            src={this.state.qr_image}
                                            alt="Card image cap"
                                            style={{height: "300px", weight: "300px"}}
                                        />
                                    </div>
                                    <div style={{textAlign: "center"}}>
                                        <h3>
                                            <IntlMessages id="common.venue_name"/>:{" "}
                                            {this.state.venueNames}
                                        </h3>
                                        <h3>
                                            <IntlMessages id="header.venue_id"/>: {this.state.venue}
                                        </h3>
                                        <h3>
                                            <IntlMessages id="header.pincode"/>:{" "}
                                            {this.state.password}
                                        </h3>
                                    </div>
                                </div>
                            </>
                        )}

                        <Button color="primary" onClick={this.qrModalToggle}>
                            <IntlMessages id="dashboard.close"/>
                        </Button>
                    </ModalFooter>
                </Modal>
                <SweetAlert
                    show={this.state.new_order}
                    warning
                    showCancel
                    cancelBtnText={<IntlMessages id="common.cancel"/>}
                    confirmBtnText={<IntlMessages id="header.view_orders"/>}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="default"
                    title={intlLoader("sweetAlerts.areYouSure", intl)}
                    onConfirm={this.goToOrderPanel}
                    onCancel={this.cancelOrderWarning}
                >
                    <h3>{this.state.notification_message}</h3>
                    <p>{this.state.notification_body}</p>
                    {/* <IntlMessages id="header.order_received"/> */}
                </SweetAlert>
                <SweetAlert
                    show={false}
                    warning
                    showCancel
                    cancelBtnText={<IntlMessages id="common.cancel"/>}
                    confirmBtnText={<IntlMessages id="header.view_orders"/>}
                    confirmBtnBsStyle="primary"
                    cancelBtnBsStyle="default"
                    title={intlLoader("sweetAlerts.areYouSure", intl)}
                    onConfirm={this.goToOrderPanel}
                    onCancel={this.cancelOrderWarning}
                >
                    <h3>{this.state.notification_message}</h3>
                    <p>{this.state.notification_body}</p>
                    {/* <IntlMessages id="header.order_received"/> */}
                </SweetAlert>

                <SweetAlert
                    show={this.state.createVenue}
                    custom
                    showCancel
                    showCloseButton
                    confirmBtnText={<IntlMessages id="header.proceed"/>}
                    cancelBtnText={<IntlMessages id="common.cancel"/>}
                    confirmBtnBsStyle="secondary"
                    cancelBtnBsStyle="light"
                    onConfirm={() =>
                        this.setState({
                            selectedLanguage: null,
                            selectedCountry: null,
                            venueName: "",
                            mobile_number: "",
                            country_warning: "",
                            language_warning: "",
                            venue_warning: "",
                            clickCount: 0,
                            modal: !this.state.modal,
                            createVenue: false,
                        })
                    }
                    onCancel={() => this.setState({createVenue: false})}
                >
                    <h1>
                        <IntlMessages id="sweetAlerts.warning"/>
                    </h1>
                    <span>
            <IntlMessages id="sweetAlerts.warning_description"/>
          </span>
                </SweetAlert>
            </AppBar>
        );
    }
}

const padding = {
    padding: 0,
};

const mapStateToProps = ({settings, venue, auth, design, Plans}) => {
    const {drawerType, locale, navigationStyle, horizontalNavPosition} =
        settings;

    return {
        drawerType,
        locale,
        navigationStyle,
        horizontalNavPosition,
        selectedVenue: venue.selectedVenue,
        venueList: venue.venues,
        id: auth.id,
        email: auth.email,
        first_name: auth.first_name,
        designId: design.designId,
        plans: Plans.plans,
    };
};
export default injectIntl(
    withRouter(
        connect(mapStateToProps, {
            getCurrentUser,
            selectVenue,
            addVenue,
            venueUpdate,
            editOneVenue,
            addPlans,
            updateSelectVenue,
            toggleCollapsedNav,
            switchLanguage,
        })(Header)
    )
);

/**
 * Created
 * ------------
 * Date: 27/08/2019
 * Author: Arun Babu

 * Updated
 * ------------
 * Date: 30/05/2020
 * Author: Arun Jose
 */
