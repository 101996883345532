import React, {Component} from 'react';
import {NavLink, withRouter} from 'react-router-dom';
import Button from '@material-ui/core/Button';
import {Button as Btn, UncontrolledCollapse} from 'reactstrap'
import IntlMessages from 'util/IntlMessages';
import CustomScrollbars from 'util/CustomScrollbars';
import './style.css';


class SidenavContent extends Component {


    /**
     *
     * @param props
     */
    constructor(props) {
        super(props)
    }

    /**
     *
     * @param event
     */
    onItemClick = (event) => {
        console.log('hahaahaahahahahahhah')
        event.currentTarget.style.backgroundColor = '#ccc';

    };

    componentDidMount() {
        const {history} = this.props;
        const that = this;
        const pathname = `#${history.location.pathname}`;// get current path

        const subMenuLi = document.querySelectorAll('.sub-menu > li');
        for (let i = 0; i < subMenuLi.length; i++) {
            subMenuLi[i].onclick = function (event) {
                event.stopPropagation();
            }
        }

        const menuLi = document.getElementsByClassName('menu');
        for (let i = 0; i < menuLi.length; i++) {
            menuLi[i].onclick = function (event) {
                for (let j = 0; j < menuLi.length; j++) {
                    const parentLi = that.closest(this, 'li');
                    if (menuLi[j] !== this && (parentLi === null || !parentLi.classList.contains('open'))) {
                        menuLi[j].classList.remove('open')
                    }
                }
                this.classList.toggle('open');
                event.stopPropagation();
            }
        }

        const activeLi = document.querySelector('a[href="' + pathname + '"]');// select current a element
        try {
            const activeNav = this.closest(activeLi, 'ul'); // select closest ul
            if (activeNav.classList.contains('sub-menu')) {
                this.closest(activeNav, 'li').classList.add('open');
            } else {
                this.closest(activeLi, 'li').classList.add('open');
            }
        } catch (error) {

        }
    }

    closest(el, selector) {
        try {
            let matchesFn;
            // find vendor prefix
            ['matches', 'webkitMatchesSelector', 'mozMatchesSelector', 'msMatchesSelector', 'oMatchesSelector'].some(function (fn) {
                if (typeof document.body[fn] == 'function') {
                    matchesFn = fn;
                    return true;
                }
                return false;
            });

            let parent;

            // traverse parents
            while (el) {
                parent = el.parentElement;
                if (parent && parent[matchesFn](selector)) {
                    return parent;
                }
                el = parent;
            }
        } catch (e) {

        }

        return null;
    }

    render() {
        const style = {
            fontWeight: "bold",
            backgroundColor: '#e8eaf6'
        };
        return (
            <CustomScrollbars
                className=" scrollbar"
                renderTrackVertical={props => <div {...props} className="track-vertical"/>}
            >
                <ul className="nav-menu">

                    {/*new list*/}
                    <li className="menu no-arrow ">
                        <NavLink to="/app/dashboard/default"
                                 activeStyle={style}
                        >
                            {/*<i className="zmdi zmdi-flower-alt zmdi-hc-5x"></i>*/}
                            <i className="zmdi zmdi-view-dashboard zmdi-hc-fw" style={{color: 'red'}}/>
                            {/*<li style={{marginLeft: -15}}><IntlMessages id="Dashboard"/></li>*/}
                            <span className="nav-header" style={{padding: 0, marginLeft: -11}}><IntlMessages id="sidebar.dashboard"/></span>
                        </NavLink>.
                        {/*<li className="nav-header"><IntlMessages id="DASHBOARD"/></li>*/}

                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/menu"
                                 activeStyle={style}
                        >
                            <i className="zmdi zmdi-menu zmdi-hc-fw" style={{color: '#2875c1'}}/>
                            <span className="nav-header" style={{padding: 0, marginLeft: 0}}><IntlMessages id="header.menu"/></span>
                            {/*<li ><IntlMessages id="Menu"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="MENU"/></li>*/}
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/design"
                                 activeStyle={style}
                        >
                            <i className="zmdi zmdi-tv-list zmdi-hc-fw" style={{color: '#ef8213'}}/>
                            <span className="nav-header" style={{padding: 0, marginLeft: 0}}><IntlMessages id="sidebar.design"/></span>
                            {/*<li ><IntlMessages id="Design"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="DESIGNS"/></li>*/}
                    </li>
                    <li className="menu ">
                        <Button href="javascript:void(0)" id='menu'>
                            <i className="zmdi zmdi-shopping-cart-plus zmdi-hc-fw"></i>
                            <span className="nav-header " style={{padding: 0, marginLeft: -5}}><IntlMessages id="dashboard.orders"/></span>
                        </Button>

                        {/*<li className="nav-header"><IntlMessages id="ORDERS"/></li>*/}
                    </li>

                    <UncontrolledCollapse toggler='menu' style={{background: '#e9e9e9'}}>
                        <li>
                            <NavLink className="prepend-icon" to="/app/orders"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-receipt zmdi-hc-fw"/>
                                <span className="nav-header" style={{padding: 0, marginLeft: 0}}><IntlMessages id="dashboard.orders"/></span>
                                {/*<li ><IntlMessages id="Orders"/></li>*/}
                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="ORDERS"/></li>*/}

                        </li>
                        <li>
                            <NavLink className="prepend-icon" to="/app/tables"
                                     activeStyle={style}
                            >
                                {/*<span className="nav-text text-transform-none">Tables</span>*/}
                                {/*<li ><IntlMessages id="Tables"/></li>*/}
                                <i className="zmdi zmdi-airline-seat-recline-normal zmdi-hc-fw" />
                                <span className="nav-header" style={{padding: 0, marginLeft: 0}}><IntlMessages id="common.table"/></span>

                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="TABLES"/></li>*/}
                        </li>
                        <li>
                            <NavLink className="prepend-icon" to="/app/waiters"
                                     activeStyle={style}
                            >
                                {/*<span className="nav-text">Waiters</span>*/}
                                {/*<li ><IntlMessages id="Waiters"/></li>*/}
                                <i className="zmdi zmdi-walk zmdi-hc-fw"/>
                                <span className="nav-header" style={{padding: 0, marginLeft: 0}}>Waiters</span>

                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="WAITERS"/></li>*/}

                        </li>
                        <li>
                            <NavLink className="prepend-icon" to="/app/modifiers"
                                     activeStyle={style}
                            >
                                {/*<span className="nav-text">Modifiers</span>*/}
                                {/*<li ><IntlMessages id="Modifiers"/></li>*/}
                                <i className="zmdi zmdi-arrows zmdi-hc-fw"/>
                                <span className="nav-header" style={{padding: 0, marginLeft: -5}}><IntlMessages id="common.modifiers"/></span>

                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="MODIFIERS"/></li>*/}
                        </li>
                    </UncontrolledCollapse>
                    <li className="menu">
                        <Button href="javascript:void(0)" id='feedback'>
                            <i className="zmdi zmdi-assignment zmdi-hc-fw" style={{color: '#02a225'}}/>
                            <span className="nav-header " style={{padding: 0, marginLeft: -11}}><IntlMessages id="dashboard.feedback"/></span>
                        </Button>
                        {/*<li className="nav-header"><IntlMessages id="FEEDBACKS"/></li>*/}
                    </li>
                    <UncontrolledCollapse toggler='feedback' style={{background: '#e9e9e9'}}>
                        <li>
                            <NavLink to="/app/surveys"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-comment-edit zmdi-hc-fw"></i>
                                <span className="nav-header " style={{padding: 0, marginLeft: 0}}><IntlMessages id="survey.survey_forms"/></span>
                                {/*<li ><IntlMessages id="Survey Form"/></li>*/}
                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="SURVEY fORM"/></li>*/}
                        </li>
                        <li>
                            <NavLink to="/app/feedbacks"
                                     activeStyle={style}
                            >
                                <i className="zmdi zmdi-check-all zmdi-hc-fw"></i>
                                <span className="nav-header " style={{padding: 0, marginLeft: 0}}><IntlMessages id="survey.survey_results"/></span>
                                {/*<li ><IntlMessages id="SURVEY RESULT"/></li>*/}
                            </NavLink>
                            {/*<li className="nav-header"><IntlMessages id="SURVEY RESULT"/></li>*/}
                        </li>
                    </UncontrolledCollapse>
                    <li className="menu no-arrow">
                        <NavLink to="/app/config"
                                 activeStyle={style}
                        >
                            <i className="zmdi zmdi-settings zmdi-hc-fw" style={{color: '#00bcd4'}}/>
                            <span className="nav-header " style={{padding: 0, marginLeft: 0}}><IntlMessages id="app_config.app_config"/></span>
                            {/*<li ><IntlMessages id="App Config"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="APP CONFIG"/></li>*/}
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/campaign">
                            <i className="fa fa-bullhorn" aria-hidden="true" style={{color: '#001ad4'}}></i>
                            <span className="nav-header " style={{padding: 0, marginLeft: -11}}><IntlMessages id="sidebar.campaigns"/></span>
                            {/*<li ><IntlMessages id="Campaigns"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="CAMPAIGNS"/></li>*/}
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/info"
                                 activeStyle={style}
                        >
                            <i className="zmdi zmdi-info-outline zmdi-hc-fw" style={{color: '#a70884'}}/>
                            <span className="nav-header " style={{padding: 0, marginLeft: 0}}><IntlMessages id="info.info_page"/></span>
                            {/*<li ><IntlMessages id="Info Page"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="INFO PAGE"/></li>*/}

                    </li>
                    <li className="menu no-arrow " style={{color: 'black'}}>
                        <NavLink to="/app/device"
                                 activeStyle={style}

                        >
                            <i className="zmdi zmdi-smartphone zmdi-hc-fw " style={{color: '#a6a6d9'}}/>
                            <span className="nav-header " style={{padding: 0, marginLeft: -5}}>DEVICES</span>
                            {/*<li ><IntlMessages id="Devices"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="DEVICES"/></li>*/}
                    </li>
                    <li className="menu no-arrow">
                        <NavLink to="/app/integration"
                                 activeStyle={style}

                        >
                            <i className="zmdi zmdi-puzzle-piece zmdi-hc-fw" style={{color: '#b2f1ba'}}/>
                            <span className="nav-header " style={{padding: 0, marginLeft: -19}}>INTEGRATIONS</span>
                            {/*<li ><IntlMessages id="Integrations"/></li>*/}
                        </NavLink>
                        {/*<li className="nav-header"><IntlMessages id="INTEGRATIONS"/></li>*/}
                    </li>
                </ul>
            </CustomScrollbars>
        );
    }
}

export default withRouter(SidenavContent);
