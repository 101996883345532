import appLocaleData from 'react-intl/locale-data/ru';
import ruMessages from '../locales/ru_RU.json';

const ruLang = {
  messages: {
    ...ruMessages
  },
  locale: 'ru-Ru',
  data: appLocaleData
};
export default ruLang;
