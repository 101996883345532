import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
// import asyncComponent from '../../../util/asyncComponent';
// import asyncComponent from './../../util/asyncComponent'
import asyncComponent from './../util/asyncComponent';

class Preview extends React.Component{
    render() {
        const {match} = this.props;
        return(
            <div className="app-wrapper"
                 style={{padding: '0'}}>
                <Switch>
                    <Redirect exact from={`${match.url}/`} to={`${match.url}/login`}/>
                    <Route path={`${match.url}/login`} component={asyncComponent(() => import('./routes/login/index'))}/>
                    <Route path={`${match.url}/home`} component={asyncComponent(() => import('./routes/home/index'))}/>
                    <Route path={`${match.url}/menu`} component={asyncComponent(() => import('./routes/menu/index'))}/>
                    <Route path={`${match.url}/item`} component={asyncComponent(() => import('./routes/item/index'))}/>
                    {/*<Route path={`${match.url}/intranet`} component={asyncComponent(() => import('./routes/Intranet'))}/>*/}
                    <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
            </div>
        )
    }
}

export default Preview;
