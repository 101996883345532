import { CommandExecutor } from './commandexecutor';
import { ConfigurationResponse } from './interfaces/commands/configuration';
import { parse as parseDuration, toSeconds } from 'iso8601-duration';
import { Logger } from './logger';

const TYPING_TIMEOUT = 5;
const HTTP_CACHE_LIFETIME = 'PT5S';
const CONSUMPTION_HORIZON_SENDING_INTERVAL = 'PT5S';
const USER_INFOS_TO_SUBSCRIBE = 100;

const MINIMUM_RETRY_DELAY = 1000;
const MAXIMUM_RETRY_DELAY = 4000;
const MAXIMUM_ATTEMPTS_COUNT = 3;
const RETRY_WHEN_THROTTLED = true;

interface BackoffConfiguration {
  min: number;
  max: number;
  maxAttemptsCount: number;
}

interface ConfigurationServices {
  commandExecutor: CommandExecutor;
}

class Configuration {
  public readonly links: {
    myConversations: string;
    conversations: string;
    users: string;
    currentUser: string;
    typing: string;
    mediaService: string;
    messagesReceipts: string;
  };

  public readonly productId?: string;

  public readonly typingIndicatorTimeoutOverride?: number;
  public readonly typingIndicatorTimeoutDefault: number = TYPING_TIMEOUT * 1000;
  public readonly backoffConfiguration: BackoffConfiguration;
  public readonly retryWhenThrottled: boolean;

  public readonly consumptionReportInterval: number;
  public readonly userInfosToSubscribe: number;
  public readonly httpCacheInterval: number;
  public readonly reachabilityEnabled: boolean;

  public readonly userIdentity: string;
  public readonly userInfo: string;
  public readonly myConversations: string;

  constructor(
    options: Record<string, any> = {},
    configurationResponse: ConfigurationResponse,
    logger: Logger
  ) {
    const constructorOptions = options.Chat || options.IPMessaging || options || {};

    this.productId = constructorOptions.productId;

    this.links = {
      myConversations: configurationResponse.links.my_conversations,
      conversations: configurationResponse.links.conversations,
      users: configurationResponse.links.users,
      currentUser: configurationResponse.links.current_user,
      typing: configurationResponse.links.typing,
      mediaService: configurationResponse.links.media_service,
      messagesReceipts: configurationResponse.links.messages_receipts
    };

    this.typingIndicatorTimeoutOverride = constructorOptions.typingIndicatorTimeoutOverride;
    this.backoffConfiguration = {
      min: MINIMUM_RETRY_DELAY,
      max: MAXIMUM_RETRY_DELAY,
      maxAttemptsCount: MAXIMUM_ATTEMPTS_COUNT,
      ...constructorOptions.backoffConfigOverride
    };
    this.retryWhenThrottled = constructorOptions.retryWhenThrottledOverride !== undefined
      ? constructorOptions.retryWhenThrottledOverride
      : RETRY_WHEN_THROTTLED;
    this.userInfosToSubscribe = constructorOptions.userInfosToSubscribeOverride
      || configurationResponse.options.user_infos_to_subscribe
      || USER_INFOS_TO_SUBSCRIBE;
    this.reachabilityEnabled = configurationResponse.options.reachability_enabled;
    this.userIdentity = configurationResponse.identity;
    this.userInfo = configurationResponse.sync_objects.my_user_info;
    this.myConversations = configurationResponse.sync_objects.my_conversations;

    const httpCacheInterval = constructorOptions.httpCacheIntervalOverride
      || configurationResponse.options.http_cache_interval
      || HTTP_CACHE_LIFETIME;

    try {
      this.httpCacheInterval = toSeconds(parseDuration(httpCacheInterval));
    } catch {
      logger.error(`Failed to parse http cache interval ${httpCacheInterval}, using default value ${HTTP_CACHE_LIFETIME}`);
      this.httpCacheInterval = toSeconds(parseDuration(HTTP_CACHE_LIFETIME));
    }

    const consumptionReportInterval = constructorOptions.consumptionReportIntervalOverride
      || configurationResponse.options.consumption_report_interval
      || CONSUMPTION_HORIZON_SENDING_INTERVAL;

    try {
      this.consumptionReportInterval = toSeconds(parseDuration(consumptionReportInterval));
    } catch {
      logger.error(
        `Failed to parse consumption report interval ${consumptionReportInterval}, using default value ${CONSUMPTION_HORIZON_SENDING_INTERVAL}`
      );
      this.consumptionReportInterval = toSeconds(parseDuration(CONSUMPTION_HORIZON_SENDING_INTERVAL));
    }
  }
}

export { Configuration };