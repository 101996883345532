import {ADD_ITEM, UPDATE_ITEM} from '../constants/ActionTypes';




export const addItem = (item) => {
    return{
        type: ADD_ITEM,
        item: item
    };
};

export const updateItem = (item) => {
    return{
        type: UPDATE_ITEM,
        item: item
    };
};