import {ADD_PATH, UPDATE_PATH, DELETE_PATH} from '../constants/ActionTypes';


const INIT_STATE = {
    paths: [{id: '', name: 'Home', basePath: 'app', parent_id: ''},
        {id: '', name: 'Menu', basePath: 'app/menu', parent_id: ''}
    ],

};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_PATH: {
            return {
                ...state,
                paths: [...state.paths, {
                    'id': action.id,
                    'name': action.name,
                    'basePath': action.basePath,
                    'parent_id': action.parent_id
                }],

            }
        }
        // case UPDATE_PATH: {
        //     return{
        //         ...state,
        //         menus: [...state.menus, action.menu]
        //     }
        // }
        case DELETE_PATH: {
            return {
                ...state,
                paths: [...state.paths.slice(0, action.index + 1)]
            }
        }
        default:
            return state;
    }
}