import {
    ADD_SECTION,
    UPDATE_SECTION,
    ADD_SECTION_ID,
    ADD_SECTION_INSIDE_SECTION,
    ADD_ITEM_INSIDE_SECTION,
    UPDATE_ITEM_INSIDE_SECTION,
    UPDATE_SECTION_INSIDE_SECTION, ORDER_CHILDREN
} from '../constants/ActionTypes';
import arrayMove from "array-move";


const INIT_STATE = {
    sections: [],
    sectionId:null,
    sectionName:null,
    section_inside_section: [],
    item_inside_section: [],
    order_children: []

};


export default (state=INIT_STATE, action) => {
    switch (action.type) {
        case ADD_SECTION: {
            return{
                ...state,
                sections: action.section
            }
        }
        case UPDATE_SECTION: {
            return{
                ...state,
                sections: [...state.sections, action.section]
            }
        }
        case ADD_SECTION_ID: {
            return{
                ...state,
                sectionId: action.id,
                sectionName: action.name,
                parentId: action.parent_id
            }
        }
        case ADD_SECTION_INSIDE_SECTION:{
            return{
                ...state,
                section_inside_section:action.section
            }
        }
        case ADD_ITEM_INSIDE_SECTION: {
            return{
                ...state,
                item_inside_section:action.item
            }
        }
        case UPDATE_ITEM_INSIDE_SECTION: {
            return{
                ...state,
                item_inside_section: [...state.item_inside_section, action.item]
            }
        }
        case UPDATE_SECTION_INSIDE_SECTION: {
            return{
                ...state,
                section_inside_section: [...state.section_inside_section, action.section]
            }
        }
        case ORDER_CHILDREN:{
            return {
                ...state,
                order_children: arrayMove([...state.order_children], action.oldIndex, action.newIndex)
            }
        }

        default:
            return state;
    }
}