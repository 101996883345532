export const BtnRadius = {
    borderRadius: 15,
    backgroundColor: 'deeppink',
    position: 'relative',
    minWidth: 10,
    minHeight: 10+' !important',
    textTransform: 'capitalize',
    display: 'inline-block',
    fontWeight: 400,
    textAlign: 'center',
    whiteSpace: 'nowrap',
    verticalAlign: 'middle',
    userSelect: 'none',
    border: 1+ 'solid ' + 'transparent',
    padding:' 0.375rem '+ '0.75rem',
    fontSize: 14,
    lineHeight: 1.2,
    transition: 'color 0.15s ease-in-out' + ','+ 'background-color 0.15s ease-in-out'+','+ 'border-color 0.15s ease-in-out'+','+ 'box-shadow 0.15s ease-in-out'
//     position: 'absolute',
//     top: '50%',
//     left: '50%',
//     transform: 'translate(-50%, -150%)',
// // -ms-transform: 'translate(-50%, -50%)',
//     backgroundColor: '#555',
//     color: 'white',
//     fontSize: '16px',
//     border: 'none',
//     cursor: 'pointer',
//     width: '4cm !important',
//     textAlign: 'center',
};

export const text = {
    color: 'orange',
    fontSize: 18
};
export const aStyle = {
    color: 'red'
};



