import { Logger } from './logger';
import { Channel } from './channel';
import { Client } from './client';
import { parseAttributes, parseTime } from './util';

const log = Logger.scope('ChannelDescriptor');

/**
 * Contains channel information.
 * Unlike {@link Channel}, this information won't be updated in realtime.
 * To have a fresh data, user should query channel descriptors again.
 *
 * @property {any} attributes - The Channel's custom attributes
 * @property {String} createdBy - The identity of the User that created Channel
 * @property {Date} dateCreated - The Date Channel was created
 * @property {Date} dateUpdated - The Date Channel was last updated
 * @property {String} friendlyName - The Channel's name
 * @property {Boolean} isPrivate - Whether the channel is private (as opposed to public)
 * @property {Number} lastConsumedMessageIndex - Index of the last Message the User has consumed in Channel
 * @property {Number} membersCount Number of members in a channel
 * @property {Number} messagesCount Number of messages in a channel
 * @property {String} sid - The Channel's unique system identifier
 * @property {Channel#Status} status - The Channel's status
 * @property {Channel#Type} type - The Channel's type
 * @property {String} uniqueName - The Channel's unique name
 */
class ChannelDescriptor {
  public client: Client;
  public descriptor: any;

  public readonly sid: string;
  public readonly channel: string;
  public readonly uniqueName: string;
  public readonly friendlyName: string;
  public readonly attributes: any;
  public readonly createdBy: string;
  public readonly dateCreated: Date;
  public readonly dateUpdated: Date;
  public readonly messagesCount: number;
  public readonly membersCount: number;
  public readonly lastConsumedMessageIndex: number;
  public readonly status: Channel.Status;
  public readonly type: Channel.Type;
  public readonly isPrivate: boolean;
  public readonly notificationLevel: Channel.NotificationLevel;

  /**
   * @param {Client} client Chat client instance
   * @param {Object} descriptor Channel descriptor data object
   * @private
   */
  constructor(client: Client, descriptor: any) {
    this.client = client;
    this.descriptor = descriptor;

    this.sid = descriptor.sid || descriptor.conversation_sid;
    this.channel = `${this.sid}.channel`;
    this.uniqueName = descriptor.unique_name;
    this.friendlyName = descriptor.friendly_name;
    this.attributes = parseAttributes(descriptor.attributes, 'Failed to parse channel attributes', log);
    this.createdBy = descriptor.created_by;
    this.dateCreated = parseTime(descriptor.date_created);
    this.dateUpdated = parseTime(descriptor.date_updated);
    this.messagesCount = descriptor.messages_count;
    this.membersCount = descriptor.participants_count;
    this.type = descriptor.type;
    this.isPrivate = descriptor.type === 'private';
    this.lastConsumedMessageIndex = descriptor.last_consumed_message_index;
    this.notificationLevel = descriptor.notification_level || undefined;
    this.status = descriptor.status || 'unknown';
  }

  /**
   * Get channel object from descriptor.
   * @returns {Promise<Channel>}
   */
  getChannel(): Promise<Channel> {
    return this.client.getChannelBySid(this.sid);
  }
}

export { ChannelDescriptor };
