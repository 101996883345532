import {ADD_MENU, UPDATE_MENU, SELECT_MENU, ORDER_MENU, DELETE_ONE_MENU, EDIT_MENU} from '../constants/ActionTypes';


export const addMenu = (menus) => {
    return {
        type: ADD_MENU,
        menus: menus
    };
};

export const updateMenu = (menu) => {
    return {
        type: UPDATE_MENU,
        menu: menu,
    };
};

export const selectMenu = (menuId) => {
    return {
        type: SELECT_MENU,
        menuId: menuId,
    };
};

export const OrderMenu = (oldIndex, newIndex) => {
    return {
        type: ORDER_MENU,
        oldIndex: oldIndex,
        newIndex: newIndex

    }
};

export const deleteOneMenu = (id) => {
    return {
        type: DELETE_ONE_MENU,
        id: id
    }
}

export const editOneMenu = (menu) => {
    return {
        type: EDIT_MENU,
        menu: menu
    }
}