import React from "react";
import {performRequest} from "./services/apiHandler";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {showAuthLoader} from "../actions";
import CircularProgress from "@material-ui/core/CircularProgress";
import IntlMessages from "../util/IntlMessages";
import Button from "@material-ui/core/Button";
import TextField from "@material-ui/core/TextField";
import {ERROR, ERROR_MESSAGE, IMAGE_ERROR_DURATION} from "../constants/constant";
import Swal from 'sweetalert2'
import SweetAlert from 'react-bootstrap-sweetalert';

import {NotificationContainer, NotificationManager} from 'react-notifications';
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";

class ResetPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            password: '',
            warningPassword: '',
            confirm_password: '',
            passwordMismatch: false,
            isExpired: false,
            isLoading:true
        }
    }

    /** 07/08/2019
     * update changes in state
     * @param event
     */
    getChanges = (e) => {
        this.setState({[e.target.name]: e.target.value, passwordMismatch: false})

    };

    componentWillMount() {
        let url = window.location.href;
        let splitUrl = url.split("/").slice(-1)[0]
        performRequest('get', 'api/reset-password/' + splitUrl + '/', null, null, false)
            .then(res => {
                this.setState({isLoading:false})

                console.log(res, 'fsafsadfasd')
            })
            .catch(error => {
                console.log(error.response.data);
                this.setState({isExpired: true,isLoading:false})
            })
    }

    forgotPassword = (event) => {
        event.preventDefault();
        let params = {
            "confirm_password": this.state.confirm_password,
            "password": this.state.password,
        };
        if (this.state.confirm_password === this.state.password) {

            let url = window.location.href;
            let splitUrl = url.split("/").slice(-1)[0]
            console.log(window.location.href.split("/").slice(-1)[0], "************", event)
            performRequest('post', 'api/reset-password/' + splitUrl + '/', params, null, false)
                .then(response => {
                    console.log(response.data, "forgot password")
                    /**~ https://sweetalert2.github.io/ ~~*/
                    Swal.fire({
                        position: 'center',
                        type: 'success',
                        text: 'Password reset successfully!',
                        showConfirmButton: false,
                        timer: IMAGE_ERROR_DURATION
                    });
                    this.setState({
                        confirm_password: '',
                        password: ''
                    })

                })
                .catch(error => {
                    NotificationManager.error(error.response.data.non_field_errors[0], null, 5000);
                    //     this.setState({
                    //         warningEmail: error.response.data.email
                    //     });
                })
        } else {
            this.setState({passwordMismatch: true})
        }

    };
    _status = () => {
        if(this.state.isExpired){
            return(
                <h3>This Link has Expired</h3>
            )
        }else {
            return(
                <div>

                    <div className="app-login-header mb-4">
                        <h1><IntlMessages id="appModule.resetPassword"/></h1>
                    </div>

                    <div className="app-login-form">
                        {/*<form>*/}
                        <fieldset>
                            <TextField
                                type="password"
                                id="required"
                                name={'password'}
                                label={'Password'}
                                fullWidth
                                onChange={(e) => this.getChanges(e)}
                                defaultValue=""
                                margin="normal"
                                className="mt-0 mb-4"
                            />
                            <TextField
                                type="password"
                                id="required"
                                name={'confirm_password'}
                                label={'Confirm Password'}
                                fullWidth
                                onChange={(e) => this.getChanges(e)}
                                defaultValue=""
                                margin="normal"
                                className="mt-0 mb-4"
                            />
                            {/*<p style={{color: 'red'}}>{this.state.warningPassword}</p>*/}
                            {this.state.passwordMismatch &&
                            <p style={{color: 'red'}}>Password does not match</p>}

                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                <Button
                                    onClick={(event) => this.forgotPassword(event)}
                                    variant="raised"
                                    style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}>
                                    <IntlMessages id="appModule.resetPassword"/>
                                </Button>
                                Just Remembered?<Link to="/signin"> SignIn
                            </Link>
                            </div>
                        </fieldset>
                        {/*</form>*/}
                    </div>
                </div>
            )
        }
    }

    render() {

        if (this.state.isLoading){
            return(
                <h3>Loading .....</h3>
            )
        }

        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div

                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">

                <div  className="app-login-main-content" >

                    <div style={{display:this.state.display}} className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        <Link style={{display:this.state.display}} className="logo-lg" to="/" title="Jambo">
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        </Link>
                    </div>

                    <div style={{display:this.state.display}} className="app-login-content" >
                        {this._status()}
                    </div>

                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }
                {showMessage && console.log(alertMessage)}
                <NotificationContainer/>
            </div>
        )
    }
}

const mapStateToProps = ({auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues
    }
};

export default connect(mapStateToProps, {showAuthLoader})(ResetPassword);