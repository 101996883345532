import {
    ADD_PREVIEW, EDIT_EXPAND, ITEMS_UNDER_SECTION,
    REMOVE_PREVIEW, SELECT_ITEM,
    SELECT_TABLE,
    SELECTED_PREVIEW_MENU, UPDATE_PREVIEW
} from '../constants/ActionTypes'

export const addPreview = (preview) => {
    return {
        type: ADD_PREVIEW,
        preview: preview
    }
};

export const selectedPreviewMenu = (menu) => {
    return {
        type: SELECTED_PREVIEW_MENU,
        menu: menu,
    }
};
export const updatePreview = (preview) => {
    return {
        type: UPDATE_PREVIEW,
        preview: preview

    }
};
export const removePreview = ( menu ) => {
    return {
        type: REMOVE_PREVIEW,
        menu : menu
    }
};
export const selectTable = (id) => {
    return {
        id: id,
        type: SELECT_TABLE
    }
};
export const selectItem = (item) => {
    return {
        item: item,
        type: SELECT_ITEM
    }
};
export const itemsUnderSection = (list) => {
    return {
        list: list,
        type: ITEMS_UNDER_SECTION
    }
};
export const editExpand = (item) => {
    return {
        item: item,
        type: EDIT_EXPAND
    }
};