import {
    ADD_SECTION_CHILDREN, DELETE_ONE_SECTION_CHILD, DELETE_ONE_SECTION_PRICE, EDIT_SECTION_CHILD,
    ORDER_SECTION_CHILDREN, UPDATE_SECTION_CHILDREN,
} from "../constants/ActionTypes";
import arrayMove from "array-move";

const INIT_STATE = {
    section_children: [],
    // order_children: []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case ADD_SECTION_CHILDREN: {
            return {
                ...state,
                section_children: action.section_children
            }
        }
        case ORDER_SECTION_CHILDREN: {
            return {
                ...state,
                section_children: arrayMove([...state.section_children], action.oldIndex, action.newIndex)
            }
        }
        case UPDATE_SECTION_CHILDREN: {
            return {
                ...state,
                section_children: [...state.section_children, action.section_children]
            }
        }
        case DELETE_ONE_SECTION_CHILD: {
            let items = [...state.section_children];
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    items.splice(index, 1)
                }
            });
            return{
                ...state,
                section_children: [...items]
            }
        }
        case DELETE_ONE_SECTION_PRICE: {
            let items = [...state.section_children];
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    items[index].price.splice(action.index, 1)
                }
            });
            return{
                ...state,
                section_children: [...items]
            }
        }
        case EDIT_SECTION_CHILD: {
            let items = [...state.section_children];
            let idx = 0;
            items.map((item,index) => {
                if ( item.id === action.id ) {
                    idx = index;
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                section_children: arrayMove([...items, action.section_children], items.length, idx)
            }

        }


        default:
            return state;
    }
}