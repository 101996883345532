import {ADD_WAITER, DELETE_WAITER} from "../constants/ActionTypes";


const INIT_STATE = {
    waiter: []
};

export default (state = INIT_STATE, actions) => {

    switch (actions.type) {
        case ADD_WAITER: {
            return {
                ...state,
                waiter: actions.waiter
            }
        }
        case DELETE_WAITER: {
            let items = [...state.waiter];
            items.map((item, index) => {
                if (item.id === actions.id) {
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                waiter: [...items]
            }
        }
        default:
            return state


    }
}