import {
    ORDER_SECTION_CHILDREN,
    ADD_SECTION_CHILDREN,
    DELETE_ONE_SECTION_CHILD, UPDATE_SECTION_CHILDREN, DELETE_ONE_SECTION_PRICE, EDIT_SECTION_CHILD,
} from "../constants/ActionTypes";


export const addSectionChildren = (section_children) => {
    return {
        type: ADD_SECTION_CHILDREN,
        section_children: section_children
    };
};

export const OrderSectionChildren = (oldIndex, newIndex) => {
    return {
        type: ORDER_SECTION_CHILDREN,
        oldIndex: oldIndex,
        newIndex: newIndex
    }
};

export const deleteOneSectionChild = (id) => {
    return {
        type: DELETE_ONE_SECTION_CHILD,
        id: id
    }
};

export const updateSectionChildren = (section_children) => {
    return {
        type: UPDATE_SECTION_CHILDREN,
        section_children: section_children
    }
};

export const deleteOneSectionPrice = (index, id) =>{
    return {
        type: DELETE_ONE_SECTION_PRICE,
        index: index,
        id: id
    }
};

export const editSectionChild = (id, children) => {
    return {
        type: EDIT_SECTION_CHILD,
        id: id,
        section_children: children
    }
};