import appLocaleData from 'react-intl/locale-data/nl';
import NlMessages from '../locales/nl_NL.json';

const Dutch = {
  messages: {
    ...NlMessages
  },
  locale: 'nl-NL',
  data: appLocaleData
};
export default Dutch;
