enum DateTimePickerView {
  YEAR = 'year',

  DATE = 'date',

  HOUR = 'hours',

  MINUTES = 'minutes',
}

export type DateTimePickerViewType = 'year' | 'date' | 'hours' | 'minutes';

export default DateTimePickerView;
