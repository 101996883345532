import {
  SELECT_VENUE,
  ADD_VENUE,
  VENUE_UPDATE,
  EDIT_VENUE,
  UPDATE_SELECT_VENUE,
} from "../constants/ActionTypes";
// import firebase from "../components/Header";
import { performRequest } from "../containers/services/apiHandler";
import { NotificationManager } from "react-notifications";

export const selectVenue = (venueName) => {
  localStorage.setItem("currentVenue", JSON.stringify(venueName));
  return {
    type: SELECT_VENUE,
    venueName: venueName,
  };
};

export const addVenue = (venue) => {
  return {
    type: ADD_VENUE,
    venue: venue,
  };
};

export const venueUpdate = (
  venueId,
  venueName,
  pin_code,
  qr_image,
  venue_id,
  languages,
  timezone,
  company,
  venue_type,
  serves_alcohol
) => {
  return {
    type: VENUE_UPDATE,
    venueId: venueId,
    venueName: venueName,
    venueQrPin: pin_code,
    venueQrImage: qr_image,
    venueQrId: venue_id,
    defaultLanguage: languages,
    venueTimezone: timezone,
    company: company,
    venue_type: venue_type,
    serves_alcohol: serves_alcohol,
  };
};

export const editOneVenue = (venue) => {
  return {
    type: EDIT_VENUE,
    venue: venue,
  };
};

export const updateSelectVenue = (venue) => {
  return {
    type: UPDATE_SELECT_VENUE,
    venue: venue,
  };
};
