import {ADD_ORDER_ENABLED, UPDATE_ORDER_ENABLED, ADD_ORDER, UPDATE_ORDER, DELETE_ORDER} from '../constants/ActionTypes';


const INIT_STATE = {
    enabled: false,
    order: [],
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case ADD_ORDER_ENABLED: {
            return {
                ...state,
                enabled: action.enabled
            }
        }
        case ADD_ORDER: {
            return {
                ...state,
                order: action.order
            }
        }
        case UPDATE_ORDER: {
            let items = [...state.order];
            let replace = []
            items.map((item, index) => {
                if (item.id === action.id) {
                    item.status = action.status
                    replace = item;
                    items.splice(index, 1)
                }
            });

            return {
                ...state,
                order: [replace, ...items]
            }

        }
        case DELETE_ORDER: {
            let items = [...state.order];
            items.map((item, index) => {
                if (item.id === action.id)
                    items.splice(index, 1)
            });
            return {
                ...state,
                order: [...items]
            }
        }


        // case DELETE_TABLE: {
        //     let items = [...state.table];
        //     items.map((item, index) => {
        //         if (item.id === actions.id) {
        //             items.splice(index, 1)
        //         }
        //     });
        //     return {
        //         ...state,
        //         table: [...items]
        //     }
        // }


        default:
            return state;
    }
}