import * as JsonDiff from 'rfc6902';
import { Logger } from '../logger';

/**
 * Checks if objects are equal
 */
function isDeepEqual(o1: Object, o2: Object): boolean {
  return JsonDiff.createPatch(o1, o2).length === 0;
}

/**
 * Deep-clone an object. Note that this does not work on object containing
 * functions.
 * @param {object} obj - the object to deep-clone
 * @returns {object}
 */
function deepClone<T>(obj: T): T {
  return JSON.parse(JSON.stringify(obj)) as T;
}

function parseToNumber(value) {
  if (typeof value !== 'undefined' && !isNaN(Number(value))) {
    return Number(value);
  }
  return null;
}

// timeString cannot be typed `string` because in member.ts
// call to parseTime(data.lastConsumptionTimestamp) uses number not a string for timestamp.
function parseTime(timeString): Date {
  try {
    return new Date(timeString);
  } catch (e) {
    return null;
  }
}

function parseAttributes(rawAttributes, warningMessage: string, log: Logger): Object {
  let attributes = {};
  if (rawAttributes) {
    try {
      attributes = JSON.parse(rawAttributes);
    } catch (e) {
      log.warn(warningMessage, e);
    }
  }

  return attributes;
}

/**
 * Construct URI with query parameters
 */
class UriBuilder {
  private base: string;
  private args: string[];
  private paths: string[];

  constructor(base: string) {
    this.base = base.replace(/\/$/, '');
    this.args = [];
    this.paths = [];
  }

  public arg(name: string, value: any): UriBuilder {
    if (typeof value !== 'undefined') {
      this.args.push(encodeURIComponent(name) + '=' + encodeURIComponent(value));
    }
    return this;
  }

  public path(name: string): UriBuilder {
    this.paths.push(encodeURIComponent(name));
    return this;
  }

  public build(): string {
    let result = this.base;
    if (this.paths.length) {
      result += '/' + this.paths.join('/');
    }

    if (this.args.length) {
      result += '?' + this.args.join('&');
    }
    return result;
  }
}

export { isDeepEqual, deepClone, UriBuilder, parseToNumber, parseTime, parseAttributes };
