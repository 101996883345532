import {
    ADD_SECTION,
    UPDATE_SECTION,
    ADD_SECTION_ID,
    ADD_SECTION_INSIDE_SECTION,
    ADD_ITEM_INSIDE_SECTION,
    UPDATE_ITEM_INSIDE_SECTION,
    UPDATE_SECTION_INSIDE_SECTION,
    ORDER_CHILDREN
} from '../constants/ActionTypes';

export const addSection = (section) => {
    return {
        type: ADD_SECTION,
        section: section
    };
};

export const updateSection = (section) => {
    return {
        type: UPDATE_SECTION,
        section: section
    };
};

export const addSectionId = (id, name, parent_id) => {
    return {
        type: ADD_SECTION_ID,
        id: id,
        name: name,
        parent_id: parent_id
    }
};

export const addSectionInsideSection = (section) => {
    return {
        type: ADD_SECTION_INSIDE_SECTION,
        section: section
    }
}

export const addItemInsideSection = (item) => {
    return {
        type: ADD_ITEM_INSIDE_SECTION,
        item: item
    }
};

export const updateItemInsideSection = (item) => {
    return {
        type: UPDATE_ITEM_INSIDE_SECTION,
        item: item
    }
};
export const updateSectionInsideSection = (section) => {
    return {
        type: UPDATE_SECTION_INSIDE_SECTION,
        section: section
    }
};

export const OrderChildren = (oldIndex, newIndex) => {

    return {
        type: ORDER_CHILDREN,
        oldIndex: oldIndex,
        newIndex: newIndex
    }
};