import appLocaleData from 'react-intl/locale-data/tr';
import saMessages from '../locales/tr_TR.json';

const saLang = {
  messages: {
    ...saMessages
  },
  locale: 'tr',
  data: appLocaleData
};
export default saLang;
