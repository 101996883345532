import React from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import asyncComponent from '../../../util/asyncComponent';
const Design = ({match}) => (

    <div className="app-wrapper">
        <Switch>
            <Redirect exact from={`${match.url}/`} to={`${match.url}/designs`}/>
            <Route path={`${match.url}/designs`} component={asyncComponent(() => import('./routes/DesignList/index'))}/>
            <Route path={`${match.url}/design-edit`} component={asyncComponent(() => import('./routes/DesignEdit/index'))}/>
            {/*<Route path={`${match.url}/design-create`} component={asyncComponent(() => import('./routes/DesignCreate/index'))}/>*/}
            {/*<Route path={`${match.url}/news`} component={asyncComponent(() => import('./routes/News'))}/>*/}
            {/*<Route path={`${match.url}/intranet`} component={asyncComponent(() => import('./routes/Intranet'))}/>*/}
            <Route component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
        </Switch>
    </div>
);

export default Design;