import appLocaleData from 'react-intl/locale-data/cs';
import csMessages from '../locales/cs_CS.json';

const csLang = {
  messages: {
    ...csMessages
  },
  locale: 'cs-CZ',
  data: appLocaleData
};
export default csLang;
