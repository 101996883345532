import React from "react";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import TextField from "@material-ui/core/TextField";
import IconButton from "@material-ui/core/IconButton";
import Button from "@material-ui/core/Button";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "@material-ui/core/CircularProgress";
import {
    showAuthLoader,
    switchLanguage
} from "actions";
import {performRequest} from './services/apiHandler';
import "./style.css"
import Input from "@material-ui/core/Input";
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";
import LanguageSwitcher from 'components/LanguageSwitcher/index';
import {
    Col,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from 'reactstrap';
const style = {
    padding: 0
}

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            password: '',
            non_field_errors: '',
            passwordError: '',
            emailError: '',
            langSwitcher: false,
        }
    }

    componentDidMount() {
        window.intercomSettings = {
            app_id: "t8wab3x6"
        };
    };
    handleRequestClose = () => {
        this.setState({
            langSwitcher: false,
        });
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher, anchorEl: event.currentTarget
        })
    };

    handleButton = (event) => {
        this.setState({
            non_field_errors: '',
            emailError: '',
            passwordError: '',
        });
        let params = {
            email: this.state.email.toLowerCase(),
            password: this.state.password
        };
        performRequest('post', 'api/login/', params, false)
            .then(response => {
                localStorage.removeItem('currentVenue');
                localStorage.setItem('accessToken', response.data.token);
                this.props.history.push('/app/dashboard/default');
            }).catch(error => {
            localStorage.setItem('userEmail', this.state.email);
            if (error.response.data.non_field_errors) {
                if (error.response.data.non_field_errors[0] === "User is not active. Please verify your email address.") {
                    this.props.history.push('/verify-email');
                }
                this.setState({
                    non_field_errors: error.response.data.non_field_errors
                })
            }
            if (error.response.data.email) {
                this.setState({
                    emailError: error.response.data.email
                })
            }
            if (error.response.data.password) {
                this.setState({
                    passwordError: error.response.data.password
                })
            }

        });
    };

    loadTermsAndConditions = () => {
        const url = 'https://www.mydigimenu.com/privacy-policy';
        window.open(url, "_blank");
    };

    render() {
        const {
            email,
            password
        } = this.state;
        const {showMessage, loader, alertMessage, locale} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        {/*<Link className="logo-lg" to="/" title="Jambo">*/}
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        {/*</Link>*/}
                    </div>

                    <div className="app-login-content">
                        <div style={{display:'flex', justifyContent:'space-between'}} >
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="common.login"/></h1>
                        </div>

                            <Dropdown
                                className="quick-menu"
                                isOpen={this.state.langSwitcher}
                                toggle={this.onLangSwitcherSelect.bind(this)}>

                                <DropdownToggle
                                    className="d-inline-block"
                                    tag="span"
                                    data-toggle="dropdown">
                                    <p style={{cursor:'pointer',color:'blue'}}>{locale.name}</p>
                                </DropdownToggle>

                                <DropdownMenu right className="w-50">
                                    <LanguageSwitcher switchLanguage={this.props.switchLanguage}
                                                    handleRequestClose={this.handleRequestClose}/>
                                </DropdownMenu>
                            </Dropdown>
                        </div>

                        <div className="app-login-form">

                            <form>
                                <p>{this.state.non_field_errors}</p>
                                <fieldset>
                                    <TextField
                                        label={<IntlMessages id="common.email"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({email: event.target.value})}
                                        defaultValue={email}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                    />
                                    {this.state.emailError &&
                                    <p style={{color: 'red'}}>{this.state.emailError}</p>}
                                    <TextField
                                        type="password"
                                        label={<IntlMessages id="appModule.password"/>}
                                        fullWidth
                                        onChange={(event) => this.setState({password: event.target.value})}
                                        defaultValue={password}
                                        margin="normal"
                                        className="mt-1 my-sm-3"
                                        style={{borderRadius: 25}}
                                    />
                                    {this.state.passwordError &&
                                    <p style={{color: 'red'}}>{this.state.passwordError}</p>}
                                    <div style={{textAlign: 'right', paddingBottom: '1%'}}>
                                        <Link to="/forgotPassword" style={{color: "#252525"}}
                                              title="Reset Password"><IntlMessages
                                            id="common.forgot"/></Link>
                                    </div>

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button onClick={(e) => this.handleButton()} variant="raised"
                                                style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}>
                                            <IntlMessages id="common.signIn"/>
                                        </Button>
                                        <Link to="/signup" style={{color: "#252525", alignSelf: "flex-end"}}>
                                            <IntlMessages id="signIn.signUp"/>
                                        </Link>
                                    </div>
                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <p>
                                        <IntlMessages id="common.agree_t_c"/><a href='#'
                                                                              onClick={() => this.loadTermsAndConditions()}><IntlMessages id="common.t_c"/></a>
                                        </p>
                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>
                </div>
                {
                    loader &&
                    <div className="loader-view">
                        <CircularProgress/>
                    </div>
                }

            </div>
        );
    }
}

const mapStateToProps = ({auth, venue, settings}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const { locale,} = settings;
    return {
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues,
        locale
    }
};

export default connect(mapStateToProps, {showAuthLoader, switchLanguage})(SignIn);