import React, {Component} from 'react';
import {createMuiTheme, MuiThemeProvider} from '@material-ui/core/styles';
import MomentUtils from '@date-io/moment';
import {MuiPickersUtilsProvider} from 'material-ui-pickers';
import {Redirect, Route, Switch} from 'react-router-dom';
import {connect} from 'react-redux';
import {IntlProvider} from 'react-intl'
import 'react-notifications/lib/notifications.css'
import 'react-big-calendar/lib/css/react-big-calendar.css';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import 'styles/jumbo.css'
import indigoTheme from './themes/indigoTheme';
import cyanTheme from './themes/cyanTheme';
import orangeTheme from './themes/orangeTheme';
import amberTheme from './themes/amberTheme';
import pinkTheme from './themes/pinkTheme';
import blueTheme from './themes/blueTheme';
import purpleTheme from './themes/purpleTheme';
import greenTheme from './themes/greenTheme';
import darkTheme from './themes/darkTheme';
import AppLocale from '../lngProvider';
import {
  AMBER,
  BLUE,
  CYAN,
  DARK_AMBER,
  DARK_BLUE,
  DARK_CYAN,
  DARK_DEEP_ORANGE,
  DARK_DEEP_PURPLE,
  DARK_GREEN,
  DARK_INDIGO,
  DARK_PINK,
  DEEP_ORANGE,
  DEEP_PURPLE,
  GREEN,
  INDIGO,
  PINK
} from 'constants/ThemeColors';

import MainApp from 'app/index';
import PreviewApp from 'preview/index';

import SignIn from './SignIn';
import SignUp from './SignUp';
import Profile from '../containers/Profile';
import Billing from '../containers/Billing';
import Plan from '../containers/Plan';
import VerifyEmail from '../containers/VerifyEmail';
import ConfirmEmail from '../containers/ConfirmEmail';
import ExportVenue from '../containers/ExportVenue';
// import Preview from './Preview/index'
import {setInitUrl} from '../actions/Auth';
import {switchLanguage} from '../actions';

import RTL from 'util/RTL';
import asyncComponent from 'util/asyncComponent';
import ForgotPassword from "./ForgotPassword";
import ResetPassword from "./ResetPassword";
import {RAZORPAY_REDIRECT_URL} from './../constants/constant'
import languageData from 'components/LanguageSwitcher/data';




const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;

class App extends Component {

  componentWillMount() {
    const language = localStorage.getItem('language');
    if(!language){
      try{
        var userLang = navigator.language  
        let lan = userLang.split('-')
        let default_language = languageData.find(item => {
          return item.locale == lan[0]
        })
        this.props.switchLanguage(default_language)
      }catch (err){
        this.props.switchLanguage({
          languageId: 'english',
          locale: 'en',
          name: 'English',
          icon: 'us'
        })
      }
    }

    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  getColorTheme(themeColor, applyTheme) {
    switch (themeColor) {
      case INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
      case DARK_INDIGO: {
        applyTheme = createMuiTheme(indigoTheme);
        break;
      }
      case DARK_CYAN: {
        applyTheme = createMuiTheme(cyanTheme);
        break;
      }
      case DARK_AMBER: {
        applyTheme = createMuiTheme(amberTheme);
        break;
      }
      case DARK_DEEP_ORANGE: {
        applyTheme = createMuiTheme(orangeTheme);
        break;
      }
      case DARK_PINK: {
        applyTheme = createMuiTheme(pinkTheme);
        break;
      }
      case DARK_BLUE: {
        applyTheme = createMuiTheme(blueTheme);
        break;
      }
      case DARK_DEEP_PURPLE: {
        applyTheme = createMuiTheme(purpleTheme);
        break;
      }
      case DARK_GREEN: {
        applyTheme = createMuiTheme(greenTheme);
        break;
      }
    }
    return applyTheme;
  }

  render() {
    const {match, location, themeColor, isDarkTheme, locale, initURL, isDirectionRTL} = this.props;
    const authUser = localStorage.getItem('accessToken') || null;
    let applyTheme = createMuiTheme(indigoTheme);
    if (isDarkTheme) {
      document.body.classList.add('dark-theme');
      applyTheme = createMuiTheme(darkTheme)
    } else {
      applyTheme = this.getColorTheme(themeColor, applyTheme);
    }
    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        if(window.location.search){
          let param = window.location.search.substring(1);
          let parsed_param = JSON.parse('{"' + decodeURI(param).replace(/"/g, '\\"').replace(/&/g, '","').replace(/=/g,'":"') + '"}')
          if(parsed_param.code && parsed_param.state=='mymenurazorpay'){
            window.location.replace(`${RAZORPAY_REDIRECT_URL}/#/app/integration/home?code=${parsed_param.code}&state=${parsed_param.state}`);
          }
        }
        return ( <Redirect to={'/app/dashboard/default'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    if (isDirectionRTL) {
      applyTheme.direction = 'rtl';
      document.body.classList.add('rtl')
    } else {
      document.body.classList.remove('rtl');
      applyTheme.direction = 'ltr';
    }

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <MuiThemeProvider theme={applyTheme}>
        <MuiPickersUtilsProvider utils={MomentUtils}>
          <IntlProvider
            locale={currentAppLocale.locale}
            messages={currentAppLocale.messages}>
            <RTL>
              <div className="app-main">
                <Switch>
                  <RestrictedRoute path={`${match.url}app`} authUser={authUser}
                                   component={MainApp}/>
                  <RestrictedRoute path={`${match.url}preview`} authUser={authUser}
                                   component={PreviewApp}/>
                  <Route path='/signin' component={SignIn}/>
                  <Route path='/signup' component={SignUp}/>
                  <Route path='/forgotPassword' component={ForgotPassword}/>
                  <Route path='/reset-password' component={ResetPassword}/>
                  <Route path='/profile' component={Profile}/>
                  <Route path='/billing' component={Billing}/>
                  <Route path='/plan' component={Plan}/>
                  <Route path='/verify-email' component={VerifyEmail}/>
                  <Route path='/confirm-email' component={ConfirmEmail}/>
                  <Route path='/export' component={ExportVenue}/>
                  {/*<Route path='/preview' component={Preview}/>*/}
                  <Route
                    component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>
                </Switch>
              </div>
            </RTL>
          </IntlProvider>
        </MuiPickersUtilsProvider>
      </MuiThemeProvider>
    );
  }
}

const mapStateToProps = ({settings, auth}) => {
  const {themeColor, sideNavColor, darkTheme, locale, isDirectionRTL} = settings;
  const {authUser, initURL} = auth;
  return {themeColor, sideNavColor, isDarkTheme: darkTheme, locale, isDirectionRTL, authUser, initURL}
};

export default connect(mapStateToProps, {setInitUrl,switchLanguage})(App);
