import React from "react";
import {Link, NavLink, Redirect, Route, Router, Switch} from "react-router-dom";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';
import IconButton from '@material-ui/core/IconButton';

import {
    showAuthLoader,
} from "actions/Auth";

import Menu from 'components/TopNav/Menu';
import Sidebar from 'containers/SideNav/index';
import ProfileSidenav from 'containers/ProfileSidenav/index';
import Footer from 'components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER

} from 'constants/ActionTypes';
import asyncComponent from "../util/asyncComponent";
import {isIOS, isMobile} from "react-device-detect";
import TopNav from 'components/TopNav';
import Toolbar from '@material-ui/core/Toolbar';
import {
    Button, Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle, Input, Modal, ModalBody, ModalFooter, ModalHeader, Progress, Row,
} from "reactstrap";
import Select from "../components/Header";
import UserInfo from 'components/UserInfo';
import {aStyle} from "../app/routes/dashboard/routes/Default/style";
import './profile.css'
import {performRequest} from "./services/apiHandler";
import {toggleCollapsedNav} from "../actions/Setting"
import Sidenav from "../containers/SideNav/index";
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";

const style = {
    padding: 0
};

class VerifyEmail extends React.Component {
    constructor() {
        super();
        this.state = {
            nav: true,
            profile: true,
            modalPassword: false,
            old_password: '',
            new_password: '',
            confirm_password: '',
            user_name: '',
            email: '',
            phone: '',
            city: '',
            state: '',
            company_name: '',
            country: '',
            occupation: '',
            id: '',
            verificationSend: false,
            successMessage: ''
        }
    }

    resendEmail = () => {
        this.state.verificationSend ?
            this.props.history.push('/signin')
        :
        performRequest('get', 'api/resend/verification-mail/', {email: localStorage.getItem('userEmail')}, null, false)
            .then(response => {
                this.setState({verificationSend: true,
                    successMessage: response.data});
                console.log("resend email", response)
            })
            .catch(error => {
                console.log("resend email", error)

            })

    };

    navControl = (e) => {
        const val = !this.state.navCollapsed;
        this.props.toggleCollapsedNav(val);
        // this.setState({
        //     nav: !this.state.nav
        // })
    };

    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1>Resend email</h1>
                        </div>
                        {
                            this.state.verificationSend &&
                                <div className="app-login-header mb-4">
                                    <h6>{this.state.successMessage}</h6>
                                </div>
                        }

                        <div className="app-login-form">
                            <div className="mb-3 d-flex align-items-center justify-content-between">
                                {/*<Link to={"/signin"}  style={{color: "#252525", alignSelf:"flex-end"}}>*/}

                                    <Button variant="raised"
                                            onClick={()=>this.resendEmail()}
                                            style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}
                                    >{
                                        this.state.verificationSend ?
                                            "SIGN IN"  : "RESEND"
                                    }

                                    </Button>


                                {/*</Link>*/}

                            </div>
                        </div>
                    </div>
                </div>

            </div>

        );
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;

    return {
        drawerType, navigationStyle, horizontalNavPosition,
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues
    }
};

export default connect(mapStateToProps, {
    showAuthLoader,
    toggleCollapsedNav

})(VerifyEmail);
