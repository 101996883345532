import React from 'react';
import {Route, Switch, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import Header from '../components/Header/index';
import Sidebar from '../containers/SideNav/index';
import {SideBarComponent} from 'containers/SideNav/newSideNav';

import Footer from '../components/Footer';
import Dashboard from './routes/dashboard';
import Menu from './routes/menu'
import Design from './routes/design'
import DesignNew from './routes/designNew'

import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
} from 'constants/ActionTypes';
import ColorOption from 'containers/Customizer/ColorOption';
import {isIOS, isMobile} from 'react-device-detect';
import asyncComponent from '../util/asyncComponent';
import TopNav from 'components/TopNav';
import './style.css'
import {NotificationManager} from "react-notifications";

class App extends React.Component {
    constructor() {
        super();
        this.state = {
            nav: true,
            profile: true,
            banner: false
        }
    }

    componentWillMount() {
        let token = localStorage.getItem('accessToken');
    }

    navControl = (e) => {
        e.preventDefault();
        this.setState({
            nav: !this.state.nav
        })
    };
    handleProfile = () => {
        this.props.history.push('/profile');
    };


    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }
        // window.innerWidth > 436 ? this.setState({nav: true}) : ''

        return (
            <div className={`app-container ${drawerStyle}`}>
                <div className={'navInMin'} style={{zIndex: '10'}}>
                    {/* <Sidebar/> */}
                </div>
                {/* <SideBarComponent/> */}
                <Sidebar banner={this.state.banner}/>
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <Header navControl={this.navControl} nav={this.state.nav} handleProfile={this.handleProfile}
                                toggleBanner={(banner) => this.setState({banner: banner})}
                                banner={this.state.banner}/>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) &&
                        <TopNav/>}
                    </div>
                    <main className="app-main-content-wrapper">
                        {
                            this.state.banner && <><br/>
                                <br/>
                            </>
                        }
                        <div className="app-main-content">

                            <Switch>

                                <Route path={`${match.url}/dashboard`} component={Dashboard}/>
                                {/*new routes*/}
                                {/*<Route path={`${match.url}/menu`}*/}
                                {/*component={asyncComponent(() => import('./routes/menu/routes/menuList'))}/>*/}
                                {/*<Route path={`${match.url}/menu`} component = {Menu}/>*/}
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/info`}
                                           component={asyncComponent(() => import('./routes/info'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/menu`} component={Menu}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/design`}
                                           component={Design}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/design-new`}
                                           component={DesignNew}/>
                                }
                                <Route path={`${match.url}/history`}
                                       component={asyncComponent(() => import('./routes/orders'))}/>

                                <Route path={`${match.url}/orders`}
                                       component={asyncComponent(() => import('./routes/orders/orders'))}/>
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/tables`}
                                           component={asyncComponent(() => import('./routes/tables'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/waiters`}
                                           component={asyncComponent(() => import('./routes/waiters'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/modifiers`}
                                           component={asyncComponent(() => import('./routes/modifiers'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/surveys`}
                                        // component={asyncComponent(() => import('./routes/surveys'))}/>
                                           component={asyncComponent(() => import('./routes/surveys/index'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/feedbacks`}
                                           component={asyncComponent(() => import('./routes/surveyResult'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/config`}
                                           component={asyncComponent(() => import('./routes/appConfig'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/appConfig`}
                                           component={asyncComponent(() => import('./routes/appConfigSettings'))}/>
                                }

                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/campaign`}
                                           component={asyncComponent(() => import('./routes/campaign'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/integration`}
                                           component={asyncComponent(() => import('./routes/integration'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/device`}
                                           component={asyncComponent(() => import('./routes/device'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/qrTable`}
                                           component={asyncComponent(() => import('./routes/qrTable'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/qrConfig`}
                                           component={asyncComponent(() => import('./routes/qrConfig'))}/>
                                }
                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/online`}
                                           component={asyncComponent(() => import('./routes/online_menu'))}/>
                                }
                                {
                                    this.props.selectedVenue && (this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2") &&
                                    <Route path={`${match.url}/subscription`}
                                           component={asyncComponent(() => import('./routes/subscription'))}/>
                                }
                                {
                                    this.props.selectedVenue && (this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2") &&
                                    <Route path={`${match.url}/payment`}
                                           component={asyncComponent(() => import('./routes/payment'))}/>
                                }
                                {/*component={asyncComponent(() => import('./routes/surveys'))}/>*/}

                                {/*for test*/}
                                {
                                    this.props.selectedVenue && (this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2") &&
                                    <Route path={`${match.url}/user-management`}
                                           component={asyncComponent(() => import('./routes/user-management'))}/>
                                }

                                {
                                    this.props.selectedVenue && this.props.selectedVenue.role != "3" &&
                                    <Route path={`${match.url}/crm`}
                                           component={asyncComponent(() => import('./routes/crm'))}/>
                                }
                                {
                                    this.props.selectedVenue &&
                                    <Route path={`${match.url}/chat`}
                                           component={asyncComponent(() => import('./routes/chat'))}/>

                                }
                                {/*end new routes*/}
                                {
                                    this.props.selectedVenue &&
                                    <Route
                                        component={asyncComponent(() => import('app/routes/extraPages/routes/404'))}/>

                                }


                            </Switch>
                        </div>
                        <Footer/>
                    </main>
                </div>
                <ColorOption/>
            </div>
        );
    }
}


const mapStateToProps = ({settings, loader, venue}) => {
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;
    return {
        drawerType,
        navigationStyle,
        horizontalNavPosition,
        loader: loader.loader,
        selectedVenue: venue.selectedVenue,
    }
};
export default withRouter(connect(mapStateToProps)(App));
