import React from 'react';
import {connect} from 'react-redux'
import {getCurrentUser} from './../../actions/index'

import './style.css'
import {
    Button,
    Dropdown,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Row,
} from 'reactstrap';
import {makeStyles} from '@material-ui/core/styles';
import Avatarw from 'react-avatar';
import {Link} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import {MdHelpOutline, MdDescription} from "react-icons/md";
import {GoFile} from "react-icons/go";
import IntlMessages from 'util/IntlMessages';
import {performRequest} from "../../containers/services/apiHandler";
import firebase from "firebase";


class UserInfo extends React.Component {

    state = {
        langSwitcher: false,

        anchorEl: null,
        open: false,
        hover: false,
        lang: false

    };
    toggleHover = () => {
        this.setState({hover: !this.state.hover})
    };
    userManagement = () => {
        this.props.history.push('/app/user-management/user-list')
    }
    handleClick = event => {
        this.setState({open: true, anchorEl: event.currentTarget});
    };

    handleRequestClose = () => {
        this.setState({open: false});
    };
    userSignOut = () => {
        const messaging = firebase.messaging();
        messaging.requestPermission();
        messaging
            .getToken()
            .then((token) => {
                const formData = new FormData();
                formData.append('fcm_token', token);
                performRequest('post', 'api/web-app-token-delete/', formData, null, false)
                    .then(e => {
                        window.Intercom('shutdown');
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('currentVenue');
                        this.props.history.push('signin/');
                    })
                    .catch(e => {
                        window.Intercom('shutdown');
                        localStorage.removeItem('accessToken');
                        localStorage.removeItem('currentVenue');
                        this.props.history.push('signin/');
                    })

            })

        window.Intercom('shutdown');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('currentVenue');
        this.props.history.push('signin/');
        // performRequest('get', 'api/devices/', null,{venue: this.props.selectedVenue.value})

        // performRequest('delete', 'api/web-app-token-delete/', null, {fcm_token: '111111111111111'})
        //     .then(e => {
        //         console.log(e, 'ee')
        //     })

    };

    onLangSwitcher = (event) => {
        this.setState({
            lang: !this.state.lang
        })
    };
    onLangSwitcherSelect = (event) => {
        this.setState({
            langSwitcher: !this.state.langSwitcher,
            // anchorEl: event.currentTarget
        })
    };

    newWindow = (url) => {
        // localStorage.setItem("pageData", "Data Retrieved from axios request")
        // route to new page by changing window.location
        window.open(url, "_blank");
    };

    render() {
        const {anchorEl} = this.state;
        const full_name = this.props.first_name ? this.props.first_name + ' ' + (this.props.last_name && this.props.last_name) : this.props.email;
        return (
            <Row>
                <Button
                    onClick={() => this.newWindow('https://learn.mydigimenu.com')}
                    className="btn btn-secondary  float-right drpBtns customButtonStyle mb-0 navInMin"><IntlMessages
                    id="header.tutorial"/>
                </Button>
                <Tooltip className='mr-4 mx-2 navTextInMin' id="tooltip-bottom" title="Help" placement="bottom">
                    <Button variant="raised" className="BtnStyle mb-0 pb-0" style={{paddingLeft: 7}}
                            onClick={() => this.newWindow('https://learn.mydigimenu.com')}>
                        <MdHelpOutline color={"#ffffff"} size={20}/>
                        {/*<i style={{color: 'white'}} className="zmdi zmdi-settings zmdi-hc-lg" />*/}
                    </Button>
                </Tooltip>
                <div>
                    <Dropdown
                        className="quick-menu "
                        isOpen={this.state.langSwitcher || this.state.lang}
                        toggle={this.onLangSwitcherSelect.bind(this)}
                    >
                        <DropdownToggle
                            className="d-inline-block "
                            tag="span"
                            data-toggle="dropdown">
                            <Row className='align-items-center'>
                                <div style={{paddingRight: 10, paddingTop: 5}}
                                     className='list-inline-item  mr-2  navProfileInMin d-none'>
                                    {/*<p onClick={this.onLangSwitcher.bind(this)}>*/}
                                    <p onClick={this.onLangSwitcherSelect.bind(this)}>
                                        Hello, {full_name.length > 11 ? (((full_name).substring(0, 11)) + '..') : full_name}</p>
                                </div>
                                <Avatarw className="user-avatar " name={full_name} round={true}/>
                            </Row>
                        </DropdownToggle>
                        <DropdownMenu right style={{top: '1px !important', width: '210px'}}>
                            <div style={{top: '1px !important'}}
                                // onClick={this.props.handleProfile}
                            >
                                <tr tabIndex={-1}
                                >
                                    <td className=" border-top-0">
                                        <div className="user-profile d-flex flex-row align-items-center">
                                            <Avatarw className="user-avatar " name={full_name} size="150" round={true}/>
                                            <div className="user-detail">
                                                <h5 className="user-name text-capitalize">{full_name}</h5>
                                                <p className="user-description">{this.props.email.length > 20 ? this.props.email.substring(0, 19) + '...' : this.props.email}</p>
                                            </div>
                                        </div>
                                    </td>
                                </tr>

                            </div>
                            <DropdownItem divider/>

                            <DropdownItem
                                onClick={this.props.handleProfile}
                            ><IntlMessages id="header.my_account"/></DropdownItem>
                            {
                                this.props.selectedVenue && this.props.selectedVenue.role != "3" && this.props.selectedVenue.role != "2" &&
                                <DropdownItem
                                    onClick={this.userManagement}
                                ><IntlMessages id="common.user_management"/></DropdownItem>
                            }
                            <DropdownItem onClick={this.userSignOut}><IntlMessages id="common.logout"/></DropdownItem>
                        </DropdownMenu>
                    </Dropdown>
                </div>
            </Row>
        );
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {locale} = settings;

    return {
        locale,
        first_name: auth.first_name,
        last_name: auth.last_name,
        email: auth.email,
        selectedVenue: venue.selectedVenue,
    }
};
export default connect(mapStateToProps, {getCurrentUser})(UserInfo);


