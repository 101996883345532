import {combineReducers} from 'redux';
import {routerReducer} from 'react-router-redux';
import Settings from './Settings';
import Auth from './Auth';
import Venue from './Venue';
import Menu from './Menu';
import Section from './Section';
import Item from './Item';
import Design from './Design';
import Table from './Table';
import Waiter from './Waiter';
import Modifier from './Modifier';
import Loader from './Loader';
import Path from './Path';
import Children from './Children';
import Progress from './Progress';
import SectionChildren from './SectionChildren'
import Campaign from "./Campaign";
import Survey from "./Survey";
import Preview from "./Preview"
import MultiLanguage from "./MultiLanguage"
import Order from "./Order"
import SurveyQuestions from "./SurveyQuestions"
import plans from "./Plans";
import Integrations from './Integrations'
const reducers = combineReducers({
    routing: routerReducer,
    settings: Settings,
    auth: Auth,
    venue: Venue,
    menu: Menu,
    section: Section,
    item: Item,
    design: Design,
    table: Table,
    waiter: Waiter,
    modifier: Modifier,
    loader: Loader,
    path: Path,
    children: Children,
    progress: Progress,
    section_children: SectionChildren,
    campaign: Campaign,
    survey: Survey,
    preview: Preview,
    MultiLanguage: MultiLanguage,
    Order: Order,
    SurveyQuestions: SurveyQuestions,
    Plans:plans,
    integrations: Integrations
});

export default reducers;
