import enLang from './entries/en-US';
import zhLang from './entries/zh-Hans-CN';
import arLang from './entries/ar_SA';
import itLang from './entries/it_IT';
import esLang from './entries/es_ES';
import frLang from './entries/fr_FR';
import nlLang from './entries/nl_NL';
import ptLang from './entries/pt_PT';
import deLang from './entries/de_DE';
import hiLang from './entries/hi_IN';
import jaLang from './entries/ja_JP';
import koLang from './entries/ko_KR';
import bgLang from './entries/bg_BG';
import csLang from './entries/cs_CS';
import roLang from './entries/ro_RO';
import ruLang from './entries/ru_RU';
import elLang from './entries/el_GR';
import trLang from './entries/tr_TR';

import {addLocaleData} from 'react-intl';

const AppLocale = {
  en: enLang,
  zh: zhLang,
  ar: arLang,
  it: itLang,
  es: esLang,
  fr: frLang,
  nl: nlLang,
  pt: ptLang,
  de: deLang,
  hi: hiLang,
  ja: jaLang,
  ko: koLang,
  bg: bgLang,
  cs: csLang,
  ro: roLang,
  ru: ruLang,
  el: elLang,
  tr: trLang



};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.ar.data);
addLocaleData(AppLocale.it.data);
addLocaleData(AppLocale.es.data);
addLocaleData(AppLocale.fr.data);
addLocaleData(AppLocale.de.data);
addLocaleData(AppLocale.pt.data);
addLocaleData(AppLocale.nl.data);

addLocaleData(AppLocale.hi.data);
addLocaleData(AppLocale.ja.data);
addLocaleData(AppLocale.ko.data);
addLocaleData(AppLocale.bg.data);
addLocaleData(AppLocale.cs.data);
addLocaleData(AppLocale.ro.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.ru.data);
addLocaleData(AppLocale.tr.data);
addLocaleData(AppLocale.el.data);

export default AppLocale;
