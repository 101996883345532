import appLocaleData from 'react-intl/locale-data/ja';
import JaMessages from '../locales/ja_JP.json';

const Japaneese = {
  messages: {
    ...JaMessages
  },
  locale: 'ja-JP',
  data: appLocaleData
};
export default Japaneese;
