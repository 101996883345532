import { EventEmitter } from 'events';
import { Logger } from '../logger';

import { Message } from '../message';
import { Channel } from '../channel';

import { SyncList, SyncClient } from 'twilio-sync';
import { SyncPaginator } from '../syncpaginator';

import { McsClient, McsMedia } from 'twilio-mcs-client';
import { Configuration } from '../configuration';
import { CommandExecutor } from '../commandexecutor';
import { SendMessageRequest } from '../interfaces/commands/sendmessage';
import { MessageResponse } from '../interfaces/commands/messageresponse';
import { SendMediaMessageRequest } from '../interfaces/commands/sendmediamessage';

const log = Logger.scope('Messages');

export interface MessagesServices {
  mcsClient: McsClient;
  syncClient: SyncClient;
  commandExecutor: CommandExecutor;
}

/**
 * Represents the collection of messages in a channel
 */
class Messages extends EventEmitter {
  private readonly messagesByIndex: Map<number, Message>;
  private messagesListPromise: Promise<SyncList>;

  constructor(
    public readonly channel: Channel,
    private readonly configuration: Configuration,
    private readonly services: MessagesServices
  ) {
    super();

    this.messagesByIndex = new Map();
    this.messagesListPromise = null;
  }

  /**
   * Subscribe to the Messages Event Stream
   * @param {String} name - The name of Sync object for the Messages resource.
   * @returns {Promise}
   */
  subscribe(name: string) {
    return this.messagesListPromise =
      this.messagesListPromise ||
      this.services.syncClient.list({ id: name, mode: 'open_existing' })
          .then(list => {

            list.on('itemAdded', args => {
              log.debug(this.channel.sid + ' itemAdded: ' + args.item.index);
              const links = {
                self: `${this.channel.links.messages}/${args.item.data.sid}`,
                conversation: this.channel.links.self,
                messages_receipts: `${this.channel.links.messages}/${args.item.data.sid}/Receipts`,
              };
              const message = new Message(args.item.index, args.item.data, this.channel, links, this.configuration, this.services);
              if (this.messagesByIndex.has(message.index)) {
                log.debug('Message arrived, but already known and ignored', this.channel.sid, message.index);
                return;
              }

              this.messagesByIndex.set(message.index, message);
              message.on('updated',
                (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
              this.emit('messageAdded', message);
            });

            list.on('itemRemoved', args => {
              log.debug(this.channel.sid + ' itemRemoved: ' + args.index);
              let index = args.index;
              if (this.messagesByIndex.has(index)) {
                let message = this.messagesByIndex.get(index);
                this.messagesByIndex.delete(message.index);
                message.removeAllListeners('updated');
                this.emit('messageRemoved', message);
              }
            });

            list.on('itemUpdated', args => {
              log.debug(this.channel.sid + ' itemUpdated: ' + args.item.index);
              let message = this.messagesByIndex.get(args.item.index);
              if (message) {
                message._update(args.item.data);
              }
            });

            return list;
          })
          .catch(err => {
            this.messagesListPromise = null;
            if (this.services.syncClient.connectionState != 'disconnected') {
              log.error('Failed to get messages object for channel', this.channel.sid, err);
            }
            log.debug('ERROR: Failed to get messages object for channel', this.channel.sid, err);
            throw err;
          });
  }

  async unsubscribe() {
    if (this.messagesListPromise) {
      let entity = await this.messagesListPromise;
      entity.close();
      this.messagesListPromise = null;
    }
  }

  /**
   * Send Message to the channel
   * @param {String} message - Message to post
   * @param {any} attributes Message attributes
   * @returns Returns promise which can fail
   */
  async send(message: string | null, attributes: any = {}): Promise<MessageResponse> {
    log.debug('Sending text message', message, attributes);

    return await this.services.commandExecutor.mutateResource<SendMessageRequest, MessageResponse>(
      'post',
      this.channel.links.messages,
      {
        body: message || '',
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined,
      }
    );
  }

  /**
   * Send Media Message to the channel
   * @param {FormData | Channel#SendMediaOptions} mediaContent - Media content to post
   * @param {any} attributes Message attributes
   * @returns Returns promise which can fail
   */
  async sendMedia(mediaContent: FormData | Channel.SendMediaOptions, attributes: any = {}) {
    log.debug('Sending media message', mediaContent, attributes);

    let media: McsMedia;
    if (typeof FormData !== 'undefined'  && (mediaContent instanceof FormData)) {
      log.debug('Sending media message as FormData', mediaContent, attributes);
      media = await this.services.mcsClient.postFormData(mediaContent);
    } else {
      log.debug('Sending media message as SendMediaOptions', mediaContent, attributes);
      let mediaOptions = mediaContent as Channel.SendMediaOptions;
      if (!mediaOptions.contentType || !mediaOptions.media) {
        throw new Error('Media content <Channel#SendMediaOptions> must contain non-empty contentType and media');
      }
      media = await this.services.mcsClient.post(mediaOptions.contentType, mediaOptions.media);
    }

    return await this.services.commandExecutor.mutateResource<SendMediaMessageRequest, MessageResponse>(
      'post',
      this.channel.links.messages,
      {
        media_sid: media.sid,
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );
  }

  /**
   * Returns messages from channel using paginator interface
   * @param {Number} [pageSize] Number of messages to return in single chunk. By default it's 30.
   * @param {String} [anchor] Most early message id which is already known, or 'end' by default
   * @param {String} [direction] Pagination order 'backwards' or 'forward', or 'forward' by default
   * @returns {Promise<Paginator<Message>>} last page of messages by default
   */
  getMessages(pageSize, anchor, direction) {
    anchor = (typeof anchor !== 'undefined') ? anchor : 'end';
    direction = direction || 'backwards';
    return this._getMessages(pageSize, anchor, direction);
  }

  private wrapPaginator(order, page, op) {
    // We should swap next and prev page here, because of misfit of Sync and Chat paging conceptions
    let shouldReverse = order === 'desc';

    let np = () => page.nextPage().then(x => this.wrapPaginator(order, x, op));
    let pp = () => page.prevPage().then(x => this.wrapPaginator(order, x, op));

    return op(page.items).then(items => ({
      items: items.sort((x, y) => { return x.index - y.index; }),
      hasPrevPage: shouldReverse ? page.hasNextPage : page.hasPrevPage,
      hasNextPage: shouldReverse ? page.hasPrevPage : page.hasNextPage,
      prevPage: shouldReverse ? np : pp,
      nextPage: shouldReverse ? pp : np
    }));
  }

  private _upsertMessage(index: number, value: any) {
    const cachedMessage = this.messagesByIndex.get(index);
    if (cachedMessage) {
      return cachedMessage;
    }

    const links = {
      self: `${this.channel.links.messages}/${value.sid}`,
      conversation: this.channel.links.self,
      messages_receipts: `${this.channel.links.messages}/${value.sid}/Receipts`,
    };
    const message = new Message(index, value, this.channel, links, this.configuration, this.services);
    this.messagesByIndex.set(message.index, message);
    message.on('updated',
      (args: Message.UpdatedEventArgs) => this.emit('messageUpdated', args));
    return message;
  }

  /**
   * Returns last messages from channel
   * @param {Number} [pageSize] Number of messages to return in single chunk. By default it's 30.
   * @param {String} [anchor] Most early message id which is already known, or 'end' by default
   * @param {String} [direction] Pagination order 'backwards' or 'forward', or 'forward' by default
   * @returns {Promise<SyncPaginator<Message>>} last page of messages by default
   * @private
   */
  private _getMessages(pageSize, anchor, direction): Promise<SyncPaginator<Message>> {
    anchor = (typeof anchor !== 'undefined') ? anchor : 'end';
    pageSize = pageSize || 30;
    let order = direction === 'backwards' ? 'desc' : 'asc';

    return this.messagesListPromise
               .then(messagesList => messagesList.getItems({
                 from: anchor !== 'end' ? anchor : void (0),
                 pageSize,
                 order
               }))
               .then(page => this.wrapPaginator(order, page
                 , items => Promise.all(items.map(item => this._upsertMessage(item.index, item.data))))
               );
  }
}

export { Messages };
