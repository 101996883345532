import {
    ADD_CAMPAIGN, DELETE_CAMPAIGN,
    EDIT_CAMPAIGN,
    UPDATE_CAMPAIGN,
} from "./../constants/ActionTypes";
import arrayMove from "array-move";


const INIT_STATE = {
    campaign: []
};


export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_CAMPAIGN: {
            return {
                ...state,
                campaign: actions.campaign
            }
        }
        case UPDATE_CAMPAIGN: {
            return {
                ...state,
                campaign: [...state.campaign, actions.campaign]
            }
        }
        case EDIT_CAMPAIGN: {
            let items = [...state.campaign];
            let idx = 0;
            items.map((item,index) => {
                if ( item.id === actions.id ) {
                    idx = index;
                    items.splice(index, 1)
                }
            });
            return {
                ...state,
                campaign: arrayMove([...items, actions.campaign], items.length, idx)
            }

        }
        case DELETE_CAMPAIGN: {
            let items = [...state.campaign];
            items.map((item,index) => {
                if ( item.id === actions.id ) {
                    items.splice(index, 1)
                }
            });
            return{
                ...state,
                campaign: [...items]
            }
        }
        default:
            return state


    }
}