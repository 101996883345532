import {ADD_DESIGN, ADD_DESIGN_ID, CLEAR_EDITED_ITEMS, EDITED_ITEMS,UPDATE_DESIGN} from "../constants/ActionTypes";

export const addDesign = (designs) => {
    return{
        type: ADD_DESIGN,
        designs: designs
    };
};

export const addDesignId = (id) => {
    return{
        type: ADD_DESIGN_ID,
        id: id
    }
};

export const editedItems = (name, color) => {
    return{
        type: EDITED_ITEMS,
        name: name,
        color: color
    }
};

export const clearEditedItems = () => {
    return{
        type: CLEAR_EDITED_ITEMS,
    }
};
export const updateDesign=(designs)=>{
    return{
        type:UPDATE_DESIGN,
        designs:designs
    }
};