import firebase from 'firebase';

export const initializeFirebase = () => {
    var firebaseConfig = {
        // apiKey: "AIzaSyB_3r8_2IGNPm2OQAuzpCmgV7o5K677w7Y",
        // authDomain: "my-first-project-2018-8def0.firebaseapp.com",
        // databaseURL: "https://my-first-project-2018-8def0.firebaseio.com",
        // projectId: "my-first-project-2018-8def0",
        // storageBucket: "my-first-project-2018-8def0.appspot.com",
        // messagingSenderId: "817361344783",
        // appId: "1:817361344783:web:c9bd358516ff480301867a"
            apiKey: "AIzaSyCU4xR0K63JzepYoUEEM8r9zVjJFJj5ZhM",
            authDomain: "tech-mte.firebaseapp.com",
            projectId: "tech-mte",
            storageBucket: "tech-mte.appspot.com",
            messagingSenderId: "396708838320",
            appId: "1:396708838320:web:a17cde9330b21971b03475"
    };
    firebase.initializeApp(firebaseConfig);
    navigator.serviceWorker
        .register('/firebase-messaging-sw.js')
        .then((registration) => {
            firebase.messaging().useServiceWorker(registration);
        });
};