//Notification Manager
import React from "react";
import Subscription from "react-redux/src/utils/Subscription";




export const ERROR_MESSAGE = 'Something went wrong..!';
export const SUCCESS_MESSAGE = 'Successfully saved';
export const SUCCESS_MESSAGE_DUPLECATE = 'Duplicate created';
export const UPDATE_SUCCESS_MESSAGE = 'Updated successfully!';
export const DELETE_SUCCESSFULLY = 'Successfully deleted';
export const CHANGE_SAVED = 'Changes saved successfully';
export const ERROR = 'Sorry';
export const SAVE = 'Congrats';
export const UPDATION = 'Congrats';
export const OPERATION_NOT_POSSIBLE = 'Operation not possible';
export const SERVER_ISSUE = 'Server issue..!';
export const ASK_SUPPORT = 'Ask Support';
export const DROP_FILE = 'Drop file here';
export const DROP_IMAGE_DESCRIPTION = 'Only *.jpeg and *.png images will be accepted';
export const DROP_VIDEO = 'Only Video files will be accepted ';
export const DROP_IMAGE_VIDEO = 'Upload image/video files';


//Warnings
export const PLEASE_DROP_IMAGE = 'please drop a file here';
export const THIS_FIELD_CAN_NOT_BE_EMPTY = 'This field can not be empty';

/*~~~~~~~~~~~~venue~~~~~~~~~~~~~~~*/
export const VENUE_CREATED = 'Venue Created.';

/*~~~~~~~~~~~~menu~~~~~~~~~~~~~~~*/
export const MENU_DELETE = 'menu deleted';
export const ITEM_DELETE = 'item deleted';
export const SECTION_DELETE = 'section deleted';
export const MENU_DELETE_WARNING = 'Do you want to delete this Menu?';
export const ITEM_DELETE_WARNING = 'Do you want to delete this Item?';
export const SECTION_DELETE_WARNING = 'Do you want to delete this Section/Item?';
export const MENU_TITLE_WARNING = 'Menu name is required.';
export const SECTION_NAME_REQUIRED = 'Section name is required.';
export const ITEM_NAME_REQUERED = 'Item name is required.';
export const MENU_CREATED = 'Menu created';
export const MENU_UPDATED = 'Menu Updated';
export const SECTION_CREATED = 'Section Created';
export const ITEM_CREATED = 'Item Created';
export const SECTION_UPDATED = 'Section Updated';
export const ITEM_UPDATED = 'Item Updated';

export const TYPE_A_MENU_NAME = 'Type a menu name';
export const TYPE_A_MENU_DESCRIPTION = 'Type a menu description';
export const TYPE_A_SECTION_NAME = 'Type a section name';
export const TYPE_A_SECTION_DESCRIPTION = 'Type a section description';

export const ADD_AN_ITEM_NAME = 'Add an item name (e.g. Garlic Butter Shrimp Pasta)';
export const ADD_A_SECTION_NAME = 'Add a section name (e.g. Pasta)';

export const UPLOAD_IMAGE_OF_LESS_THAN = 'Upload image of size less than 10MB';
export const UPLOAD_IMAGE_OF_LESS_THAN_15 = 'Upload image of size less than 15MB';
export const UPLOAD_VIDEO_OF_LESS_THAN = 'Upload video of size less than 15MB';
export const UPLOAD_VIDEO_OF_LESS_THAN_20 = 'Upload video of size less than 20MB';

export const EXPORT_HEADER = 'Select a language to export your menu';
export const IMAGE_RESIZE_URL = 'https://cdn.filestackcontent.com/AIrmcfT2RHuURVcuw5FDaz/resize=height:100/';

export const image_manipulator = "https://d2ceauryvxkzea.cloudfront.net/fit-in/350x350/"; 

export const THREE_FRACTION_CURRENCY = ['OMR', 'KWD', 'BHD', 'CLF', 'UYW', 'IQD', 'JOD', 'LYD'];


/*~~~~~~~~~~~~design~~~~~~~~~~~~~~~*/
export const DESIGN_DELETE = 'design deleted';
export const DESIGN_DELETE_WARNING = 'Do you want to delete this Design?';

export const ADD_A_THEME_TITLE = 'Add a theme title';
export const TYPE_A_THEME_DESCRIPTION = 'Type a theme description';
export const DESIGN_NAME_IS_REQUIRED = 'Design name is required';
export const IMAGE_FORMAT = ['jfif'];
export const VIDEO_FORMAT = ['mp4', 'm4a', 'm4v', 'f4v', 'f4a', 'm4b', 'm4r', 'f4b', 'mov', '3gp', '3gp2', '3g2', '3gpp', '3gpp2', 'ogg', 'oga', 'ogv', 'ogx', 'wmv', 'wma', 'asf*', 'avi', 'flv', 'webm'];
export const R2L_LANGUAGES = ["ar", "he", "ur"];

/*~~~~~~~~~~~~localize~~~~~~~~~~~~~~~*/
export const SELECT_LANGAUAGE_TO_TRANSILATE = 'Select languages to translate';
export const ACTIVATE_LANGUAGE_THAT_WILL_DISPLAY_ON_YOUR_MENU = 'Activate the languages that will be displayed on your menu';

/*~~~~~~~~~~~~Campaign~~~~~~~~~~~~~~~*/
export const TITLE_IS_REQUIRED = 'Title is required';
export const CAMPAIGN_DELETE_WARNING = 'Do you want to delete this Campaign?';

/*~~~~~~~~~~~~common~~~~~~~~~~~~~~~*/
export const IMAGE_UPLOAD_SIZE = 5242880;
export const IMAGE_UPLOAD_SIZE_10MB = 10485760;
export const VIDEO_UPLOAD_SIZE = 15728640;
export const DRAG_PRESS_DELAY = 150;
export const IMAGE_ERROR_DURATION = 1800;
export const VIDEO_UPLOAD_SIZE_20MB = 20970520;

/*~~~~~~~~~~~~AWS S3~~~~~~~~~~~~~~~*/
export const BUCKET_NAME = "mymenu-backend-staging";
export const DIR_NAME = "media";
export const REGION = "ap-south-1";
export const ACCESS_KEY_CODE = "AKIA22FUWJ5FBXICYZNS";
export const SECRET_ACCESS_KEY = "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";

/*~~~~~~~~~~~~2CHECKOUT~~~~~~~~~~~~~~~*/
export const MERCHANT_CODE = "250211546276";
export const PUBLISHABLE_KEY = "BB902762-9DCD-4141-812B-C0196EED9738";
// export const MERCHANT_CODE_TEST = "901417134";
// export const PUBLISHABLE_KEY_TEST = "E6B0445B-96DA-47DB-9187-DA5148A7E7E1";
export const MERCHANT_CODE_TEST = "901417089";
export const PUBLISHABLE_KEY_TEST = "865272E2-6273-4B95-8F6B-864AC408B617";


/*~~~~~~~~~~~~~~~Subscription~~~~~~~~~~~~~~*/
export const FREE = "FREE";
export const BASIC = "BASIC";
export const PRO = "PRO";
export const ADVANCED = "ADVANCED";
export const PREMIUM = "PREMIUM";
export const BILLED_YEARLY = "billed yearly";
export const BILLED_MONTHLY = "billed monthly";
export const UNLIMITED_MENUS = "Unlimited Menus";
export const ONLINE_SUPPORT = "24/7 Online Support";
export const ONBOARDING_SECTION = "Free Onboarding Session";
export const QR_MENU = "QR Menu";
export const BUILDING_SERVICE = "Menu Building Service";
export const POS_INTEGRATION = "POS Integrations";
export const PRINTER_INTEGRATION = "Kitchen Printer Integrations";
export const MANY_TABLETS = "As many tablets as you need";
export const FRANCHISE_OFFER = "Franchise Offers";
export const LETS_TALK = "Other needs? Let's talk!";
export const UPTO_5 = "Up to 5 tablets";
export const UPTO_1 = "Up to 1 tablets";
export const UPTO_25 = "Up to 25 tablets";
export const UPTO_50 = "Up to 50 tablets";
export const SUBSCRIBE = "SUBSCRIBE";
export const GET_QUOTE = "GET QUOTE";
export const MOST_POPULAR = 'MOST\n' + 'POPULAR';
export const BEST_VALUE = 'BEST\n' + 'VALUE\n';

/*~~~~~~~~~~~~~~~Device~~~~~~~~~~~~~~*/
export const DEVICE_DELETE_WARNING = 'Do you want to delete this Device?';

/*~~~~~~~~~~~~~~~Signin~~~~~~~~~~~~~~*/
export const RESET_MAIL_INSTRUCTION = 'Password recovery instruction has been sent to your email.';

/*~~~~~~~~~~~~~~~Signin~~~~~~~~~~~~~~*/
export const TABLE_HEAD_COLOR = '#cbb252';

export const IMAGE_COMPRESSION_QUALITY = .4;

/*~~~~~~~~~~~~~~Profile~~~~~~~~~~~~~*/
export const PROFILE_UPDATED = 'Profile updated';
export const PASSWORD_UPDATED = 'Password updated';


export const GOOGLE_PLACES_API = 'AIzaSyA4xG4jiJuzS-_0qjB9aBcUbrtQ3gj0IzI';
export const API_KEY_PRIVATE = 'AIzaSyCfd7mveIxByAWyoYTRN144r0Cuf9pTwhI';


let TESTING = false

if(window.location.hostname == 'localhost' || window.location.hostname == 'testing.mydigimenu.com'){
    TESTING = true
}

//live stripe romania
// export const STRIPE_KEY  = 'ca_HxvVhKaOOIppJSBdoStY8YqTyyXrkANK';


//  stripe
export const STRIPE_KEY  = TESTING ? 'ca_HxuZrrq84v2t1VtZ0OCW7UFocR936LEF' : 'ca_HxuZG3w5SgVf0YLXc3syRfm84MnQMVRz'


// razorpay  india
export const RAZORPAY_KEY = TESTING ? 'FaBaYMY2HmdSXm' :  'FaBaYfTmckXyYk' ;
export const RAZORPAY_REDIRECT_URL = TESTING ?  'https://testing.mydigimenu.com' : 'https://app.mydigimenu.com';
export const RAZORPAY_MODE = TESTING ?  'test' : "live";
export const RAZORPAY_SECRET_KEY = TESTING ? 'XrnlM2gHSGwEDOjwkXr39O31' : 'CSo7vfFWiYV5Kz2MaUwClbr7';

// checkout 
export const CHECKOUT_PUB_KEY =  TESTING ? 'pk_test_5d1c7163-93c2-42aa-9a32-75b0c9ac28c1' : 'pk_1b355f30-230f-4bfc-9d67-2ed41f222ca9'


export const image_api = "https://my-menu.imgix.net/"

export const POSIT_KEY = "U2tDbGRGZUJ3OlU0a2dycXZKSHY0b1A4bHZkdVIvQ2w4YldBb0ZXcTdtbmZkTDJGZ0NEVDQ9"

// pk_1b355f30-230f-4bfc-9d67-2ed41f222ca9 checkout international

// pk_009661e6-1b1c-4158-bf20-3d4292481eb9 uae


// clover api keys 
export const CLOVER_KEY = TESTING ? 'VRVMZR4JEBEPA' :  'VRVMZR4JEBEPA' ;
export const CLOVER_SECRET = TESTING ? 'cbe9b858-5e89-57e3-a1ec-8450a6425abb' :  'cbe9b858-5e89-57e3-a1ec-8450a6425abb' ;
