import {ADD_ORDER, ADD_ORDER_ENABLED, UPDATE_ORDER, DELETE_ORDER} from '../constants/ActionTypes';

export const addOrderEnabled = (enabled) => {
    return {
        type: ADD_ORDER_ENABLED,
        enabled: enabled,
    }
};

export const addOrder = (order) => {
    return {
        type: ADD_ORDER,
        order: order
    }
}

export const updateOrder = (id, status) => {
    return {
        type: UPDATE_ORDER,
        status: status,
        id: id
    }
}

export const deleteOrder = id => {
    return {
        type: DELETE_ORDER,
        id: id
    }
}

