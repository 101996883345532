import appLocaleData from 'react-intl/locale-data/el';
import saMessages from '../locales/el_GR.json';

const saLang = {
  messages: {
    ...saMessages
  },
  locale: 'el',
  data: appLocaleData
};
export default saLang;
