import {ADD_MODIFIER, DELETE_MODIFIER, EDIT_MODIFIER} from "./../constants/ActionTypes"

export const addModifier = (modifier) => {
    return {
        type: ADD_MODIFIER,
        modifier: modifier
    }
};

export const deleteModifier=(id)=>{
    return {
        type:DELETE_MODIFIER,
        id: id
    }
};

export const editModifier = (id, modifier) => {
    return {
        type: EDIT_MODIFIER,
        id: id,
        modifier: modifier
    }
};