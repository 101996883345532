import appLocaleData from 'react-intl/locale-data/pt';
import PtMessages from '../locales/pt_PT.json';

const Portu = {
  messages: {
    ...PtMessages
  },
  locale: 'pt-PT',
  data: appLocaleData
};
export default Portu;
