import {ADD_LANGUAGE} from "./../constants/ActionTypes";


const INIT_STATE = {
    language: []
};


export default (state = INIT_STATE, actions) => {
    switch (actions.type) {
        case ADD_LANGUAGE: {
            return {
                ...state,
                language: actions.language
            }
        }

        default:
            return state
    }
}