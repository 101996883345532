import { EventEmitter } from 'events';
import { UserDescriptor } from './userdescriptor';
import { Users } from './data/users';
import { User } from './user';
import { isDeepEqual, parseTime, parseAttributes } from './util';
import { Logger } from './logger';
import { validateTypesAsync, literal } from 'twilio-sdk-type-validator';
import { Channel } from './channel';
import { CommandExecutor } from './commandexecutor';
import { EditMemberRequest, EditMemberResponse } from './interfaces/commands/editmember';

const log = Logger.scope('Member');

interface MemberDescriptor {
  attributes?: Object;
  dateCreated: any;
  dateUpdated: any;
  identity: string;
  roleSid?: string;
  lastConsumedMessageIndex: number;
  lastConsumptionTimestamp: number;
  type?: Member.Type;
  userInfo: string;
}

interface MemberState {
  attributes: any;
  dateCreated: Date;
  dateUpdated: Date;
  identity: string;
  isTyping: boolean;
  lastConsumedMessageIndex: number | null;
  lastConsumptionTimestamp: Date;
  roleSid: string;
  sid: string;
  type: Member.Type;
  typingTimeout: any;
  userInfo: string;
}

export interface MemberServices {
  users: Users;
  commandExecutor: CommandExecutor;
}

interface MemberLinks {
  self: string;
}

namespace Member {
  export type UpdateReason = 'attributes' | 'dateCreated' | 'dateUpdated' | 'roleSid' | 'lastConsumedMessageIndex' | 'lastConsumptionTimestamp';

  export type Type = 'chat' | 'sms' | 'whatsapp';

  export interface UpdatedEventArgs {
    member: Member;
    updateReasons: Member.UpdateReason[];
  }
}

/**
 * @classdesc A Member represents a remote Client in a Channel.
 * @property {any} attributes - Object with custom attributes for Member
 * @property {Channel} channel - The Channel the remote Client is a Member of
 * @property {Date} dateCreated - The Date this Member was created
 * @property {Date} dateUpdated - The Date this Member was last updated
 * @property {String} identity - The identity of the remote Client
 * @property {Boolean} isTyping - Whether or not this Member is currently typing
 * @property {Number} lastConsumedMessageIndex - Latest consumed Message index by this Member.
 * Note that just retrieving messages on a client endpoint does not mean that messages are consumed/read,
 * please consider reading about [Consumption Horizon feature]{@link https://www.twilio.com/docs/api/chat/guides/consumption-horizon}
 * to find out how to mark messages as consumed.
 * @property {Date} lastConsumptionTimestamp - Date when Member has updated their consumption horizon
 * @property {String} sid - The server-assigned unique identifier for the Member
 * @property {Member#Type} type - The type of Member
 * @fires Member#typingEnded
 * @fires Member#typingStarted
 * @fires Member#updated
 */
class Member extends EventEmitter {

  private state: MemberState;

  constructor(
    data: MemberDescriptor,
    sid: string,
    public readonly channel: Channel,
    private readonly links: MemberLinks,
    private readonly services: MemberServices
  ) {
    super();

    this.services = services;
    this.state = {
      attributes: parseAttributes(data.attributes,
        'Retrieved malformed attributes from the server for member: ' + sid,
        log),
      dateCreated: data.dateCreated ? parseTime(data.dateCreated) : null,
      dateUpdated: data.dateCreated ? parseTime(data.dateUpdated) : null,
      sid: sid,
      typingTimeout: null,
      isTyping: false,
      identity: data.identity || null,
      roleSid: data.roleSid || null,
      lastConsumedMessageIndex: Number.isInteger(data.lastConsumedMessageIndex) ? data.lastConsumedMessageIndex : null,
      lastConsumptionTimestamp: data.lastConsumptionTimestamp ? parseTime(data.lastConsumptionTimestamp) : null,
      type: data.type || 'chat',
      userInfo: data.userInfo
    };

    if (!data.identity && !data.type) {
      throw new Error('Received invalid Member object from server: Missing identity or type of Member.');
    }
  }

  /**
   * The update reason for <code>updated</code> event emitted on Member
   * @typedef {('attributes' | 'dateCreated' | 'dateUpdated' | 'roleSid' |
    'lastConsumedMessageIndex' | 'lastConsumptionTimestamp')} Member#UpdateReason
   */

  /**
   * The type of Member
   * @typedef {('chat' | 'sms' | 'whatsapp')} Member#Type
   */

  public get sid(): string { return this.state.sid; }

  public get attributes(): Object { return this.state.attributes; }

  public get dateCreated(): Date { return this.state.dateCreated; }

  public get dateUpdated(): Date { return this.state.dateUpdated; }

  public get identity(): string { return this.state.identity; }

  public get isTyping(): boolean { return this.state.isTyping; }

  public get lastConsumedMessageIndex(): number | null { return this.state.lastConsumedMessageIndex; }

  public get lastConsumptionTimestamp(): Date { return this.state.lastConsumptionTimestamp; }

  public get roleSid(): string { return this.state.roleSid; }

  public get type(): Member.Type { return this.state.type; }

  /**
   * Private method used to start or reset the typing indicator timeout (with event emitting)
   * @private
   */
  _startTyping(timeout) {
    clearTimeout(this.state.typingTimeout);

    this.state.isTyping = true;
    this.emit('typingStarted', this);
    this.channel.emit('typingStarted', this);

    this.state.typingTimeout = setTimeout(() => this._endTyping(), timeout);
    return this;
  }

  /**
   * Private method function used to stop typing indicator (with event emitting)
   * @private
   */
  _endTyping() {
    if (!this.state.typingTimeout) { return; }

    this.state.isTyping = false;
    this.emit('typingEnded', this);
    this.channel.emit('typingEnded', this);

    clearInterval(this.state.typingTimeout);
    this.state.typingTimeout = null;
  }

  /**
   * Private method function used update local object's property roleSid with new value
   * @private
   */
  _update(data) {
    let updateReasons: Member.UpdateReason[] = [];

    let updateAttributes =
      parseAttributes(
        data.attributes,
        'Retrieved malformed attributes from the server for member: ' + this.state.sid,
        log);

    if (data.attributes && !isDeepEqual(this.state.attributes, updateAttributes)) {
      this.state.attributes = updateAttributes;
      updateReasons.push('attributes');
    }

    let updatedDateUpdated = parseTime(data.dateUpdated);
    if (data.dateUpdated &&
      updatedDateUpdated.getTime() !== (this.state.dateUpdated && this.state.dateUpdated.getTime())) {
      this.state.dateUpdated = updatedDateUpdated;
      updateReasons.push('dateUpdated');
    }

    let updatedDateCreated = parseTime(data.dateCreated);
    if (data.dateCreated &&
      updatedDateCreated.getTime() !== (this.state.dateCreated && this.state.dateCreated.getTime())) {
      this.state.dateCreated = updatedDateCreated;
      updateReasons.push('dateCreated');
    }

    if (data.roleSid && this.state.roleSid !== data.roleSid) {
      this.state.roleSid = data.roleSid;
      updateReasons.push('roleSid');
    }

    if ((Number.isInteger(data.lastConsumedMessageIndex) || data.lastConsumedMessageIndex === null)
      && this.state.lastConsumedMessageIndex !== data.lastConsumedMessageIndex) {
      this.state.lastConsumedMessageIndex = data.lastConsumedMessageIndex;
      updateReasons.push('lastConsumedMessageIndex');
    }

    if (data.lastConsumptionTimestamp) {
      let lastConsumptionTimestamp = new Date(data.lastConsumptionTimestamp);
      if (!this.state.lastConsumptionTimestamp ||
        this.state.lastConsumptionTimestamp.getTime() !== lastConsumptionTimestamp.getTime()) {
        this.state.lastConsumptionTimestamp = lastConsumptionTimestamp;
        updateReasons.push('lastConsumptionTimestamp');
      }
    }

    if (updateReasons.length > 0) {
      this.emit('updated', { member: this, updateReasons: updateReasons });
    }

    return this;
  }

  /**
   * Gets User Descriptor for this member. Supported only for <code>chat</code> type of Members
   * @returns {Promise<UserDescriptor>}
   */
  async getUserDescriptor(): Promise<UserDescriptor> {
    if (this.type != 'chat') {
      throw new Error('Getting User Descriptor is not supported for this Member type: ' + this.type);
    }

    return this.services.users.getUserDescriptor(this.state.identity);
  }

  /**
   * Gets User for this member and subscribes to it. Supported only for <code>chat</code> type of Members
   * @returns {Promise<User>}
   */
  async getUser(): Promise<User> {
    if (this.type != 'chat') {
      throw new Error('Getting User is not supported for this Member type: ' + this.type);
    }

    return this.services.users.getUser(this.state.identity, this.state.userInfo);
  }

  /**
   * Remove Member from the Channel.
   * @returns {Promise<void>}
   */
  async remove(): Promise<void> {
    return this.channel.removeMember(this);
  }

  /**
   * Edit member attributes.
   * @param {any} attributes new attributes for Member.
   * @returns {Promise<Member>}
   */
  @validateTypesAsync(['string', 'number', 'boolean', 'object', literal(null)])
  async updateAttributes(attributes: any): Promise<Member> {
    await this.services.commandExecutor.mutateResource<EditMemberRequest, EditMemberResponse>(
      'post',
      this.links.self,
      {
        attributes: attributes !== undefined ? JSON.stringify(attributes) : undefined
      }
    );

    return this;
  }
}

export { MemberDescriptor, Member };

/**
 * Fired when Member started to type.
 * @event Member#typingStarted
 * @type {Member}
 */

/**
 * Fired when Member ended to type.
 * @event Member#typingEnded
 * @type {Member}
 */

/**
 * Fired when Member's fields has been updated.
 * @event Member#updated
 * @type {Object}
 * @property {Member} member - Updated Member
 * @property {Member#UpdateReason[]} updateReasons - Array of Member's updated event reasons
 */
