import React from "react";
import {Link, Route, Switch} from "react-router-dom";
import {connect} from "react-redux";
import AppBar from '@material-ui/core/AppBar';

import {showAuthLoader,} from "actions/Auth";

import Menu from 'components/TopNav/Menu';
import Footer from 'components/Footer';
import {
    ABOVE_THE_HEADER,
    BELOW_THE_HEADER,
    COLLAPSED_DRAWER,
    FIXED_DRAWER,
    HORIZONTAL_NAVIGATION,
    INSIDE_THE_HEADER
} from 'constants/ActionTypes';
import asyncComponent from "../util/asyncComponent";
import {isIOS, isMobile} from "react-device-detect";
import TopNav from 'components/TopNav';
import Toolbar from '@material-ui/core/Toolbar';
import {
    Button,
    Col,
    Dropdown,
    DropdownMenu,
    DropdownToggle,
    Input,
    Modal,
    ModalBody,
    ModalFooter,
    ModalHeader,
    Row,
} from "reactstrap";
import Select from 'react-select';
import UserInfo from 'components/UserInfo';
import './profile.css'
import {performRequest} from "./services/apiHandler";
import {toggleCollapsedNav} from "../actions/Setting"
import Sidenav from "../containers/SideNav/index";
import {NotificationContainer, NotificationManager} from "react-notifications";
import IntlMessages from 'util/IntlMessages';
import {
    SAVE,
    PROFILE_UPDATED,
    TABLE_HEAD_COLOR,
    PASSWORD_UPDATED,
    IMAGE_ERROR_DURATION,
    ERROR_MESSAGE, ERROR
} from "../constants/constant";
import {ScaleLoader} from "react-spinners";
import LoadingOverlay from "react-loading-overlay";
import Swal from 'sweetalert2/dist/sweetalert2.js'
import Table from "@material-ui/core/Table";
import TableHead from "@material-ui/core/TableHead";
import moment from "moment";
import {intlLoader} from 'util/intlLoader';
import { injectIntl } from 'react-intl';


const style = {
    padding: 0
};

class SignIn extends React.Component {
    constructor() {
        super();
        this.state = {
            nav: true,
            profile: true,
            modalPassword: false,
            old_password: '',
            new_password: '',
            confirm_password: '',
            user_name: '',
            email: '',
            mobile_number: '',
            city: '',
            state: '',
            company_name: '',
            country: [],
            occupation: '',
            id: '',
            first_name: '',
            last_name: '',
            is_password_match: false,
            is_password_right: false,
            temp_country: '',
            selected_country: '',
            isFetching: false,
            modalLoader: false,
            warningFullName: false,
        }
    }

    componentDidMount() {
        let token = localStorage.getItem('accessToken');
        console.log(token, 'token from local store')
        let country = '';
        this.setState({isFetching: true})
        performRequest('get', 'current_user/')
            .then(response => {
                this.setState({
                    id: response.data.id,
                    email: response.data.email,
                }, function () {
                    performRequest('get', 'users/edit/' + this.state.id)
                        .then(resp => {
                            country = resp.data.country;

                            this.setState({
                                first_name: resp.data.first_name,
                                last_name: resp.data.last_name,
                                user_name: resp.data.first_name + ' ' + resp.data.last_name,
                                company_name: (resp.data.company_name !== null && resp.data.company_name !== "null") ? resp.data.company_name : '',
                                mobile_number: resp.data.mobile_number,
                                city: resp.data.city !== null ? resp.data.city : '',
                                state: resp.data.state !== null ? resp.data.state : '',
                                temp_country: resp.data.country,
                            })
                        });
                    performRequest('get', 'api/countries/')
                        .then(response => {
                            response.data.results.map(item => {
                                result.push({value: item['iso'], label: item['name']})
                            });
                            performRequest('get', 'api/countries/?offset=100')
                                .then(response => {
                                    response.data.results.map(item => {
                                        result.push({value: item['iso'], label: item['name']})
                                    });
                                    performRequest('get', 'api/countries/?offset=200')
                                        .then(response => {
                                            response.data.results.map(item => {
                                                result.push({value: item['iso'], label: item['name']})
                                            });
                                            this.setState({country: [...result]})
                                        })
                                        .finally(e => {
                                            let selectedCountrry = this.state.country.filter((val) => val.value === country ? val : '')[0];
                                            this.setState({
                                                selected_country: selectedCountrry,
                                                isFetching: false
                                            })
                                        });
                                })
                        }).catch(error => {
                        this.setState({isFetching: false})
                    })

                })

            });
        let result = [];


    }

    navControl = (e) => {
        const val = !this.state.navCollapsed;
        this.props.toggleCollapsedNav(val);
        // this.setState({
        //     nav: !this.state.nav
        // })
    };
    getChanges = (e) => {
        this.setState({
            [e.target.name]: e.target.value,
        })
    };
    handleProfile = () => {
        this.props.history.push('/profile');
    };
    onOptionBoxSelect = () => {
        this.setState({
            selectBox: !this.state.selectBox
        })
    };
    onAppsSelect = () => {
        this.setState({
            apps: !this.state.apps
        })
    };
    toggleDrawer = (side, open) => () => {
        this.setState({
            [side]: open,
        });
    };

    handleUpdate = () => {
        console.log("@@@", this.state.user_name)

        if (this.state.user_name.length === 0 || this.state.user_name.indexOf(' ') === -1 || this.state.user_name.split(' ')[1] === "") {
            this.setState({warningFullName: true});
        }else {
            let user_name = this.state.user_name.split(' ');
            let last_name = "";
            if (user_name.length > 2){
                user_name.slice(1).map(item =>{
                    last_name = last_name + " " + item;
                })
            }else {
                last_name = user_name[1]
            }
            let data = new FormData();
            data.append('email', this.state.email);
            data.append('first_name', user_name[0]);
            data.append('last_name', last_name);

            data.append('state', this.state.state);
            data.append('city', this.state.city);
            // data.append('country', this.state.country);
            data.append('mobile_number', this.state.mobile_number);
            data.append('company_name', this.state.company_name);
            data.append('country', this.state.selected_country['value']);

            if (!this.state.warningFullName) {
                performRequest('put', 'users/edit/' + this.state.id + '/', '', data)
                    .then(response => {
                        NotificationManager.success(<IntlMessages id={PROFILE_UPDATED}/>, <IntlMessages
                            id={SAVE}/>);
                    })
                    .catch(error => {
                        NotificationManager.error(ERROR_MESSAGE, ERROR, 5000);
                    })
            }
        }
    };

    changeHandler = event => {
        if (event.target.name === 'user_name') {
            let names = [];
            try {
                names = event.target.value.split(" ");
            } catch (e) {
                console.log(e, 'error');
            }
            if (names.length <= 1) {
                this.setState({warningFullName: true})
            } else {
                this.setState({warningFullName: false})
            }
        }
        this.setState({[event.target.name]: event.target.value})


    };

    saveEditData = () => {
        if (this.state.new_password !== this.state.confirm_password) {
            this.setState({is_password_match: true})
        } else {
            this.setState({modalLoader:true,
                is_password_match: false});
            performRequest('put', 'api/account/password/' + this.state.id + '/', '', {
                // 'old_password': this.state.old_password,
                'new_password': this.state.new_password,
                'confirm_new_password': this.state.confirm_password
            })
                .then(res => {
                    console.log("Password", res)
                    NotificationManager.success(<IntlMessages id={PASSWORD_UPDATED}/>, <IntlMessages
                        id={SAVE}/>);
                    localStorage.setItem('accessToken', res.data.token);
                    this.setState({
                        modalPassword: false,
                        new_password: '',
                        confirm_password: '',
                        modalLoader: false
                    })
                })
                .catch(error => {
                    this.setState({
                        new_password: '',
                        confirm_password: '',
                        modalLoader: false,
                        modalPassword: false
                    });
                    NotificationManager.error(ERROR_MESSAGE, error.response.data.old_password, 5000);
                    console.log(error.response.data);
                })
        }
    };

    handleEditChangeCountry = event => {
        let selectedCountrry = this.state.country.filter((val) => val.value === event.value ? val : '')[0];
        this.setState({selected_country: selectedCountrry})
    };


    render() {
        const {match, drawerType, navigationStyle, horizontalNavPosition} = this.props;
        const drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'fixed-drawer' : drawerType.includes(COLLAPSED_DRAWER) ? 'collapsible-drawer' : 'mini-drawer';

        //set default height and overflow for iOS mobile Safari 10+ support.
        if (isIOS && isMobile) {
            document.body.classList.add('ios-mobile-view-height')
        } else if (document.body.classList.contains('ios-mobile-view-height')) {
            document.body.classList.remove('ios-mobile-view-height')
        }

        return (
            <div className={`app-container ${drawerStyle}`}>
                <div className={'navInMin'} style={{zIndex: '10'}}>
                    {/*<ProfileSidenav/>*/}
                    <Sidenav/>

                </div>
                <div className="app-main-container">
                    <div
                        className={`app-header ${navigationStyle === HORIZONTAL_NAVIGATION ? 'app-header-horizontal' : ''}`}>
                        {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER) &&
                        <TopNav styleName="app-top-header"/>}
                        <AppBar
                            className={`app-main-header customGradient ${(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER) ? 'app-main-header-top' : ''}`}>

                            <Toolbar className="app-toolbar" disableGutters={false}>

                                {this.props.nav ? <div className='menuCustom' onClick={this.props.navControl}>
                                        <i className="zmdi zmdi-close zmdi-hc-2x"/>
                                    </div> :
                                    <div className='menuCustom' onClick={this.navControl}>
                                        {/*<i className="zmdi zmdi-menu zmdi-hc-2x"/>*/}
                                        <span className="jr-menu-icon">
                              <span className="menu-icon"/>
                            </span>
                                    </div>
                                }
                                <Link className="app-logo mr-2 d-none d-sm-block" to="/app/dashboard/default">
                                    <img src={require('./../../src/assets/images/logo.png')} alt="mydine"
                                         title="mymenu"/>
                                </Link>

                                <div style={{paddingLeft: 40, paddingTop: '5px'}}>
                                    <Button className="btn btn-secondary cardButton1Detail float-right"
                                            onClick={() => this.props.history.push('/app/dashboard/default')}>
                                                <IntlMessages id="header.back_to_dashboard"/>
                                    </Button>
                                </div>
                                {(navigationStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER) &&
                                <Menu/>}

                                <ul className="header-notifications list-inline ml-auto">
                                    <li className="d-inline-block d-lg-none list-inline-item">
                                        <Dropdown
                                            className="quick-menu nav-searchbox"
                                            isOpen={this.state.selectBox}
                                            toggle={this.onOptionBoxSelect.bind(this)}>
                                            <DropdownMenu right className="p-0">
                                                <Select styleName="search-dropdown"
                                                        onChange={this.handleChange}
                                                        value={this.props.selectedVenue}
                                                        options={this.props.venueList}
                                                />
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>
                                    {/*Apps Icons*/}
                                    <li className="list-inline-item  d-lg-none">
                                        <Dropdown
                                            className="quick-menu app-notification"
                                            isOpen={this.state.apps}
                                            toggle={this.onAppsSelect.bind(this)}>

                                            <DropdownToggle
                                                className="d-inline-block hov"
                                                tag="span"
                                                data-toggle="dropdown">

                                  {/*<span className="app-notification-menu">*/}
                                  {/*  <i className="zmdi zmdi-apps zmdi-hc-fw zmdi-hc-lg"/>*/}
                                  {/*  <span>Apps</span>*/}
                                  {/*</span>*/}
                                            </DropdownToggle>

                                            <DropdownMenu style={{width: 200}}>
                                                {/*{this.Apps()}*/}
                                            </DropdownMenu>
                                        </Dropdown>
                                    </li>


                                    <li className="list-inline-item" style={{marginLeft: '30px'}}>
                                        <UserInfo {...this.props}
                                                  handleProfile={this.props.handleProfile}/>

                                    </li>
                                </ul>
                            </Toolbar>
                        </AppBar>
                    </div>

                    <main className="app-main-content-wrapper">
                        {this.state.isFetching ? <LoadingOverlay
                                active={this.state.isFetching}
                                styles={{

                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0, 0, 0, 0)'
                                    })
                                }}
                                spinner={<ScaleLoader
                                    sizeUnit={"px"}
                                    size={35}
                                    color={TABLE_HEAD_COLOR}
                                    loading={this.state.isFetching}

                                />}
                            >
                                <div style={{height: '400px'}}/>
                            </LoadingOverlay> :
                            <div className="container">
                                <br/>
                                <br/>
                                <Row>
                                    <h2><IntlMessages id="profile.profile_information"/></h2>
                                </Row>
                                <div className="row">
                                    <div className="col-md-6">


                                        <div className="container">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="common.name"/></label>
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <input placeholder={'John Doe'}
                                                           name={'user_name'}
                                                           value={this.state.user_name}
                                                           onChange={this.changeHandler}
                                                           className="form-control"/>
                                                </Col>
                                                {this.state.warningFullName &&
                                                <p style={{color: 'red', marginLeft: 15}}><IntlMessages id="common.please_enter_your_full_name"/></p>}
                                            </Row>
                                        </div>
                                        <br/>
                                        <div className="container">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="common.email"/></label>
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <input placeholder={'john@doerestaurant.com'}
                                                           name={'email'}
                                                           value={this.state.email}
                                                           className="form-control"/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br/>
                                        <div className="container">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="common.company_name"/></label>
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>

                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <input placeholder={intlLoader('menu.add_description', this.props.intl)} name={'company_name'}
                                                           onChange={this.changeHandler}
                                                           value={this.state.company_name}

                                                           className="form-control"/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br/>

                                    </div>
                                    <br/>
                                    <div className="col-md-6">
                                        <div className="container">
                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="common.phone"/></label>
                                                </Col>

                                            </Row>

                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <input placeholder={'+1 (850) 885 06 29'} name={'mobile_number'}
                                                           onChange={this.changeHandler}
                                                           value={this.state.mobile_number}
                                                           className="form-control"/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <br/>
                                        <div className="container">
                                            <Row>
                                                <Col xs='8' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="common.country"/></label>
                                                </Col>
                                                <Col xs='4'>

                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col xs='12' className='align-self-center'>
                                                    <Select styleName="search-dropdown"
                                                            onChange={this.handleEditChangeCountry}
                                                            placeholder={intlLoader("header.select_country",this.props.intl)}
                                                            name='country'
                                                            options={this.state.country}
                                                            value={this.state.selected_country}
                                                    />
                                                    {/*<input name={'country'}*/}
                                                    {/*className="form-control"*/}
                                                    {/*value={this.state.country}/>*/}
                                                </Col>
                                            </Row>

                                        </div>
                                        <br/>
                                        <div className="container">
                                            <Row>
                                                <Col xs='6' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="header.city"/></label>
                                                </Col>
                                                <Col xs='6' className='align-self-center'>
                                                    <label className="d-inline "><IntlMessages id="header.state"/></label>
                                                </Col>
                                            </Row>
                                            <Row>

                                                <Col xs='6' className='align-self-center'>
                                                    <input name={'city'}
                                                           value={this.state.city}
                                                           onChange={this.changeHandler}
                                                           placeholder={intlLoader("common.city_eg",this.props.intl)}
                                                           className="form-control"/>
                                                </Col>
                                                <Col xs='6' className='align-self-center'>
                                                    <input name={'state'}
                                                           value={this.state.state}
                                                           onChange={this.changeHandler}
                                                           placeholder={intlLoader("common.city_eg",this.props.intl)}
                                                           className="form-control"/>
                                                </Col>
                                            </Row>
                                        </div>
                                        <div className="container">
                                            <br/>

                                            <Row>
                                                <Col xs='12' className='align-self-center'>
                                                    <span style={{cursor: 'pointer', color: 'red'}}
                                                          onClick={() => this.setState({modalPassword: true})}
                                                    ><IntlMessages id="profile.change_password"/></span>
                                                </Col>

                                            </Row>
                                        </div>

                                    </div>

                                </div>
                                <br/>
                                <div className=' row justify-content-center'>
                                    <Button onClick={() => this.handleUpdate()}><IntlMessages id="common.update"/></Button>
                                </div>
                            </div>
                        }
                    </main>
                    <Footer/>

                    <Modal isOpen={this.state.modalPassword}>
                        <ModalHeader><IntlMessages id="profile.change_password"/></ModalHeader>
                        <ModalBody>
                            <LoadingOverlay
                                active={this.state.modalLoader}
                                styles={{

                                    overlay: (base) => ({
                                        ...base,
                                        background: 'rgba(0, 0, 0, 0)'
                                    })
                                }}
                                spinner={<ScaleLoader
                                    sizeUnit={"px"}
                                    size={35}
                                    color={TABLE_HEAD_COLOR}
                                    loading={this.state.modalLoader}

                                />}
                            >
                                <Input type={'password'} placeholder={intlLoader("common.new_password",this.props.intl)} name={'new_password'}
                                       className='w-100 mb-3'
                                       value={this.state.new_password} onChange={(e) => this.getChanges(e)}/>
                                <br/>
                                <Input type={'password'} placeholder={intlLoader("profile.change_password_confirm",this.props.intl)} name={'confirm_password'}
                                       className='w-100 mb-3'
                                       value={this.state.confirm_password} onChange={(e) => this.getChanges(e)}/>
                                {this.state.is_password_match ? <p style={{color: 'red'}}><IntlMessages id="profile.change_password_confirm"/></p> : ''}
                            </LoadingOverlay>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={this.saveEditData}
                                    disabled={this.state.image_progress}><IntlMessages id="common.save"/></Button>{' '}
                            <Button color="secondary"
                                    onClick={() => this.setState({modalPassword: false})}><IntlMessages id="common.cancel"/></Button>
                        </ModalFooter>
                    </Modal>
                </div>

                <iframe id="ifmcontentstoprint" style={{
                    height: '0px',
                    width: '0px',
                    display: 'none',
                    position: 'absolute'
                }}>
                </iframe>
                <div id='printarea'
                     style={{display: 'none', justifyContent: 'center'}}
                >
                    <div>
                        <div style={{width: '60%', float: 'left', margin: '5px'}}>
                            <h2>My Menu</h2>
                            <div style={{width: '50%'}}>
                                <p>Abhi Bose
                                    1408, JLT
                                    P O Box 53675
                                    Dubai Dubai
                                    United Arab Emirates
                                    abhi@mydigimenu.com
                                </p>
                            </div>
                        </div>
                        <div style={{width: '35%', float: 'right', margin: '5px'}} className={'logoImage'}>
                            <img
                                src={require('../assets/images/logo_single_line_golden.png')}
                                alt="mymenu" title="MyMenu"
                                style={{height: '40px', weight: '80px'}}/>
                        </div>
                    </div>
                    <div style={{
                        width: '60%',
                        float: 'left',
                        margin: '5px'
                    }}>
                        <div
                            style={{
                                width: '60%',
                            }}>
                            <p><b>Bill to</b><br/>
                                <span>{this.state.full_name} - {this.state.email}</span><br/>
                                <span>{this.state.address_line_1}</span><br/>
                                <span>{this.state.address_line_2}</span><br/>
                                <span>{this.state.state}, {this.state.zip_code}</span>
                            </p>
                        </div>

                    </div>
                    <div style={{
                        width: '60%',
                        float: 'left',
                        margin: '5px'
                    }}>
                        {/*<h2>$18.00 due August 31, 2019*/}
                        {/*</h2>*/}
                    </div>
                    <div style={{
                        width: '100%',
                        float: 'left',
                        margin: '5px'
                    }}>

                        <table
                            className='table-striped'
                            style={{
                                borderBottom: '1px solid black',
                                borderCollapse: 'collapse'
                            }}>
                            <thead

                            >
                            <tr style={{}}>
                                <th style={{
                                    textAlign: 'left',
                                    width: '40%',
                                    height: '50px',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'


                                }}>
                                    Description
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'
                                }}>
                                    Qty
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'


                                }}>
                                    Unit price
                                </th>
                                <th style={{
                                    textAlign: 'left',
                                    width: '20%',
                                    borderBottom: '1px solid black',
                                    borderCollapse: 'collapse'

                                }}>
                                    Amount
                                </th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr
                                style={{
                                    height: '50px',
                                    backgroundColor: '#9999CC'
                                }}
                            >
                                <td style={{
                                    width: '40%',
                                    backgroundColor: 'yellowgreen'
                                }}>
                                    {this.state.description}

                                </td>

                                <td style={{
                                    width: '20%',

                                }}>
                                    1
                                </td>
                                <td style={{
                                    width: '20%',

                                }}>
                                    {this.state.amount}
                                </td>
                                <td style={{
                                    width: '20%',

                                }}>
                                    {this.state.amount}
                                </td>
                            </tr>
                            <tr>
                                <td style={{
                                    width: '40%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    Subtotal
                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    {this.state.amount}
                                </td>
                            </tr>
                            <tr
                                style={{
                                    height: '50px'
                                }}
                            >
                                <td style={{
                                    width: '40%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    Discount (0% off)
                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    0.00
                                </td>
                            </tr>
                            <tr style={{
                                height: '50px',
                            }}
                            >
                                <td style={{
                                    width: '40%',

                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>

                                </td>
                                <td style={{
                                    width: '20%',
                                }}>
                                    Amount due
                                </td>
                                <td style={{
                                    width: '20%',

                                }}>
                                    {this.state.amount}
                                </td>
                            </tr>
                            </tbody>
                        </table>


                    </div>
                    {/*<div style={{*/}
                    {/*    position: 'absolute',*/}
                    {/*    bottom: '80px'*/}
                    {/*}}>*/}
                    {/*    <p>Pay with card*/}
                    {/*        Visit pay.stripe.com/invoice/invst_1e75BAVIKGt8h1fGewTcdZPabI</p>*/}
                    {/*    <p>Questions? Contact FineDine - Bambulabs, Inc. at support@finedinemenu.com or call*/}
                    {/*        at +1 415-688-7217</p>*/}
                    {/*</div>*/}
                </div>
                <NotificationContainer/>


            </div>
        )
    }
}

const mapStateToProps = ({settings, auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    const {drawerType, navigationStyle, horizontalNavPosition} = settings;

    return {
        drawerType, navigationStyle, horizontalNavPosition,
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues
    }
};

export default injectIntl(connect(mapStateToProps, {
    showAuthLoader,
    toggleCollapsedNav

})(SignIn));
