import appLocaleData from 'react-intl/locale-data/ro';
import roMessages from '../locales/ro_RO.json';

const roLang = {
  messages: {
    ...roMessages
  },
  locale: 'ro-RO',
  data: appLocaleData
};
export default roLang;
