import { UriBuilder } from '../util/index';
import { RestPaginator } from '../restpaginator';
import { UserDescriptor } from '../userdescriptor';
import { Users } from './users';
import { Network } from '../services/network';
import { Configuration } from '../configuration';

export interface UserDescriptorsServices {
  users: Users;
  network: Network;
}

class UserDescriptors {
  constructor(
    private readonly configuration: Configuration,
    private readonly services: UserDescriptorsServices,
  ) {
  }

  public async getUserDescriptor(identity: string): Promise<UserDescriptor> {
    const url = new UriBuilder(this.configuration.links.users).path(identity).build();
    let response = await this.services.network.get(url);
    return new UserDescriptor(this.services, response.body);

  }

  public async getChannelUserDescriptors(channelSid: string, args: any = {}): Promise<RestPaginator<UserDescriptor>> {
    const url = new UriBuilder(this.configuration.links.users)
      .arg('ConversationSid', channelSid).arg('PageToken', args.pageToken)
      .build();
    let response = await this.services.network.get(url);
    return new RestPaginator<UserDescriptor>(response.body.users.map(x => new UserDescriptor(this.services, x))
      , pageToken => this.getChannelUserDescriptors(channelSid, { pageToken })
      , response.body.meta.prev_token
      , response.body.meta.next_token);
  }
}

export { UserDescriptors };
