import {ADD_WAITER, DELETE_WAITER} from "../constants/ActionTypes";

export const addWaiter=(waiter)=>{
    return{
        type:ADD_WAITER,
        waiter:waiter
    }
}
export const deleteWaiter = (id) => {
    return {
        type: DELETE_WAITER,
        id: id
    }
};