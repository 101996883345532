import { Logger } from './logger';
import { User } from './user';
import { Users } from './data/users';
import { parseAttributes } from './util';

const log = Logger.scope('UserDescriptor');

export interface UserDescriptorServices {
  users: Users;
}

/**
 * @classdesc Extended user information.
 * Note that <code>online</code> and <code>notifiable</code> properties are eligible
 * to use only if reachability function is enabled.
 * You may check if it is enabled by reading value of {@link Client}'s <code>reachabilityEnabled</code> property.
 *
 * @property {String} identity - User identity
 * @property {String} friendlyName - User friendly name, null if not set
 * @property {any} attributes - Object with custom attributes for user
 * @property {Boolean} online - User real-time channel connection status
 * @property {Boolean} notifiable - User push notification registration status
 *
 * @constructor
 * @param {Users} users Users instance
 * @param {Object} descriptor User descriptor data object
 */
class UserDescriptor {

  private services: UserDescriptorServices;
  private descriptor: any;

  public readonly identity: string;
  public readonly friendlyName: string;
  public readonly attributes: any;
  public readonly online: boolean;
  public readonly notifiable: boolean;

  /**
   * @param {UserDescriptorServices} services - services, needed for UserDescriptor
   * @param {Object} descriptor User descriptor data object
   * @private
   */
  constructor(services: UserDescriptorServices, descriptor: any) {
    this.services = services;
    this.descriptor = descriptor;

    this.identity = descriptor.identity;
    this.friendlyName = descriptor.friendly_name;
    this.attributes = parseAttributes(descriptor.attributes, 'Failed to parse user attributes', log);
    this.online = descriptor.is_online;
    this.notifiable = descriptor.is_notifiable;
  }

  /**
   * Gets User object from descriptor and subscribes to it.
   * @returns {Promise<User>}
   */
  subscribe(): Promise<User> {
    return this.services.users.getUser(this.identity, this.descriptor.sync_unique_name);
  }

  _getDescriptor() {
    return this.descriptor;
  }
}

export { UserDescriptor };
