import React from 'react';
import {connect} from 'react-redux';
import {NavLink, withRouter} from 'react-router-dom';
import Drawer from '@material-ui/core/Drawer';
import {Config} from 'constants/ThemeColors';
import SidenavContent from './SidenavContent';
import UserInfo from 'components/UserInfo';
import {COLLAPSED_DRAWER, FIXED_DRAWER, HORIZONTAL_NAVIGATION} from 'constants/ActionTypes';
import {toggleCollapsedNav, updateWindowWidth} from 'actions/Setting';
import './style.css'
import Button from "../SideNav";
import {UncontrolledCollapse} from "reactstrap";
import CustomScrollbars from 'util/CustomScrollbars';

class ProfileSidenav extends React.PureComponent {

    onToggleCollapsedNav = (e) => {
        const val = !this.props.navCollapsed;
        this.props.toggleCollapsedNav(val);
    };

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        console.log(this.props.location.pathname,'kdkdkddk')
        window.addEventListener('resize', () => {
            this.props.updateWindowWidth(window.innerWidth)
        });
    }

    render() {
        const {navCollapsed, drawerType, width, navigationStyle} = this.props;
        console.log(navCollapsed, 'JJJJJJJJJJJJJJJJJJJJ');
        const style = {
            // fontWeight: "bold",
            // backgroundColor: '#e8eaf6'
        };
        let drawerStyle = drawerType.includes(FIXED_DRAWER) ? 'd-xl-flex' : drawerType.includes(COLLAPSED_DRAWER) ? '' : 'd-flex';
        let type = 'permanent';
        if (drawerType.includes(COLLAPSED_DRAWER) || (drawerType.includes(FIXED_DRAWER) && width < 1200)) {
            type = 'temporary';
        }
        if (width < 768) {
            type = 'temporary';
        }
        if (navigationStyle === HORIZONTAL_NAVIGATION) {
            drawerStyle = '';
            type = 'temporary';
        }
        return (
            <div className={`app-sidebar width d-none ${drawerStyle}`} style={{width: 90 + '!important'}}>
                <Drawer className="app-sidebar-content"
                        style={{top: '0px !important'}}
                        variant={type}
                        open={type.includes('temporary') ? navCollapsed : true}
                        onClose={this.onToggleCollapsedNav}
                        classes={{
                            paper: type === 'temporary' ? '' : 'side-nav',
                        }}
                >
                    {/*<UserInfo {...this.props}/>*/}
                    <CustomScrollbars
                        className=" scrollbar"
                        renderTrackVertical={props => <div {...props} className="track-vertical"/>}
                        style={{backgroundColor: '#252525', position: 'initial'}}>
                        <ul className="nav-menu">
                            {/*new list*/}
                            <li className="menu no-arrow ">
                                <NavLink to="/profile"
                                         activeStyle={style}
                                >
                                    {/*<i className="zmdi zmdi-flower-alt zmdi-hc-5x"></i>*/}
                                    <i className="zmdi zmdi-account-box zmdi-hc-fw" style={{color: '#cbb252'}}/>
                                    {/*<li style={{marginLeft: -15}}><IntlMessages id="Dashboard"/></li>*/}
                                    <span className="nav-header" style={{padding: 0, marginLeft: 0}}>Profile</span>
                                </NavLink>
                                {/*<li className="nav-header"><IntlMessages id="DASHBOARD"/></li>*/}

                            </li>
                            <li className="menu no-arrow">
                                <NavLink to="/billing"
                                         activeStyle={style}

                                >
                                    <i className="zmdi zmdi-chart zmdi-hc-fw" style={{color: '#cbb252'}}/>
                                    <span className="nav-header" style={{padding: 0, marginLeft: 0}}>Billing aaaaaaaaaa</span>
                                    {/*<li ><IntlMessages id="Menu"/></li>*/}
                                </NavLink>
                                {/*<li className="nav-header"><IntlMessages id="MENU"/></li>*/}
                            </li>
                            <li className="menu no-arrow">
                                <NavLink to="/plan"
                                         activeStyle={style}

                                >
                                    <i className="zmdi zmdi-tv-list zmdi-hc-fw" style={{color: '#cbb252'}}/>
                                    <span className="nav-header" style={{padding: 0, marginLeft: 0}}>Plan</span>
                                    {/*<li ><IntlMessages id="Design"/></li>*/}
                                </NavLink>
                                {/*<li className="nav-header"><IntlMessages id="DESIGNS"/></li>*/}
                            </li>
                        </ul>
                    </CustomScrollbars>
                </Drawer>
            </div>
        );
    }
}

const mapStateToProps = ({settings}) => {
    const {navCollapsed, drawerType, width, navigationStyle} = settings;
    return {navCollapsed, drawerType, width, navigationStyle}
};

export default withRouter(connect(mapStateToProps, {toggleCollapsedNav, updateWindowWidth})(ProfileSidenav));

