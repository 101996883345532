const languageData = [
  {
    languageId: 'saudi-arabia',
    locale: 'ar',
    name: 'Arabic',
    icon: 'sa'
  },
  {
    languageId: 'bg-BG',
    locale: 'bg',
    name: 'Bulgarian',
    icon: 'bg'
  },
  {
    languageId: 'cs-CS',
    locale: 'cs',
    name: 'Czech',
    icon: 'cz'
  },
  {
    languageId: 'chinese',
    locale: 'zh',
    name: 'Chinese',
    icon: 'cn'
  },
  {
    languageId: 'dutch',
    locale: 'nl',
    name: 'Dutch',
    icon: 'nl'
  },
  {
    languageId: 'english',
    locale: 'en',
    name: 'English',
    icon: 'us'
  },
  {
    languageId: 'french',
    locale: 'fr',
    name: 'French',
    icon: 'fr'
  },
  {
    languageId: 'de-DE',
    locale: 'de',
    name: 'German',
    icon: 'de'
  },
  {
    languageId: 'hindi',
    locale: 'hi',
    name: 'Hindi',
    icon: 'in'
  },
  {
    languageId: 'italian',
    locale: 'it',
    name: 'Italian',
    icon: 'it'
  },
  {
    languageId: 'japanese',
    locale: 'ja',
    name: 'Japanese',
    icon: 'jp'
  },
  {
    languageId: 'korean',
    locale: 'ko',
    name: 'Korean',
    icon: 'kr'
  },
  {
    languageId: 'portuguese',
    locale: 'pt',
    name: 'Portuguese',
    icon: 'pt'
  },
  {
    languageId: 'ro-RO',
    locale: 'ro',
    name: 'Romanian',
    icon: 'ro'
  },
  {
    languageId: 'ru-RU',
    locale: 'ru',
    name: 'Russian',
    icon: 'ru'
  },
  {
    languageId: 'spanish',
    locale: 'es',
    name: 'Spanish',
    icon: 'es'
  },
  {
    languageId: 'greek',
    locale: 'el',
    name: 'Greek',
    icon: 'el'
  },

  {
    languageId: 'turkish',
    locale: 'tr',
    name: 'Turkish',
    icon: 'tr'
  },
];
export default languageData;
