import appLocaleData from 'react-intl/locale-data/ko';
import KoMessages from '../locales/ko_KR.json';

const Korean = {
  messages: {
    ...KoMessages
  },
  locale: 'ko-KR',
  data: appLocaleData
};
export default Korean;
