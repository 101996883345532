import {
    ORDER_CHILDREN,
    ADD_CHILDREN,
    UPDATE_CHILDREN,
    DELETE_ONE_CHILD,
    DELETE_ONE_PRICE, EDIT_CHILD,
} from "../constants/ActionTypes";


export const addChildren = (children) => {

    return {
        type: ADD_CHILDREN,
        children: children
    };
};

export const updateChildren = (children) => {
    return {
        type: UPDATE_CHILDREN,
        children: children
    }
};

export const OrderChildren = (oldIndex, newIndex) => {
    return {
        type: ORDER_CHILDREN,
        oldIndex: oldIndex,
        newIndex: newIndex
    }
};

export const deleteOneChild = (id) => {
    return {
        type: DELETE_ONE_CHILD,
        id: id
    }
};

export const deleteOnePrice = (index, id) =>{
    return {
        type: DELETE_ONE_PRICE,
        index: index,
        id: id
    }
};

export const editChild = (id, children) => {
    return {
        type: EDIT_CHILD,
        id: id,
        children: children
    }
};