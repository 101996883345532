import React, {useRef} from "react";
import {Col, Row} from "reactstrap";
import {performRequest} from "./services/apiHandler";
import {connect} from "react-redux";
import {addPreview, selectedPreviewMenu, selectTable} from "../actions";
import ReactHtmlParser from "react-html-parser";
import LoadingOverlay from "react-loading-overlay";
import {ScaleLoader} from "react-spinners";
import {IMAGE_RESIZE_URL, R2L_LANGUAGES, TABLE_HEAD_COLOR, image_manipulator} from "../constants/constant";
import Icon from "../assets/icons/icon";
import './style.css';
import ReactToPrint from "react-to-print";
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";
import * as XLSX from 'xlsx';
import {saveAs} from 'file-saver';
import JSZip from "jszip";

class ExportVenue extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            sectionList: [],
            itemList: [],
            language: '',
            selectedMenuName: '',
            selectedMenuDescription: '',
            loader: false,
            symbol: ''
        }
    }

    componentDidMount() {
        let currentVenue = JSON.parse(localStorage.getItem('currentVenue'));
        let id = window.location.href.split("/").slice(-2)[0];
        let language = window.location.href.split("/").slice(-1)[0];
        this.setState({loader: true});
        performRequest('get', 'api/menu/', {venue: currentVenue.value})
            .then(response => {
                response.data.results.map(item => {
                    if (item.id === parseInt(id)) {
                        this.setState({
                            selectedMenuName: item.menu_titles[language],
                            selectedMenuDescription: item.menu_descriptions[language]
                        })
                    }
                });
            });
        id &&
        performRequest('get', 'api/export/menu/', {menu: id})
            .then(response => {
                console.log(response.data.children.filter(item => item.type === 'section'),'wwwwwwwwwwwwwwwwwwwwwwwwwwwwwwww')
                this.setState({
                    sectionList: response.data.children.filter(item => item.type === 'section'),
                    itemList: response.data.children.filter(item => item.type === 'item'),
                    language: language,
                    symbol: response.data.currency,
                    loader: false
                })
            })
    }

    render() {
        return (
            this.state.loader ?
                <LoadingOverlay
                    active={this.state.loader}
                    styles={{

                        overlay: (base) => ({
                            ...base,
                            background: 'rgba(0, 0, 0, 0)'
                        })
                    }}
                    spinner={<ScaleLoader
                        sizeUnit={"px"}
                        size={35}
                        color={TABLE_HEAD_COLOR}
                        loading={this.state.loader}

                    />}
                >
                    <div style={{height: '400px'}}/>
                </LoadingOverlay>
                :
                <div className='container'>
                    <p className='text-center' style={{
                        color: 'rgb(87, 89, 98)',
                        fontWeight: 600,
                        fontSize: '1.9rem',
                        marginTop: 70
                    }}>{this.state.selectedMenuName}</p>
                    <p className='text-center mb-5'
                       style={{fontSize: '1.3rem', color: 'rgb(87, 89, 98)'}}>{this.state.selectedMenuDescription}</p>
                    <div style={{direction: R2L_LANGUAGES.includes(this.state.language) ? 'rtl' : 'ltr'}}>
                        {this.state.sectionList.length?this.state.sectionList.map(section =>
                            <div>
                                <div className='text-center' style={{color: 'rgb(87, 89, 98)'}}>
                                    <p className='mb-0' style={{fontWeight: 600, fontSize: '1.7rem'}}>
                                        {section.section_names[this.state.language]}</p>
                                    <p className='mb-0' style={{
                                        fontSize: '1rem',
                                        marginTop: -5
                                    }}>{section.section_descriptions[this.state.language]}</p>
                                </div>
                                <div className='mt-5'>
                                    {this.state.itemList.map(item =>
                                        section.id === item.section &&
                                        <Row className='mb-5'>
                                            <Col lg={2} md={3} sm={2} xs={2} className='align-self-center'>
                                                <img className='rounded-circle object-fit size-80 pull-right'
                                                    //  src={item.image ? IMAGE_RESIZE_URL + item.image : require('../preview/routes/assets/food.png')}
                                                     src={item.image ? image_manipulator + item.image.split('/').splice(3).join('/') : require('../preview/routes/assets/food.png')}

                                                />
                                            </Col>
                                            <Col lg={10} md={9} sm={10} xs={10} style={{color: 'rgb(87, 89, 98)'}}
                                                 className='pl-4'>
                                                <Row style={{marginBottom: 3}}>
                                                    <p className='mb-0'
                                                       style={{
                                                           fontSize: '1.1rem',
                                                           fontWeight: 600
                                                       }}>{item.item_names[this.state.language]}</p>
                                                </Row>
                                                <Row style={{marginBottom: 3}}>
                                                    <p className='mb-0'
                                                       style={{fontSize: '.9rem'}}>{ReactHtmlParser(item.item_descriptions[this.state.language])}</p>
                                                </Row>
                                                <Row style={{marginBottom: 3}}>
                                                    {item.warning.map(item =>
                                                        <Icon icon={item.warning.ingredient} size={25}
                                                              className={R2L_LANGUAGES.includes(this.state.language) ? 'ml-3' : 'mr-3'}
                                                              style={{marginBottom: 3}}/>
                                                    )}
                                                </Row>
                                                <Row>
                                                    {item.price.map(item =>
                                                        <div
                                                            className={R2L_LANGUAGES.includes(this.state.language) ? 'ml-4' : 'mr-4'}>
                                                            <b className={R2L_LANGUAGES.includes(this.state.language) ? 'mb-0 ml-1' : 'mb-0 mr-1'}
                                                               style={{fontSize: '.9rem',}}>{this.state.symbol + item.price}</b>
                                                            <span className='mb-0' style={{
                                                                fontSize: '.8rem',
                                                                fontWeight: 500
                                                            }}>{item.description[this.state.language]}</span>
                                                        </div>
                                                    )}
                                                </Row>
                                            </Col>
                                        </Row>
                                    )}
                                </div>
                            </div>
                        ):this.state.itemList.map(item =>

                            <Row className='mb-5'>
                                <Col lg={2} md={3} sm={2} xs={2} className='align-self-center'>
                                    <img className='rounded-circle object-fit size-80 pull-right'
                                        //  src={item.image ? IMAGE_RESIZE_URL + item.image : require('../preview/routes/assets/food.png')}
                                         src={item.image ? image_manipulator + item.image.split('/').splice(3).join('/') : require('../preview/routes/assets/food.png')}

                                    />
                                </Col>
                                <Col lg={10} md={9} sm={10} xs={10} style={{color: 'rgb(87, 89, 98)'}}
                                     className='pl-4'>
                                    <Row style={{marginBottom: 3}}>
                                        <p className='mb-0'
                                           style={{
                                               fontSize: '1.1rem',
                                               fontWeight: 600
                                           }}>{item.item_names[this.state.language]}</p>
                                    </Row>
                                    <Row style={{marginBottom: 3}}>
                                        <p className='mb-0'
                                           style={{fontSize: '.9rem'}}>{ReactHtmlParser(item.item_descriptions[this.state.language])}</p>
                                    </Row>
                                    <Row style={{marginBottom: 3}}>
                                        {item.warning.map(item =>
                                            <Icon icon={item.warning.ingredient} size={25}
                                                  className={R2L_LANGUAGES.includes(this.state.language) ? 'ml-3' : 'mr-3'}
                                                  style={{marginBottom: 3}}/>
                                        )}
                                    </Row>
                                    <Row>
                                        {item.price.map(item =>
                                            <div
                                                className={R2L_LANGUAGES.includes(this.state.language) ? 'ml-4' : 'mr-4'}>
                                                <b className={R2L_LANGUAGES.includes(this.state.language) ? 'mb-0 ml-1' : 'mb-0 mr-1'}
                                                   style={{fontSize: '.9rem',}}>{this.state.symbol + item.price}</b>
                                                <span className='mb-0' style={{
                                                    fontSize: '.8rem',
                                                    fontWeight: 500
                                                }}>{item.description[this.state.language]}</span>
                                            </div>
                                        )}
                                    </Row>
                                </Col>
                            </Row>
                        )}
                    </div>
                </div>
        );
    }
}

class PrintMenu extends React.Component {
    render() {
        return (
            <div className='container-fluid' style={{overflowY: 'scroll'}}>
                <div>
                    <ReactToPrint
                        trigger={() =>
                            <a style={{color: '#ffffff'}}>
                                <div
                                    style={{
                                        position: 'absolute',
                                        top: 20,
                                        right: 50,
                                        borderRadius: 2,
                                        backgroundColor: PRIMARY_GOLDEN,
                                        borderColor: PRIMARY_GOLDEN,
                                        color: '#fff',
                                        cursor: 'pointer',
                                        fontSize: 14,
                                        fontWeight: 400,
                                        paddingBottom: 7,
                                        paddingTop: 7,
                                        paddingRight: 20,
                                        paddingLeft: 20,
                                    }}>
                                    Print
                                </div>
                            </a>}
                        content={() => this.componentRef}
                    />
                </div>

                <ExportVenue
                    style={{width: '100%'}}
                    allMenu={this.props.allMenu}
                    selectedMenu={this.props.selectedMenu}
                    error={() => this.props.history.push('/')}
                    ref={el => (this.componentRef = el)}/>
            </div>
        );
    }
}

const mapStateToProps = ({menu}) => {
    return {
        allMenu: menu.menus,
        selectedMenu: menu.selectedMenu
    }
};
export default connect(mapStateToProps, {})(PrintMenu)
