import React from "react";
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import IntlMessages from "../util/IntlMessages";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import CircularProgress from "@material-ui/core/CircularProgress";
import {showAuthLoader,} from "actions/Auth";
import {performRequest} from "./services/apiHandler";
import * as Swal from "sweetalert2";
import {RESET_MAIL_INSTRUCTION} from "../constants/constant";
import {PRIMARY_GOLDEN} from "../constants/ThemeColors";

/** 05/08/2019
 * forgot password class
 */
class ForgotPassword extends React.Component {
    constructor() {
        super();
        this.state = {
            email: '',
            warningEmail: ''
        }
    }

    /** 07/08/2019
     * update changes in state
     * @param event
     */
    getChanges = (e) => {
        this.setState({[e.target.name]: e.target.value})
    };

    forgotPassword = (event) =>{
        event.preventDefault();
        performRequest('post', 'api/forgot-password/', {"email": this.state.email}, null, false)
            .then(response => {
                localStorage.removeItem('currentVenue');
                Swal.fire({
                    // title: 'Error!',
                    text: RESET_MAIL_INSTRUCTION,
                    type: 'info',
                    confirmButtonText: 'Ok'
                }).then(()=>{
                    this.props.history.push('/signin');
                })

            }).catch(error => {
                console.log(error, "error forgot password")
            this.setState({
                warningEmail: error.response.data.email
            });
        })
    };

    render() {
        const {showMessage, loader, alertMessage} = this.props;
        return (
            <div
                className="app-login-container d-flex justify-content-center align-items-center animated slideInUpTiny animation-duration-3">
                <div className="app-login-main-content">

                    <div className="app-logo-content d-flex align-items-center justify-content-center customGradient">
                        <Link className="logo-lg" to="/" title="Jambo">
                            <img style={{margin: 5}} src={require('./../../src/assets/images/logo (1).png')}
                                 alt="mymenu" width='250' height='200' title="MyMenu"/>
                        </Link>
                    </div>

                    <div className="app-login-content">
                        <div className="app-login-header mb-4">
                            <h1><IntlMessages id="appModule.resetPassword"/></h1>
                        </div>

                        <div className="app-login-form">
                            <form>
                                <fieldset>
                                    <TextField
                                        type="email"
                                        id="required"
                                        name={'email'}
                                        label={<IntlMessages id="common.email"/>}
                                        fullWidth
                                        onChange={(e) => this.getChanges(e)}
                                        defaultValue=""
                                        margin="normal"
                                        className="mt-0 mb-4"
                                    />
                                    <p style={{color:'red'}}>{this.state.warningEmail}</p>

                                    <div className="mb-3 d-flex align-items-center justify-content-between">
                                        <Button
                                            onClick={(event) => this.forgotPassword(event)}
                                            variant="raised" style={{backgroundColor: "#252525", color: PRIMARY_GOLDEN}}>
                                            <IntlMessages id="appModule.resetPassword"/>
                                        </Button>

                                    </div>
                                </fieldset>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
        )
    }
}

const mapStateToProps = ({auth, venue}) => {
    const {loader, alertMessage, showMessage, authUser} = auth;
    return {
        loader, alertMessage, showMessage, authUser,
        venueList: venue.venues
    }
};

export default connect(mapStateToProps, {showAuthLoader})(ForgotPassword);